import { InjectionToken } from "@angular/core";
import {
  IDdt,
  IDdtCarrier,
  IDdtCustomer,
  IDdtDestination,
  IDdtFilters,
  IDdtPaginatedList,
  IDdtSubmission
} from "../shared/interfaces/ddt";
import { Observable } from "rxjs";
import { IVehicle, IVehiclePaginatedList, IVehicleDamage } from "../../vehicles/shared/interfaces/vehicles.type";



export const DDT_SERVICE: InjectionToken<IDdtService> = new InjectionToken<IDdtService>("Ddt Service Implementation");

export interface IDdtService {
  searchCarrier(term: string): Observable<IDdtCarrier[]>;
  searchCarrierLicensePlates(term: string): Observable<string[]>;
  searchDestination(term: string): Observable<IDdtDestination[]>;
  searchCustomer(term: string): Observable<IDdtCustomer[]>;
  searchVin(term: string): Observable<IVehiclePaginatedList<IVehicle>>;
  submitDdt(ddt: IDdtSubmission): Observable<IDdt>;
  updateDdt(id: string, ddt: IDdtSubmission): Observable<IDdt>;
  fetchDdtList(filters: IDdtFilters): Observable<IDdtPaginatedList<IDdt>>;
  fetchDdt(id: string): Observable<IDdt>;
  fetchDdtSign(id: string): Promise<Response>;
  fetchDdtDamages(id: string): Observable<IVehicleDamage[]>;
}
