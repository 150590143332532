import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {PriceListUiComponentsModule} from '@nes/price-list-ui-components';
import {NesCommonComponentsModule} from '@nes/nes-common-components';
import {NesAngularConfigurationModule, ConfigurationProviders} from '@nes/angular-configuration';


import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule } from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';



import {NesInputAddressPrimaryComponent} from './billing/components/nes-input-address-primary/nes-input-address-primary.component';
import {NesInputAddressSecondaryComponent} from './billing/components/nes-input-address-secondary/nes-input-address-secondary.component';
import {NesInputEmailGroupComponent} from './billing/components/nes-input-email-group/nes-input-email-group.component';
import {NesInputFiscalCodeComponent} from './billing/components/nes-input-fiscal-code/nes-input-fiscal-code.component';
import {NesInputLegalRepresentativeComponent} from './billing/components/nes-input-legal-representative/nes-input-legal-representative.component';
import {NesInputOfficeSelectionComponent} from './billing/components/nes-input-office-selection/nes-input-office-selection.component';
import {NesInputPaymentExpireComponent} from './billing/components/nes-input-payment-expire/nes-input-payment-expire.component';
import {NesInputPaymentTypeComponent} from './billing/components/nes-input-payment-type/nes-input-payment-type.component';
import {NesInputPhoneComponent} from './billing/components/nes-input-phone/nes-input-phone.component';
import {NesInputPhoneGroupComponent} from './billing/components/nes-input-phone-group/nes-input-phone-group.component';
import {NesInputVatExemptionComponent} from './billing/components/nes-input-vat-exemption/nes-input-vat-exemption.component';
import {NesInputVatGroupComponent} from './billing/components/nes-input-vat-group/nes-input-vat-group.component';
import {NesListContactComponent} from './billing/components/nes-list-contact/nes-list-contact.component';

import {NesCounterBadgeComponent} from './layout/components/nes-counter-badge/nes-counter-badge.component';
import {NesDialogDeleteComponent} from './layout/components/nes-dialog-delete/nes-dialog-delete.component';
import {NesFooterComponent} from './layout/components/nes-footer/nes-footer.component';
import {NesHeaderAlertComponent} from './layout/components/nes-header-alert/nes-header-alert.component';
import {NesHeaderComponent} from './layout/components/nes-header/nes-header.component';
import {NesNavigationComponent} from './layout/components/nes-navigation/nes-navigation.component';
import {NesPortletActionsComponent} from './layout/components/nes-portlet-actions/nes-portlet-actions.component';
import {NesPortletFiltersComponent} from './layout/components/nes-portlet-filters/nes-portlet-filters.component';
import {NesPortletFormComponent} from './layout/components/nes-portlet-form/nes-portlet-form.component';
import {LocationsModule} from '@nes/location-service-components';
import { NesBlankPageComponent } from './layout/components/nes-blank-page/nes-blank-page.component';

@NgModule({
  declarations: [
    NesCounterBadgeComponent,
    NesDialogDeleteComponent,
    NesFooterComponent,
    NesHeaderAlertComponent,
    NesHeaderComponent,
    NesInputAddressPrimaryComponent,
    NesInputAddressSecondaryComponent,
    NesInputEmailGroupComponent,
    NesInputFiscalCodeComponent,
    NesInputLegalRepresentativeComponent,
    NesInputOfficeSelectionComponent,
    NesInputPaymentExpireComponent,
    NesInputPaymentTypeComponent,
    NesInputPhoneComponent,
    NesInputPhoneGroupComponent,
    NesInputVatExemptionComponent,
    NesInputVatGroupComponent,
    NesListContactComponent,
    NesNavigationComponent,
    NesPortletActionsComponent,
    NesPortletFiltersComponent,
    NesPortletFormComponent,
    NesBlankPageComponent
  ],
  exports: [
    NesCounterBadgeComponent,
    NesDialogDeleteComponent,
    NesFooterComponent,
    NesHeaderAlertComponent,
    NesHeaderComponent,
    NesInputAddressPrimaryComponent,
    NesInputAddressSecondaryComponent,
    NesInputEmailGroupComponent,
    NesInputFiscalCodeComponent,
    NesInputLegalRepresentativeComponent,
    NesInputOfficeSelectionComponent,
    NesInputPaymentExpireComponent,
    NesInputPaymentTypeComponent,
    NesInputPhoneComponent,
    NesInputPhoneGroupComponent,
    NesInputVatExemptionComponent,
    NesInputVatGroupComponent,
    NesListContactComponent,
    NesNavigationComponent,
    NesPortletActionsComponent,
    NesPortletFiltersComponent,
    NesPortletFormComponent,
    PriceListUiComponentsModule,
    NesCommonComponentsModule,
    // FileManagerModule,
    NgxMaterialTimepickerModule,
    MatPaginatorModule,
    LocationsModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    RouterModule,
    // FileManagerModule,
    NgxMaterialTimepickerModule,
    MatPaginatorModule,
    NesCommonComponentsModule,
    LocationsModule,
    NesAngularConfigurationModule.forRoot([
      ConfigurationProviders
    ]),
    PriceListUiComponentsModule
  ],
  entryComponents: [
    NesDialogDeleteComponent,
    NesListContactComponent
  ]
})
export class NesModule {
}
