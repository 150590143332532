import { Component, OnInit, Input, forwardRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { RegistrySubjectSelectionDialogService } from '../registry-subject-selection-dialog/registry-subject-selection-dialog.service';
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { RegistryService } from '../../services/registry.service';
import {filter} from "rxjs/operators";

const CUSTOM_VALUE_ACCESSOR: any = {
  provide : NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RegistrySubjectSelectionInputComponent),
  multi : true,
};

@Component({
  selector: 'registry-subject-selection-input',
  providers : [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './registry-subject-selection-input.component.html',
  styleUrls: ['./registry-subject-selection-input.component.scss']
})

export class RegistrySubjectSelectionInputComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() placeholder = '';
  @Input() defaultValue: string = null;
  @Input() requiredError: boolean = false;
  @Output() changed: EventEmitter<string> = new EventEmitter<string>();
  nameControl: FormControl = new FormControl('');
  private get disabled(): boolean{
      return this.nameControl.disabled;
  }
  private set disabled(newValue: boolean){
      !!newValue ? this.nameControl.disable() : this.nameControl.enable();
  }
  private onChange: Function;
  private onTouched: Function;

  private internalValue: string = null;

  public get inputClass(): {} {
    return { 'default': !!this.defaultValue && this.defaultValue === this.internalValue };
  }

  constructor(private registrySubjectSelectionDialogService: RegistrySubjectSelectionDialogService,
              private rService: RegistryService) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
    this.disabled = false;

    this.setNameValue("test");
  }

  get isRequired(): boolean {
    return !!this.internalValue;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
  }

  setNameValue(subject: any): void {
    if (subject.hasOwnProperty('name'))
      this.nameControl.setValue(subject.name);

    if (subject.hasOwnProperty('companyName'))
      this.nameControl.setValue(subject.companyName);
  }

  writeValue(value: string): void {
    this.internalValue = value;

    if(value === null){
      this.setNameValue({name: ''});
      return;
    }
    this.nameControl.setValue(value);
    // this.rService.retrieveRegistry(value).subscribe(response => {
    //   this.setNameValue(response);
    // });
  }

  registerOnChange(fn: any): void{
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setValue(value: string): void {
    this.writeValue(value);
    this.onChange(this.nameControl.value);
    this.changed.emit(!!value ? value : '');
    this.onTouched();
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get icon(): string {
    return !!this.nameControl.value ? 'mda-clear' : 'mda-search';
  }

  get isEnabled(): boolean {
    return !this.nameControl.disabled;
  }

  public inputAction(preserveValue: boolean = true): void {
    if (!this.isEnabled)
      return;

    if (preserveValue || !(this.nameControl.value !== '' && this.nameControl.value !== null)) {
      this.openDialogWindow();
    } else {
       this.reset();
    }
  }

  public openDialogWindow(): void {
    let dialogArgs = {
      title: 'Seleziona',
      breadcrumbs: [''],
      class: ''
    };

    this.registrySubjectSelectionDialogService
      .open(dialogArgs)
      .pipe(filter(result => !!result))
      .subscribe(
        (result) => {
          this.setValue(result.id);
          this.setNameValue(result);
        }
      );
  }

  private reset(): void {
    if (this.nameControl.value !== '' && this.nameControl.value !== null) {
      this.setValue(null);
    }
  }

}
