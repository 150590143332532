import { DossierConfiguration } from '../modules/dossiers/dossier.configuration';
import { PersonsRegistryConfiguration } from '../modules/registry/persons-registry.configuration';
import { BillingConfiguration } from '../modules/billing/billing.configuration';
import { VehiclesConfiguration } from '../modules/vehicles/vehicles.configuration';
import {AuthenticationConfiguration} from "../modules/authentication/authentication.configuration";

export class ApplicationConfiguration {

  personsRegistry: PersonsRegistryConfiguration;
  dossier: DossierConfiguration;
  vehicles: VehiclesConfiguration;
  billing: BillingConfiguration;
  authentication: AuthenticationConfiguration;

  /*
   TODO: refactor these settings in a manner that reflect application module composition
     (ie: places service configuration, accounting service configuration...)
     like it was done for the above personsRegistry
   */
  api: {
    dictionary: {
      country: string;
      internationalPrefix: string;
      paymentExpire: string;
      paymentType: string;
      province: string;
      vatExemption: string;
    };
  };

  alfresco: {
    externalUrl: string;
  };

}
