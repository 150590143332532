import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CustomerSelectionDialogComponent } from './customer-selection-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class CustomerSelectionDialogService {

    constructor(
        private dialog: MatDialog
    ) { }

    public open(
        args?: {
            title?: string;
            bradcrumbs?: string[];
            dialogClass?: string;
        }
        ): Observable<any> {

            let dialogArgs = !!args ? {
                title: args.title || null,
                breadcrumbs: args.bradcrumbs || null
            } : null;

            return this
                .dialog
                .open(
                    CustomerSelectionDialogComponent,
                    {
                        data: dialogArgs,
                        panelClass: args.dialogClass || null,
                        autoFocus: false
                    }
                )
                .afterClosed();
    }
}
