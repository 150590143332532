<nes-portlet [loading]="loading" [withoutBorder]="true" [class]="'floating-window'" [title]="'Accedi'">
  <h1>Cambia password</h1>
  <form class="authentication-form" [formGroup]="form" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
    <h4
      *ngIf="submitted">
      {{message}}
    </h4>

    <mat-form-field class="example-full-width"
                    *ngIf="!submitted">
      <mat-label>Password</mat-label>
      <input type="password" matInput formControlName="password"
             placeholder="">
      <mat-error *ngIf="form.controls['password'].hasError('required')">
        Password  <strong>richesta</strong>
      </mat-error>
      <mat-error *ngIf="form.controls['password'].hasError('minLength')">
        Lunghezza minima del password è di <strong>6</strong> caratteri
      </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width"
                    *ngIf="!submitted">
      <mat-label>Conferma password</mat-label>
      <input type="password" matInput formControlName="confirmPassword"
             placeholder="">
      <mat-error *ngIf="form.controls['password'].hasError('passwordMatch')">
        Le password non coincidono
      </mat-error>
    </mat-form-field>

    <div class="form-footer">
      <button
        [disableRipple]="true"
        type="button"
        class="flex_center "
        color="secondary"
        mat-button
        (click)="onBack()"
      >
        Indietro
      </button>
      <button
        [disableRipple]="true"
        type="button"
        class="flex_center "
        color="accent"
        mat-button
        *ngIf="!submitted"
        (click)="onSubmit()"
      >
        Invia
      </button>
    </div>
  </form>
</nes-portlet>
