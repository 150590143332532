import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from '../../../vehicles/services/vehicle-details-service.service.interface';
import { Subscription } from 'rxjs';
import { IOutgoingVehicle, IVehicleFilters } from '../../../vehicles/shared/interfaces/vehicles.type';
import { ILocalDataStorageService } from "../../../../shared/services/local-data-storage.service.interface";
import { LOCAL_DATA_STORAGE_SERVICE } from "../../../../shared/services/local-data-storage.service.interface";



@Component({
  selector: 'app-outgoing-vehicles',
  templateUrl: './outgoing-vehicles.component.html',
  styleUrls: ['./outgoing-vehicles.component.scss']
})
export class OutgoingVehiclesComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) matSort: MatSort;



  public loading = false;
  public requestChainRunning = false;
  public firstLoad = true;
  public shouldUpdate = false;
  public recoverFilters = false;
  public tableFilter: any;
  public vehicleFilter: IVehicleFilters;
  public destinationCode = '';
  public vehicles: IOutgoingVehicle[];
  public vehicleBrandsAndModels: any;
  public totalVehicles: number = 0;
  public listType: string = "paged";

  private filtersKey = 'vehicles-list-filters';
  private subscriptions: Subscription[] = [];



  constructor(
    @Inject(VEHICLE_DETAILS_SERVICE) private service: IVehicleDetailsService,
    @Inject(LOCAL_DATA_STORAGE_SERVICE) private localStorageService: ILocalDataStorageService) {
  }



  ngOnInit(): void {
    this.recoverFilters = this.localStorageService.shouldUseLocalData(this.filtersKey);
    this.localStorageService.setUseLocalData(this.filtersKey, false);
    this.vehicleFilter = Object.assign({}, { pageNumber: 0, pageSize: 10 });
    this.fetchVehicleList();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }



  get vehicleDetails(): string[] {
    return ['label', 'manufacturer-and-model', 'color', 'sector-and-lane', 'order-sector-and-lane'];
  }



  public getLicencePlateAndVinCombination(vehicle: IOutgoingVehicle): string {
    const parts = [];
    if (vehicle.vin) parts.push(vehicle.vin);
    if (vehicle.licensePlate) parts.push(vehicle.licensePlate);
    return parts.join(' - ');
  }

  public getSectorAndLaneCombination(vehicle: IOutgoingVehicle): string {
    const parts = [];
    if (vehicle.sectorLabel) parts.push(vehicle.sectorLabel);
    if (vehicle.laneNumber) parts.push(vehicle.laneNumber);
    return parts.join(' - ');
  }

  public getOrderSectorAndLaneCombination(vehicle: IOutgoingVehicle): string {
    const parts = [];
    if (vehicle.orderSectorLabel) parts.push(vehicle.orderSectorLabel);
    if (vehicle.orderLaneNumber) parts.push(vehicle.orderLaneNumber);
    return parts.join(' - ');
  }

  public getManufacturerAndModelCombination(vehicle: IOutgoingVehicle): string {
    const parts = [];
    if (vehicle.manufacturer) parts.push(vehicle.manufacturer);
    if (vehicle.model) parts.push(vehicle.model);
    return parts.join(' ');
  }

  public onPaginationChange(e): void {
    let page = this.vehicleFilter.pageSize !== e.pageSize ? 0 : e.pageIndex;

    Object.assign(this.vehicleFilter, {
      text: this.vehicleFilter.text,
      pageNumber: page,
      pageSize: e.pageSize
    });
    this.fetchVehicleList();
  }

  public onListTypeChange(): void {  
    this.fetchVehicleList();
  }

  public print() {
    setTimeout(function () {
      window.print();
    }, 500);
  }



  private checkQueuedRequests(): boolean {
    if (this.requestChainRunning) {
      this.shouldUpdate = true;
    }

    return this.requestChainRunning;
  }

  private fetchVehicleList(): void {
    if (this.checkQueuedRequests()) return;
    this.loading = true;
    this.requestChainRunning = true;
    let interruptLoading = true;
    if (this.listType == "paged") {
      this.subscriptions.push(
        this.service
          .getOutgoingVehiclesPage(Object.assign({}, this.vehicleFilter))
          .subscribe(response => {
            if (!!this.shouldUpdate) {
              this.shouldUpdate = false;
              this.requestChainRunning = false;
              this.fetchVehicleList();
              interruptLoading = false;
              return;
            }
            else {
              this.vehicles = response.rows;
              this.totalVehicles = response.totalRows;
              this.shouldUpdate = false;
            }
            this.firstLoad = false;
          },
            error => { console.log(error) },
            () => {
              if (interruptLoading) this.loading = false;
              this.requestChainRunning = false;
            }
          ));
      }
    else {
      this.subscriptions.push(
        this.service
          .getOutgoingVehiclesList()
          .subscribe(response => {
            if (!!this.shouldUpdate) {
              this.shouldUpdate = false;
              this.requestChainRunning = false;
              this.fetchVehicleList();
              interruptLoading = false;
              return;
            }
            else {
              this.vehicles = response;
              this.shouldUpdate = false;
            }
            this.firstLoad = false;
          },
            error => { console.log(error) },
            () => {
              if (interruptLoading) this.loading = false;
              this.requestChainRunning = false;
            }
          ));
      }
  }
}
