import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vehicle-vin-taken-dialog',
  templateUrl: './vehicle-vin-taken-dialog.component.html',
  styleUrls: ['./vehicle-vin-taken-dialog.component.scss']
})
export class VehicleVinTakenDialogComponent implements OnInit {

  passedData = null;

  constructor(
    private router: Router, 
    public dialogRef: MatDialogRef<VehicleVinTakenDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.passedData = data;
  }

  ngOnInit(): void {
  }

  redirectToVehicle(): void{
    this.router.navigate(['/veicoli/dettaglio/', this.passedData.vehicleId.toString()]);
    this.onClose();
  }

  onClose(): void{
    this.dialogRef.close();
  }
}
