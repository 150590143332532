import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IReportRequest } from '../../shared/interfaces/billing.type';
import { IBillingService, BILLING_SERVICE } from '../../shared/services/billing.service.interface';
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from '../../../vehicles/services/vehicle-details-service.service.interface';
import { ISearchResult } from '../../../vehicles/shared/interfaces/vehicles.type';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment from 'moment';
import { Moment } from "moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { MM_YYYY_FORMAT } from "../../shared/interfaces/date-formats";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";



@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MM_YYYY_FORMAT },
  ],
})
export class BillingComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    date: new FormControl(moment())
  });

  public today = new Date();

  public contractFormControl: FormControl = new FormControl(null);
  public contractOptions;
  public contractId;

  private dateParts = {
    month: null,
    year: null
  };



  public constructor(
    @Inject(BILLING_SERVICE) private billingService: IBillingService,
    @Inject(VEHICLE_DETAILS_SERVICE) private vehicleService: IVehicleDetailsService) { }

  public ngOnInit(): void {
    this.initDate();
  }

  public ngAfterViewInit() {
    this.initContractControl();
  }

  public chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.form.get("date").value;
    ctrlValue.year(normalizedYear.year());
    this.form.get("date").setValue(ctrlValue);
    this.dateParts.year = normalizedYear.year();
  }

  public chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.form.get("date").value;
    ctrlValue.month(normalizedMonth.month());
    this.form.get("date").setValue(ctrlValue);
    this.dateParts.month = normalizedMonth.month();
    datepicker.close();
  }

  public updateContractAutocompleteResults(term: string): void {
    if (!term) {
      this.contractId = null;
      return;
    }
    this.vehicleService.searchContract(term).subscribe((options) => this.contractOptions = options.map((option) => {
      return {
        id: option.id,
        name: option.name.split("\n")[0].trim(),
      } as ISearchResult;
    }));
  }

  public handleContractAutocomplete(event): void {
    const foundValue = this.contractOptions.find((value) => value.name === event.option.value);
    this.contractFormControl.setValue(event.option.value);
    if (foundValue) {
      this.contractId = foundValue.id;
    }
  }

  public async requestBillingReport(): Promise<void> {
    if (!!this.form.valid) {
      const formData = this.form.getRawValue();
      const request: IReportRequest = {
        date: {
          year: formData.date.year(),
          month: formData.date.month() + 1
        }
      }
      request.contractId = this.contractId;

      this.billingService.requestDownload(request).subscribe((response) => {
      });
    } else {
      this.markFormGroupTouched(this.form);
    }
  }

  public get isSubmitDisabled(): boolean {
    const formData = this.form.getRawValue();
    return !formData.date.year() || !formData.date.month() || !this.contractId;
  };



  private initDate() {
    const date = moment(new Date()).subtract(1, 'months').startOf('month');
    this.dateParts.month = date.month();
    this.dateParts.year = date.year();
    this.form.get('date').setValue(date);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  private initContractControl(): void {
    this.contractFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        if (!res) {
          this.contractId = null;
        } else {
          this.contractId = this.filterAgainstResults(this.contractOptions, res, this.contractId);
        }
        this.updateContractAutocompleteResults(res);
      });
  }

  private filterAgainstResults(options: any, term: string, value: string): string {
    let result = null;
    if (!options) return value;
    options.forEach(
      (option) => {
        if (option.name === term) result = value;
      })
    return result;
  }
}
