import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {forkJoin, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {
  CompanyDTORequest,
  CompanyDTOResponse, EmailAddressDTOResponse,
  IndividualDTORequest,
  IndividualDTOResponse,
  OfficeDTORequest,
  OfficeDTOResponse,
  PagedOfficeDTOResponse,
  PagedRepresentativeDTOResponse,
  PagedSubjectListDTOResponse, PhoneNumberDTOResponse,
  PublicAdministrationDTORequest,
  PublicAdministrationDTOResponse,
  RegistryAddressFilter,
  RegistryContactFilter,
  RegistryFilter,
  RegistryType,
  RepresentativeDTORequest,
  RepresentativeDTOResponse, RepresentativeExtendedDTOResponse
} from '../interfaces/registry.type';

import {Utils} from '../classes/utils';
import {ApplicationConfiguration} from '../../../../configuration/application.configuration';
import {PersonsRegistryConfiguration} from "../../persons-registry.configuration";

@Injectable({
  providedIn: 'root'
})
export class RegistryService {

  constructor(private http: HttpClient, private personsRegistryConfiguration: PersonsRegistryConfiguration) {}

  // --

  public fetchRegistry(filter: RegistryFilter): Observable<PagedSubjectListDTOResponse> {
    const params = this.prepareRegistryFilter(filter);

    return this
      .http
      .get<PagedSubjectListDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects',
        { params: new HttpParams({ fromObject: params }) }
      );
  }

  public retrieveRegistry(id: string): Observable<CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse> {
    return this
      .http
      .get<
        CompanyDTOResponse |
        IndividualDTOResponse |
        PublicAdministrationDTOResponse
      >(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + id
      );
  }

  public storeRegistry(body: CompanyDTORequest | IndividualDTORequest | PublicAdministrationDTORequest): Observable<CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse> {
    return this
      .http
      .post<
        CompanyDTOResponse |
        IndividualDTOResponse |
        PublicAdministrationDTOResponse
      >(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/',
        this.reduceObject(body)
      );
  }

  public updateRegistry(body: CompanyDTORequest | IndividualDTORequest | PublicAdministrationDTORequest, id: number): Observable<CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse> {
    return this
      .http
      .put<
        CompanyDTOResponse |
        IndividualDTOResponse |
        PublicAdministrationDTOResponse
      >(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + id,
        this.reduceObject(Object.assign(body, {id}))
      );
  }

  // --

  public fetchAddress(filter: RegistryAddressFilter): Observable<PagedOfficeDTOResponse> {
    const params = this.prepareRegistryFilter(filter);

    return this
      .http
      .get<PagedOfficeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Offices',
        { params: new HttpParams({ fromObject: params }) }
      );
  }

  public storeAddress(filter: RegistryAddressFilter, body: OfficeDTORequest): Observable<OfficeDTOResponse> {
    return this
      .http
      .post<OfficeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Offices',
        this.reduceObject(body)
      );
  }

  public updateAddress(filter: RegistryAddressFilter, body: OfficeDTORequest, id: number): Observable<OfficeDTOResponse> {
    return this
      .http
      .put<OfficeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Offices/' + id,
        this.reduceObject(Object.assign(body, {id}))
      );
  }

  public deleteAddress(filter: RegistryAddressFilter, id: number): Observable<OfficeDTOResponse> {
    return this
      .http
      .delete<OfficeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Office/' + id
      );
  }

  // --

  public fetchContact(filter: RegistryContactFilter): Observable<PagedRepresentativeDTOResponse> {

    const params = this.prepareRegistryFilter(filter);

    return this
      .http
      .get<PagedRepresentativeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Representative',
        { params: new HttpParams({ fromObject: params }) }
      )
      .pipe(
        map(r => {
          r.rows.map((e: RepresentativeExtendedDTOResponse) => {
            const id = e.subject.id;

            e.faxNumbers = [];
            e.phoneNumbersLandline = [];
            e.emailAddresses = [];

            forkJoin(
                this.retrieveRegistry(id),
                this.fetchAddress({id: id.toString()})
              )
              .subscribe(([ subject, address]) => {

                // subject -- headquarter
                const headquarterPhone = (k: PhoneNumberDTOResponse) => Object.assign({}, {key: 'Sede principale', value: k.number});
                const headquarterEmail = (k: EmailAddressDTOResponse) => Object.assign({}, {key: 'Sede principale', value: k.email});
                const subjectType = Utils.identifyByTypeNumber(subject.type);

                if (subjectType === RegistryType.company) {
                  (subject as CompanyDTOResponse).headquarter.faxNumbers.map(headquarterPhone).forEach((a: any) => {e.faxNumbers.push(a); });
                  (subject as CompanyDTOResponse).headquarter.phoneNumbersLandline.map(headquarterPhone).forEach((a: any) => {e.phoneNumbersLandline.push(a); });
                  (subject as CompanyDTOResponse).headquarter.emailAddresses.map(headquarterEmail).forEach((a: any) => {e.emailAddresses.push(a); });
                }

                if (subjectType === RegistryType.individual) {
                  (subject as IndividualDTOResponse).residence.faxNumbers.map(headquarterPhone).forEach((a: any) => {e.faxNumbers.push(a); });
                  (subject as IndividualDTOResponse).residence.phoneNumbersLandline.map(headquarterPhone).forEach((a: any) => {e.phoneNumbersLandline.push(a); });
                  (subject as IndividualDTOResponse).residence.emailAddresses.map(headquarterEmail).forEach((a: any) => {e.emailAddresses.push(a); });
                }

                if (subjectType === RegistryType.publicCompany) {
                  (subject as PublicAdministrationDTOResponse).headquarter.faxNumbers.map(headquarterPhone).forEach((a: any) => {e.faxNumbers.push(a); });
                  (subject as PublicAdministrationDTOResponse).headquarter.phoneNumbersLandline.map(headquarterPhone).forEach((a: any) => {e.phoneNumbersLandline.push(a); });
                  (subject as PublicAdministrationDTOResponse).headquarter.emailAddresses.map(headquarterEmail).forEach((a: any) => {e.emailAddresses.push(a); });
                }

                // subject -- addresses
                address.rows.forEach((o: OfficeDTOResponse) => {
                  o.faxNumbers.map((k: PhoneNumberDTOResponse) => Object.assign({}, {key: o.name, value: k.number})).forEach((a: any) => {e.faxNumbers.push(a); });
                  o.phoneNumbersLandline.map((k: PhoneNumberDTOResponse) => Object.assign({}, {key: o.name, value: k.number})).forEach((a: any) => {e.phoneNumbersLandline.push(a); });
                  o.emailAddresses.map((k: EmailAddressDTOResponse) => Object.assign({}, {key: o.name, value: k.email})).forEach((a: any) => {e.emailAddresses.push(a); });
                });

              });

          });

        return r;

      })
    );

  }

  public storeContact(filter: RegistryContactFilter, body: RepresentativeDTORequest): Observable<RepresentativeDTOResponse> {
    return this
      .http
      .post<RepresentativeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Representative',
        this.reduceObject(body)
      );
  }

  public updateContact(filter: RegistryContactFilter, body: RepresentativeDTORequest, id: number): Observable<RepresentativeDTOResponse> {
    return this
      .http
      .put<RepresentativeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Representative/' + id,
        this.reduceObject(Object.assign(body, {id}))
      );
  }

  public deleteContact(filter: RegistryContactFilter, id: number): Observable<RepresentativeDTOResponse> {
    return this
      .http
      .delete<RepresentativeDTOResponse>(
        this.personsRegistryConfiguration.service.baseUrl + 'api/Subjects/' + filter.id + '/Representative/' + id
      );
  }

  // --

  private prepareRegistryFilter(filter: RegistryFilter): any {
    const params: any = {};

    if (filter.text) {
      params.Search = filter.text.toString();
    }

    if (filter.sort) {
      params.SortField = filter.sort.toString();
    }

    if (filter.direction) {
      params.SortDirection = filter.direction.toString();
    }

    if (filter.pageNumber) {
      params.PageNumber = filter.pageNumber.toString();
    }

    if (filter.pageSize) {
      params.PageSize = filter.pageSize.toString();
    }

    if (filter.fiscal) {
      params.FiscalCode = filter.fiscal.toString();
    }

    if (filter.company) {
      params.Company = true;
    }

    if (filter.individual) {
      params.Individual = true;
    }

    if (filter.publicAdministration) {
      params.PublicAdministration = true;
    }

    return params;
  }

  private reduceObject(b: any): any {
    if (Array.isArray(b)) {

      return b
        .map(v => v && typeof v === 'object' ? this.reduceObject(v) : v)
        .reduce((a, v) => (v ? [...a, v] : a), []);

    }

    return Object.entries(b)
      .map(([k, v]) => [k, v && typeof v === 'object' ? this.reduceObject(v) : v])
      .reduce((a, [k, v]) => ((v == null || v === '') ? a : {...a, [k]: v}), {});
  }

}
