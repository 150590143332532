import { Component, OnInit, Inject, ViewChildren, QueryList, Input, OnDestroy } from '@angular/core';
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from 'src/app/modules/vehicles/services/vehicle-details-service.service.interface';
import { Guid } from 'guid-typescript';
import { VehicleDialogsService } from 'src/app/modules/vehicles/dialogs/vehicle-dialogs.service';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { IVehicleDTO } from 'src/app/modules/vehicles/shared/interfaces/vehicles.type';


interface IDossierVehicleFilters {
  page: number,
  pageSize: number
}
@Component({
  selector: 'dossier-details-vehicles',
  templateUrl: './dossier-details-vehicles.component.html',
  styleUrls: ['./dossier-details-vehicles.component.scss']
})
export class DossierDetailsVehiclesComponent implements OnInit, OnDestroy {

  @ViewChildren(MatTable) matTables : QueryList<MatTable<any>>;
  @Input()  set data(newValue:IVehicleDTO[]){
              this.vehicleList = newValue || [];
              this.generatePaginatedVehicleList()
              if(!!this.matTables) this.matTables.toArray().forEach(each => each.renderRows());
            }
  @Input() editable: boolean = true;
  @Input() dType: string = '';

  vehicleFilters: IDossierVehicleFilters = {
    page: 0,
    pageSize: 10
  }
  vehicleList: IVehicleDTO[] = [];
  paginatedVehicleList: IVehicleDTO[] = [];
  selectedVehicles: Guid[] = [];
  private subscriptions: Subscription[] = [];

  get hasIndeterminateSelection(): boolean {
    if(this.vehicleList.length === 0) return false;

    return this.selectedVehicles.length > 0 && (this.vehicleList.length !== this.selectedVehicles.length);
  }

  get hasSelection(): boolean {
    if(this.vehicleList.length === 0) return false;

    return this.selectedVehicles.length > 0;
  }

  get hasFullSelection(): boolean{
    if(this.vehicleList.length === 0) return false;

    return this.vehicleList.length === this.selectedVehicles.length;
  }

  get vehicleDetails(): string[] {
    if(!this.editable) return ['licensePlate', 'vin'];
    return ['checkbox', 'licensePlate', 'vin'];
  }

  constructor(
    @Inject(VEHICLE_DETAILS_SERVICE) public vService: IVehicleDetailsService,
    private vDialogService: VehicleDialogsService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
  }

  public toggleVehicleSelection(vehicleId: Guid){
    let foundIndex = this.selectedVehicles.findIndex(selectedId => selectedId.equals(vehicleId));
    if(foundIndex < 0){
      this.selectedVehicles.push(vehicleId);
    }else{
      this.selectedVehicles.splice(foundIndex, 1);
    }
  }

  public openSelectionDialog(): void{
    let args = {
      breadcrumbs: ['Pratiche', 'Veicoli'],
      title: 'Seleziona Veicolo',
      dType: this.dType
    }
    this.subscriptions.push(
      this.vDialogService.openVehicleSelectionDialog(args).subscribe(args => {
        if(!!args){
          if(args.action === 'select'){
            if(args.data.length > 0){

              this.addVehicles(args.data);
            }
          }else if(args.action === 'create'){

            this.openCreationDialog();
          }
        }
      })
    );
  }

  public openCreationDialog(): void {
    let args = {
      breadcrumbs: ['Pratiche', 'Veicoli'],
      title: 'Nuovo Veicolo'
    }
    this.subscriptions.push(
      this.vDialogService
      .openVehicleCreationDialog(args)
      .subscribe(args => {
        if(!!args){
          if(args.action === 'select'){

            this.openSelectionDialog();
          }else if(args.action === 'create'){
            if(args.data.length > 0){
              
              this.addVehicles(args.data);
            }
          }
        }
      })
    );
  }

  public addVehicles(vehicles: any) {
    vehicles.forEach(vehicle => {

      let foundId = this.vehicleList.findIndex(selectedVehicle => selectedVehicle.id.equals(vehicle.id));

      if(foundId < 0) this.vehicleList.push(vehicle);  
    });
    this.generatePaginatedVehicleList();
    this.matTables.toArray().forEach(each => each.renderRows());
  }

  public removeVehicles() {

    this.selectedVehicles.forEach(selectedVehicle => {
      
      let foundId = this.vehicleList.findIndex(vehicle => selectedVehicle.equals(vehicle.id));
      if(foundId >= 0) this.vehicleList.splice(foundId, 1);  
    });
    this.selectedVehicles = [];
    this.generatePaginatedVehicleList();
    this.matTables.toArray().forEach(each => each.renderRows());
  }

  public isVehicleSelected(id: Guid): boolean {
    return this.selectedVehicles.findIndex(selectedVehicleId => selectedVehicleId.equals(id)) !== -1;
  }

  public selectAllVehicles(): void {
    if(this.hasFullSelection){

      this.selectedVehicles = [];
    }else{

      this.selectedVehicles = [];
      this.vehicleList.forEach(vehicle => this.selectedVehicles.push(vehicle.id));
    }
  }

  public onPaginationChange(e): void {
    this.vehicleFilters.page = this.vehicleFilters.pageSize !== e.pageSize ? 0 : e.pageIndex;
    this.vehicleFilters.pageSize = e.pageSize;

    this.generatePaginatedVehicleList();
  }

  public generatePaginatedVehicleList(): void{
    this.paginatedVehicleList = this.vehicleList.slice(this.vehicleFilters.page * 10, this.vehicleFilters.page * this.vehicleFilters.pageSize + this.vehicleFilters.pageSize);
  }
  // --
}
