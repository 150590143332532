import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';



import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';

import {PayableActionSheetComponent} from './payable-action-sheet/payable-action-sheet.component';
import {ValidationAlertComponent} from './validation-alert/validation-alert.component';

@NgModule({
  declarations: [
    PayableActionSheetComponent,
    ValidationAlertComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatListModule
  ]
})
export class ComponentModule {
}
