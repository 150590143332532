import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NesBlankPageComponent } from "./shared/layout/components/nes-blank-page/nes-blank-page.component";
import { NesSidenavLayoutComponent } from './shared/layout/components/nes-sidenav-layout/nes-sidenav-layout.component';
import { NewDossierTypeSelectionDialogTriggerComponent } from './modules/dossiers/dossier-create/new-dossier-type-selection-dialog-trigger/new-dossier-type-selection-dialog-trigger.component';
import { DossierDdtListComponent } from "./modules/dossiers/dossier-ddt-list/dossier-ddt-list.component";
import { DossierDdtPrintComponent } from "./modules/dossiers/dossier-ddt-print/dossier-ddt-print.component";
import { AuthGuard } from "./modules/authentication/shared/auth.guard";
import { RolesGuard } from "./modules/authentication/shared/roles.guard";
import { UserRoles } from "./modules/authentication/shared/interfaces/user-roles";



const routes: Routes = [
  {
    path: '',
    redirectTo: '/mappa',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    canActivate: [AuthGuard],
    path: '',
    component: NesSidenavLayoutComponent,
    children: [
      {
        path: 'blank',
        component: NesBlankPageComponent
      },
      {
        path: 'mappa',
        canActivate: [RolesGuard],
        data: { roles: [UserRoles.Read] },
        loadChildren: () => import('./modules/service-area-mapping/service-area-mapping.module').then(m => m.ServiceAreaMappingModule)
      },
      {
        path: 'veicoli',
        loadChildren: () => import('./modules/vehicles/vehicles.module').then(m => m.VehiclesModule)
      },
      {
        path: 'ordini-di-uscita',
        loadChildren: () => import('./modules/work-orders/work-orders.module').then(m => m.WorkOrdersModule)
      },
      {
        path: 'ddt',
        canActivate: [RolesGuard],
        data: { roles: [UserRoles.Read] },
        component: DossierDdtListComponent,
      },
      {
        path: 'ddt/dettaglio/:id',
        canActivate: [RolesGuard],
        data: { roles: [UserRoles.Read] },
        component: DossierDdtPrintComponent,
      },
      {
        path: 'ddt/nuovo',
        canActivate: [RolesGuard],
        data: { roles: [UserRoles.Write] },
        component: DossierDdtPrintComponent,
      },
      {
        path: 'anagrafica',
        canActivate: [RolesGuard],
        data: { roles: [UserRoles.Read] },
        loadChildren: () => import('./modules/registry/registry.module').then(m => m.RegistryModule)
      },
      {
        path: 'rendicontazione',
        canActivate: [RolesGuard],
        data: { roles: [UserRoles.Billing] },
        loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule)
      }
    ]
  }
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
