<div class="nes-navigation">

  <ng-template ngFor let-nav let-i="index" [ngForOf]="navigation">
    <mat-expansion-panel *ngIf="nav.child" (opened)="expand(i)" [expanded]="currentExpanded === i">
      <mat-expansion-panel-header [collapsedHeight]="'38px'" [expandedHeight]="'38px'">
        <mat-panel-title>{{ nav.label }}</mat-panel-title>
      </mat-expansion-panel-header>

      <mat-nav-list [disableRipple]="true">
        <div *ngFor="let link of nav.child">
          <a mat-list-item *ngIf="isRoute(link)" [routerLink]="link.route" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" [disableRipple]="true">{{ link.label }}</a>
          <a mat-list-item *ngIf="isAction(link)" (click)="executeAction(link)" [disableRipple]="true">{{ link.label }}</a>
        </div>
        
      </mat-nav-list>

    </mat-expansion-panel>

    <mat-nav-list [disableRipple]="true" *ngIf="!nav.child">
      <a mat-list-item [routerLink]="nav.route" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}" (click)="expand(-1)" [disableRipple]="true">{{ nav.label }}</a>
    </mat-nav-list>
  </ng-template>

</div>
