<nes-portlet *ngIf="!formOnly" [breadcrumb]="['Home', 'Veicoli']" [title]="detailMode ? vehicleLabel : 'Nuovo veicolo'" [loading]="loading" [withoutBorder]="true" [tags]="detailMode ? tags : []">
  <nes-portlet-title [title]="'Informazioni generali'">
    <div class="flex-row">
      <button [disableRipple]="true" class="flex_center __print _spaced" color="accent" mat-button (click)="backAction()">Indietro</button>
      <button [disableRipple]="true" class="flex_center __print _spaced" color="accent" mat-button (click)="deleteVehicle()">Elimina</button>
      <button [disableRipple]="true" class="flex_center __print _spaced" color="accent" mat-button (click)="removeArrive()" *ngIf="isArrived()">Annulla ingresso</button>
      <button [disableRipple]="true" class="flex_center __print _spaced" color="accent" mat-button (click)="removeLocations()" *ngIf="hasLocation()">Rimuovi dal piazzale</button>
    </div>
  </nes-portlet-title>
  <ng-container *ngTemplateOutlet="vehicleForm"></ng-container>
</nes-portlet>

<ng-container *ngIf="!!formOnly" ><ng-container *ngTemplateOutlet="vehicleForm"></ng-container></ng-container>

<div class="nes-portlet-form-submit outside" *ngIf="!detailMode && !externalControls" >
  <button type="button" mat-button [disableRipple]="true" (click)="saveVehicle()" class="form-submit-button">
    <span class="label">Salva veicolo</span>
  </button>
</div>

<ng-template #vehicleForm>
  <nes-portlet-form>

    <form [formGroup]="vehicleDetailsForm" #vehicleDetailsGeneralForm autocomplete="off" class="vehicle_details_general_form" (ngSubmit)="saveVehicle()">
      <mat-error class="_independent _centered" *ngIf="null !== isAtLeastOneIdentifyingFieldPresent && !isAtLeastOneIdentifyingFieldPresent">Almeno uno dei campi <b>Telaio o Targa</b> deve essere compilato</mat-error>
      <div class="nes-portlet-form-wr">
        <div class="nes-portlet-form-col _two">
          <div class="_input_group">
            <mat-form-field class="fill-available">
              <input matInput placeholder="Targa" formControlName="licensePlate" (blur)="findAtLeastOneField()" (input)="findAtLeastOneField()">
              <mat-error *ngIf="vehicleDetailsForm.controls['licensePlate'].hasError('minlength')">La Targa inserita è troppo <b>corta</b></mat-error>
              <mat-error *ngIf="null !== isAtLeastOneIdentifyingFieldPresent && !isAtLeastOneIdentifyingFieldPresent">Almeno uno dei campi <b>Telaio o Targa</b> deve essere compilato</mat-error>
            </mat-form-field>
          </div>
          <div class="_input_group _multiple calendar_group">
            <div class="field-container _half">
              <mat-form-field class="fill-available">
                <input matInput placeholder="Stato" formControlName="status">
              </mat-form-field>
            </div>
            <div class="field-container _half">
              <mat-form-field [attr.input-status]="!vehicleDetailsForm.controls['creationDate'].disabled ? '' : 'DISABLED'" class="fill-available">
                <mat-label>Data di inserimento</mat-label>
                <input matInput [matDatepicker]="creationDatePicker" [min]="dateNow" formControlName="creationDate" (click)="creationDatePicker.open()" readonly >
                <mat-datepicker-toggle matSuffix *ngIf="!vehicleDetailsForm.controls['creationDate'].disabled" [for]="creationDatePicker">
                  <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #creationDatePicker disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="_input_group _multiple">
            <div class="field-container _half">
              <mat-form-field class="fill-available">
                <mat-label>Produttore</mat-label>
                <input matInput
                       formControlName="brand"
                       [matAutocomplete]="autocompleteBrand"
                       (blur)="vehicleDetailsForm.controls['brand'].updateValueAndValidity()"
                >
              </mat-form-field>
              <br>
              <mat-error *ngIf="isBrandFieldRequired && !isBrandFieldValid" class="standalone">Campo <b>obbligatorio</b></mat-error>
              <mat-error *ngIf="!isBrandFieldRequired && !isBrandFieldValid" class="standalone"><b>Valore</b> non valido</mat-error>
              <mat-autocomplete #autocompleteBrand="matAutocomplete" (optionSelected)="handleBrandAutocomplete($event)">
                <mat-option *ngFor="let option of brandOptions" [value]="option.name">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="field-container _half">
              <mat-form-field class="fill-available">
                <mat-label>Modello</mat-label>
                <input matInput
                       formControlName="model"
                       [matAutocomplete]="autocompleteModel"
                       (blur)="vehicleDetailsForm.controls['model'].updateValueAndValidity()"
                >
              </mat-form-field>
              <br>
              <mat-error *ngIf="isModelFieldRequired && !isModelFieldValid" class="standalone">Campo <b>obbligatorio</b></mat-error>
              <mat-error *ngIf="!isModelFieldRequired && !isModelFieldValid" class="standalone"><b>Valore</b> non valido</mat-error>
              <mat-autocomplete #autocompleteModel="matAutocomplete" (optionSelected)="handleModelAutocomplete($event)">
                <mat-option *ngFor="let option of modelOptions" [value]="option.name">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="_input_group">
            <mat-form-field class="fill-available">
              <input matInput placeholder="Colore" formControlName="color">
            </mat-form-field>
          </div>
          <div class="_input_group _auto-flex">
            <mat-form-field class="fill-available">
              <input matInput placeholder="Posizione attuale" formControlName="position">
            </mat-form-field>
            <button *ngIf="!!detailMode" [disableRipple]="true" type="button" class="flex_center __print _spaced push-left _input-complimentary" color="accent" mat-button (click)="openPositionsHistoryDialog()">
              <mat-icon class="_light">history</mat-icon>
              Visualizza storico
            </button>
          </div>
        </div>

        <div class="nes-portlet-form-col _two">
          <div class="_input_group">
            <mat-form-field>
              <input matInput placeholder="Telaio" formControlName="vin" (blur)="checkForVin()" (input)="findAtLeastOneField()">
              <mat-error *ngIf="vehicleDetailsForm.controls['vin'].hasError('isTaken')">Telaio <b>già registrato</b></mat-error>
              <mat-error *ngIf="vehicleDetailsForm.controls['vin'].hasError('minlength')">Il Telaio inserito è troppo <b>corto</b></mat-error>
              <mat-error *ngIf="vehicleDetailsForm.controls['vin'].hasError('maxlength')">Il Telaio inserito è troppo <b>lungo</b></mat-error>
            </mat-form-field>
          </div>
          <div class="_input_group _multiple">
            <div class="field-container _half _pull-bottom">
              <mat-form-field [attr.input-status]="!vehicleDetailsForm.controls['arrivedAt'].disabled ? '' : 'DISABLED'" class="fill-available">
                <mat-label>Data di arrivo</mat-label>
                <input matInput [matDatepicker]="arrivalDatePicker" [min]="dateNow" formControlName="arrivedAt" (click)="arrivalDatePicker.open()" readonly >
                <mat-datepicker-toggle matSuffix *ngIf="!vehicleDetailsForm.controls['arrivedAt'].disabled" [for]="arrivalDatePicker">
                  <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #arrivalDatePicker disabled="false"></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Mezzo di entrata</mat-label>
                <mat-select formControlName="arrivedBy" disabled>
                  <mat-option value="Truck">Bisarca</mat-option>
                  <mat-option value="Ship">Nave</mat-option>
                  <mat-option value="Rail">Treno</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="field-container _half _pull-bottom">
              <mat-form-field [attr.input-status]="!vehicleDetailsForm.controls['leftAt'].disabled ? '' : 'DISABLED'" class="fill-available">
                <mat-label>Data di uscita</mat-label>
                <input matInput [matDatepicker]="departureDatePicker" [min]="dateNow" formControlName="leftAt" (click)="departureDatePicker.open()" readonly >
                <mat-datepicker-toggle matSuffix *ngIf="!vehicleDetailsForm.controls['leftAt'].disabled" [for]="departureDatePicker">
                  <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #departureDatePicker disabled="false"></mat-datepicker>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Mezzo di uscita</mat-label>
                <mat-select formControlName="leftBy" disabled>
                  <mat-option value="Truck">Bisarca</mat-option>
                  <mat-option value="Ship">Nave</mat-option>
                  <mat-option value="Rail">Treno</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="_input_group">
            <mat-form-field class="full-width">
              <mat-label>Commitente</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="customer"
                [matAutocomplete]="autocompleteCustomer"
                (blur)="vehicleDetailsForm.controls['customer'].updateValueAndValidity()"
              >
            </mat-form-field>
            <mat-error *ngIf="isCustomerFieldRequired && !isCustomerFieldRequired" class="standalone">Campo <b>obbligatorio</b></mat-error>
            <mat-error *ngIf="!isCustomerFieldRequired && !isCustomerFieldValid" class="standalone"><b>Valore</b> non valido</mat-error>
            <mat-autocomplete #autocompleteCustomer="matAutocomplete" (optionSelected)="handleCustomerAutocomplete($event)">
              <mat-option *ngFor="let option of customerOptions" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="_input_group _auto-flex">
            <mat-form-field class="fill-available">
              <input matInput placeholder="Codice destinazione" formControlName="destinationCode">
            </mat-form-field>
            <button *ngIf="!!editingDestinationCode" type="button" class="flex_center __print _spaced push-left _input-complimentary" mat-button (click)="cancelDestinationCodeEdit()">
              Annulla
            </button>
            <button *ngIf="!!editingDestinationCode" [disableRipple]="true" type="button" class="flex_center __print _spaced push-left _input-complimentary" color="accent" mat-button (click)="saveDestinationCode()">
              Salva
            </button>
            <button *ngIf="!!detailMode && !editingDestinationCode" [disableRipple]="true" type="button" class="flex_center __print _spaced push-left _input-complimentary" color="accent" mat-button [disabled]="!RolesGuardHelper.canWrite()" (click)="setDestinationCodeEditable()">
              Modifica
            </button>
          </div>
          <div class="_input_group _auto-flex"
               *ngIf="ddtId">
            <mat-form-field class="fill-available">
              <input matInput placeholder="Documento di trasporto" formControlName="ddt">
            </mat-form-field>
            <button
              [disableRipple]="true"
              type="button"
              class="flex_center icon-btn push-left _input-complimentary "
              color="accent"
              mat-button
              *ngIf="ddtId"
              [routerLink]="['/ddt/dettaglio/' + ddtId]"
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </div>
          <div class="_input_group _auto-flex">
            <mat-form-field class="fill-available">
              <mat-label>Indirizzo destinazione</mat-label>
              <textarea matInput rows="2"  formControlName="destinationAddress"></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      </form>
  </nes-portlet-form>
</ng-template>
