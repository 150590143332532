import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrdersListComponent } from './views/work-orders-list/work-orders-list.component';
import { WorkOrdersDetailsComponent } from './views/work-orders-details/work-orders-details.component';
import { WorkOrdersRoutingModule } from "./work-orders-routing.module";
import { WORK_ORDERS_SERVICE } from "./shared/interfaces/work-orders.interface";
import { NesModule } from "../../shared/nes.module";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTableModule } from "@angular/material/table";
import { ServiceAreaMappingModule } from "../service-area-mapping/service-area-mapping.module";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { WorkOrdersService } from "./shared/services/work-orders.service";
import { VehiclesModule } from "../vehicles/vehicles.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OutgoingVehiclesComponent } from './views/outgoing-vehicles/outgoing-vehicles.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    WorkOrdersListComponent,
    WorkOrdersDetailsComponent,
    OutgoingVehiclesComponent
  ],
  imports: [
    CommonModule,
    WorkOrdersRoutingModule,
    NesModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTableModule,
    ServiceAreaMappingModule,
    VehiclesModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatTooltipModule
  ]
})
export class WorkOrdersModule {
  static forRoot(): ModuleWithProviders<WorkOrdersModule> {
    return {
      ngModule: WorkOrdersModule,
      providers: [
        {
          provide: WORK_ORDERS_SERVICE,
          useClass: WorkOrdersService
        }
      ]
    };
  }
}
