import { Injectable } from '@angular/core';

import { NavigationRoot, RouteNavigationItem, ActionNavigationItem, ANavigationItem } from '../models/navigation.type';
import { RolesGuardHelper } from "../../utils/roles-guard-helper";
import { UserRoles } from "../../../modules/authentication/shared/interfaces/user-roles";



@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  get roles(): UserRoles[] {
    return RolesGuardHelper.getUserRoles();
  }

  constructor() { }



  public getNavigation(): NavigationRoot[] {
    return [
      this.serviceAreaMappingNavigation(),
      this.vehiclesNavigation(),
      this.workOrdersNavigation(),
      this.ddtNavigation(),
      this.registryNavigation(),
      this.billingNavigation(),
      this.statisticsNavigation()
    ].filter((item) => {
      return !!item && (item.isParent ? item.child?.length > 0 : true)
    });
  }



  private serviceAreaMappingNavigation(): NavigationRoot {
    return this.filterByRoles({
      label: 'Mappa',
      route: '/mappa',
      roles: [UserRoles.Read]
    });
  }

  private vehiclesNavigation(): NavigationRoot {
    return this.filterByRoles({
      label: 'Veicoli',
      isParent: true,
      child: [
        new RouteNavigationItem(
          'Lista veicoli',
          '/veicoli',
          [UserRoles.Read]
        ),
        new RouteNavigationItem(
          'Nuovo veicolo',
          '/veicoli/nuovo',
          [UserRoles.Write]
        ),
        new RouteNavigationItem(
          'Import veicoli',
          '/veicoli/import',
          [UserRoles.Write]
        ),
        new RouteNavigationItem(
          'Import danni',
          '/veicoli/import-danni',
          [UserRoles.Write]
        ),
        new RouteNavigationItem(
          'Blocca veicoli',
          '/veicoli/blocchi',
          [UserRoles.Write]
        ),
        new RouteNavigationItem(
          'Olio veicoli',
          '/veicoli/report-olio',
          [UserRoles.Read]
        )
      ]
    });
  }

  private workOrdersNavigation(): NavigationRoot {
    return this.filterByRoles({
      label: 'Ordini di uscita',
      isParent: true,
      child: [
        new RouteNavigationItem(
          'Lista veicoli in uscita',
          '/ordini-di-uscita/veicoli-in-uscita',
          [UserRoles.Read]
        ),
        new RouteNavigationItem(
          'Lista ordini di uscita',
          '/ordini-di-uscita',
          [UserRoles.Read]
        ),
        new RouteNavigationItem(
          'Nuovo ordine di uscita',
          '/ordini-di-uscita/nuovo',
          [UserRoles.Write]
        ),
      ]
    });
  }

  private registryNavigation(): NavigationRoot {
    return this.filterByRoles({
      label: 'Anagrafiche',
      isParent: true,
      child: [
        new RouteNavigationItem(
          'Committenti',
          '/anagrafica/committenti'
        ),
        new RouteNavigationItem(
          'Contratti',
          '/anagrafica/contratti'
        ),
        new RouteNavigationItem(
          'Destinatari',
          '/anagrafica/destinatari'
        ),
        new RouteNavigationItem(
          'Trasportatori',
          '/anagrafica/trasportatori'
        ),
        new RouteNavigationItem(
          'Marche',
          '/anagrafica/marche'
        ),
        new RouteNavigationItem(
          'Modelli',
          '/anagrafica/modelli'
        ),
      ],
      roles: [UserRoles.Read]
    });
  }

  private ddtNavigation(): NavigationRoot {
    return this.filterByRoles({
      label: 'DDT',
      isParent: true,
      child: [
        new RouteNavigationItem(
          'Lista DDT',
          '/ddt/',
          [UserRoles.Read]
        ),
        new RouteNavigationItem(
          'Nuovo DDT',
          '/ddt/nuovo',
          [UserRoles.Write]
        )
      ]
    });
  }

  private billingNavigation(): NavigationRoot {
    return this.filterByRoles({
      label: 'Rendicontazione',
      route: '/rendicontazione',
      roles: [UserRoles.Billing]
    });
  }

  private statisticsNavigation(): NavigationRoot {
    return this.filterByRoles({
      label: 'Statistiche',
      route: '/rendicontazione/statistiche',
      roles: [UserRoles.Billing]
    });
  }



  private filterByRoles(item: NavigationRoot): NavigationRoot {
    let unauthorized: boolean = false;
    if (item.roles?.length > 0) {
      unauthorized = !!item.roles.find((role) => !this.roles.includes(role));
    }
    if (unauthorized) {
      return null;
    }

    if (!!item.child) {
      const finalChildrenList: Array<ANavigationItem> = [];
      item.child?.forEach((child) => {
        const filteredChild = this.filterChildByRoles(child);
        if (!!filteredChild) finalChildrenList.push(filteredChild);
      });

      item.child = finalChildrenList;
    }

    return item;
  }

  private filterChildByRoles(item: ANavigationItem): ANavigationItem {
    let unauthorized: boolean = false;
    if (item.roles?.length > 0) {
      unauthorized = !!item.roles.find((role) => !this.roles.includes(role));
    }
    if (unauthorized) {
      return null;
    }
    return item;
  }
}
