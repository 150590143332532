import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ComponentModule} from '../../common/components/component.module';
import {PayableActionSheetComponent} from '../../common/components/payable-action-sheet/payable-action-sheet.component';

import {DossiersRoutingModule} from './dossiers-routing.module';

import {NesModule} from '../../shared/nes.module';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DossierListComponent } from './dossier-list/dossier-list.component';
import { DossierCreateComponent } from './dossier-create/dossier-create.component';
import { DossierDetailsComponent } from './dossier-details/dossier-details.component';
import { DossierDetailsGeneralComponent } from './dossier-details/dossier-details-general/dossier-details-general.component';
import { DossierTypeSelectionDialogComponent } from './dialogs/dossier-type-selection-dialog/dossier-type-selection-dialog.component';
import { DOSSIERS_SERVICE } from './services/dossiers-service.service.interface';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatInputModule } from '@angular/material/input';
import { DossierMovementSourceAndDestinationControlsComponent } from './shared/components/dossier-movement-source-and-destination-controls/dossier-movement-source-and-destination-controls.component';
import { ServiceAreaMappingModule } from '../service-area-mapping/service-area-mapping.module';
import { RegistryModule } from '../registry/registry.module';
import { NewDossierTypeSelectionDialogTriggerComponent } from './dossier-create/new-dossier-type-selection-dialog-trigger/new-dossier-type-selection-dialog-trigger.component';
import { MatChipsModule } from '@angular/material/chips';
import { DossierDetailsVehiclesComponent } from './dossier-details/dossier-details-vehicles/dossier-details-vehicles.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { VehiclesModule } from '../vehicles/vehicles.module';
import { DossierDetailsBillingComponent } from './dossier-details/dossier-details-billing/dossier-details-billing.component';
import { DossierService } from './services/dossier.service';
import { DossierDetailsActivitiesComponent } from './dossier-details/dossier-details-activities/dossier-details-activities.component';
import { DossierDdtPrintComponent } from './dossier-ddt-print/dossier-ddt-print.component';
import {MatRadioModule} from "@angular/material/radio";
import {DDT_SERVICE} from "./services/ddt.service.interface";
import {DdtService} from "./services/ddt.service";
import {DossierDdtListComponent} from "./dossier-ddt-list/dossier-ddt-list.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {VEHICLE_DETAILS_SERVICE} from "../vehicles/services/vehicle-details-service.service.interface";
import {VehicleDetailsService} from "../vehicles/services/vehicle-details.service";
import {LOCAL_DATA_STORAGE_SERVICE} from "../../shared/services/local-data-storage.service.interface";
import {LocalDataStorageService} from "../../shared/services/local-data-storage.service";

@NgModule({
  declarations: [
    DossierListComponent,
    DossierDdtListComponent,
    DossierCreateComponent,
    DossierDetailsComponent,
    DossierDetailsGeneralComponent,
    DossierTypeSelectionDialogComponent,
    DossierMovementSourceAndDestinationControlsComponent,
    NewDossierTypeSelectionDialogTriggerComponent,
    DossierDetailsVehiclesComponent,
    DossierDetailsBillingComponent,
    DossierDetailsActivitiesComponent,
    DossierDdtPrintComponent
  ],
    imports: [
        DossiersRoutingModule,
        CommonModule,
        ComponentModule,
        NesModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        NgxMaterialTimepickerModule,
        ServiceAreaMappingModule,
        RegistryModule,
        MatChipsModule,
        VehiclesModule,
        MatProgressSpinnerModule
    ],
  entryComponents: [
    PayableActionSheetComponent
  ]
})
export class DossiersModule {
  static forRoot(): ModuleWithProviders<DossiersModule> {
    return {
      ngModule: DossiersModule,
      providers: [
        {
          provide: DOSSIERS_SERVICE,
          useClass: DossierService
        },
        {
          provide: DDT_SERVICE,
          useClass: DdtService
        }
      ]
    };
  }
}
