import { Component, OnInit, Inject, ViewChildren, QueryList, Input, OnDestroy } from '@angular/core';
import { Guid } from 'guid-typescript';
import { MatTable } from '@angular/material/table';
import {Observable, Subscription} from 'rxjs';
import { DOSSIERS_SERVICE, IDossiersService } from '../../services/dossiers-service.service.interface';
import { IDossierActivity } from '../../shared/interfaces/dossier.type';

interface IDossierActivitiesFilters {
  page: number,
  pageSize: number
}

@Component({
  selector: 'dossier-details-activities',
  templateUrl: './dossier-details-activities.component.html',
  styleUrls: ['./dossier-details-activities.component.scss']
})
export class DossierDetailsActivitiesComponent implements OnInit, OnDestroy {


  @ViewChildren(MatTable) matTables : QueryList<MatTable<any>>;
  @Input()
  set data(newValue: Guid[]) {
    this.selectedActivities = newValue || [];
  }
  @Input() editable: boolean = true;

  activitiesList: IDossierActivity[] = [];
  selectedActivities: Guid[] = [];

  activities: Observable<IDossierActivity[]>;

  private subscriptions: Subscription[] = [];

  get hasIndeterminateSelection(): boolean {
    if(this.activitiesList.length === 0) return false;

    return this.selectedActivities.length > 0 && (this.activitiesList.length !== this.selectedActivities.length);
  }

  get hasSelection(): boolean {
    if(this.selectedActivities.length === 0) return false;

    return this.selectedActivities.length > 0;
  }

  get rowsToDisplay(): IDossierActivity[] {
    const rows = [];

    if(!!this.editable) return this.activitiesList;

    this.selectedActivities.forEach((sA) => {
      rows.push(this.activitiesList.find((a) => sA.equals(a.id))
      );
    });

    return rows;
  }

  get hasFullSelection(): boolean{
    if(this.selectedActivities.length === 0) return false;

    return this.selectedActivities.length === this.selectedActivities.length;
  }

  get activitiesDetails(): string[] {
    return ['checkbox', 'name'];
  }

  constructor(
    @Inject(DOSSIERS_SERVICE) public dService: IDossiersService,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.fetchActivities();
  }

  fetchActivities(): void {
    this.activities = this.dService.getDossierActivities();
  }

  public toggleActivitySelection(activityId: Guid){
    let foundIndex = this.selectedActivities.findIndex(selectedId => selectedId.equals(activityId));
    if(foundIndex < 0){
      this.selectedActivities.push(activityId);
    }else{
      this.selectedActivities.splice(foundIndex, 1);
    }
  }

  public isActivitySelected(id: Guid): boolean {
    return this.selectedActivities.findIndex(selectedVehicleId => selectedVehicleId.equals(id)) !== -1;
  }

  public selectAllActivities(): void {
    if(this.hasFullSelection){

      this.selectedActivities = [];
    }else{

      this.selectedActivities = [];
      this.activitiesList.forEach(activity => this.selectedActivities.push(activity.id));
    }
  }
}
