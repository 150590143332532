import {Component, Inject, Input, OnInit} from '@angular/core';
import {IVehicleMovement} from "../../shared/interfaces/vehicles.type";
import {Subscription} from "rxjs";
import {
  IVehicleDetailsService,
  VEHICLE_DETAILS_SERVICE
} from "../../services/vehicle-details-service.service.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-vehicle-positions-history-dialog',
  templateUrl: './vehicle-positions-history-dialog.component.html',
  styleUrls: ['./vehicle-positions-history-dialog.component.scss']
})
export class VehiclePositionsHistoryDialogComponent implements OnInit {

  private vehicleId: string;
  public loading = false;
  public firstLoad = true;
  movements: IVehicleMovement[];
  private subscriptions: Subscription[] = [];

  // --

  constructor(
    public dialogRef: MatDialogRef<VehiclePositionsHistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService) {
    this.vehicleId = data.vehicleId;
  }

  ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => {

      subscription.unsubscribe();
    })
  }

  ngOnInit(): void {
    if (this.vehicleId) this.fetchMovements();
  }

  get tableColumns(): string[] {
    return ['date', 'position'];
  }

  private fetchMovements() {
    this.loading = true;
    this.subscriptions.push(
      this.vService.getVehicleMovements(this.vehicleId).subscribe((response: IVehicleMovement[]) => {
        this.movements = response;
        this.loading = false;
        this.firstLoad = false;
      })
    )

    // this.movements = [
    //   {
    //     sector: 'A',
    //     lane: 1,
    //     date: new Date(),
    //   },
    //   {
    //     sector: 'A',
    //     lane: 2,
    //     date: new Date(),
    //
    //   },
    //   {
    //     sector: 'A',
    //     lane: 3,
    //     date: new Date(),
    //   }
    // ];
  }

  generatePositionString(movement: IVehicleMovement): string {
    const result = [];
    if (movement.sector) result.push(movement.sector);
    if (movement.lane) result.push(movement.lane);
    return result.join(' - ');
  }

  public close(): void{
    this.dialogRef.close();
  }
}
