import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ApplicationConfiguration, ApplicationConfigurationProvider } from './configuration';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MomentDateModule} from '@angular/material-moment-adapter';
import {AppRoutingModule} from './app-routing.module';
import {NesModule} from './shared/nes.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DossiersModule} from './modules/dossiers/dossiers.module';
import {ServiceAreaMappingModule} from './modules/service-area-mapping/service-area-mapping.module';
import {VehiclesModule} from './modules/vehicles/vehicles.module';
import {PriceListUiComponentsModule} from '@nes/price-list-ui-components';
import {ConfigurationProviders, NesAngularConfigurationModule} from '@nes/angular-configuration';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {MatPaginatorIntlItalian} from './common/customizations/MatPaginatorIntItalian';
import {registerLocaleData} from '@angular/common';

import italian from '@angular/common/locales/it';
import italianExt from '@angular/common/locales/extra/it';
import {PersonsRegistryConfiguration} from "./modules/registry/persons-registry.configuration";
import {PersonsRegistryConfigurationProvider} from "./configuration/persons-registry-configuration.provider";
import {DossierConfiguration} from "./modules/dossiers/dossier.configuration";
import {DossierConfigurationProvider} from "./configuration/dossier-configuration.provider";
import {VehiclesConfiguration} from './modules/vehicles/vehicles.configuration';
import {BillingConfigurationProvider} from './configuration/billing-configuration.provider';
import { BillingConfiguration } from './modules/billing/billing.configuration';
import { VehiclesConfigurationProvider } from './configuration/vehicles-configuration.provider';
import { BillingModule } from './modules/billing/billing.module';
import {ServiceAreaMappingConfiguration} from "./modules/service-area-mapping/dossier.configuration";
import {SnackbarInterceptor} from "./shared/interceptors/snackbar.interceptor";
import {CustomDateAdapter} from "./shared/date-adapters/custom-date-adapter";
import {Platform} from "@angular/cdk/platform";
import {WorkOrdersModule} from "./modules/work-orders/work-orders.module";
import {AuthenticationModule} from "./modules/authentication/authentication.module";
import {AuthenticationConfigurationProvider} from "./configuration/authentication-configuration.provider";
import {AuthenticationConfiguration} from "./modules/authentication/authentication.configuration";
import {NesSidenavLayoutComponent} from "./shared/layout/components/nes-sidenav-layout/nes-sidenav-layout.component";
import {MatButtonModule} from "@angular/material/button";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {AuthInterceptor} from "./modules/authentication/shared/interceptors/auth.interceptor";
import {AuthorizationInterceptor} from "./modules/authentication/shared/interceptors/authorization.interceptor";

registerLocaleData(italian, 'it', italianExt);

@NgModule({
  declarations: [
    AppComponent,
    NesSidenavLayoutComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NesModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatSidenavModule,
    MatSlideToggleModule,
    NesAngularConfigurationModule.forRoot(
      ConfigurationProviders.forAsyncSource(ApplicationConfiguration, ApplicationConfigurationProvider),
      ConfigurationProviders.forSection(PersonsRegistryConfiguration, PersonsRegistryConfigurationProvider),
      ConfigurationProviders.forSection(DossierConfiguration, DossierConfigurationProvider),
      ConfigurationProviders.forSection(VehiclesConfiguration, VehiclesConfigurationProvider),
      ConfigurationProviders.forSection(BillingConfiguration, BillingConfigurationProvider),
      ConfigurationProviders.forSection(ServiceAreaMappingConfiguration, BillingConfigurationProvider),
      ConfigurationProviders.forSection(AuthenticationConfiguration, AuthenticationConfigurationProvider)
    ),
    DossiersModule.forRoot(),
    ServiceAreaMappingModule.forRoot(),
    VehiclesModule.forRoot(),
    PriceListUiComponentsModule.forRoot(30, '$'),
    BillingModule.forRoot(),
    MomentDateModule,
    BillingModule.forRoot(),
    WorkOrdersModule.forRoot(),
    AuthenticationModule.forRoot()
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: LOCALE_ID,
      useValue: 'it'
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY'
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    },
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    },

    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlItalian
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SnackbarInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
