<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Ordini di uscita']" [title]="'Lista veicoli in uscita'" [withoutBorder]="false"
  [loading]="firstLoad" class="vehicle-list">
  <div class="list-type-selector">
    <input type="radio" id="paged-ref" name="listType" value="paged" [(ngModel)]="listType" (ngModelChange)="onListTypeChange()">
    <label for="paged-ref">Lista paginata</label>
    <input type="radio" id="full-ref" name="listType" value="full" [(ngModel)]="listType" (ngModelChange)="onListTypeChange()">
    <label for="full-ref">Lista completa</label>
    <button [disableRipple]="true" class="flex_center" color="accent" mat-button (click)="print()"
    *ngIf="(listType != 'paged')" style="margin-left: auto;">Stampa</button>
  </div>
  <nes-portlet-table [dataSource]="vehicles">
    <div class="table-container">
      <div class="table-loading-shade" *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="vehicles">
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Targa o telaio</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getLicencePlateAndVinCombination(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="manufacturer-and-model">
          <mat-header-cell *matHeaderCellDef>Marca e modello</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getManufacturerAndModelCombination(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Colore</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.color }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="sector-and-lane">
          <mat-header-cell *matHeaderCellDef>Settore e fila attuale</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getSectorAndLaneCombination(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="order-sector-and-lane">
          <mat-header-cell *matHeaderCellDef>Settore e fila ordine</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getOrderSectorAndLaneCombination(row) }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="vehicleDetails"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: vehicleDetails;"
          [routerLink]="'/veicoli/dettaglio/' + row.id"></mat-row>
      </mat-table>
    </div>
    <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [pageIndex]="vehicleFilter.pageNumber"
      (page)="onPaginationChange($event)" [length]="totalVehicles" [pageSize]="vehicleFilter.pageSize"
      [pageSizeOptions]="[10, 25, 50]" *ngIf="(listType == 'paged')"></mat-paginator>
  </nes-portlet-table>
</nes-portlet>