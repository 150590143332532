import {Injectable} from '@angular/core';
import {ApplicationConfiguration} from './application.configuration';
import {HttpClient} from '@angular/common/http';
import {HttpConfigurationProvider} from '@nes/angular-configuration';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ApplicationConfigurationProvider extends HttpConfigurationProvider<ApplicationConfiguration> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

  protected get configurationUrl(): string {
    return '/assets/configuration/config.' + environment.name + '.json';
  }

}
