<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Veicoli', 'Import danni']" [title]="'Import danni'" [loading]="false"
  [withoutBorder]="true">
  <nes-portlet-title [title]="'Seleziona file da caricare'">
  </nes-portlet-title>
  <form class="nes-portlet-form-wr" [formGroup]="importForm">
    <div class="nes-portlet-form-col _two">
      <div class="_input_group _added_border">
        <label>{{ fileName ? fileName : "File"}}</label>
        <button type="button" mat-button [disableRipple]="true" (click)="triggerFileInput()" class="form-action-button">
          <span class="label">Seleziona File</span>
        </button>
        <input type="file" #fileInput (change)="onFileSelected($event)">
      </div>
    </div>
  </form>
  <div class="nes-portlet-form-submit outside">
    <button type="button" mat-button [disableRipple]="true" (click)="onUpload()" class="form-submit-button"
      [disabled]="!file || loading">
      <span class="label">Carica <mat-spinner *ngIf="loading" diameter="18" color="accent"></mat-spinner></span>
    </button>
  </div>
</nes-portlet>

<nes-portlet [breadcrumb]="[null]" [title]="'Esito importazione'" [loading]="false" [withoutBorder]="false"
  *ngIf="!!importResult">
  <mat-list>
    <mat-list-item class="result-list-item state_success" *ngIf="!!importResult.succeeded">
      <mat-icon class="heading-icon" matListItemIcon>check_circle</mat-icon>
      <div matListItemTitle>{{importResult.succeeded}} {{importResult.succeeded < importResult.total ? ' su ' +
          importResult.total : '' }} veicoli importati.</div>
    </mat-list-item>
    <mat-list-item class="result-list-item state_warning" *ngIf="!!importResult.alreadyExists">
      <mat-icon class="heading-icon" matListItemIcon>error</mat-icon>
      <div matListItemTitle>{{importResult.alreadyExists}} {{importResult.alreadyExists === 1 ? 'veicolo' : 'veicoli'}}
        già presenti nell'archivio.</div>
    </mat-list-item>
    <mat-list-item class="result-list-item state_error" *ngIf="!!importResult.failed">
      <mat-icon class="heading-icon" matListItemIcon>dangerous</mat-icon>
      <div matListItemTitle>{{importResult.failed}} {{importResult.failed === 1 ? 'veicolo' : 'veicoli'}} non sono stati
        importati per errori d'inserimento.</div>
    </mat-list-item>
  </mat-list>
</nes-portlet>

<nes-portlet [breadcrumb]="[null]" [title]="'Dettaglio importazione'" [loading]="false" [withoutBorder]="false"
  *ngIf="!!importResponse">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let c of importResponse; let i = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon
            [className]="'heading-icon ' + (c.completedItems < c.values.length ? c.completedItems === 0 ? 'state_error' : 'state_warning' : 'state_success')">

            {{c.completedItems < c.values.length ? c.completedItems===0 ? 'dangerous' : 'error' : 'check_circle' }}
              </mat-icon>
              <span *ngIf="!disableRestrictedInputs">Vagone&nbsp; </span> {{c.key}}
        </mat-panel-title>
        <mat-panel-description>
          {{c.completedItems}}/{{c.values.length}} telai importati
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let v of c.values">
          <mat-icon matListItemIcon
            [className]="'heading-icon ' + (v.status !== 'CREATED' ? v.status === 'ERROR' ? 'state_error' : 'state_warning' : 'state_success')">
            {{v.status !== 'CREATED' ? v.status === 'ERROR' ? 'dangerous' : 'error' : 'check_circle'}}
          </mat-icon>

          {{v.vin}}
          <span class="spacer" *ngIf="v.status !== 'CREATED'">
            -
          </span>
          <span [className]="'reason ' + (v.status !== 'ERROR' ? 'state_warning' : 'state_error')"
            *ngIf="v.status !== 'CREATED'">
            {{mapStatus(v.status)}}
          </span>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</nes-portlet>
