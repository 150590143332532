import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { downloadFileFromBlob } from 'src/app/shared/utils/utils';
import {IDdtReportResponseDTO, IVehicleDTO} from '../../vehicles/shared/interfaces/vehicles.type';
import { DossierConfiguration } from '../dossier.configuration';
import { IDossierFilters, DossierFilteredResults, ADossier, IDossierBillingSetting, IDossierDTOResponse, IngressDossier, DossierVehicleTransportType, ShipTransportDetails, TrainTransportDetails, CarTransporterTransportDetails, IDossierVehicleTransportDetails, ExgressDossier, ADossierWithCustomer, ITransportTypeDetails, IDossiersListingDTOResponse, IDossiersPaginatedList, IDossierDTO, ProcessingDossier, IDossierActivityDTO, IDossierActivity } from '../shared/interfaces/dossier.type';
import { IDossiersService, IDossierType } from './dossiers-service.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DossierService implements IDossiersService {

  constructor(private http: HttpClient, private dossierConfiguration: DossierConfiguration) { }

  dossierTypes: IDossierType[] = [
    {
      slug: 'ingress',
      name: 'Ingresso',
      key: 'InboundVehiclesDossier'
    },
    {
      slug: 'exgress',
      name: 'Uscita',
      key: 'OutboundVehiclesDossier'
    },
    {
      slug: 'processing',
      name: 'Lavorazione',
      key: 'VehiclesActivitiesDossier'
    },
    /* {
      slug: 'moving',
      name: 'Movimentazione'
    },
    {
      slug: 'damage',
      name: 'Danni'
    }, */
  ];

  getDossiers(filters: IDossierFilters): Observable<IDossiersPaginatedList<IDossierDTO>> {
    const params: any = {
      PageNumber: filters.page,
      PageSize: 10,
      searchQuery: filters.searchTerm
    }
    return this
    .http
    .get<
      IDossiersListingDTOResponse
    >(
      this.dossierConfiguration.service.baseUrl + 'dossiers',
      { params: new HttpParams({ fromObject: Object.assign(params, {
        PageNumber: params.PageNumber + 1
      }) }) }
    )
    .pipe(
      map(r => {
        const rows = r.rows.map((row) => {
          return this.listedDossierDTO(row);
        });
        return {
          rows,
          totalRows: r.totalRowCount
        } as IDossiersPaginatedList<IDossierDTO>
      })
    );
  }

  getDossierTypes(): Observable<IDossierType[]> {
    return of(this.dossierTypes);
  }

  getDossier(id: string): Observable<ADossier> {
    return this
    .http
    .get<
      IDossierDTOResponse
    >(
      this.dossierConfiguration.service.baseUrl + 'dossiers/' + id
    )
    .pipe(
      map(data => {
        return this.dossierDTO(data);
      })
    );
  }

  getDossierVehicles(id: string): Observable<IVehicleDTO[]> {
    return this
    .http
    .get<
      IDossierDTOResponse
    >(
      this.dossierConfiguration.service.baseUrl + 'dossiers/' + id
    )
    .pipe(
      map(data => {
        return this.vehicleDTO(data.vehicles);
      })
    );
  }

  getDossierActivities(): Observable<IDossierActivity[]> {
    const params: any = {
      systemReserved: false
    }
    return this
    .http
    .get<
      IDossierActivityDTO[]
    >(
      this.dossierConfiguration.service.baseUrl + 'dossier-activities',
      { params: new HttpParams({ fromObject: params}) }
    )
    .pipe(
      map((data: IDossierActivityDTO[]) => {
        const result: IDossierActivity[] = [];
        data.forEach((a: IDossierActivityDTO) => {
          result.push({
            id: Guid.parse(a.id),
            name: a.name
          } as IDossierActivity)
        })
        return result;
      })
    );
  }

  createDossierBillingSetting(setting: IDossierBillingSetting): Observable<IDossierBillingSetting> {
    throw new Error('Method not implemented.');
  }

  getDossierBillingSettings(dossierId: string): Observable<IDossierBillingSetting[]> {
    throw new Error('Method not implemented.');
  }

  editDossierSettings(dossierId: string, settings: IDossierBillingSetting[]): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  removeDossierBillingSettings(settings: Guid[]): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  saveDossier(dossier: ADossier, vehicles: Guid[]): Observable<ADossier> {
    const body = this.generatePostBody(dossier, vehicles);
    return this
      .http
      .post<
        IDossierDTOResponse
      >(
        this.dossierConfiguration.service.baseUrl + 'dossiers',
        body
      )
      .pipe(
        map(data => {
          return this.dossierDTO(data);
        })
      );
  }

  requestDossierDDT(id: string): Observable<IDdtReportResponseDTO> {
    let params: HttpParams = new HttpParams();

    return this
    .http
    .get(
      this.dossierConfiguration.service.baseUrl + 'dossiers/' + id +'/ddt'
    )
      .pipe(
        map((response: any) => {
          return {
            comp: response.comp,
            date: response.dateTime,
            time: "string",
            branch: response.branch,
            truck: response.truck,
            id: response.id,
            load: response.load,
            planBegin: response.planBegin,
            planEnd: response.planEnd,
            carrier: response.carrier,
            isBegin: response.isBegin,
            isEnd: response.isEnd,
            destination: response.vehicles[0]?.deliveryAddress.split('\n').join('<br>') || '',
            vehicles: response.vehicles.map(
              (veh: any) => {
                return {
                  vin: veh.vin,
                  model: veh.model,
                  manufacturer: veh.manufacturer,
                  branch: veh.branch
                }
              }
            )
          }
        })
      )as Observable<IDdtReportResponseDTO>;
  }

  editDossier(dossier: ADossier, vehicles: Guid[]): Observable<ADossier> {
    const body = this.generatePostBody(dossier, vehicles);
    return this
      .http
      .put<
        IDossierDTOResponse
      >(
        this.dossierConfiguration.service.baseUrl + 'dossiers/' + dossier.id,
        body
      )
      .pipe(
        map(data => {
          return this.dossierDTO(data);
        })
      );
  }

  generatePostBody(dossier: ADossier, vehicles: Guid[]): any {
    const body = {
      customerId: (dossier as ADossierWithCustomer).customer,
      vehiclesIds: vehicles.map(v => v.toString())
    }
    if ( dossier.completionDate ) body['completedAt'] = dossier.completionDate;

    if(dossier.is('ingress')) {
      body['arrivalDateTime'] = (dossier as IngressDossier).arrivalDateAndTime;
      body['arrivalDateTimeConfirmed'] = (dossier as IngressDossier).isTimeConfirmed;
      body['meanOfTransport'] = this.mapMeanOfTransportDetails((dossier as IngressDossier).vehicleTransport.details);
    } else if(dossier.is('exgress')) {
      body['departureDateTime'] = (dossier as ExgressDossier).departureDateAndTime;
      body['departureDateTimeConfirmed'] = (dossier as ExgressDossier).isTimeConfirmed;
      body['meanOfTransport'] = this.mapMeanOfTransportDetails((dossier as ExgressDossier).vehicleTransport.details);
      body['notes'] = this.mapMeanOfTransportDetails((dossier as ExgressDossier).notes);
    } else if(dossier.is('processing')) {
      const typedDossier = dossier as ProcessingDossier;
      body['activitiesIds'] = typedDossier.activities.map(a => a.toString());
      body['desiredCompletionDateTime'] = typedDossier.expiryDate;
    }

    return body;
  }

  dossierDTO(data: any): ADossier {
    if(!data) return null;
    let dossier: ADossier;
    if(data.arrivalTime) {
      dossier = new IngressDossier(
        data.id,
        data.dossierCode,
        data.customerId,
        data.arrivalTime,
        data.arrivalTimeConfirmed,
        // this.meanOfTransportDTO(data.meanOfTransport),
        new TrainTransportDetails({
          trainNumber: '625314'
        }),
        data.customerCompanyName
        );
    } else if (data.departureTime) {
      dossier = new ExgressDossier(
        data.id,
        data.dossierCode,
        data.customerId,
        data.departureTime,
        data.departureTimeConfirmed,
        // this.meanOfTransportDTO(data.meanOfTransport),
        new TrainTransportDetails({
          trainNumber: '625314'
        }),
        data.notes? data.notes : null,
        data.customerCompanyName
      )
    } else if (data.activities) {
      dossier = new ProcessingDossier(
        data.id,
        data.dossierCode,
        data.customerId,
        data.desiredCompletionTime ? data.desiredCompletionTime : null,
        data.activities.map((activity) => Guid.parse(activity.id)),
        data.customerCompanyName
      )
    }

    if (data.completedAt) dossier.setCompletionDate(data.completedAt)
    return dossier;
  }

  listedDossierDTO(data: any): IDossierDTO {
    if(!data) return null;

    return {
      id: Guid.parse(data.id),
      code: data.dossierCode,
      type: data.dossierType,
      customer: data.customerCompanyName,
      vehicles: data.vehicles?.length | 0
    }
  }

  vehicleDTO(data: any[]): IVehicleDTO[] {
    const result = [];
    data.forEach(v => {
      result.push({
        id: Guid.parse(v.id),
        vin: v.vin,
        licensePlate: v.licensePlate
        /* color?: IVehicleColor;
        date?: Date;
        brand?: IVehicleBrand | null;
        model?: IVehicleModelDTO | null; */
      });
    })
    return result;
  }

  meanOfTransportDTO(data: any): IDossierVehicleTransportDetails {
    if(data.name) {
      return new ShipTransportDetails({
        shipName: data.name
      });
    } else if (data.code) {
      return new TrainTransportDetails({
        trainNumber: data.code
      });
    } else if (data.licensePlates) {
      return new CarTransporterTransportDetails({
        licensePlates: data.licensePlates
      });
    }

    return null;
  }

  mapMeanOfTransportDetails(data: any): Object {
    if(data.trainNumber) {
      data.trainCode = data.trainNumber;
      delete data.trainNumber;
    }
    return data;
  }
}
