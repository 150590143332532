import {
  IVehicle,
  IVehicleDdtDetails,
  IVehicleDTOResponse
} from "../interfaces/vehicles.type";
import {Guid} from "guid-typescript";
import {EVehicleStatus} from "../enums/vehicles";

export const vehicleDdtToVehicle = (vehicle: IVehicleDTOResponse): IVehicle => {
  let ddtDetails: IVehicleDdtDetails = null;
  const result: IVehicle = {} as IVehicle;
  result.ticked = false;
  if (vehicle.transportDocumentId)
  {
    ddtDetails = {
      id: vehicle.transportDocumentId,
      number: vehicle.transportDocumentNumber,
      date: new Date(vehicle.transportDocumentCreatedAt)
    }
  }

  if (vehicle.id) { result.id = Guid.parse(vehicle.id); }
  if (vehicle.vin) { result.vin = vehicle.vin; }
  if (vehicle.blockReasons) { result.blockReasons = vehicle.blockReasons; }
  if (vehicle.arrivedAt) { result.arrivedAt = new Date(vehicle.arrivedAt); }
  if (vehicle.leftAt) { result.leftAt = new Date(vehicle.leftAt); }
  if (vehicle.arrivedBy) { result.arrivedBy = vehicle.arrivedBy; }
  if (vehicle.leftBy) { result.leftBy = vehicle.leftBy; }
  if (vehicle.licensePlate) { result.licensePlate = vehicle.licensePlate; }
  if (vehicle.color) { result.color = vehicle.color; }
  if (vehicle.createdAt) { result.date = new Date(vehicle.createdAt); }
  if (vehicle.customer) { result.customer = vehicle.customer; }
  if (vehicle.contract) { result.contract = vehicle.contract; }
  if (vehicle.manufacturer) { result.brand = vehicle.manufacturer === 'Sconosciuto' ? '' : vehicle.manufacturer; }
  if (vehicle.model) { result.model = vehicle.model; }
  if (vehicle.sectorLabel) { result.sector = vehicle.sectorLabel; }
  if (vehicle.sector) { result.sector = vehicle.sector; }
  if (vehicle.sectorId) { result.sectorId = vehicle.sectorId; }
  if (vehicle.laneNumber) { result.lane = vehicle.laneNumber; }
  if (vehicle.status) { result.status = mapVehicleStatusDTO(vehicle.status); }
  if (vehicle.destinationAddress) { result.destinationAddress = vehicle.destinationAddress; }
  if (vehicle.destinationCode) { result.destinationCode = vehicle.destinationCode; }
  if (vehicle.ticked) { result.ticked = vehicle.ticked; }
  if (vehicle.orderId) { result.orderId = vehicle.orderId; }
  if (ddtDetails) { result.ddt = ddtDetails; }


  return result as IVehicle
}


const mapVehicleStatusDTO = (status: string): EVehicleStatus => {
  switch (status) {
    case 'NotArrived':
      return EVehicleStatus.Registered;
    case 'Parked':
      return EVehicleStatus.Parked;
    case 'Arrived':
      return EVehicleStatus.Arrived;
    case 'WillArrive':
      return EVehicleStatus.WillArrive;
    case 'InOutboundSector':
      return EVehicleStatus.Outbound;
    case 'InOutgoingDocument':
      return EVehicleStatus.OutgoingDocument;
    case 'Left':
      return EVehicleStatus.Left;
    case 'Lost':
      return EVehicleStatus.Lost;
    default:
      return EVehicleStatus.None;
  }
}
