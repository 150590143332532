<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Veicoli', 'Olio veicoli']" [title]="'Report olio veicoli'" [loading]="loading" [withoutBorder]="true" >
  <nes-portlet-title [title]="'Scegli periodo'">
  </nes-portlet-title>

  <form class="nes-portlet-form-wr _main" [formGroup]="form">

    <div class="nes-portlet-form-wr">
      <div class="nes-portlet-form-col _two">
        <div class="_input_group">
          <mat-label>Operazione</mat-label>
          <mat-button-toggle-group formControlName="operationType">
            <mat-button-toggle value="view" >Visualizza</mat-button-toggle>
            <mat-button-toggle value="download">Scarica</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="nes-portlet-form-col _two">
      </div>
    </div>
    <div class="nes-portlet-form-wr ">
      <div class="nes-portlet-form-col _two">
        <div class="_input_group datepicker">
          <mat-form-field>
            <mat-label>Mese e anno</mat-label>
            <input
              [max]="today"
              matInput
              [matDatepicker]="dp"
              formControlName="date"
              readonly>
            <mat-datepicker-toggle [disableRipple]="true" [for]="dp">
              <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="multi-year"
                            (yearSelected)="chosenYearHandler($event)"
                            (monthSelected)="chosenMonthHandler($event, dp)"
                            panelClass="month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="nes-portlet-form-col _two">
        <div class="_input_group">
          <customer-selection-input
            placeholder="Cliente"
            formControlName="customer">
          </customer-selection-input>
        </div>
      </div>
    </div>
  </form>
  <div class="nes-portlet-form-submit outside">
    <button type="button" mat-button [disableRipple]="true" (click)="requestOilReport()" class="form-submit-button">
      <span class="label">Genera report</span>
    </button>
  </div>
</nes-portlet>

<nes-portlet *ngIf="reportTriggered" [loading]="loading">

  <nes-portlet-title [title]="'Totale'">
  </nes-portlet-title>
  <div class="nes-portlet-form-wr" [formGroup]="form">

    <div class="nes-portlet-form-col _two">
      <div class="_input_group">
        <mat-form-field class="fill-available">
          <input matInput placeholder="Litri totali" formControlName="liters">
        </mat-form-field>
      </div>
    </div>

    <div class="nes-portlet-form-col _two">
    </div>
  </div>

  <nes-portlet-title [title]="'Dettagli'">
  </nes-portlet-title>
  <nes-portlet-table [dataSource]="vehicles" >
    <div class="table-container">
      <mat-table [dataSource]="paginatedVehicles">
        <ng-container matColumnDef="vin">
          <mat-header-cell *matHeaderCellDef>Telaio</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.vin }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="vac">
          <mat-header-cell *matHeaderCellDef>VAC</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.vac }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Data di arrivo</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ formatDateColumn(row.arrivedAt) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="liters">
          <mat-header-cell *matHeaderCellDef>Litri</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ formatAmountColumn(row.liters.toString()) }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: tableColumns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [pageIndex]="vehicleFilter.pageNumber" (page)="onPaginationChange($event)" [length]="vehicles.length" [pageSize]="vehicleFilter.pageSize" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </nes-portlet-table>

</nes-portlet>
