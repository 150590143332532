<nes-portlet [loading]="loading" *ngIf="RolesGuardHelper.canWrite() || vehicleBlockages.length > 0">
    <nes-portlet-title [title]="'BLOCCHI'">
    </nes-portlet-title>


  <nes-portlet-table [dataSource]="vehicleBlockages" *ngIf="undefined !== vehicleBlockages && vehicleBlockages.length">
    <mat-table [dataSource]="vehicleBlockages">
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>Codice blocco</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.type }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef>Inizio</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.from | date: 'dd/MM/y' }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef>Fine</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.to | date: 'dd/MM/y' }}
          <span class="blank_placeholder" *ngIf="!row.to">
            <button
              [disableRipple]="true"
              type="button"
              class="flex_center icon-btn"
              color="accent"
              [disabled]="!RolesGuardHelper.canWrite()"
              mat-button
              (click)="closeBlockage(row.id)">
              Sblocca
            </button>
          </span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="vehicleBlockagesDetails"></mat-header-row>
      <mat-row [ngClass]="{'inactive': isRowInactive(row), '_selectable': !isRowInactive(row)}"  *matRowDef="let row; columns: vehicleBlockagesDetails;"></mat-row>
    </mat-table>
</nes-portlet-table>

  <nes-portlet-form *ngIf="RolesGuardHelper.canWrite()">
    <form [formGroup]="blockageInsetForm" autocomplete="off" (ngSubmit)="saveBlockage()">
      <div class="nes-portlet-form-wr">
        <div class="nes-portlet-form-col _two">
          <div class="_input_group _auto-flex">
            <mat-form-field class="fill-available">
              <mat-label>Codice blocco</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="type"
                [matAutocomplete]="autocompleteBlockageType"
                (blur)="blockageInsetForm.controls['type'].updateValueAndValidity()">
            </mat-form-field>
            <mat-autocomplete #autocompleteBlockageType="matAutocomplete" (optionSelected)="handleTypeAutocomplete($event)">
              <mat-option *ngFor="let option of customerOptions" [value]="option.name">
                {{option.name}}
              </mat-option>
            </mat-autocomplete>
            <button
              [disableRipple]="true"
              type="button"
              class="flex_center icon-btn push-left _input-complimentary "
              color="accent"
              mat-button
              (click)="saveBlockage()">
              Blocca
            </button>
          </div>
        </div>
        <div class="nes-portlet-form-col _two">
        </div>
      </div>
    </form>
  </nes-portlet-form>
</nes-portlet>
