<nes-portlet-footer *ngIf="edit">
  <button [disableRipple]="true" class="save-and-print flex_center" color="accent" mat-button *ngIf="!existingDDt"
    (click)="save()">Salva e stampa</button>
</nes-portlet-footer>

<nes-portlet [breadcrumb]="['Home', 'Nuovo DDT']" [title]="title" [loading]="loading" [withoutBorder]="true">
  <nes-portlet-title class="no_spacing no_print horizontal">
    <button [disableRipple]="true" class="flex_center" color="accent" mat-button (click)="triggerPrint()"
      *ngIf="!edit">Stampa</button>
    <nes-portlet-unlock (onEdit)="passToEdit()" (onSave)="save()" (onCancel)="cancelEdit()" [isEditable]="edit"
      *ngIf="existingDDt && RolesGuardHelper.canWrite()"></nes-portlet-unlock>
  </nes-portlet-title>

  <div class="print-damages-selector">
    <input type="radio" id="only-document-ref" name="printDamages" value="only-document" [(ngModel)]="printDamages">
    <label for="only-document-ref">Stampa solo il DDT</label>
    <input type="radio" id="only-damages-ref" name="printDamages" value="only-damages" [(ngModel)]="printDamages">
    <label for="only-damages-ref">Stampa solo i danni</label>
    <input type="radio" id="all-ref" name="printDamages" value="all" [(ngModel)]="printDamages">
    <label for="all-ref">Stampa il DDT e i danni</label>
  </div>

  <div class="layout-container" [ngClass]="{ 'page-break' : printDamages == 'all', 'no-print' : printDamages == 'only-damages' }">
    <div class="col">
      <div class="print-header">
        <img src="/assets/icons/logo-tencara.svg" />
        <h2>{{title}}</h2>
      </div>
    </div>

    <div class="col-half">
      <div class="data-wrapper" *ngIf="!edit">
        <label class="data-label">MITTENTE</label>
        <textarea readonly [(ngModel)]="sender"></textarea>
      </div>
      <div [class]="[
          'data-wrapper',
          ddtData.customer.searchTriggered && (ddtData.customer.searchResults.length || ddtData.customer.loading) ? 'outstanding': '',
          !ddtData.customer.value ? 'field-required' : ''].join(' ')" #customerField>
        <label class="data-label">COMITTENTE</label>
        <textarea [(ngModel)]="ddtData.customer.value" (ngModelChange)="ddtData.customer.update.next($event)"
          [readonly]="!edit"></textarea>
        <div class="search_results_container"
          *ngIf="ddtData.customer.searchTriggered && (ddtData.customer.searchResults.length || ddtData.customer.loading)">
          <ul>
            <li *ngFor="let result of ddtData.customer.searchResults"
              (click)="applySearchResult(result, ddtData.customer)" [innerHTML]="parseLineBreaks(result.value)">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-half">
      <div class="data-wrapper __inline" *ngIf="!edit">
        <label class="data-label">NUMERO</label>
        <h3> <span *ngIf="!!documentNr">{{ddtDate|date:"MM"}} / {{documentNr}}</span></h3>
      </div>

      <div class="data-wrapper __inline" *ngIf="!edit">
        <label class="data-label">DEL</label>
        <h3><span *ngIf="!!ddtDate">{{ddtDate|date:"dd/MM/yyyy"}}</span></h3>
      </div>

      <div [class]="[
          'data-wrapper',
          ddtData.destination.searchTriggered && (ddtData.destination.searchResults.length || ddtData.destination.loading) ? 'outstanding': '',
          !ddtData.destination.value ? 'field-required' : ''].join(' ')" #destinationField>
        <label class="data-label">DESTINATARIO</label>
        <textarea [(ngModel)]="ddtData.destination.value" (ngModelChange)="ddtData.destination.update.next($event)"
          [readonly]="!edit" rows="4"></textarea>
        <div class="search_results_container"
          *ngIf="ddtData.destination.searchTriggered && (ddtData.destination.searchResults.length || ddtData.destination.loading)">
          <ul>
            <li *ngFor="let result of ddtData.destination.searchResults"
              (click)="applySearchResult(result, ddtData.destination)" [innerHTML]="parseLineBreaks(result.value)">
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="adder-banner" *ngIf="edit">
        <service-area-position-input (positionChange)="handlePositionChange($event)" title="Seleziona fila di carico"
          [sectorType]="sectorType" [showRows]="true" [disableInput]="true">
          <button [disableRipple]="true" class="add-from-load-lane flex_center" color="accent" mat-button>
            Aggiungi da fila di carico
          </button>
        </service-area-position-input>
      </div>
      <div class="table-container">
        <div class="table-loading-shade" *ngIf="loading || loadingVehicles">
          <mat-spinner [diameter]="26" *ngIf="loading || loadingVehicles"></mat-spinner>
        </div>
        <table class="vehicle_list centered">
          <tr>
            <th>TELAIO O TARGA</th>
            <th>MARCA E MODELLO</th>
          </tr>
          <tr *ngFor="let vehicle of vehicleList; let i = index">
            <td>
              <div class="vehicle-mismatch-alert"
                *ngIf="edit && (!vehicle.customer || !vehicle.address || checkVehicleCustomer(vehicle.customer) || checkVehicleDestination(vehicle.address))">
                <mat-icon>warning</mat-icon>
                <span class="floating-alert" *ngIf="edit && !vehicle.customer">
                  Il veicolo è privo di committente.
                </span>
                <span class="floating-alert" *ngIf="edit && !vehicle.address">
                  Il veicolo è privo di destinatario.
                </span>
                <span class="floating-alert" *ngIf="edit && checkVehicleCustomer(vehicle.customer)">
                  Il committente del veicolo non corrisponde al committente del DDT.
                </span>
                <span class="floating-alert" *ngIf="edit && checkVehicleDestination(vehicle.address)">
                  Il destinatario del veicolo non corrisponde al destinatario del DDT.
                </span>
              </div>
              {{vehicle.value}}
              <button *ngIf="edit" (click)="vehicleList.splice(i, 1)"><mat-icon>close</mat-icon></button>
            </td>
            <td>
              <span *ngIf="!!vehicle.model">
                {{vehicle.model}}
              </span>
            </td>
          </tr>
          <tr *ngIf="vehicleList.length < 9 && !edit">
            <td></td>
            <td></td>
          </tr>
          <tr *ngIf="vehicleList.length < 9 && edit" class="vin_selection">
            <td [class]="[
                'centered_search_field',
                ddtData.vehicles.searchTriggered && (ddtData.vehicles.searchResults.length || ddtData.vehicles.loading) ? 'outstanding': '',
                vehicleList.length === 0 ? 'field-required' : ''].join(' ')
              " #vehiclesField>
              <input type="text" [(ngModel)]="ddtData.vehicles.value"
                (ngModelChange)="ddtData.vehicles.update.next($event)" [readonly]="!edit">

              <div class="search_results_container"
                *ngIf="ddtData.vehicles.searchTriggered && (ddtData.vehicles.searchResults.length || ddtData.vehicles.loading)">
                <ul>
                  <li *ngFor="let result of ddtData.vehicles.searchResults" (click)="addVin(result)"
                    [innerHTML]="formatVehicleSearchIntersection(getVehicleLabel(result))">
                  </li>
                </ul>
              </div>
            </td>
            <td></td>
          </tr>
          <tr *ngFor="let space of leftoverVinList">
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col" *ngIf="edit && !documentNr">
      <table class="footer_table _standalone">
        <tr>
          <td>
            <label class="data-label-small">EXCEPTION ROUTING CODE</label>
            <br>
            <input type="text" [(ngModel)]="exceptionRoutingCode">
          </td>
        </tr>
      </table>
    </div>
    <div class="col">
      <table class="footer_table">
        <tr class="row_halves">
          <td colspan="3">
            <label class="data-label-small">TRASPORTO A MEZZO</label>
            <br>
            <mat-radio-group class="disabled_radios">
              <mat-radio-button value="1">Mittente</mat-radio-button>
              <mat-radio-button value="2" checked>Vettore</mat-radio-button>
              <mat-radio-button value="3">Destinatario</mat-radio-button>
            </mat-radio-group>
          </td>
          <td colspan="3">
            <label class="data-label-small">CAUSALE</label>
            <br>
            <p>Scarichi per deposito/custodia o vendita</p>
          </td>
        </tr>
      </table>
      <table class="footer_table">
        <tr class="row_thirds">
          <td [class]="[
              ddtData.carrier.searchTriggered && (ddtData.carrier.searchResults.length || ddtData.carrier.loading) ? 'outstanding': '',
              !ddtData.carrier.value ? 'field-required' : ''].join(' ')" colspan="2" #carrierField>
            <label class="data-label-small">VETTORE</label>
            <br>
            <input type="text" [(ngModel)]="ddtData.carrier.value" (ngModelChange)="ddtData.carrier.update.next($event)"
              [readonly]="!edit">
            <div class="search_results_container"
              *ngIf="ddtData.carrier.searchTriggered && (ddtData.carrier.searchResults.length || ddtData.carrier.loading)">
              <ul>
                <li *ngFor="let result of ddtData.carrier.searchResults"
                  (click)="applySearchResult(result, ddtData.carrier)">
                  {{result.value}}
                </li>
              </ul>
            </div>
          </td>
          <td [class]="[
              ddtData.carrierLicensePlate.searchTriggered && (ddtData.carrierLicensePlate.searchResults.length || ddtData.carrierLicensePlate.loading) ? 'outstanding': '',
              !ddtData.carrierLicensePlate.value ? 'field-required' : ''].join(' ')" colspan="2"
            #carrierLicensePlateField>
            <label class="data-label-small">TARGA</label>
            <br>
            <input type="text" [(ngModel)]="ddtData.carrierLicensePlate.value"
              (ngModelChange)="ddtData.carrierLicensePlate.update.next($event)" [readonly]="!edit">
            <div class="search_results_container"
              *ngIf="ddtData.carrierLicensePlate.searchTriggered && (ddtData.carrierLicensePlate.searchResults.length || ddtData.carrierLicensePlate.loading)">
              <ul>
                <li *ngFor="let result of ddtData.carrierLicensePlate.searchResults"
                  (click)="applySearchResult(result, ddtData.carrierLicensePlate)">
                  {{result}}
                </li>
              </ul>
            </div>
          </td>
          <td [class]="[
              !shippingNumber ? 'field-required' : ''].join(' ')" colspan="2">
            <label class="data-label-small">ANNOTAZIONI</label>
            <br>
            <input type="text" [(ngModel)]="shippingNumber" [readonly]="!edit">
          </td>
        </tr>
        <tr class="row_thirds" *ngIf="!edit">
          <td colspan="2">
            <label class="data-label-small">FIRMA MITTENTE</label>
            <br>
            <img src="/assets/images/tencara-ddt-signature.png" />
          </td>
          <td colspan="2">
            <label class="data-label-small">FIRMA VETTORE</label>
            <img [src]="ddtSign" *ngIf="ddtSign" alt="firma ddt" class="sign">
            <span class="placeholder" *ngIf="!ddtSign"></span>
          </td>
          <td colspan="2">
            <label class="data-label-small">FIRMA DESTINATARIO</label>
            <span class="placeholder"></span>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div [ngClass]="{ 'page-break' : printDamages != 'only-document', 'no-print' : printDamages == 'only-document' }" *ngFor="let vehicleId of getDamageVehicleIds()">
    <p>
      <strong>
        Vehicle Loss and Damage Report<br>
        Meldung über Transportschäden /- verluste<br>
        Informe de Danos y Faltas
      </strong>
    </p>
    <table class="vldr-module damages-vehicle">
      <tr>
        <td>Model</td>
        <td>{{ damages[vehicleId].vehicle.model }}</td>
      </tr>
      <tr>
        <td>Serial No.</td>
        <td>{{ damages[vehicleId].vehicle.vin }}{{ damages[vehicleId].vehicle.licensePlate }}</td>
      </tr>
    </table>
    <p>
      AIAG Coding
    </p>
    <table>
      <tr>
        <td>
          <img class="damages-table" src="/assets/images/damages-print-table.png">
        </td>
        <td>
          <img class="damages-image" src="/assets/images/damages-print-image.png">
        </td>
      </tr>
    </table>
    <table class="vldr-module">
      <tr>
        <td class="damages-column-20 damages-column-center">Position code</td>
        <td class="damages-column-20 damages-column-center">Damage code</td>
        <td class="damages-column-20 damages-column-center">Severity code</td>
        <td class="damages-column-40 damages-column-center">Note</td>
      </tr>
      <tr *ngFor="let damage of damages[vehicleId].damages">
        <td class="damages-column-center">{{ damage.positionCode }}</td>
        <td class="damages-column-center">{{ damage.damageCode }}</td>
        <td class="damages-column-center">{{ damage.severityCode }}</td>
        <td class="damages-column-left">{{ damage.note }}</td>
      </tr>
    </table>
    <br>
    <table class="vldr-module">
      <tr>
        <td class="damages-column-center">Sender's signature</td>
        <td class="damages-column-center">Carrier's signature</td>
      </tr>
      <tr>
        <td class="damages-column-center">
          <img src="/assets/images/tencara-ddt-signature.png" class="damages-sign" />
        </td>
        <td class="damages-column-center">
          <img [src]="ddtSign" *ngIf="ddtSign" alt="Firma" class="damages-sign">
          <span class="placeholder" *ngIf="!ddtSign"></span>
        </td>
      </tr>
    </table>
</div>
</nes-portlet>
