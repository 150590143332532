<mat-sidenav-container class="map-external-container">
  <mat-sidenav #sidenav mode="side" opened class="example-sidenav"
               [fixedInViewport]="true"
               [fixedTopGap]="0"
               [mode]="'side'"
               [position]="'end'"
               [fixedBottomGap]="0"
  >
    <mat-toolbar class="sidebar-header">

      <div class="_input_group">

        <!-- Search input -->
        <mat-form-field>
          <mat-label>Cerca</mat-label>
          <input matInput [(ngModel)]="searchTerm" (ngModelChange)="searchTermUpdate.next($event)">
        </mat-form-field>

        <!-- Search results list -->
        <div
            class="search-results-container"
            *ngIf="!!vinSearchResults"
            #searchResultList
        >
          <ul>
            <li *ngFor="let vehicle of vinSearchResults"
                (click)="fetchVehicleDetails(vehicle.vin || vehicle.licensePlate)">
              {{getVehicleLabel(vehicle)}}
            </li>
          </ul>
        </div>
      </div>
    </mat-toolbar>
    <div class="sidenav-body" #sidenavBody>

      <!-- Sectors list -->
      <div class="scrollbar-list-header"
           *ngIf="!selectedSector && !selectedLane && !vehicleDetails">
          <span class="quantity-indicator">
            <span>
                  <mat-icon>directions_car</mat-icon> {{mixedSectorsAvailability.filled}} / {{mixedSectorsAvailability.total}}</span>
          </span>
      </div>
      <div class="scrollbar-list-header _controls"
           *ngIf="!!selectedSector && !selectedLane && !vehicleDetails">
          <span>
              <button mat-icon-button (click)="setSelectedEntity({sector: selectedSectorDetails.parentId, lane: null})">
                <mat-icon>arrow_back</mat-icon>
              </button>
              Settore {{selectedSectorDetails.label}}
          </span>
          <span class="quantity-indicator">
            <span>
                <mat-icon>directions_car</mat-icon> {{selectedSectorAvailability.filled}} / {{selectedSectorAvailability.total}}</span>
          </span>
      </div>
      <div class="scrollbar-list-header _controls"
           *ngIf="!!selectedLane && !vehicleDetails">
          <span>
              <button mat-icon-button (click)="setSelectedEntity({sector: selectedSector, lane: null})">
                <mat-icon>arrow_back</mat-icon>
              </button>
              Settore {{selectedSectorDetails.label}} - Fila {{selectedLane}}
          </span>
          <span class="quantity-indicator">
            <span>
                <mat-icon>directions_car</mat-icon> {{selectedLaneDetails.vehicleCount !== undefined ? selectedLaneDetails.vehicleCount : "_"}} / {{selectedLaneDetails.length}}</span>
          </span>
          <span class="full" *ngIf="!!selectedLaneDetails.comment">
            <span >
              <span>Note: {{selectedLaneDetails.comment}}</span>
            </span>
          </span>
      </div>
      <div class="scrollbar-list-header _controls"
           *ngIf="!!vehicleDetails">
          <span>
              <button mat-icon-button (click)="backFromVehicleDetails()">
                <mat-icon>arrow_back</mat-icon>
              </button>
              Settore {{vehicleDetails.sector}} - Fila {{vehicleDetails.lane}}
          </span>
      </div>
      <div class="scrollable">
        <mat-list
        role="list"
        class="sector-list"
        *ngIf="!selectedSector && !selectedLane && !vehicleDetails"
      >
        <!-- Listing -->
        <mat-list-item
          role="listitem"
          [id]="'sector-' + sector.id"
          *ngFor="let sector of mixedSidebarSectors; let i = index"
          [attr.class]="getListElementClasses(sector.id, null, {group: sector.type === 'group', separator: sector.type === 'separator'})"
          (click) ="sector.type === 'group' ? setSelectedGroup(sector.id) : setSelectedEntity({sector: sector.id, lane: null})"
          (mouseenter) ="setInteractionStatus(sector.id,null,true)"
          (mouseleave) ="setInteractionStatus()"
        >
          <div matListItemTitle *ngIf="sector.type !== 'separator'" >Settore {{sector.label}}</div>
          <div matListItemLine *ngIf="sector.type === 'group' && !!getGroupAvailability(sector.id)">veicoli {{getGroupAvailability(sector.id).filled}} / {{getGroupAvailability(sector.id).total}}</div>
          <div matListItemLine *ngIf="sector.type === 'sector' && !!getSectorLaneAvailability(sector.id)">veicoli {{getSectorLaneAvailability(sector.id).filled}} / {{getSectorLaneAvailability(sector.id).total}}</div>
        </mat-list-item>
      </mat-list>

        <!-- Lanes list -->
        <mat-list
          role="list"
          *ngIf="!!selectedSector && !selectedLane && !vehicleDetails"
          class="lane-list"
        >

          <!-- Listing -->
          <mat-list-item
            role="listitem"
            *ngFor="let lane of lanes"
            [id]="'lane-' + lane.label"
            (click)="setSelectedEntity({sector: null, lane: lane.label})"
            [attr.class]="getListElementClasses(null, lane.label, {availability: lane.availability})"
            (mouseenter)="setInteractionStatus(null, lane.label, true)"
            (mouseleave)="setInteractionStatus()"
          >

            <div matListItemTitle>
              Fila {{lane.label}}
              <span
                *ngIf="lane.comment"
                [matTooltipPosition]="'above'"
                [matTooltip]="lane.comment">
              - {{lane.comment}}
            </span>
            </div>
            <div matListItemLine *ngIf="lane.vehicleCount !== null">veicoli {{lane.vehicleCount !== undefined ? lane.vehicleCount : "_"}} / {{lane.length}}</div>
          </mat-list-item>
        </mat-list>

        <!-- Lane vehicles list -->
        <mat-list
          role="list"
          *ngIf="!!selectedLane && !vehicleDetails"
        >
          <!-- Listing -->
          <mat-list-item
            [attr.class]="'hoverable vehicle-list-item' + (v.isLost ? ' lost' : '')"
            role="listitem"
            *ngFor="let v of laneVehicles; let i = index"
            (click)="fetchVehicleDetails(v.vin || v.licensePlate)"
          >

            <div matListItemTitle>{{getVehicleLabel(v)}}</div>
            <div matListItemLine class="icon" *ngIf="v.isLost">
              <mat-icon matListItemIcon>crisis_alert</mat-icon>
            </div>


          </mat-list-item>
        </mat-list>

        <!-- Vehicle details list -->
        <mat-list
          role="list"
          *ngIf="!!vehicleDetails"
        >

          <!-- ID -->
          <mat-list-item
            role="listitem"
          >
            {{vehicleDetails.vin}}
          </mat-list-item>

          <!-- Costumer -->
          <mat-list-item
            role="listitem"
            *ngIf="!!vehicleDetails.costumer"
          >
            Commitente: {{vehicleDetails.costumer}}
          </mat-list-item>

          <!-- Brand -->
          <mat-list-item
            role="listitem"
            *ngIf="!!vehicleDetails.brand"
          >
            Produttore: {{vehicleDetails.brand}}
          </mat-list-item>

          <!-- Model -->
          <mat-list-item
            role="listitem"
            *ngIf="!!vehicleDetails.model"
          >
            Modello: {{vehicleDetails.model}}
          </mat-list-item>

          <!-- Model -->
          <mat-list-item
            role="listitem"
            *ngIf="!!vehicleDetails.destinationCode"
          >
            Codice destinazione: {{vehicleDetails.destinationCode}}
          </mat-list-item>

          <!-- Model -->
          <mat-list-item
            role="listitem"
            *ngIf="!!vehicleDetails.destinationAddress"
          >
            Indirizzo destinazione: {{vehicleDetails.destinationAddress}}
          </mat-list-item>
        </mat-list>
      </div>

    </div>
  </mat-sidenav>

  <!-- Map Canvas -->
  <mat-sidenav-content >
    <mg-canvas-map
      #map
      [sectors]="sectors"
      [sectorGroups]="sectorGroups"
      [interactionStatus]="interactionStatus"
      [activeLane]="selectedLane"
      [visibleSectors]="visibleSectors"
      [selectedSector]="selectedSector"
      [selectedLane]="selectedLane"
      [lanesAnomalies]="lanesAnomalies"
      [lanesAvailability]="lanesAvailability"
      (targetChanged)="setSelectedEntity($event)"
      (mapInteractionsStatusChange)="setInteractionStatus($event.sector, $event.lane)"
    >
    </mg-canvas-map>
  </mat-sidenav-content>
</mat-sidenav-container>
