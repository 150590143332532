import {Paginator} from '../../../../common/interfaces/paginator.type';

export interface RegistryFilter {
  text?: string;
  fiscal?: string;
  sort?: string;
  direction?: string;
  pageNumber?: number;
  pageSize?: number;
  company?: boolean;
  individual?: boolean;
  publicAdministration?: boolean;
}

export interface RegistryAddressFilter extends RegistryFilter {
  id?: string;
}

export interface RegistryContactFilter extends RegistryFilter {
  id?: string;
}

// --

export enum RegistryType {
  company = 'Company',
  individual = 'Individual',
  publicCompany = 'PublicAdministration',
}

// --

export interface BaseDTORequest {
  id?: number;
}

export interface SubjectDTORequest extends BaseDTORequest {
  type: RegistryType;
  favoritePaymentMethod: FavoritePaymentMethodDTORequest;
  vatExemptionCode: string;
}

export interface CompanyDTORequest extends SubjectDTORequest {
  companyName: string;
  fiscalcode: string;
  headquarter: OfficeDTORequest;
  legalRepresentative: SubjectDTORequest;
  pec: string;
  sdi: string;
  vatGroupMember: boolean;
  vatNumber: string;
  email?: string;
  parentCompanyID?: number;
  permanentEstablishment?: CompanyDTORequest;
}

export interface IndividualDTORequest extends SubjectDTORequest {
  fiscalcode: string;
  name: string;
  pec: string;
  residence: PlaceDTORequest;
  sdi: string;
  surname: string;
  vatNumber: string;
  domicile?: PlaceDTORequest;
  emailAddresses?: EmailAddressDTORequest;
  faxNumbers?: PhoneNumberDTORequest;
  phoneNumbersLandline?: PhoneNumberDTORequest;
  phoneNumbersMobile?: PhoneNumberDTORequest;
}

export interface PublicAdministrationDTORequest extends SubjectDTORequest {
  fiscalcode: string;
  headquarter: OfficeDTORequest;
  name: string;
  pec: string;
  sdi: string;
  vatNumber: string;
  cig?: string;
  code?: string;
  email?: string;
}

export interface RepresentativeDTORequest extends BaseDTOResponse {
  roleCode: string;
  office: number;
  subject: SubjectDTORequest;
}

export interface FavoritePaymentMethodDTORequest {
  paymentChannelTypeCode: string;
  paymentTimingCode: string;
}

export interface PlaceDTORequest extends BaseDTORequest {
  streetAddress: StreetAddressDTORequest;
  phoneNumbersLandline: PhoneNumberDTORequest[];
  faxNumbers: PhoneNumberDTORequest[];
  emailAddresses: EmailAddressDTORequest[];
}

export interface OfficeDTORequest extends PlaceDTORequest {
  name?: string;
  roleCode?: string;
}

export interface StreetAddressDTORequest {
  city: string;
  countryCode: string;
  regionCode: string;
  street: string;
  zipCode: string;
  nation?: string;
  streetNumber?: string;
}

export interface PhoneNumberDTORequest {
  number: string;
}

export interface EmailAddressDTORequest {
  email: string;
}

// --


export interface BaseDTOResponse {
  id: string;
}

export interface SubjectDTOResponse extends BaseDTOResponse {
  favoritePaymentMethod: FavoritePaymentMethodDTOResponse;
  inverseRepresentative: RepresentativeDTOResponse[];
  representatives: RepresentativeDTOResponse[];
  type: RegistryType;
  vatExemptionCode: string;
}

export interface CompanyDTOResponse extends SubjectDTOResponse {
  branchOffice: OfficeDTOResponse[];
  companyName: string;
  email: string;
  fiscalcode: string;
  headquarter: OfficeDTOResponse;
  legalRepresentative: CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse;
  parentCompany: CompanyDTOResponse;
  pec: string;
  permanentEstablishment: CompanyDTOResponse;
  sdi: string;
  vatGroupMember: boolean;
  vatNumber: string;
}

export interface IndividualDTOResponse extends SubjectDTOResponse {
  domicile: PlaceDTOResponse;
  emailAddresses: EmailAddressDTOResponse;
  faxNumbers: PhoneNumberDTOResponse;
  fiscalcode: string;
  name: string;
  offices: OfficeDTOResponse[];
  pec: string;
  phoneNumbersLandline: PhoneNumberDTOResponse;
  phoneNumbersMobile: PhoneNumberDTOResponse;
  residence: PlaceDTOResponse;
  sdi: string;
  surname: string;
  vatNumber: string;
}

export interface PublicAdministrationDTOResponse extends SubjectDTOResponse {
  branchOffice: OfficeDTOResponse[];
  cig: string;
  code: string;
  email: string;
  fiscalcode: string;
  headquarter: OfficeDTOResponse;
  name: string;
  pec: string;
  sdi: string;
  vatNumber: string;
}

export interface RepresentativeDTOResponse extends BaseDTOResponse {
  roleCode: string;
  office: OfficeDTOResponse;
  subject: SubjectDTOResponse;
}

export interface RepresentativeExtendedDTOResponse extends RepresentativeDTOResponse {
  emailAddresses: any;
  faxNumbers: any;
  phoneNumbersLandline: any;
}

export interface FavoritePaymentMethodDTOResponse {
  paymentChannelTypeCode: string;
  paymentTimingCode: string;
}

export interface PlaceDTOResponse extends BaseDTOResponse {
  emailAddresses: EmailAddressDTOResponse[];
  faxNumbers: PhoneNumberDTOResponse[];
  phoneNumbersLandline: PhoneNumberDTOResponse[];
  streetAddress: StreetAddressDTOResponse;
}

export interface OfficeDTOResponse extends PlaceDTOResponse {
  name: string;
  roleCode: string;
}

export interface StreetAddressDTOResponse {
  city: string;
  countryCode: string;
  nation: string;
  regionCode: string;
  street: string;
  streetNumber: string;
  zipCode: string;
}

export interface PhoneNumberDTOResponse {
  number: string;
}

export interface EmailAddressDTOResponse {
  email: string;
}

// --

export interface SubjectListDTOResponse extends BaseDTOResponse {
  city: string;
  fiscalCode: string;
  name: string;
  region: string;
  type: string;
}

export interface PagedSubjectListDTOResponse extends Paginator {
  rows: SubjectListDTOResponse[];
}

export interface PagedOfficeDTOResponse extends Paginator {
  rows: OfficeDTOResponse[];
}

export interface PagedRepresentativeDTOResponse extends Paginator {
  rows: RepresentativeExtendedDTOResponse[];
}
