<nes-portlet-title [title]="'Elenco veicoli ' + (vehicleList.length !== 0 ? '( ' + vehicleList.length + ' )' : '')"></nes-portlet-title>
    <div class="nes-table-action _forced_padded _reduced_padding" *ngIf="!!editable">
        <div class="nes-table-action-box">
            <mat-checkbox [disableRipple]="true" (click)="$event.stopPropagation()" (change)="selectAllVehicles()" [checked]="hasFullSelection" [indeterminate]="hasIndeterminateSelection">seleziona tutti</mat-checkbox>
            <mat-icon svgIcon="mda-dots" class="separator"></mat-icon>
        </div>
        <div class="nes-table-action-box _full_width">
            <span></span>
            <button [disableRipple]="true" [class.disabled]="!hasSelection" class="flex_center" mat-button color="primary" (click)="removeVehicles()">Rimuovi</button>
            <button [disableRipple]="true" mat-button color="primary" class="flex_center" (click)="openSelectionDialog()">Aggiungi veicolo</button>
        </div>
    </div>

    <nes-portlet-table [dataSource]="vehicleList">
        <mat-table [dataSource]="paginatedVehicleList">
            <ng-container matColumnDef="checkbox">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox  *ngIf="!!editable" [disableRipple]="true" (change)="toggleVehicleSelection(row.id)" [checked]="isVehicleSelected(row.id)"></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="licensePlate">
                <mat-header-cell *matHeaderCellDef>Targa</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.licensePlate ? row.licensePlate : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="vin">
                <mat-header-cell *matHeaderCellDef>Telaio</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.vin }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="brand">
                <mat-header-cell *matHeaderCellDef>Marca</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.brand?.name ? row.brand.name : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="model">
                <mat-header-cell *matHeaderCellDef>Modello</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.model?.name ? row.model.name : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef>Colore</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.color?.name ? row.color.name : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="length">
                <mat-header-cell *matHeaderCellDef>Segmento lunghezza</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.model?.lengthSegment.name ? row.model.lengthSegment.name : '' }}</mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="vehicleDetails"></mat-header-row>
            <mat-row *matRowDef="let row; columns: vehicleDetails;" ></mat-row>
        </mat-table>
        <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [length]="vehicleList.length" (page)="onPaginationChange($event)" [pageIndex]="vehicleFilters.page" [pageSize]="vehicleFilters.pageSize" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
    </nes-portlet-table>