import {IDdtService} from "./ddt.service.interface";
import {
  IDdt,
  IDdtCarrier,
  IDdtCarrierLicencePlate,
  IDdtCustomer,
  IDdtDestination, IDdtFilters, IDdtListingDTOResponse, IDdtPaginatedList,
  IDdtSubmission,
  IDdtVehicle
} from "../shared/interfaces/ddt";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {DossierConfiguration} from "../dossier.configuration";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {Guid} from "guid-typescript";
import {
  IVehicle,
  IVehicleDamage,
  IVehicleListingDTOResponse,
  IVehiclePaginatedList
} from "../../vehicles/shared/interfaces/vehicles.type";

@Injectable({
  providedIn: 'root'
})
export class DdtService implements IDdtService {
  constructor(private http: HttpClient, private configuration: DossierConfiguration) {
  }

  searchCustomer(term: string): Observable<IDdtCustomer[]> {
    const params: any = {}
    if (term) params.search = term;
    return this
      .http
      .get<
        IDdtCustomer[]
      >(
        this.configuration.service.baseUrl + 'customers',
        {params: new HttpParams({fromObject: params})}
      );
  }

  searchDestination(term: string): Observable<IDdtDestination[]> {
    const params: any = {}
    if (term) params.search = term;
    return this
      .http
      .get<
        IDdtDestination[]
      >(
        this.configuration.service.baseUrl + 'destinations',
        { params: new HttpParams({ fromObject: params }) }
      );
  }

  submitDdt(ddt: IDdtSubmission): Observable<IDdt> {
    const body = ddt;
    return this
      .http
      .post<
        IDdt
      >(
        this.configuration.service.baseUrl + 'transport-documents',
        body
      );
  }

  updateDdt(id: string, ddt: IDdtSubmission): Observable<IDdt> {
    const body = ddt;
    return this
      .http
      .put<
        IDdt
      >(
        this.configuration.service.baseUrl + 'transport-documents/' + id,
        body
      );
  }

  searchCarrier(term: string): Observable<IDdtCarrier[]> {
    const params: any = {
      search: term
    }
    return this
      .http
      .get<
        IDdtCarrier[]
      >(
        this.configuration.service.baseUrl + 'carriers',
        { params: new HttpParams({ fromObject: params }) }
      );
  }

  searchCarrierLicensePlates(term: string): Observable<string[]> {
    const params: any = {
      search: term
    }
    return this
      .http
      .get<
        string[]
      >(
        this.configuration.service.baseUrl + 'carrier-license-plates',
        { params: new HttpParams({ fromObject: params }) }
      );
  }

  searchVin(term: string): Observable<IVehiclePaginatedList<IVehicle>> {
    const params: any = {
      PageNumber: 1,
      PageSize: 10,
      search: term
    }
    return this
      .http
      .get<
        IVehicleListingDTOResponse
      >(
        this.configuration.service.baseUrl + 'vehicles',
        {params: new HttpParams({fromObject: params})}
      )
      .pipe(
        map(r => {
          const rows = r.rows.map((row) => {
            return {
              id: Guid.parse(row.id),
              vin: row.vin,
              licensePlate: row.licensePlate,
              color: row.color,
              date: new Date(row.createdAt),
              customer: row.customer,
              brand: row.manufacturer,
              model: row.model
            } as IVehicle
          });
          return {
            rows,
            totalRows: r.totalRowCount
          } as IVehiclePaginatedList<IVehicle>
        })
      );
  }

  fetchDdtList(filters: IDdtFilters): Observable<IDdtPaginatedList<IDdt>> {
    const params: any = {
      PageNumber: filters.page,
      PageSize: 10
    }
    return this
      .http
      .get<
        IDdtListingDTOResponse
      >(
        this.configuration.service.baseUrl + 'transport-documents',
        { params: new HttpParams({ fromObject: Object.assign(params, {
              PageNumber: params.PageNumber + 1
            }) }) }
      )
      .pipe(
        map(r => {
          const rows = r.rows.map((row) => {
            return this.listedDdtDTO(row);
          });
          return {
            rows,
            totalRows: r.totalRowCount
          } as IDdtPaginatedList<IDdt>
        })
      );
  }

  fetchDdt(id: string): Observable<IDdt> {
    return this
      .http
      .get<
        IDdt
      >(
        this.configuration.service.baseUrl + 'transport-documents/' + id
      );
  }

  fetchDdtSign(id: string): Promise<Response> {
    const authToken = localStorage.getItem("ACCESS_TOKEN")!;
    return fetch( this.configuration.service.baseUrl + 'transport-documents/' + id + '/signature', {
      headers: { 'Authorization': authToken }
    })
  }
  
  fetchDdtDamages(id: string): Observable<IVehicleDamage[]> {
    return this.http.get<IVehicleDamage[]>(this.configuration.service.baseUrl + 'transport-documents/' + id + '/damages');
  }

  private listedDdtDTO(data: any) {
    if(!data) return null;
    return {
      id: data.id,
      createdAt: data.createdAt,
      vehicles: data.vehicles,
      number: data.number,
      customer: data.customer,
      sender: data.sender,
      destination: data.destination,
      shippingNumber: data.shippingNumber,
      carrier: data.carrier,
      carrierLicensePlate: data.carrierLicensePlate
    }
  }
}
