<div class="nes-header">
  <div class="nes-header-box">
    <mat-icon svgIcon="mda-calendar" class="_left"></mat-icon>
    <div class="nes-header-time">
      {{ currentDate | date:'EEEE' }}
      <span>{{ currentDate | date:'dd/MM/yyyy' }}</span>
    </div>
  </div>

  <div class="nes-header-box">
    <ng-content></ng-content>
  </div>

  <div class="nes-header-box">
    <div class="nes-header-user">
      {{ userLastname }} {{ userFirstname }}
      <span>{{ userEmail }}</span>
    </div>
  </div>
</div>
