<div class="user-panel">
  <h5>{{userName}}</h5>
  <mat-icon
    matTooltip="Cambia password"
    matTooltipPosition="above"
    [routerLink]="'/auth/change-password'"
  >lock_reset</mat-icon>
  <mat-icon
            class="_light _right"
            (click)="logout()"
            matTooltip="Logout"
            matTooltipPosition="above">logout</mat-icon>
</div>
