<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Rendicontazione']" [title]="'Scarica rendicontazione'" [loading]="false"
  [withoutBorder]="true">
  <nes-portlet-title [title]="'Periodo e contratto'">
  </nes-portlet-title>
  <form class="nes-portlet-form-wr" [formGroup]="form">
    <div class="nes-portlet-form-wr">
      <div class="nes-portlet-form-col _two">
        <div class="_input_group datepicker">
          <mat-form-field>
            <mat-label>Mese e anno</mat-label>
            <input [max]="today" matInput [matDatepicker]="dp" formControlName="date" readonly>
            <mat-datepicker-toggle [disableRipple]="true" [for]="dp">
              <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
              (monthSelected)="chosenMonthHandler($event, dp)" panelClass="month-picker">
            </mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="nes-portlet-form-col _two textual-field contract-div">
        <mat-form-field class="full-width">
          <mat-label>Contratto</mat-label>
          <input matInput placeholder="" [formControl]="contractFormControl" [matAutocomplete]="autocompleteContract"
            (blur)="contractFormControl.updateValueAndValidity()">
        </mat-form-field>
        <mat-autocomplete #autocompleteContract="matAutocomplete" (optionSelected)="handleContractAutocomplete($event)">
          <mat-option *ngFor="let option of contractOptions" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
  </form>
  <div class="nes-portlet-form-submit outside">
    <button type="button" mat-button [disableRipple]="true" [disabled]="isSubmitDisabled"
      (click)="requestBillingReport()" class="form-submit-button">
      <span class="label">Scarica</span>
    </button>
  </div>
</nes-portlet>