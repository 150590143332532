import {Component, Inject, OnInit} from '@angular/core';
import {AUTHENTICATION_SERVICE, IAuthenticationService} from "../../shared/services/authentication.service.interface";
import {Router} from "@angular/router";

@Component({
  selector: 'user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})
export class UserPanelComponent implements OnInit {

  constructor(
    private router: Router,
    @Inject(AUTHENTICATION_SERVICE) private authService: IAuthenticationService) {}

  ngOnInit(): void {
  }

  get userName() {
    const userData = this.authService.getUserData();
    const userNameParts = [];
    if (userData.firstName) userNameParts.push(userData.firstName);
    if (userData.lastName) userNameParts.push(userData.lastName);
    return userNameParts.join(' ');
  }

  public async logout(): Promise<void> {
    try {
      await this.authService.logout();
      this.router.navigate(['/auth/login']);
    } catch (e) {
      console.error(e);
    }
  }
}
