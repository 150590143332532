import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RegistryListComponent} from './registry-list/registry-list.component';
import {VehicleBrandsListComponent} from "./lists/vehicle-brands-list/vehicle-brands-list.component";
import {VehicleModelsListComponent} from "./lists/vehicle-models-list/vehicle-models-list.component";
import {CustomersListComponent} from "./lists/customers-list/customers-list.component";
import {ContractsListComponent} from "./lists/contracts-list/contracts-list.component";
import {DestinationsListComponent} from "./lists/destinations-list/destinations-list.component";
import {CarriersListComponent} from "./lists/carriers-list/carriers-list.component";

const routes: Routes = [
  {
    path: 'marche',
    component: VehicleBrandsListComponent
  },
  {
    path: 'modelli',
    component: VehicleModelsListComponent
  },
  {
    path: 'committenti',
    component: CustomersListComponent
  },
  {
    path: 'contratti',
    component: ContractsListComponent
  },
  {
    path: 'trasportatori',
    component: CarriersListComponent
  },
  {
    path: 'destinatari',
    component: DestinationsListComponent
  },
  // {
  //   path: 'dettaglio/:id',
  //   component: RegistryDetailComponent
  // },
  // {
  //   path: 'nuova',
  //   component: RegistryDetailComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistryRoutingModule {
}
