import {Component, OnDestroy, OnInit} from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'nes-header',
  templateUrl: './nes-header.component.html'
})
export class NesHeaderComponent implements OnInit, OnDestroy {

  private user: any;

  // --

  public currentDate: any;

  // --

  constructor() {
  }

  ngOnInit(): void {
    this.currentDate = moment.now();
  }

  ngOnDestroy(): void {
  }

  // --

  get userEmail(): string {
    if (this.user && this.user.entry) {
      return this.user.entry.email;
    }

    return '';
  }

  get userFirstname(): string {
    if (this.user && this.user.entry) {
      return this.user.entry.firstName;
    }

    return '';
  }

  get userLastname(): string {
    if (this.user && this.user.entry) {
      return this.user.entry.lastName;
    }

    return '';
  }

}
