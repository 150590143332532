<div class="dossier-movement-source-and-destination-container">

    <ng-container *ngIf="!isSelectBefore" ><ng-container *ngTemplateOutlet="sourceAndDestinationFields"></ng-container></ng-container>

    <div class="nes-portlet-form-col _three"
         *ngIf="!!vehicleTypeControl"
         [formGroup]="vehicleTypeControl"
        >
        <div class="select_field">
            <mat-form-field [ngClass]="{'readonly': !_editable}" >
              <mat-label>Mezzo di trasporto</mat-label>
              <mat-select #transportField formControlName="type" (selectionChange)="changeVehicleType(transportField.value)">
                <mat-option *ngFor="let vehicle of movementVehicles;" [value]="vehicle.index" (click)="changeVehicleType(vehicle.index)">{{vehicle.description}}</mat-option>
              </mat-select>
              <nes-icon svgIcon="blue_angle_bottom" (click)="transportField.toggle()"></nes-icon>
              <mat-error *ngIf="!!isVehicleTypeSelectRequired">Campo <b>obbligatorio</b></mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="nes-portlet-form-col _three"
         *ngIf="!!getVehicleTypeDetailField('carTransporterDetails') && !getVehicleTypeDetailField('carTransporterDetails').get('fieldValue').disabled"
         >
        <mat-form-field [class.hasError]="!!isCarTransporterDetailsFieldRequired">
            <mat-chip-list #licensePlatesChipList aria-label="Targhe Bisarca">
                <mat-chip
                    *ngFor="let licensePlate of licensePlates"
                    (removed)="removeLicensePlate(licensePlate)"
                >
                {{licensePlate}}
                <mat-icon *ngIf="_editable" matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input 
                placeholder="Targhe"
                #licensePlatesInput
                [formControl]="getCarTransporterValueControl()"
                [matChipInputFor]="licensePlatesChipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addLicensePlates()"
                [readonly]="!_editable"
                (blur)="addLicensePlates()">
            </mat-chip-list>
        </mat-form-field>
        <mat-error *ngIf="!!isCarTransporterDetailsFieldRequired" class="standalone">Campo <b>obbligatorio</b></mat-error>
    </div>

    <div class="nes-portlet-form-col _three"
         *ngIf="!!getVehicleTypeDetailField('shipName') && !getVehicleTypeDetailField('shipName').disabled "
         >
        <mat-form-field [class.hasError]="!!isShipNameFieldRequired">
            <input 
            matInput 
            placeholder="Nome Nave" 
            [formControl]="getVehicleTypeDetailField('shipName')" 
            (blur)="getVehicleTypeDetailField('shipName').markAsTouched()" 
            [readonly]="!_editable">
        </mat-form-field>
        <mat-error *ngIf="!!isShipNameFieldRequired" class="standalone">Campo <b>obbligatorio</b></mat-error>
    </div>

    <div class="nes-portlet-form-col _three"
         *ngIf="!!getVehicleTypeDetailField('trainNumber') && !getVehicleTypeDetailField('trainNumber').disabled "
         >
        <mat-form-field  [class.hasError]="!!isTrainNumberFieldRequired">
            <input 
            matInput 
            placeholder="Numero Treno" 
            [formControl]="getVehicleTypeDetailField('trainNumber')" 
            [readonly]="!_editable">
        </mat-form-field>
        <mat-error *ngIf="!!isTrainNumberFieldRequired" class="standalone">Campo <b>obbligatorio</b></mat-error>
    </div>

    <ng-container *ngIf="!!isSelectBefore" ><ng-container *ngTemplateOutlet="sourceAndDestinationFields"></ng-container></ng-container>

</div>