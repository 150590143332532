import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from "rxjs";
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from "../../services/vehicle-details-service.service.interface";
import { IVehicle, IVehicleDamage } from "../../shared/interfaces/vehicles.type";



@Component({
  selector: 'app-vehicle-damages-dialog',
  templateUrl: './vehicle-damages-dialog.component.html',
  styleUrls: ['./vehicle-damages-dialog.component.scss']
})
export class VehicleDamagesDialogComponent implements OnInit {
  public loading = false;
  public firstLoad = true;
  public vehicle: IVehicle;
  public damages: IVehicleDamage[];

  private subscriptions: Subscription[] = [];



  constructor(
    public dialog: MatDialogRef<VehicleDamagesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(VEHICLE_DETAILS_SERVICE) private service: IVehicleDetailsService) {
    this.vehicle = data.vehicle;
  }



  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  ngOnInit(): void {
    if (this.vehicle.id) this.fetchDamages();
  }

  get tableColumns(): string[] {
    return ['positionCode', 'damageCode', 'severityCode', 'note'];
  }

  private fetchDamages() {
    this.loading = true;
    this.subscriptions.push(
      this.service.getVehicleDamages(this.vehicle.id).subscribe((response: IVehicleDamage[]) => {
        this.damages = response;
        this.loading = false;
        this.firstLoad = false;
      })
    )
  }
  
  public print() {
    window.print();
  }

  public close(): void {
    this.dialog.close();
  }
}
