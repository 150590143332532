<button class="nes_close_btn" mat-icon-button (click)="cancel()"><mat-icon>clear</mat-icon></button>

<nes-dialog [title]="'Seleziona tipo pratica'" [withoutBorder]="false">
  <form >
    <div class="nes-portlet-form-wr">
      <div class="nes-portlet-form-col _two select_field">
        <mat-form-field [formGroup]="formData">
          <mat-label>Tipo</mat-label>
          <mat-select #dossierTypeSelector formControlName="type">
            <mat-option *ngFor="let type of dossierTypes;" [value]="type.slug">
              {{type.name}}
            </mat-option>
          </mat-select>
          <nes-icon svgIcon="blue_angle_bottom"></nes-icon>
          <mat-error *ngIf="formData.controls['type'].hasError('required')">
            Campo obbligatorio
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="form-footer">
    <button [disableRipple]="true" mat-button (click)="cancel()">Annulla</button>
    <button [disableRipple]="true" class="form-submit-button" mat-button (click)="confirmSelection()">Conferma</button>
  </div>
</nes-dialog>
