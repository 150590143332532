import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {IDdtCarrier, IDdtDestination} from "../../../dossiers/shared/interfaces/ddt";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {DDT_SERVICE, IDdtService} from "../../../dossiers/services/ddt.service.interface";

@Component({
  selector: 'carriers-list',
  templateUrl: './carriers-list.component.html',
  styleUrls: ['./carriers-list.component.scss']
})
export class CarriersListComponent implements OnInit, OnDestroy {
  public loading = false;
  public firstLoad = true;
  public shouldUpdate = false;
  public tableFilter: any;
  carriers: IDdtCarrier[];
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              @Inject(DDT_SERVICE) private ddtService: IDdtService) { }

  ngOnInit(): void {
    this.fetchCarriersList()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }


  get tableColumns(): string[] {
    return ['label'];
  }

  private checkQueuedRequests(): boolean {
    if (this.loading) {
      this.shouldUpdate = true;
    }

    return this.loading;
  }

  private getLabel(customer: IDdtCarrier): string {
    return customer.value || "N/A";
  }

  private fetchCarriersList(): void {
    if (this.checkQueuedRequests()) return;
    this.loading = true;
    this.subscriptions.push(
      this.ddtService
        .searchCarrier("")
        .subscribe(res => {
            if (this.shouldUpdate) {
              this.shouldUpdate = false;
              this.loading = false;
              this.fetchCarriersList();
              return;
            }
            else {
              this.carriers = res;
              this.shouldUpdate = true;
            }
            this.firstLoad = false;
          },
          err => {
            console.log(err)
          },
          () => {
            this.loading = false;
          }
        ));
  }
}

