import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BillingValidators } from "../../billing/validators/billing-validators";
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from "../services/vehicle-details-service.service.interface";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";



@Component({
  selector: 'import-damages',
  templateUrl: './import-damages.component.html',
  styleUrls: ['./import-damages.component.scss']
})
export class ImportDamagesComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  public file: File | undefined;
  private activeModelControl: boolean = false;
  public progress: number | undefined;
  public fileName: string | undefined;
  public importResponse: any | undefined;
  public importResult: any | undefined;
  public loading = false;
  public importForm: FormGroup;
  public disableRestrictedInputs = false;
  public productColumns = [1]
  public toggledModelColumnInput: boolean = false;
  public toggledDateColumnInput: boolean = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(@Inject(VEHICLE_DETAILS_SERVICE) private service: IVehicleDetailsService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.importForm = this.formBuilder.group({
      customer: ["", [Validators.required]],
      brand: ["", [Validators.required]],
      modelColumn: [{ value: 1, disabled: true }, [Validators.required, Validators.min(1)]],
      arrivalDate: [""],
      arrivalDateColumn: [{ value: 1, disabled: true }, [Validators.required, Validators.min(1)]],
      arrivedBy: [null, [Validators.required]],
      modelInputType: ["id"],
    });
  }

  public onFileSelected(event: any): void {
    this.fileName = event.target.files[0].name;
    this.file = event.target.files[0];
  }

  public async onUpload(): Promise<void> {
    if (this.file) {
      this.loading = true;
      const disabled = [];
      Object.keys(this.importForm.controls).forEach(key => {
        let control = this.importForm.controls[key];
        if (control.disabled) disabled.push(key);
      });
      this.importForm.disable();
      try {
        const event = await this.service
          .uploadDamages(this.file)
          .toPromise();
        this.importResponse = [];
        if (!event.railroadCars) event.railroadCars = {
          "Veicoli gestiti": event.vehicles
        }
        this.generateImportResult(event.railroadCars);
        Object.keys(event.railroadCars).forEach((item) => {
          this.importResponse.push({
            key: item,
            completedItems: event.railroadCars[item].filter((vehicle) => vehicle.status === 'CREATED').length,
            values: event.railroadCars[item]
          })
        });

      } catch (error) {
        console.log(error);
      }
      this.importForm.enable();
      disabled.forEach(key => this.importForm.controls[key].disable());
      this.loading = false;
    }
  }

  generateImportResult(railroadCars) {
    this.importResult = {
      succeeded: 0,
      failed: 0,
      alreadyExists: 0,
      total: 0
    }
    Object.keys(railroadCars).forEach((item) => {
      railroadCars[item].forEach((car) => {
        this.importResult.total++;
        switch (car.status) {
          case 'ERROR':
            this.importResult.failed++;
            break;
          case 'ALREADY_EXISTS':
            this.importResult.alreadyExists++;
            break;
          default:
            this.importResult.succeeded++;
            break;
        }
      })
    })
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public removeProductColumnIndex(column: number) {
    this.productColumns = this.productColumns.filter((value) => value !== column);
  }

  addProductColumnIndex($event: MatChipInputEvent) {
    const input = $event.input;
    const value = $event.value;

    if (!Number.isNaN(parseInt(value)) && !this.productColumns.includes(parseInt(value))) {
      this.productColumns.push(parseInt(value));
    }

    if (input) {
      input.value = '';
    }

    this.productColumns.sort((a, b) => a - b);
  }
}
