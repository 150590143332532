import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from "@angular/material/snack-bar";



@Injectable()
export class SnackbarInterceptor implements HttpInterceptor {
  public constructor(private snackBar: MatSnackBar) { }
  
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((err: HttpErrorResponse, caught) => {
          if (err.error instanceof Error) {
            this.triggerSnackbar('Errore sconosciuto');
          } else {
            if (this.isString(err.error)) {
              this.triggerSnackbar('Error: ' + err.error);
            } else {
              this.triggerSnackbar('Error: ' + err.message || err.statusText);
            }
          }
          if (err.status && (err.status === 401 || err.status === 403) && err.url && !err.url.endsWith('login')) {
            return of<HttpEvent<any>>();
          }
          throw err;
        })
      ) as Observable<HttpEvent<any>>;
  }



  private triggerSnackbar(message: string): void {
    this.snackBar.open(message, '', {
      panelClass: 'nes-snackbar-error',
      duration: 4000,
      horizontalPosition: 'right'
    });
  }

  private isString(obj) {
    return (Object.prototype.toString.call(obj) === '[object String]');
  }
}
