import { Injectable } from '@angular/core';
import { IServiceAreaMappingService } from './service-area-mapping.service.interface';
import { Observable } from 'rxjs';
import { IMapSidebarVehicleDetails } from "../interfaces/map.type";
import {HttpClient, HttpParams} from "@angular/common/http";
import {map} from "rxjs/operators";
import {ServiceAreaMappingConfiguration} from "../../dossier.configuration";
import MapSector from "../../classes/sector";
import {Vertex} from "../../classes/geometry";
import {ILaneDetails, ISectorGroup} from "../../types/service-area-mapping";
import {
  IVehicle,
  IVehicleListingDTOResponse,
  IVehiclePaginatedList
} from "../../../vehicles/shared/interfaces/vehicles.type";
import {Guid} from "guid-typescript";
import {SectorType} from "../enums/sector-type";

@Injectable({
  providedIn: 'root'
})
export class ServiceAreaMappingService implements IServiceAreaMappingService {

  constructor(private http: HttpClient, private serviceAreaMappingConfiguration: ServiceAreaMappingConfiguration) {}
  getSectorsWithDetail(type: SectorType = SectorType.All): Observable<MapSector[]> {
    let sectorEndpoint = 'sectors';
    if (type === SectorType.Parking) sectorEndpoint = 'parking-sectors';
    if (type === SectorType.Outbound) sectorEndpoint = 'outbound-sectors';
    return this
      .http
      .get<
        any
      >(
        this.serviceAreaMappingConfiguration.service.baseUrl + sectorEndpoint
      )
      .pipe(map((r: any[]) => {
        return r.map((object) => {
          return new MapSector({
            id: object.id,
            label: object.label,
            color: object.color,
            vertices: {
              tl: new Vertex(object.upLeftX, object.upLeftY),
              tr: new Vertex(object.upRightX, object.upRightY),
              bl: new Vertex(object.downLeftX, object.downLeftY),
              br: new Vertex(object.downRightX, object.downRightY)
            },
            laneLength: object.laneLength,
            isOutboundSector: object.isOutboundSector,
            laneCount: object.laneCount,
            lanes: object.lanes,
            numerationType: object.numerationType,
            parentId: object.parentId,
            numerationStart: object.numerationStart,
            groupId: object.groupId,
            vehicleCount: object.vehicleCount,
            order: object.order || 0,
            slotCount: object.slotCount || 0,
          });
        })

      }));
  }

  getSectorLanes(sectorId: string): Observable<ILaneDetails[]> {
    return this
      .http
      .get<
        ILaneDetails[]
      >(
        this.serviceAreaMappingConfiguration.service.baseUrl + 'sectors/' + sectorId + '/lanes'
      );
  }

  getLaneVehicles(sectorId: string, laneId: number): Observable<IMapSidebarVehicleDetails[]> {
    return this
      .http
      .get<
        any[]
      >(
        this.serviceAreaMappingConfiguration.service.baseUrl + 'sectors/' + sectorId + '/lanes/' + laneId
      )
      .pipe(
        map(r => {
          return r.map((vehicle) => {
            return {
              vin: vehicle.vin,
              licensePlate: vehicle.licensePlate,
              sector: sectorId,
              lane: laneId,
              isLost: vehicle.status === 'Lost',
              brand: vehicle.brand,
              model: vehicle.model,
              costumer: vehicle.customer,
              destinationAddress: vehicle.destinationAddress,
              destinationCode: vehicle.destinationCode
            }
          });
        })
      );
  }

  searchForVehicleByVin(vin: string): Observable<IVehiclePaginatedList<IVehicle>> {
    const params: any = {
      PageNumber: 1,
      PageSize: 10,
      search: vin
    }
    return this
      .http
      .get<
        IVehicleListingDTOResponse
      >(
        this.serviceAreaMappingConfiguration.service.baseUrl + 'vehicles',
        {params: new HttpParams({fromObject: params})}
      )
      .pipe(
        map(r => {
          const rows = r.rows.map((row) => {
            return {
              id: Guid.parse(row.id),
              vin: row.vin,
              licensePlate: row.licensePlate,
              customer: row.customer,
              color: row.color,
              date: new Date(row.createdAt),
              brand: row.manufacturer,
              destinationAddress: row.destinationAddress,
              model: row.model
            } as IVehicle
          });
          return {
            rows,
            totalRows: r.totalRowCount
          } as IVehiclePaginatedList<IVehicle>
        })
      );
  }

  getVehicleDetails(vin): Observable<any> {
    const params: any = {
      vin
    }
    return this.http.get<any>(
        this.serviceAreaMappingConfiguration.service.baseUrl + 'search-vehicle/',
        { params: new HttpParams({ fromObject: params }) }
      );
  }

  getSectorGroups(): Observable<ISectorGroup[]> {
    return this
      .http
      .get<
        ISectorGroup[]
      >(
        this.serviceAreaMappingConfiguration.service.baseUrl + 'groups'
      );
  }

  getSectorAnomalies(sectorId: string): Observable<any[]> {
    return this
      .http
      .get<
        any[]
      >(
        this.serviceAreaMappingConfiguration.service.baseUrl + 'sectors/' + sectorId + '/anomalies'
      );
  }
}
