<nes-header></nes-header>

<nes-portlet [breadcrumb]="['Home', 'Veicoli']" [title]="'Lista veicoli'" [withoutBorder]="false" [loading]="firstLoad" class="vehicle-list">
  <nes-portlet-filter-bar (search)="applyFilter($event)">
    <div class="nes-portlet-filter-bar-col select_field">
      <mat-form-field>
        <mat-label>Stato</mat-label>
        <mat-select #sectorVehicleStatus [formControl]="statusSelect">
          <mat-option [value]="option.code" *ngFor="let option of statusFilterChoices" (click)="setVehicleStatusFilter(option.value)">{{option.label}}</mat-option>
        </mat-select>
        <nes-icon svgIcon="blue_angle_bottom"  (click)="sectorVehicleStatus.toggle()"></nes-icon>
      </mat-form-field>
      <mat-icon *ngIf="statusSelect.value" (click)="clearVehicleStatusSelect();sectorVehicleStatus.close()">clear</mat-icon>
    </div>
    <div class="nes-portlet-filter-bar-col textual-field">
      <mat-form-field>
        <input matInput placeholder="Codice destinazione" [value]="destinationCode" #destinationCodeFilter>
      </mat-form-field>
    </div>
    <div class="nes-portlet-filter-bar-col textual-field">
      <mat-form-field class="full-width">
        <mat-label>Commitente</mat-label>
        <input
          matInput
          placeholder=""
          [formControl]="customerFormControl"
          [matAutocomplete]="autocompleteCustomer"
          (blur)="customerFormControl.updateValueAndValidity()"
        >
      </mat-form-field>
      <mat-autocomplete #autocompleteCustomer="matAutocomplete" (optionSelected)="handleCustomerAutocomplete($event)">
        <mat-option *ngFor="let option of customerOptions" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="nes-portlet-filter-bar-col textual-field">
      <mat-form-field class="full-width">
        <mat-label>Contratto</mat-label>
        <input
          matInput
          placeholder=""
          [formControl]="contractFormControl"
          [matAutocomplete]="autocompleteContract"
          (blur)="contractFormControl.updateValueAndValidity()"
        >
      </mat-form-field>
      <mat-autocomplete #autocompleteContract="matAutocomplete" (optionSelected)="handleContractAutocomplete($event)">
        <mat-option *ngFor="let option of contractOptions" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="nes-portlet-filter-bar-col align-right">
      <button [disableRipple]="true" mat-button color="accent" class="flex_center" (click)="downloadCSV()">
        <mat-icon>download</mat-icon>
        Scarica CSV
      </button>
    </div>
  </nes-portlet-filter-bar>

  <nes-portlet-table [dataSource]="vehicles" >
    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="vehicles">
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Targa o telaio</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getLicencePlateAndVinCombination(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Inserimento</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.date | date: 'dd/MM/y' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="contract">
          <mat-header-cell *matHeaderCellDef>Contratto</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getFirstRow(row.contract) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="manufacturer-and-model">
          <mat-header-cell *matHeaderCellDef>Marca e modello</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getManufacturerAndModelCombination(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="destination-address">
          <mat-header-cell *matHeaderCellDef>Indirizzo di destinazione</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.destinationAddress }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Colore</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.color }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="sector-and-lane">
          <mat-header-cell *matHeaderCellDef>Settore e fila</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getSectorAndLaneCombination(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Stato</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getVehicleStatusLabel(row.status)}}

            <button
              [disableRipple]="true"
              type="button"
              class="flex_center icon-btn push-left _input-complimentary "
              color="accent"
              mat-button
              *ngIf="!!row.ddt"
              [routerLink]="['/ddt/dettaglio/' + row.ddt.id]"
            >
              <mat-icon>visibility</mat-icon>
            </button>
          </mat-cell>1
        </ng-container>
        <ng-container matColumnDef="indicator">
          <mat-header-cell *matHeaderCellDef class="col-10"></mat-header-cell>
          <mat-cell *matCellDef="let row" class="col-10 icon-edit">
            <nes-icon svgIcon="blue_angle"></nes-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="vehicleDetails"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: vehicleDetails;" [routerLink]="'/veicoli/dettaglio/' + row.id" ></mat-row>
      </mat-table>
    </div>
      <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [pageIndex]="vehicleFilter.pageNumber" (page)="onPaginationChange($event)" [length]="totalVehicles" [pageSize]="vehicleFilter.pageSize" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </nes-portlet-table>
</nes-portlet>
