import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NesModule } from '../../shared/nes.module';
import { BillingRoutingModule } from './billing-routing.module';
import { ComponentModule } from 'src/app/common/components/component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BILLING_SERVICE } from './shared/services/billing.service.interface';
import { BillingService } from './shared/services/billing.service';
import { BillingComponent } from './components/billing/billing.component';
import { RegistryModule } from '../registry/registry.module';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



@NgModule({
  declarations: [
    BillingComponent,
    StatisticsComponent
  ],
  imports: [
    CommonModule,
    BillingRoutingModule,
    NesModule,
    ComponentModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatAutocompleteModule,
    RegistryModule,
    MatDatepickerModule
  ]
})
export class BillingModule {
  static forRoot(): ModuleWithProviders<BillingModule> {
    return {
      ngModule: BillingModule,
      providers: [
        {
          provide: BILLING_SERVICE,
          useClass: BillingService
        }
      ]
    };
  }
}
