
<form #vehicleDetailsGeneralForm autocomplete="off" [formGroup]="formData" class="vehicle_details_general_form" >

  <div class="nes-portlet-form-wr">

      <div class="nes-portlet-form-col _three" *ngIf="isFieldVisible('customer')">
          <div class="_input_group">
            <registry-subject-selection-input 
            placeholder="Cliente" 
            [formControl]="formData.controls['customer']"
            [requiredError]="!!isCustomerFieldRequired">
          </registry-subject-selection-input>
            <mat-error *ngIf="!!isCustomerFieldRequired" class="standalone">Campo <b>obbligatorio</b></mat-error>
          </div>
      </div>

      <div class="nes-portlet-form-col _three" *ngIf="isFieldVisible('arrivalOrDepartureDate')">
          <div class="_input_group">
            <mat-form-field>
              <mat-label>{{dossierType === 'ingress' ? 'Data Arrivo' : 'Data Partenza'}}</mat-label>
              <input matInput [matDatepicker]="arrivalOrDepartureDate" formControlName="arrivalOrDepartureDate" (click)="arrivalOrDepartureDate.open()" readonly>
              <mat-datepicker-toggle [disableRipple]="true"  matSuffix *ngIf="!formData.controls['arrivalOrDepartureDate'].disabled" [for]="arrivalOrDepartureDate">
                <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #arrivalOrDepartureDate disabled="false"></mat-datepicker>
              <mat-error *ngIf="formData.controls['arrivalOrDepartureDate'].hasError('required')">Campo <b>obbligatorio</b></mat-error>
            </mat-form-field>
          </div>
      </div>

      <div class="nes-portlet-form-col _three" *ngIf="isFieldVisible('occurrenceDate')">
          <div class="_input_group">
            <mat-form-field>
              <mat-label>Data Avvenimento</mat-label>
              <input matInput [matDatepicker]="occurrenceDate" formControlName="occurrenceDate" (click)="occurrenceDate.open()" readonly>
              <mat-datepicker-toggle [disableRipple]="true"  matSuffix *ngIf="!formData.controls['occurrenceDate'].disabled" [for]="occurrenceDate">
                <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #occurrenceDate disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
      </div>

      <div class="nes-portlet-form-col _three" *ngIf="isFieldVisible('discoveryDate')">
          <div class="_input_group">
            <mat-form-field>
              <mat-label>Data Rinvenimento</mat-label>
              <input matInput [matDatepicker]="discoveryDate" formControlName="discoveryDate" (click)="discoveryDate.open()" readonly>
              <mat-datepicker-toggle [disableRipple]="true"  matSuffix *ngIf="!formData.controls['discoveryDate'].disabled" [for]="discoveryDate">
                <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #discoveryDate disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
      </div>

      <div class="nes-portlet-form-col _three" *ngIf="isFieldVisible('expiryDate')">
          <div class="_input_group">
            <mat-form-field>
              <mat-label>Data Scadenza</mat-label>
              <input matInput [matDatepicker]="expiryDate" formControlName="expiryDate" (click)="expiryDate.open()" readonly>
              <mat-datepicker-toggle [disableRipple]="true"  matSuffix *ngIf="!formData.controls['expiryDate'].disabled" [for]="expiryDate">
                <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #expiryDate disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
      </div>

      <div class="nes-portlet-form-col _three hour_field" *ngIf="isFieldVisible('arrivalHour')" [ngClass]="{'disabled': !formData.controls['arrivalHour'].enabled}">
          <ng-template #timepickerIcon>
            <nes-icon svgIcon="blue_angle" ngxMaterialTimepickerToggleIcon></nes-icon>
          </ng-template>
          <mat-label>Ora / minuti</mat-label>
          <ngx-timepicker-field [toggleIcon]="timepickerIcon" [format]="24" [disabled]="!formData.controls['arrivalHour'].enabled" [cancelBtnTmpl]="cancelBtn"
          [confirmBtnTmpl]="confirmBtn" [formControl]="formData.controls['arrivalHour']" [defaultTime]="formData.controls['arrivalHour'].value"></ngx-timepicker-field>
          <ng-template #cancelBtn>
            <button mat-button class="cancel_button">ANNULLA</button>
          </ng-template>
          <ng-template #confirmBtn>
            <button class="form-action-button" mat-button>SELEZIONA ORARIO</button>
          </ng-template>
          <mat-error *ngIf="formData.controls['arrivalHour'].hasError('required')">Campo <b>obbligatorio</b></mat-error>
      </div>

      <div class="nes-portlet-form-col _three hour_field" *ngIf="isFieldVisible('departureHour')" [ngClass]="{'disabled': !formData.controls['departureHour'].enabled}">
          <ng-template #timepickerIcon>
            <nes-icon svgIcon="blue_angle" ngxMaterialTimepickerToggleIcon></nes-icon>
          </ng-template>
          <mat-label>Ora / minuti</mat-label>
          <ngx-timepicker-field [toggleIcon]="timepickerIcon" [format]="24" [disabled]="!formData.controls['departureHour'].enabled" [cancelBtnTmpl]="cancelBtn"
          [confirmBtnTmpl]="confirmBtn" [formControl]="formData.controls['departureHour']" [defaultTime]="formData.controls['departureHour'].value"></ngx-timepicker-field>
          <ng-template #cancelBtn>
            <button mat-button class="cancel_button">ANNULLA</button>
          </ng-template>
          <ng-template #confirmBtn>
            <button class="form-action-button" mat-button>SELEZIONA ORARIO</button>
          </ng-template>
          <mat-error *ngIf="formData.controls['departureHour'].hasError('required')">Campo <b>obbligatorio</b></mat-error>
      </div>

      <div class="nes-portlet-form-col _three" *ngIf="isFieldVisible('isDateConfirmed')">
          <mat-slide-toggle
              formControlName="isDateConfirmed"
              [color]="'primary'">
              Orario confermato
          </mat-slide-toggle>
      </div>

      <div class="nes-portlet-form-col _three" *ngIf="isFieldVisible('completionDate')">
          <div class="_input_group">
            <mat-form-field>
              <mat-label>Data Completamento</mat-label>
              <input matInput [matDatepicker]="completionDate" formControlName="completionDate" (click)="completionDate.open()" readonly>
              <mat-datepicker-toggle [disableRipple]="true"  matSuffix *ngIf="!formData.controls['completionDate'].disabled" [for]="completionDate">
                <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #completionDate disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
      </div>

      <div class="nes-portlet-form-col" *ngIf="isFieldVisible('notes')">
          <div class="_input_group">
            <mat-form-field>
              <mat-label>Note</mat-label>
              <textarea matInput formControlName="notes" [disabled]="!formData.controls['notes'].enabled"></textarea>
            </mat-form-field>
          </div>
      </div>

      <dossier-movement-source-and-destination-controls 
              *ngIf="isFieldVisible('source') || isFieldVisible('destination') || isFieldVisible('vehicleTransport')"
              #movementPositionControls
              [editable]="editable"
              [dossierType]="dossierType" 
              [isSelectBefore]="dossierType === 'exgress' ? false : true" 
              [(vehicleTypeControl)]="formData.controls['vehicleTransport']" 
              >
      </dossier-movement-source-and-destination-controls>
  </div>

</form>