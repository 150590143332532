import {CollectionViewer, DataSource} from '@angular/cdk/collections';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {Paginator} from '../../../../common/interfaces/paginator.type';
import {PagedSubjectListDTOResponse, RegistryFilter, SubjectListDTOResponse} from '../interfaces/registry.type';

import {RegistryService} from '../services/registry.service';

// --

export class RegistryDatasource implements DataSource<SubjectListDTOResponse> {

  private dataSubject: BehaviorSubject<SubjectListDTOResponse[]>;
  private loadingSubject: BehaviorSubject<boolean>;

  private paginator: Paginator;

  // --

  constructor(private registryService: RegistryService) {
    this.dataSubject = new BehaviorSubject<SubjectListDTOResponse[]>([]);
    this.loadingSubject = new BehaviorSubject<boolean>(false);

    this.pagination = {pageCount: 0, pageNumber: 0, pageSize: 0, rowCount: 0, unreadRowCount: 0};
  }

  // --

  connect(collectionViewer: CollectionViewer): Observable<SubjectListDTOResponse[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  // --

  get length(): number {
    return this.data.length;
  }

  get data(): SubjectListDTOResponse[] {
    return this.dataSubject.getValue();
  }

  set data(data: SubjectListDTOResponse[]) {
    this.dataSubject.next(data || []);
  }

  get pagination(): Paginator {
    return this.paginator;
  }

  set pagination(paginator: Paginator) {
    this.paginator = paginator;
  }

  get isLoading(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  // --

  public activateLoader(): void {
    this.loadingSubject.next(true);
  }

  public deactivateLoader(): void {
    this.loadingSubject.next(false);
  }

  public fetchRegistry(filter: RegistryFilter) {
    this.activateLoader();

    // fetch registry objects
    this.registryService.fetchRegistry(filter)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.deactivateLoader())
      )
      .subscribe((response: PagedSubjectListDTOResponse) => {
        this.dataSubject.next(response.rows || []);

        // refresh page metadata
        this.updatePaginator(response);
      });
  }

  // --

  private updatePaginator(table: PagedSubjectListDTOResponse): void {
    this.paginator = {
      pageCount: table.pageCount,
      pageNumber: table.pageNumber,
      pageSize: table.pageSize,
      rowCount: table.rowCount,
      unreadRowCount: table.unreadRowCount,
    };
  }

}
