import {Injectable} from "@angular/core";
import {ApplicationConfiguration} from "./application.configuration";
import {ConfigurationSectionProvider} from "@nes/angular-configuration";
import {DossierConfiguration} from "../modules/dossiers/dossier.configuration";

@Injectable({providedIn: 'root'})
export class DossierConfigurationProvider implements ConfigurationSectionProvider<ApplicationConfiguration, DossierConfiguration> {

  constructor(private applicationConfiguration: ApplicationConfiguration) {}

  get section(): DossierConfiguration { return this.applicationConfiguration.dossier; }
}
