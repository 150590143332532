import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentModule } from 'src/app/common/components/component.module';

import { NesModule } from 'src/app/shared/nes.module';



import { PositionInputDialogService } from './position-input-dialog/position-input-dialog.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { PositionInputDialogComponent } from './position-input-dialog/position-input-dialog.component';
import { ServiceAreaPositionInputComponent } from './service-area-position-input/service-area-position-input.component';
import { SERVICE_AREA_MAPPING_SERVICE } from './shared/services/service-area-mapping.service.interface';
import { ServiceAreaMappingService } from './shared/services/service-area-mapping.service';
import {ServiceAreaMappingRoutingModule} from "./service-area-mapping-routing.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { MapViewComponent } from './map-view/map-view.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { MatToolbarModule} from "@angular/material/toolbar";
import {MatListModule} from "@angular/material/list";
import { CanvasMapComponent } from './canvas-map/canvas-map.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {VEHICLE_DETAILS_SERVICE} from "../vehicles/services/vehicle-details-service.service.interface";
import {VehicleDetailsService} from "../vehicles/services/vehicle-details.service";
import {LOCAL_DATA_STORAGE_SERVICE} from "../../shared/services/local-data-storage.service.interface";
import {LocalDataStorageService} from "../../shared/services/local-data-storage.service";

@NgModule({
  declarations: [
    ServiceAreaPositionInputComponent,
    PositionInputDialogComponent,
    MapViewComponent,
    CanvasMapComponent
  ],
    imports: [
        CommonModule,
        ComponentModule,
        // NgModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatSidenavModule,
        MatToolbarModule,
        MatListModule,
        NesModule,
        ServiceAreaMappingRoutingModule,
        MatTooltipModule
    ],
  exports: [
    ServiceAreaPositionInputComponent,
    PositionInputDialogComponent,
  ]
})
export class ServiceAreaMappingModule {
  static forRoot(): ModuleWithProviders<ServiceAreaMappingModule> {
    return {
      ngModule: ServiceAreaMappingModule,
      providers: [
        PositionInputDialogService,
        {
          provide: SERVICE_AREA_MAPPING_SERVICE,
          useClass: ServiceAreaMappingService
        }
      ]
    };
  }
}
