import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ISearchResult} from "../../../vehicles/shared/interfaces/vehicles.type";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
  IVehicleDetailsService,
  VEHICLE_DETAILS_SERVICE
} from "../../../vehicles/services/vehicle-details-service.service.interface";
import {DDT_SERVICE, IDdtService} from "../../../dossiers/services/ddt.service.interface";
import {IDdtDestination} from "../../../dossiers/shared/interfaces/ddt";

@Component({
  selector: 'destinations-list',
  templateUrl: './destinations-list.component.html',
  styleUrls: ['./destinations-list.component.scss']
})
export class DestinationsListComponent implements OnInit, OnDestroy {
  public loading = false;
  public firstLoad = true;
  public shouldUpdate = false;
  public tableFilter: any;
  destinations: IDdtDestination[];
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              @Inject(DDT_SERVICE) private ddtService: IDdtService,
              @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService) { }

  ngOnInit(): void {
    this.fetchDestinationsList()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }


  get tableColumns(): string[] {
    return ['label'];
  }

  private checkQueuedRequests(): boolean {
    if (this.loading) {
      this.shouldUpdate = true;
    }

    return this.loading;
  }

  private getLabel(customer: IDdtDestination): string {
    return customer.value || "N/A";
  }

  private fetchDestinationsList(): void {
    if (this.checkQueuedRequests()) return;
    this.loading = true;
    this.subscriptions.push(
      this.ddtService
        .searchDestination("")
        .subscribe(res => {
            if (this.shouldUpdate) {
              this.shouldUpdate = false;
              this.loading = false;
              this.fetchDestinationsList();
              return;
            }
            else {
              this.destinations = res;
              this.shouldUpdate = true;
            }
            this.firstLoad = false;
          },
          err => {
            console.log(err)
          },
          () => {
            this.loading = false;
          }
        ));
  }
}
