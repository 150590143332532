import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Guid} from 'guid-typescript';
import {Observable, of} from 'rxjs';
import {VehiclesConfiguration} from '../vehicles.configuration';
import {
  ILengthSegments, IModelSearchResult,
  IPartialVehicle, ISearchResult,
  IVehicle,
  IVehicleDamage, IVehicleDamageDTO,
  IVehicleBlockage, IVehicleBlockageDTO,
  IVehicleBlockageType,
  IVehicleBrand,
  IVehicleBrandListingDTOResponse,
  IVehicleColor,
  IVehicleColorListingDTOResponse, IVehicleDdtDetails,
  IVehicleDTO,
  IVehicleDTOResponse,
  IVehicleFilters,
  IVehicleListingDTOResponse,
  IVehicleModel, IVehicleModelsFilters, IVehicleMovement,
  IVehiclePaginatedList, IVehiclesOilReport, IVehiclesOilReportDTOResponse,
  IOutgoingVehicle, IOutgoingVehiclePage
} from '../shared/interfaces/vehicles.type';
import { IVehicleDetailsService } from './vehicle-details-service.service.interface';
import {map} from 'rxjs/operators';
import {EVehicleStatus} from "../shared/enums/vehicles";
import * as moment from "moment";
import {vehicleDdtToVehicle} from "../shared/mappers/vehicle.mapper";
import {dateToLocalizedIso} from "../../../shared/utils/utils";
import {IReportRequest} from "../../billing/shared/interfaces/billing.type";

@Injectable({
  providedIn: 'root'
})
export class VehicleDetailsService implements IVehicleDetailsService {

  constructor(
    private http: HttpClient,
    private vehiclesConfiguration: VehiclesConfiguration,
  ) { }

  getVehicleColors(): Observable<IVehicleColor[]> {
    const params: any = {
      PageNumber: 1,
      PageSize: 10
    }
    return this
    .http
    .get<
      IVehicleColorListingDTOResponse
    >(
      this.vehiclesConfiguration.service.baseUrl + 'vehicleColors',
      { params: new HttpParams({ fromObject: params }) }
    )
    .pipe(
      map(r => {
        r.rows.push({
          id: Guid.create(),
          name: 'Test Pushed'
        })
        return r.rows
      })
    );
  }
  private convertMomentToApiDate(from: moment.Moment): string {
    const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    let date = from.format(dateFormat).replace('+', '%2B');

    if (date.substr(-3,3) === ':00') date = date.substr(0, date.length - 3);
    return date;
  }
  getLengthSegments(): Observable<ILengthSegments[]> {
    return of([{
      id: Guid.create(),
      name: 'Test'
    }])
  }
  getVehicleBrands(): Observable<IVehicleBrand[]> {
    const params: any = {
      PageNumber: 1,
      PageSize: 10
    }
    return this
    .http
    .get<
      IVehicleBrandListingDTOResponse
    >(
      this.vehiclesConfiguration.service.baseUrl + 'vehicleManufacturers',
      { params: new HttpParams({ fromObject: params }) }
    )
    .pipe(
      map(r => {
        return r.rows.map(b => {
          return b.brand
        });
      })
    );
  }
  getVehiclesOilReport(request: IReportRequest): Observable<IVehiclesOilReport> {
    let params: HttpParams = new HttpParams()
      .set("year", request.date.year.toString())
      .set("month", request.date.month.toString())


    if (request.customer) {
      params = params.set('customerId', request.customer.id.toString());
    }
    return this
      .http
      .get<
        IVehiclesOilReportDTOResponse
      >(
        this.vehiclesConfiguration.service.baseUrl + 'motor-oil-report',
        {
          params
        }
      )
      .pipe(
        map(r => {
          const vehicles = [];
          r.vehicles.forEach(b => {
            vehicles.push({
              arrivedAt: new Date(b.arrivedAt),
              vin: b.vin,
              vac: b.vac,
              liters: b.liters,
              arrivedBy: b.arrivedBy
            });
          });
          return {
            totalLiters: r.totalLiters,
            vehicles
          };
        })
      );
  }
  getVehicleModelsByBrandId(id: Guid): Observable<IVehicleModel[]> {
    throw new Error('Method not implemented.');
  }
  addVehicleModel(newModel: IVehicleModel): Observable<Guid> {
    throw new Error('Method not implemented.');
  }
  addVehicleBrand(newBrand: IVehicleBrand): Observable<Guid> {
    throw new Error('Method not implemented.');
  }
  addVehicle(newVehicle: IPartialVehicle): Observable<IVehicleDTO> {
    return this
      .http
      .post<
        IVehicleDTOResponse
      >(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles',
        {
          vin: newVehicle.vin,
          licensePlate: newVehicle.licensePlate,
          modelId: newVehicle.model,
          customerId: newVehicle.customer,
          destinationCode: newVehicle.destinationCode || ""
        }
      )
      .pipe(
        map(t => {
          return {
            id: Guid.parse(t.id),
            vin: t.vin,
            licensePlate: t.licensePlate,
            color: t.color,
            date: new Date(t.createdAt),
            brand: t.manufacturer,
            model: t.model,
            customer: t.customer
          } as IVehicleDTO
        })
      );
  }
  editVehicle(vehicle: IPartialVehicle): Observable<boolean> {
    return this
      .http
      .post<
        IVehicleDTOResponse
      >(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicle.id,
        {
          vin: vehicle.vin,
          licensePlate: vehicle.licensePlate
        }
      )
      .pipe(
        map(t => {
          return !!t.id
        })
      );
  }
  deleteVehicle(vehicleId: Guid): Observable<boolean> {
    return this.http.delete(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicleId)
      .pipe(
        map(t => {
          return !!t
        })
      );
  }
  removeArrive(vehicleId: Guid): Observable<boolean> {
    return this.http.delete(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicleId + '/arrived-at')
      .pipe(
        map(t => {
          return !!t
        })
      );
  }
  removeLocations(vehicleId: Guid): Observable<boolean> {
    return this.http.delete(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicleId + '/locations')
      .pipe(
        map(t => {
          return !!t
        })
      );
  }
  isBrandExistent(name: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  isVinRegistered(name: string, id: Guid): Observable<IVehicle> {
    throw new Error('Method not implemented.');
  }
  getVehicles(filters: IVehicleFilters): Observable<IVehiclePaginatedList<IVehicle>> {
    const params: any = {
      PageNumber: filters.pageNumber,
      PageSize: filters.pageSize | 10
    }
    if (filters.text) params.search = filters.text;
    if (filters.destinationCode) params.destinationCode = filters.destinationCode;
    if (filters.customerValue) params.customerId = filters.customerValue;
    if (filters.contractValue) params.contractId = filters.contractValue;
    if (null !== filters.vehicleStatus && undefined !== filters.vehicleStatus) params.status = this.mapVehicleStatus(filters.vehicleStatus);
    return this
    .http
    .get<
      IVehicleListingDTOResponse
    >(
      this.vehiclesConfiguration.service.baseUrl + 'vehicles',
      { params: new HttpParams({ fromObject: Object.assign(params, {
        PageNumber: params.PageNumber + 1
      }) }) }
    )
    .pipe(
      map(r => {
        const rows = r.rows.map(vehicleDdtToVehicle);
        return {
          rows,
          totalRows: r.totalRowCount
        } as IVehiclePaginatedList<IVehicle>
      })
    );
  }
  getVehiclesCSV(filters: IVehicleFilters): Observable<string> {
    let options = {};
    if (filters.text) {options['search'] = filters.text}
    if (filters.vehicleStatus) {options['status'] = this.mapVehicleStatus(filters.vehicleStatus)}
    if (filters.destinationCode) options['destinationCode'] = filters.destinationCode;
    if (filters.customerValue) options['customerId'] = filters.customerValue;
    if (filters.contractValue) options['contractId'] = filters.contractValue;
    return this
      .http
      .get<
        string
      >(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles-csv',
        { params: new HttpParams({ fromObject: options as any }), responseType: 'text' as 'json' }
      );
  }
  private mapVehicleStatus(status: EVehicleStatus): string {
    switch (status) {
      case EVehicleStatus.Registered:
        return "NotArrived";
      case EVehicleStatus.Parked:
        return "Parked";
      case EVehicleStatus.Arrived:
        return "Arrived";
      case EVehicleStatus.WillArrive:
        return "WillArrive";
      case EVehicleStatus.Outbound:
        return "InOutboundSector";
      case EVehicleStatus.OutgoingDocument:
        return "InOutgoingDocument";
      case EVehicleStatus.Left:
        return "Left";
      case EVehicleStatus.Lost:
        return "Lost";
      default:
        return null;
    }
  }
  getVehiclesWithDetails(filters: IVehicleFilters): Observable<IVehiclePaginatedList<IVehicleDTO>> {
    const params: any = {
      PageNumber: filters.pageNumber,
      PageSize: filters.pageSize | 10
    }
    return this
    .http
    .get<
      IVehicleListingDTOResponse
    >(
      this.vehiclesConfiguration.service.baseUrl + 'vehicles',
      { params: new HttpParams({ fromObject: Object.assign(params, {
        PageNumber: params.PageNumber + 1
      }) }) }
    )
    .pipe(
      map(r => {
        const rows = r.rows.map((row) => {
          return {
            id: Guid.parse(row.id),
            vin: row.vin,
            color: row.color,
            licensePlate: row.licensePlate,
            date: new Date(row.createdAt),
            brand: row.manufacturer,
            model: row.model,
            customer: row.customer,
            contract: row.contract
          } as IVehicle
        });
        return {
          rows,
          totalRows: r.totalRowCount
        } as IVehiclePaginatedList<IVehicle>
      })
    );
  }
  getVehicleDetails(id: string): Observable<IVehicle> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + id;
    return this.http
      .get<IVehicleDTOResponse>(url)
      .pipe(map(vehicleDdtToVehicle));
  }
  getVehicleBrandsAndModels(): Observable<{ brand: IVehicleBrand; models: IVehicleModel[]; }[]> {
    throw new Error('Method not implemented.');
  }
  getVehicleDamages(id: Guid): Observable<IVehicleDamage[]> {
    return this
      .http
      .get<IVehicleDamageDTO[]>(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + id.toString() + "/damages"
      ).pipe(map(blockages => blockages.map(this.mapVehicleDamage)));
  }
  addVehicleDamage(vehicleId: String, positionCode: Number, damageCode: Number, severityCode: Number, note: String): Observable<IVehicleDamage> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicleId + '/damages';
    
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
    var json = "{ \"positionCode\": " + positionCode + ", \"damageCode\": " + damageCode + ", \"severityCode\": ";
    if (!severityCode) json += "null";
    else json += severityCode;
    json += ", \"note\": ";
    if (note == null) json += "null";
    else json += "\"" + note + "\"";
    json += " }"; // TODO schifo, ma il serializzatore metteva i doppi apici intorno ai numeri!!!

    return this.http
      .post<IVehicleDamageDTO>(url, json, { headers: headers })
      .pipe(map(this.mapVehicleDamage));
  }
  removeVehicleDamage(damageId: Guid): Observable<IVehicleDamage> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'damages/' + damageId;
    return this
      .http
      .delete<IVehicleDamageDTO>(
        url
      ).pipe(map(this.mapVehicleDamage))
  }
  getVehicleBlockages(id: Guid): Observable<IVehicleBlockage[]> {
    return this
      .http
      .get<
        IVehicleBlockageDTO[]
      >(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + id.toString() + "/blocks"
      ).pipe(map(blockages => blockages.map(this.mapVehicleBlockage)));
  }
  getVehicleBlockageDetails(id: Guid): Observable<IVehicleBlockage> {
    throw new Error('Method not implemented.');
  }
  getVehicleBlockageTypes(): Observable<IVehicleBlockageType[]> {
    return of([]);
  }
  addVehicleBlockage(vehicleId: String, reason: String): Observable<IVehicleBlockage> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicleId + '/blocks';
    return this
      .http
      .post<
        IVehicleBlockageDTO
      >(
        url,
        "\"" + reason + "\"",
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      ).pipe(map(this.mapVehicleBlockage))
  }
  closeVehicleBlockage(vehicleId: String, blockageId: String): Observable<IVehicleBlockage> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicleId + '/blocks/' + blockageId;
    return this
      .http
      .delete<
        IVehicleBlockageDTO
      >(
        url
      ).pipe(map(this.mapVehicleBlockage))
  }
  editVehicleDestinationCode(vehicleId: string, destinationCode: string ): Observable<any> {
    return this
      .http
      .put<
        any
      >(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + vehicleId + '/destination-code',
        "\"" + destinationCode + "\"",
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      );
  }
  getVehicleWithDetails(id: Guid): Observable<IVehicleDTO> {
    throw new Error('Method not implemented.');
  }
  uploadVehicles(file: File, contractId: string, arrivedAt?: Date, arrivedBy?: string): Observable<any> {
    let url = this.vehiclesConfiguration.service.baseUrl + 'vehicles-import/?contractId=' + contractId;
    if (!!arrivedAt) url += '&arrivedAt=' + this.convertMomentToApiDate(moment(arrivedAt));
    if (!!arrivedBy) url += '&arrivedBy=' + arrivedBy;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(url, formData);
  }
  uploadDamages(file: File): Observable<any> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'damages-import';
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any>(url, formData);
  }
  searchCustomer(term: string): Observable<ISearchResult[]> {
    const params: any = {}
    if (!!term) params.search = term;
    return this
      .http
      .get<
        any[]
      >(
        this.vehiclesConfiguration.service.baseUrl + 'customers',
        { params: new HttpParams({ fromObject: params }) }
      )
      .pipe(map((res) => {
        const values = [];
        res.forEach((entry) => {
          values.push({
            name: entry.value,
            id: entry.id
          })
        })
        return values;
      }));
  }
  searchContract(term: string): Observable<ISearchResult[]> {
    const params: any = {}
    if (!!term) params.search = term;
    return this
      .http
      .get<any[]>(
        this.vehiclesConfiguration.service.baseUrl + 'contracts',
        { params: new HttpParams({ fromObject: params }) }
      )
      .pipe(map((res) => {
        const values = [];
        res.forEach((entry) => {
          values.push({
            name: entry.description,
            id: entry.id
          })
        })
        return values;
      }));
  }
  searchBrand(term: string): Observable<ISearchResult[]> {
    const params: any = {}
    if (!!term) params.search = term;
    return this
      .http
      .get<
        ISearchResult[]
      >(
        this.vehiclesConfiguration.service.baseUrl + 'manufacturers',
        { params: new HttpParams({ fromObject: params }) }
      );
  }
  getVehicleModels(params: IVehicleModelsFilters = {}): Observable<IModelSearchResult[]> {
    let url = this.vehiclesConfiguration.service.baseUrl;
    if (params.manufacturerId) {
      url += 'manufacturers/' + params.manufacturerId + '/models';
    } else {
      url += 'models';
    }
    return this
      .http
      .get<
        IModelSearchResult[]
      >(
        url,
        { params: new HttpParams({ fromObject: params as any }) }
      );
  }
  searchBrandModel(brandId: string, term: string): Observable<ISearchResult[]> {
    const params: any = {
      search : term
    }
    return this
      .http
      .get<
        ISearchResult[]
      >(
        this.vehiclesConfiguration.service.baseUrl + 'manufacturers/' + brandId + '/models',
        { params: new HttpParams({ fromObject: params }) }
      );
  }
  getVehicleMovements(id: string): Observable<IVehicleMovement[]> {
    return this
      .http
      .get<
        any[]
      >(
        this.vehiclesConfiguration.service.baseUrl + 'vehicles/' + id + '/locations'
      ).pipe(map((res) => {
        return res.map((dto) => {
          return {
            sector: dto.sectorLabel,
            lane: dto.laneNumber,
            date: new Date(dto.parkedAt),
          }
        })
      }));
  }
  private mapVehicleDamage(damage: IVehicleDamageDTO): IVehicleDamage {
    const result = {
      id: damage.id,
      positionCode: damage.positionCode,
      damageCode: damage.damageCode,
      severityCode: damage.severityCode,
      note: damage.note,
      isImported: damage.isImported
    } as IVehicleDamage;

    return result;
  }
  private mapVehicleBlockage(blockage: IVehicleBlockageDTO): IVehicleBlockage {
    const result = {
      id: blockage.id,
      type: blockage.reason,
      from: new Date(blockage.from)
    } as IVehicleBlockage;

    if (blockage.to) result.to = new Date(blockage.to);

    return result;
  }
  bulkAddVehicleBlockage(vehicleIds: String[], reason: String): Observable<null> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'blocks/' + reason;
    return this
      .http
      .post<
        null
      >(
        url,
        JSON.stringify(vehicleIds),
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      )
  }
  bulkCloseVehicleBlockage(vehicleIds: String[], reason: String): Observable<any> {
    const url = this.vehiclesConfiguration.service.baseUrl + 'blocks/' + reason;
    return this
      .http
      .request(
        'delete',
        url,
        {
          body: JSON.stringify(vehicleIds),
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
      )
  }
  getOutgoingVehiclesPage(filters: IVehicleFilters): Observable<IVehiclePaginatedList<IOutgoingVehicle>> {
    const params: any = {
      PageNumber: filters.pageNumber,
      PageSize: filters.pageSize | 10
    }
    return this.http.get<IOutgoingVehiclePage>(
      this.vehiclesConfiguration.service.baseUrl + 'outgoing-vehicles',
      { params: new HttpParams({ fromObject: Object.assign(params, { PageNumber: params.PageNumber + 1 }) }) }
    )
    .pipe(
      map(r => {
        return {
          rows: r.rows,
          totalRows: r.totalRowCount
        } as IVehiclePaginatedList<IOutgoingVehicle>
      })
    );
  }
  getOutgoingVehiclesList(): Observable<IOutgoingVehicle[]> {
    return this.http.get<IOutgoingVehicle[]>(this.vehiclesConfiguration.service.baseUrl + 'outgoing-vehicles-list');
  }
}
