import {Injectable} from '@angular/core';
import {ILocalDataStorageService} from "./local-data-storage.service.interface";


@Injectable({
  providedIn: 'root'
})
export class LocalDataStorageService implements ILocalDataStorageService {

  constructor() { }

  get(key: string): any {
    const savedData: any = this.getSaveData();
    return savedData[key];
  }

  save(key: string, value: any): void {
    const savedData: any = this.getSaveData();
    const updatedData = Object.assign(savedData, {[key]: value});
    localStorage.setItem('tc-storage', JSON.stringify(updatedData));
  }

  shouldUseLocalData(key: string): boolean {
    const savedData: any = this.getSaveData();
    return !!savedData[key]?.shouldUpdate;
  }

  setUseLocalData(key: string, value: boolean): void {
    const savedData: any = this.getSaveData();
    if (!savedData || !savedData[key]) return;
    savedData[key].shouldUpdate = value;
    localStorage.setItem('tc-storage', JSON.stringify(savedData));
  }

  private getSaveData(): any {
    let result = localStorage.getItem('tc-storage') || '{}';
    result = JSON.parse(result); // HACK
    if (typeof result === 'string') result = JSON.parse(result);
    return result;
  }
}
