import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {DossierListComponent} from './dossier-list/dossier-list.component';
import { DossierDetailsComponent } from './dossier-details/dossier-details.component';

const routes: Routes = [
  {
    path: '',
    component: DossierListComponent,
  },
  {
    path: 'nuova',
    component: DossierDetailsComponent,
  },
  {
    path: 'dettaglio/:id',
    component: DossierDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DossiersRoutingModule {
}
