import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {PositionInputDialogService} from '../position-input-dialog/position-input-dialog.service';
import {Subscription} from 'rxjs';
import {IServiceAreaMappingAreaField, IServiceAreaPositionDetails} from '../position';
import {
  IServiceAreaMappingService,
  SERVICE_AREA_MAPPING_SERVICE
} from '../shared/services/service-area-mapping.service.interface';
import {FormControl} from '@angular/forms';
import {SectorType} from "../shared/enums/sector-type";
import MapSector from "../classes/sector";

@Component({
  selector: 'service-area-position-input',
  templateUrl: './service-area-position-input.component.html',
  styleUrls: ['./service-area-position-input.component.scss']
})
export class ServiceAreaPositionInputComponent implements OnInit {

  private subscriptions: Array<Subscription> = []

  @Output() positionChange: EventEmitter<IServiceAreaPositionDetails> = new EventEmitter<IServiceAreaPositionDetails>();
  @Input() disableInput: boolean = false;
  @Input() showRows: boolean = false;
  @Input() title: string = "Seleziona Posizione";
  @Input() placeholder: string;
  @Input() editable: boolean = true;
  @Input() sectorType: SectorType = SectorType.All;
  @Input() label: string;
  @Input() requiredError: boolean = false;
  @Input() enableOccupation: boolean = false;
  private laneOccupation: number;
  private laneLength: number;
  @Input()
    get value(){
      return this._value;
    }
    set value(passedValue: IServiceAreaPositionDetails) {
      this._value = passedValue;
      this.generateInputValue();
    }
  @Output() valueChange: EventEmitter<IServiceAreaPositionDetails> = new EventEmitter<IServiceAreaPositionDetails>();
  public sectors: MapSector[] = [];


  _value: IServiceAreaPositionDetails = this.defaultValue;
  inputLabel: string = "";
  formControl: FormControl = new FormControl('');

  get defaultValue(): IServiceAreaPositionDetails {
    return {
      sector: null,
      lane: null
    };
  }

  private async fetchSectors() {
      this.sectors = await this.serviceAreaMappingService.getSectorsWithDetail(this.sectorType).toPromise();
  }

  constructor(
    private positionInputDialogService: PositionInputDialogService,
    @Inject(SERVICE_AREA_MAPPING_SERVICE) private serviceAreaMappingService: IServiceAreaMappingService
    ) {}

  async ngOnInit() {
    await this.fetchSectors();
  }

  private async generateInputValue() {
    if (this.sectors.length === 0) await this.fetchSectors();
    if (this.sectors.length > 0) {
      const sector = this.sectors.find(sector => sector.id === this._value.sector);
      if (!sector) {
        this.inputLabel = '';
        this.formControl.setValue(this.inputLabel)
        return;
      }
      this.inputLabel = sector.label + ' - ' + this._value.lane;
    } else {
      this.inputLabel = '';
    }
    this.updateOccupationLabel();
    this.formControl.setValue(this.inputLabel)
  }

  public openDialog(): void {
    if(!this.editable) return;
    this.subscriptions.push(
      this
        .positionInputDialogService
        .open(
          this._value,
          this.showRows,
          this.title,
          this.sectorType,
          'position-input-dialog',
          this.enableOccupation
        )
        .pipe(
        )
        .subscribe(
          (result) => {
            if(!!result){
              this.positionChange.emit(result);
              this.value = result;
              this.generateInputValue();
              this.updateOccupationLabel();
              this.valueChange.emit(this.value);
            }
          }
        )
    );
  }

  private async updateOccupationLabel() {
    if (!this.enableOccupation) return;
    this.laneOccupation = null;
    this.laneLength = null;
    const occupation = await this.serviceAreaMappingService.getSectorLanes(this._value.sector).toPromise();
    const anomalies = await this.serviceAreaMappingService.getSectorAnomalies(this._value.sector).toPromise();
    const sectorDetails = this.sectors.find(existentSector => existentSector.id === this._value.sector);
    const foundAnomaly = anomalies.find(anomaly => anomaly.laneNumber === this._value.lane && anomaly.anomalyType === 'DifferentLength');
    const foundOccupation = occupation.find(occupation => occupation.laneNumber === this._value.lane);
    this.laneOccupation = foundOccupation ? foundOccupation.vehicleCount : 0;
    this.laneLength = foundAnomaly ? foundAnomaly.laneLength : sectorDetails.laneLength;
  }
  private async generateSectorLanes(id: string): Promise<void> {

  }
}
