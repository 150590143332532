import { Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { Subscription } from 'rxjs';
import { DossierDialogsService } from '../dossier-dialogs.service';
import { Router } from '@angular/router';
import {DDT_SERVICE, IDdtService} from "../services/ddt.service.interface";
import {IDdtFilters, IDdt} from "../shared/interfaces/ddt";
import {RolesGuardHelper} from "../../../shared/utils/roles-guard-helper";

@Component({
  selector: 'app-dossier-ddt-list',
  templateUrl: './dossier-ddt-list.component.html',
  styleUrls: ['./dossier-ddt-list.component.scss']
})
export class DossierDdtListComponent implements OnInit, OnDestroy{


  public loading = false;
  public firstLoad = true;
  public shouldUpdate = false;
  ddtList: IDdt[] = [];
  ddtFilters: IDdtFilters = {
    page: 0
  }
  resultsCount: number = 0;

  private subscriptions: Subscription[] = [];

  // --

  id: string;
  createdAt: string;
  number: string;
  customer: string;
  sender: string;
  destination: string;
  shippingNumber: string;
  carrier: string;
  carrierLicensePlate: string;
  vehicles: any[];
  get ddtDetails() {
    return ['number', 'createdAt', 'carrier', 'vehicles', 'indicator'];
  }

  // --

  constructor(
    @Inject(DDT_SERVICE) private ddtService: IDdtService,
    private router: Router,
    private ddService: DossierDialogsService
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.fetchDdtList();
  }

  // --

  private checkQueuedRequests(): boolean {
    if (this.loading) {
      this.shouldUpdate = true;
    }

    return !this.loading;
  }

  private fetchDdtList(): void {
    if (!this.checkQueuedRequests()) return;
    this.loading = true;
    this.subscriptions.push(
      this.ddtService.fetchDdtList(this.ddtFilters)
      .subscribe(ddt => {
        if (this.shouldUpdate) {
          this.shouldUpdate = false;
          this.loading = false;
          this.fetchDdtList();
          return;
        }
        else {
          this.loading = false;
          if(!ddt) return;
          this.resultsCount = ddt.totalRows;
          this.ddtList = ddt.rows;
        }
        this.firstLoad = false;
      })
    );
  }

  public selectDdt(code: string): void {
    this.router.navigate(["/pratiche", "ddt", "dettaglio"], { queryParams: { id: code } })
  }

  public applyFilters(e: string): void {
  }

  public updatePagination(e): void {

    this.ddtFilters.page = e.pageIndex;
    this.fetchDdtList();
  }

  readonly RolesGuardHelper = RolesGuardHelper;
}
