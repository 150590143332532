// auth.guard.ts
import {Inject, Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {AUTHENTICATION_SERVICE, IAuthenticationService} from "./services/authentication.service.interface";
import {ANavigationItem, NavigationRoot} from "../../../shared/layout/models/navigation.type";
import {UserRoles} from "./interfaces/user-roles";
import {RolesGuardHelper} from "../../../shared/utils/roles-guard-helper";

@Injectable({
  providedIn: 'root'
})
export class RolesGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!next.data['roles'] || next.data['roles'].length == 0) {
      return true;
    }

    if (!next.data['roles'].find((role) => !RolesGuardHelper.getUserRoles().includes(role))) {
      return true;
    }

    this.router.navigate(['/blank']);
    return false;
  }
}
