<button class="nes_close_btn" mat-icon-button (click)="close()"><mat-icon>clear</mat-icon></button>

<nes-dialog [title]="'Storico posizioni'" [withoutBorder]="false">
  <nes-portlet-table [dataSource]="movements" >
    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="movements">
        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef>Posizione</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ generatePositionString(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Data</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.date | date: 'dd/MM/y HH:mm' }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: tableColumns;"></mat-row>
      </mat-table>
    </div>
  </nes-portlet-table>

  <div class="form-footer">
    <button [disableRipple]="true" mat-button (click)="close()">Chiudi</button>
  </div>
</nes-dialog>
