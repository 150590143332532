import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPositionInputDialogData, IServiceAreaPositionDetails, IServiceAreaMappingRowField, IServiceAreaMappingAreaField, IServiceAreaMappingSectorField } from '../position';
import { SERVICE_AREA_MAPPING_SERVICE, IServiceAreaMappingService } from '../shared/services/service-area-mapping.service.interface';
import { Guid } from 'guid-typescript';
import {Subscription} from "rxjs";
import MapSector from "../classes/sector";
import {ILaneDetails, ISectorGroup} from "../types/service-area-mapping";
import {SectorType} from "../shared/enums/sector-type";


@Component({
  selector: 'app-position-input-dialog',
  templateUrl: './position-input-dialog.component.html',
  styleUrls: ['./position-input-dialog.component.scss']
})


export class PositionInputDialogComponent implements OnInit, OnDestroy {

  positionForm: FormGroup = this.fb.group({
    sector: null,
    row: null
  });
  private subscriptions: Subscription[] = [];
  public sectors: MapSector[] = [];
  public sectorGroups: ISectorGroup[] = [];
  public groupedSectors: ISectorGroup[] = [];
  public lanes: any[] = [];
  public title: string = "Seleziona Posizione";
  public sectorType = SectorType.All;

  showRows = true;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PositionInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IPositionInputDialogData,
    @Inject(SERVICE_AREA_MAPPING_SERVICE) private serviceAreaMappingService: IServiceAreaMappingService
  ){
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.showRows = this.data.showRows;
    if (this.data.title) this.title = this.data.title;
    if (this.data.sectorType) this.sectorType = this.data.sectorType;
    this.fetchSectorGroups();
    this.fetchSectors();
  }

  private fetchSectorGroups() {
    this.subscriptions.push(
      this.serviceAreaMappingService.getSectorGroups()
        .subscribe(response => {
          this.sectorGroups = response;
          if (this.sectors.length > 0) {
            this.generateGroupedSectors();
          }
        })
    );
  }

  private fetchSectors() {
    this.subscriptions.push(
      this.serviceAreaMappingService.getSectorsWithDetail(this.sectorType)
        .subscribe(response => {
          this.sectors = response;
          if (this.sectorGroups.length > 0) {
            this.generateGroupedSectors();
          }
        })
    );
  }

  private generateGroupedSectors() {
    this.sectorGroups.forEach(group => {
      group.sectors = this.sectors.filter(sector => sector.groupId === group.id);
    });
    const ungroupedSectors = this.sectors.filter(sector => sector.groupId === null && sector.laneCount > 0);
    this.groupedSectors = this.sectorGroups.concat(ungroupedSectors.map(sector => {
      return {
        id: sector.id,
        label: sector.label,
        order: sector.order,
        sectors: [sector]
      }
    }))
      .filter(group => group.sectors.length > 0)
      .sort((a, b) => a.order > b.order ? 1 : -1);
  }

  async generateSectorLanes(id: string): Promise<void> {
    let lanes = [];
    const sector = this.groupedSectors.find(sector => sector.id === id);
    if (!sector) return;
    for (let i = 0; i < sector.sectors.length; i++) {
      const innerSector = sector.sectors[i];
      const occupation = await this.serviceAreaMappingService.getSectorLanes(innerSector.id).toPromise();
      const anomalies = await this.serviceAreaMappingService.getSectorAnomalies(innerSector.id).toPromise();
      lanes = lanes.concat(this.calcSectorLanes(innerSector, occupation, anomalies))
    }
    this.lanes = lanes.sort((a, b) => a.laneNumber > b.laneNumber ? 1 : -1);
  }

  private calcSectorLanes(sector: MapSector, occupation: ILaneDetails[], anomalies: any[]): any[] {
    const lanes = [];

    let laneNumber = sector.numerationStart || (sector.numerationType === 'Even' ? 2 : 1);
    for (let i = 0; i < sector.laneCount; i++) {
      const foundAnomaly = anomalies.find(anomaly => anomaly.laneNumber === laneNumber && anomaly.anomalyType === 'DifferentLength');
      const foundOccupation = occupation.find(occupation => occupation.laneNumber === laneNumber);
      const laneDetails = {
        label: "",
        laneNumber: laneNumber,
        sectorId: sector.id,
        occupied: foundOccupation ? foundOccupation.vehicleCount : 0,
        length: foundAnomaly ? foundAnomaly.laneLength : sector.laneLength
      }
      laneDetails['label'] = laneDetails['laneNumber'].toString();
      if (!!foundOccupation?.note) laneDetails['label'] += ' - ' + foundOccupation.note;
      if (!!this.data.enableOccupation) laneDetails['label'] += " - (" + laneDetails['occupied'] + '/' + laneDetails['length'] + ")";

      lanes.push(
        laneDetails
      )
      sector.numerationType === 'Both' ? laneNumber++ : laneNumber += 2;
    }
    return lanes;
  }

  public onSave(){
    if(!!this.positionForm.valid){
      const formData = this.positionForm.getRawValue();
      const positionResult: IServiceAreaPositionDetails = {
        sector: formData.row.sectorId,
        lane: formData.row.laneNumber
      }
      this.onClose(positionResult);
    }else{
      Object.keys(this.positionForm.controls).forEach(field => {
        const control = this.positionForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  onClose(argsToPass: IServiceAreaPositionDetails = null): void{
    this.dialogRef.close(argsToPass);
  }
}
