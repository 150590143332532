import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BillingConfiguration } from '../../billing.configuration';
import { IReportRequest } from '../interfaces/billing.type';
import { IBillingService } from './billing.service.interface';
import { downloadFileFromBlob } from '../../../../shared/utils/utils';



@Injectable({ providedIn: 'root' })
export class BillingService implements IBillingService {
  constructor(private http: HttpClient, private billingConfiguration: BillingConfiguration) { }

  requestDownload(request: IReportRequest): Observable<Blob | ArrayBuffer> {
    let params: HttpParams = new HttpParams()
      .set("year", request.date.year.toString())
      .set("month", request.date.month.toString())

    if (request.customer) {
      params = params.set('customerId', request.customer.id.toString());
    }
    if (request.contractId) {
      params = params.set('contractId', request.contractId.toString());
    }

    const headers = new HttpHeaders({
      Accept: 'application/octet-stream'
    });
    return this.http.get(
      this.billingConfiguration.service.baseUrl + 'billing-report',
      {
        headers,
        params,
        responseType: "blob"
      }
    )
      .pipe(
        tap(x => {
          downloadFileFromBlob(x, 'rendicontazione.csv');
        })
      )
  }

  requestStatistics(): Observable<any> {
    return this.http.get(this.billingConfiguration.service.baseUrl + 'statistics');
  }
}
