import {Component, OnDestroy, OnInit} from '@angular/core';

import {NavigationService} from '../../services/navigation.service';
import {NavigationRoot, ANavigationItem, NavigationItemType, ActionNavigationItem} from '../../models/navigation.type';
import {NavigationEnd, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'nes-navigation',
  templateUrl: './nes-navigation.component.html'
})
export class NesNavigationComponent implements OnInit, OnDestroy {

  public navigation: NavigationRoot[];
  public currentExpanded: number;
  private subscriptions: Subscription[] = [];

  // --

  constructor(private navigationService: NavigationService, private router: Router) {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.getNavigation();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnInit() {
    this.currentExpanded = -1;
    this.getNavigation();
  }

  // --

  private getNavigation(): void {
    this.navigation = this.navigationService.getNavigation();
  }

  public expand(index: number): void {
    this.currentExpanded = index;
  }

  public isRoute(navItem: ANavigationItem): boolean {
    return navItem.type == NavigationItemType.Route;
  }

  public isAction(navItem: ANavigationItem): boolean {
    return navItem.type == NavigationItemType.Action;
  }

  public executeAction(actionNavItem: ActionNavigationItem) {
    actionNavItem.action();
  }

}
