<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Anagrafiche', 'Contratti']" [title]="'Lista contratti'" [withoutBorder]="false" [loading]="firstLoad">
  <nes-portlet-filter-bar class="hide-search">
  </nes-portlet-filter-bar>
  <nes-portlet-table [dataSource]="contracts">
    <div class="table-container">
      <div class="table-loading-shade" *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="contracts">
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Contratti</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getLabel(row) }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: tableColumns;"></mat-row>
      </mat-table>
    </div>
  </nes-portlet-table>
</nes-portlet>