import { Component, OnInit, ViewChild, Inject, OnDestroy} from '@angular/core';
import { Guid } from 'guid-typescript';
import { ActivatedRoute, Router } from '@angular/router';
import { DossierDetailsGeneralComponent } from './dossier-details-general/dossier-details-general.component';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { IServiceAreaPositionDetails } from '../../service-area-mapping/position';
import { IDossierVehicleTransportDetails, ShipTransportDetails, TrainTransportDetails, CarTransporterTransportDetails, ADossier, IngressDossier, ExgressDossier, ProcessingDossier, DossierVehicleTransportType, ADossierWithCustomer } from '../shared/interfaces/dossier.type';
import { DOSSIERS_SERVICE, IDossiersService } from '../services/dossiers-service.service.interface';
import { DossierDetailsVehiclesComponent } from './dossier-details-vehicles/dossier-details-vehicles.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEventPattern, Subscription } from 'rxjs';
import {IDdtReportResponseDTO, IVehicleDTO} from '../../vehicles/shared/interfaces/vehicles.type';
import { DossierDetailsActivitiesComponent } from './dossier-details-activities/dossier-details-activities.component';

@Component({
  selector: 'app-dossier-details',
  templateUrl: './dossier-details.component.html',
  styleUrls: ['./dossier-details.component.scss']
})
export class DossierDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('generalDetailsForm') generalDetailsForm: DossierDetailsGeneralComponent;
  @ViewChild('vinList') vinList: DossierDetailsVehiclesComponent;
  @ViewChild('activitiesList') activitiesList: DossierDetailsActivitiesComponent;

  portletTitle = '';
  detailMode = false;
  dossierType: string = null;
  reportData: IDdtReportResponseDTO;
  dossierId: string = null;
  dossierVehicles: IVehicleDTO[] = null;
  dossierActivities: Guid[] = [];
  dossierDetails: ADossier = null;
  pageStatus: string = 'details';
  private asyncFunctionStates: string[] = [];

  get dossierHasCustomer(): boolean {
    return this.dossierDetails?.is('ingress')
      || this.dossierDetails?.is('exgress')
      || this.dossierDetails?.is('processing');
  }
  get dossierWithCustomerDetails(): ADossierWithCustomer {
    return this.dossierDetails as ADossierWithCustomer;
  };

  editable = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    @Inject(DOSSIERS_SERVICE) private dService: IDossiersService
    ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe()
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.route.params.subscribe(params =>{
        this.dossierId = params.id;

        if(this.dossierId && this.dossierId !== null){

          this.detailMode = true;
          this.editable = false;
          this.fetchGeneralDetailsForm();
          this.fetchVehicleList();
        }
      })
    );

    if(!this.detailMode){

      this.subscriptions.push(
        this.route.queryParamMap.subscribe(params =>{

          this.dossierType = params.get('type');
          this.dService.getDossierTypes()
          .subscribe(dossierTypes => {

            const dossierTypeName: string = dossierTypes.find(type => type.slug === this.dossierType).name.toLowerCase();
            if(!!dossierTypeName) this.portletTitle = 'Nuova pratica di ' + dossierTypeName;
          });
        })
      );
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }
  }

  get loading(): boolean {
    return !!this.asyncFunctionStates.length;
  }

  public fetchVehicleList() {
    this.manageAsyncFunctionsState('add', 'fetchVehicleList');
    this.subscriptions.push(

      this.dService.getDossierVehicles(this.dossierId)
      .subscribe(vehicles => {
        this.dossierVehicles = vehicles;
        this.manageAsyncFunctionsState('remove', 'fetchVehicleList');
      })
    );
  }

  public fetchGeneralDetailsForm(){
    this.manageAsyncFunctionsState('add', 'fetchGeneralDetailsForm');
    this.subscriptions.push(
      this.dService.getDossier(this.dossierId)
        .subscribe(dossier => {
          if(!dossier) return;

          this.portletTitle = 'Pratica ' + dossier.code;
          this.dossierType = dossier.type;
          this.dossierDetails = dossier;
          if((dossier as ProcessingDossier).activities)
          this.dossierActivities = (dossier as ProcessingDossier).activities;

          // Workaround of a bug that prevents the value of dossierDetails passed to the child component to refresh,
          // the decision was to change the value of child component directly
          if(!!this.generalDetailsForm) this.generalDetailsForm.data = this.dossierDetails;
          this.manageAsyncFunctionsState('remove', 'fetchGeneralDetailsForm');
        })
    );
  }

  public manageAsyncFunctionsState(process: 'add' | 'remove', key: string) {
    const functionIndex =  this.asyncFunctionStates.findIndex((functionName) => functionName === key);
    if (process === 'add' && functionIndex < 0)
      this.asyncFunctionStates.push(key);
    else if(process === 'remove' && functionIndex > -1)
      this.asyncFunctionStates.splice(functionIndex, 1);
  }

  public cancel() {}

  public save(): void {
    const form = this.generalDetailsForm.onSave();
    this.markFormGroupTouched(form);
    const vehicles = this.vinList.vehicleList;
    const dossierVinList: Guid[] = [];
    vehicles.forEach(vehicle => dossierVinList.push(vehicle.id));
    let occurrenceDate = null;
    let discoveryDate = null;
    let expiryDate = null;
    let completionDate = null;
    let customer: number = null;
    let dossierDateAndTime: string = null;        // Dossier departure or arrival date and time in ISO_8601 format
    let isTimeConfirmed = false;
    let notes: string = null;
    let vehicleTransportType: IDossierVehicleTransportDetails = null;
    let source: IServiceAreaPositionDetails = null;
    let destination: IServiceAreaPositionDetails = null;
    let dossierToSave: ADossier = null;

    if (!!form.valid) {
      const formData = form.getRawValue();

      if (this.isFieldVisible('customer', form)){
        customer = !isNaN(formData.customer) ? formData.customer : (this.dossierDetails as IngressDossier).customer ;
      }

      if(this.isFieldVisible('arrivalOrDepartureDate', form)){
        let date = formData['arrivalOrDepartureDate'];

        if(this.isFieldVisible('arrivalHour', form)){
          const time = formData.arrivalHour.split(':');
          date.setHours(parseInt(time[0]), parseInt(time[1]), 0, 0);
          dossierDateAndTime = moment(new Date(date)).toISOString(true);
        } else if(this.isFieldVisible('departureHour', form)){
          const time = formData.departureHour.split(':');
          date.setHours(parseInt(time[0]), parseInt(time[1]), 0, 0);
          dossierDateAndTime = moment(new Date(date)).toISOString(true);
        }
      }

      if (this.isFieldVisible('isDateConfirmed', form)){

        isTimeConfirmed = formData.isDateConfirmed;
      }

      if(this.isFieldVisible('occurrenceDate', form)){

        occurrenceDate = formData['occurrenceDate'];
      }

      if(this.isFieldVisible('discoveryDate', form)){

        discoveryDate = formData['discoveryDate'];
      }

      if(this.isFieldVisible('expiryDate', form)){

        expiryDate = formData['expiryDate'];
      }

      if(this.isFieldVisible('completionDate', form)){

        completionDate = formData['completionDate'];
      }

      if(this.isFieldVisible('notes', form)){

        notes = formData['notes'];
      }

      if(this.isFieldVisible('vehicleTransport', form)){

        const vehicleType = formData.vehicleTransport.type;
        let transport: IDossierVehicleTransportDetails;

        if (vehicleType === 0) {

          transport = new CarTransporterTransportDetails({
            licensePlates: formData.vehicleTransport.details.carTransporterDetails.licensePlates
          });

        }

        if(vehicleType === 1) {

          transport = new ShipTransportDetails({
            shipName: formData.vehicleTransport.details.shipName
          });

        }

        if (vehicleType === 2) {

          transport = new TrainTransportDetails({
            trainNumber: formData.vehicleTransport.details.trainNumber
          });

        }


        vehicleTransportType = transport;
      }

      if (this.dossierType === 'ingress') {
        dossierToSave = new IngressDossier(
          '',
          '',
          customer,
          dossierDateAndTime,
          isTimeConfirmed,
          vehicleTransportType
        )
      }

      if (this.dossierType === 'exgress') {
        dossierToSave = new ExgressDossier(
          '',
          '',
          customer,
          dossierDateAndTime,
          isTimeConfirmed,
          vehicleTransportType,
          notes
        );
      }

      if (this.dossierType === 'processing') {
        const activities = this.activitiesList.selectedActivities;
        dossierToSave = new ProcessingDossier(
          '',
          '',
          customer,
          expiryDate,
          activities
        );
      }

      // if (this.dossierType === 'moving') {
      //   dossierToSave = new MovingDossier(
      //     destination,
      //     expiryDate
      //   );
      // }
      //
      // if (this.dossierType === 'damage') {
      //   dossierToSave = new DamageDossier(
      //     occurrenceDate,
      //     discoveryDate
      //   )
      // }

      if (completionDate) dossierToSave.setCompletionDate(completionDate);
      const snack = (message) => this.snackBar.open(message, '', {
        panelClass: 'nes-snackbar',
        duration: 4000,
        horizontalPosition: 'right'
      });

      if (!this.detailMode) {

        this.subscriptions.push(
          this.dService
          .saveDossier(dossierToSave, dossierVinList)
          .subscribe(newDossier => {
            this.router.navigate(['/pratiche/dettaglio/' + newDossier.id ])
          })
        );

      } else {

        dossierToSave.id = this.dossierId;

        this.subscriptions.push(
          this.dService.editDossier(dossierToSave, dossierVinList)
          .subscribe(response => {
            if(response !== null)
              this.toggleGeneral(false);
              snack('Pratica modificata');
          })
        );
      }
    }else{
      this.markFormGroupTouched(form);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public toggleGeneral(status: boolean): void {

    this.editable = status;
    if(!status) this.resetPortlet();
  }

  private resetPortlet(): void{
    this.fetchGeneralDetailsForm();
    this.fetchVehicleList();
  }

  private isFormValid(form: FormGroup): boolean {
    let result = true;

    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      control.markAsTouched({ onlySelf: false });
      if( !control.valid ) result = false;
    });

    return result;
  }

  private downloadReport(): void {
    this.dService.requestDossierDDT(this.dossierId).subscribe((response) => {
      this.reportData = Object.assign(response, {
        destination: 'TENCARA Srl<br>' +
          '\n' +
          'Via della Chimica 4<br>' +
          '30176 Venezia<br>' +
          'P.I. 04439720279'
        })
      this.pageStatus = 'report';
    });
  }

  public isFieldVisible(field: string, form?: FormGroup): boolean{
    return !!form ? !!form.get(field) : false;
  }
}
