import {
  CompanyDTOResponse,
  IndividualDTOResponse,
  PublicAdministrationDTOResponse,
  RegistryType
} from '../interfaces/registry.type';

export class Utils {

  static decodeSubjectType(type: string = ''): string {
    switch (type) {
      case RegistryType.company:
        return 'Azienda';
      case RegistryType.individual:
        return 'Persona Fisica / Professionista';
      case RegistryType.publicCompany:
        return 'Pubblica Amministrazione';
      default:
        return '';
    }
  }

  static decodeSubjectPlace(city: string = '', province: string = ''): string {
    const output = [];

    if (city) {
      output.push(city);
    }

    if (province) {
      output.push(province);
    }

    return output.join(' - ').trim();
  }

  static identifyByTypeNumber(type: any): RegistryType {
    switch (type) {
      case 0:
        return RegistryType.company;
      case 1:
        return RegistryType.individual;
      case 2:
        return RegistryType.publicCompany;
      default:
        return type;
    }
  }

  static identifyPrivateCompany(subject: CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse): boolean {
    if (subject && typeof subject.type === 'number') {
      return subject.type === 0;
    }

    return subject && subject.type === RegistryType.company;
  }

  static identifyIndividual(subject: CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse): boolean {
    if (subject && typeof subject.type === 'number') {
      return subject.type === 1;
    }

    return subject && subject.type === RegistryType.individual;
  }

  static identifyPublicCompany(subject: CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse): boolean {
    if (subject && typeof subject.type === 'number') {
      return subject.type === 2;
    }

    return subject && subject.type === RegistryType.publicCompany;
  }

}


