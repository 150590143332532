import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from "../services/vehicle-details-service.service.interface";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";



@Component({
  selector: 'import-from-file',
  templateUrl: './import-from-file.component.html',
  styleUrls: ['./import-from-file.component.scss']
})
export class ImportFromFileComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  public file: File | undefined;
  public progress: number | undefined;
  public fileName: string | undefined;
  public importResponse: any | undefined;
  public importResult: any | undefined;
  public loading = false;
  public importForm: FormGroup;
  public contractFormControl: FormControl = new FormControl(null);
  public contractOptions;
  public contractId;
  public disableRestrictedInputs = false;
  public productColumns = [1]
  public toggledModelColumnInput: boolean = false;
  public toggledDateColumnInput: boolean = false;
  
  private activeModelControl: boolean = false;

  constructor(@Inject(VEHICLE_DETAILS_SERVICE) private service: IVehicleDetailsService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.importForm = this.formBuilder.group({
      contract: ["", [Validators.required]],
      arrivalDate: [""],
      arrivalDateColumn: [{ value: 1, disabled: true }, [Validators.required, Validators.min(1)]],
      arrivedBy: [null, [Validators.required]]
    });

    this.initContractControl();

    this.importForm.controls['arrivedBy'].disable();
    this.importForm.controls['arrivedBy'].setValue(null);
    this.importForm.controls['arrivalDate'].disable();
    this.importForm.controls['arrivalDate'].setValue(null);
    this.disableRestrictedInputs = true;
  }

  get isArrivalDateColumnFieldRequired(): boolean {
    let arrivalDateColumnField = this.importForm.controls['arrivalDateColumn'];

    return arrivalDateColumnField.touched && arrivalDateColumnField.hasError('required');
  }

  get isArrivalDateColumnFieldValid(): boolean {
    let arrivalDateColumnField = this.importForm.controls['arrivalDateColumn'];
    if ((arrivalDateColumnField.value + "").length < 1 || !arrivalDateColumnField.touched) return true;
    return !arrivalDateColumnField.hasError('min');
  }

  public toggleModelColumnInput(): void {
    if (this.toggledModelColumnInput) {
      if (this.activeModelControl) this.importForm.controls['model'].enable();
      this.importForm.controls['modelColumn'].disable();
      this.toggledModelColumnInput = false;
    } else {
      this.importForm.controls['model'].disable();
      this.importForm.controls['modelColumn'].enable();
      this.toggledModelColumnInput = true;
    }
  }

  public toggleArrivalDateColumnInput(): void {
    if (this.toggledDateColumnInput) {
      this.importForm.controls['arrivalDate'].enable();
      this.importForm.controls['arrivalDateColumn'].disable();
      this.toggledDateColumnInput = false;
    } else {
      this.importForm.controls['arrivalDate'].disable();
      this.importForm.controls['arrivalDateColumn'].enable();
      this.toggledDateColumnInput = true;
    }
  }

  public updateContractAutocompleteResults(term: string): void {
    if (!term) {
      this.contractId = null;
      return;
    }
    this.service.searchContract(term).subscribe(options => this.contractOptions = options);
  }

  public handleContractAutocomplete(event): void {
    const foundValue = this.contractOptions.find((value) => value.name === event.option.value);
    this.contractFormControl.setValue(event.option.value);
    console.log(foundValue);
    if (!!foundValue &&
        !!foundValue.name &&
        foundValue.name.toLowerCase().includes('ford')) {
      this.importForm.controls['arrivedBy'].enable();
      this.importForm.controls['arrivalDate'].enable();
      this.disableRestrictedInputs = false;
    } else {
      this.importForm.controls['arrivedBy'].disable();
      this.importForm.controls['arrivedBy'].setValue(null);
      this.importForm.controls['arrivalDate'].disable();
      this.importForm.controls['arrivalDate'].setValue(null);
      this.disableRestrictedInputs = true;
    }
    if (foundValue) {
      this.contractId = foundValue.id;
    }
  }

  public onFileSelected(event: any): void {
    this.fileName = event.target.files[0].name;
    this.file = event.target.files[0];
  }

  public async onUpload(): Promise<void> {
    if (this.file) {
      this.loading = true;
      const disabled = [];
      Object.keys(this.importForm.controls).forEach(key => {
        let control = this.importForm.controls[key];
        if (control.disabled) disabled.push(key);
      });
      this.importForm.disable();
      const arrivedAt = this.importForm.controls['arrivalDate'].value;
      const arrivedBy = this.importForm.controls['arrivedBy'].value;
      try {
        const event = await this.service
          .uploadVehicles(this.file, this.contractId, arrivedAt, arrivedBy)
          .toPromise();
        this.importResponse = [];
        if (!event.railroadCars) event.railroadCars = {
          "Veicoli gestiti": event.vehicles
        }
        this.generateImportResult(event.railroadCars);
        Object.keys(event.railroadCars).forEach((item) => {
          this.importResponse.push({
            key: item,
            completedItems: event.railroadCars[item].filter((vehicle) => vehicle.status === 'CREATED').length,
            values: event.railroadCars[item]
          })
        });

      } catch (error) {
        console.log(error);
      }
      this.importForm.enable();
      disabled.forEach(key => this.importForm.controls[key].disable());
      this.loading = false;
    }
  }

  public generateImportResult(railroadCars) {
    this.importResult = {
      succeeded: 0,
      failed: 0,
      alreadyExists: 0,
      total: 0
    }
    Object.keys(railroadCars).forEach((item) => {
      railroadCars[item].forEach((car) => {
        this.importResult.total++;
        switch (car.status) {
          case 'ERROR':
            this.importResult.failed++;
            break;
          case 'ALREADY_EXISTS':
            this.importResult.alreadyExists++;
            break;
          default:
            this.importResult.succeeded++;
            break;
        }
      })
    })
  }

  public triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  public mapStatus(status: string) {
    switch (status) {
      case 'CREATED':
        return 'Importato';
      case 'ERROR':
        return 'Errore inserimento';
      case 'ALREADY_EXISTS':
        return 'Già inserito';
      default:
        return '';
    }
  }



  private initContractControl(): void {
    this.contractFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        if (!res) {
          this.contractId = null;
        } else {
          this.contractId = this.filterAgainstResults(this.contractOptions, res, this.contractId);
        }
        this.updateContractAutocompleteResults(res);
      });
  }

  private filterAgainstResults(options: any, term: string, value: string): string {
    let result = null;
    if (!options) return value;
    options.forEach(
      (option) => {
        if (option.name === term) result = value;
      })
    return result;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
