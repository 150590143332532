<nes-header></nes-header>

<nes-portlet [breadcrumb]="['Home', 'Pratiche']" [title]="portletTitle" [loading]="loading" [withoutBorder]="true">
    <ng-container *ngTemplateOutlet="generalFormTab"></ng-container>
</nes-portlet>

<ng-template #generalFormTab>
    <nes-portlet-title [title]="'Informazioni generali'">
        <nes-portlet-unlock (onEdit)="toggleGeneral(true)" (onSave)="save()" (onCancel)="toggleGeneral(false)" [isEditable]="editable" *ngIf="detailMode && !dossierDetails?.completionDate"></nes-portlet-unlock>
<!--        <button [disableRipple]="true" class="flex_center __print" color="accent" mat-button *ngIf="!editable && dossierType === 'exgress'" (click)="pageStatus = 'report'">-->
<!--        <button [disableRipple]="true" class="flex_center __print _spaced" color="accent" mat-button *ngIf="!editable && dossierType === 'exgress'" (click)="downloadReport()">-->
<!--            <mat-icon svgIcon="mda-print" class="_light _right"></mat-icon>-->
<!--        </button>-->
        </nes-portlet-title>
    <div *ngIf="!!dossierType">
        <nes-portlet-form>
            <nes-dossier-details-general #generalDetailsForm [data]="dossierDetails" [dossierType]="dossierType" [editable]="editable"></nes-dossier-details-general>
        </nes-portlet-form>
    </div>

    <dossier-details-activities #activitiesList [data]="dossierActivities" [editable]="editable" *ngIf="dossierType === 'processing' || dossierActivities.length > 0"></dossier-details-activities>

    <dossier-details-vehicles [dType]="dossierType" #vinList [data]="dossierVehicles" [editable]="editable" ></dossier-details-vehicles>

    <nes-portlet-footer *ngIf="!detailMode">
        <button [disableRipple]="true" class="flex_center" color="accent" mat-button (click)="save()">Conferma Inserimento pratica</button>
        <button [disableRipple]="true" class="flex_center" mat-button (click)="cancel()">Annulla</button>
    </nes-portlet-footer>
</ng-template>
