<button class="nes_close_btn" mat-icon-button (click)="onClose()"><mat-icon>clear</mat-icon></button>
<nes-dialog [title]="'Telaio già registrato'" [withoutBorder]="false">

    <p>
        Il telaio inserito risulta già registrato,
        <br>
        Vuoi vedere i dettagli del veicolo: <b>{{passedData.vin}}</b> ?
    </p>

    <div class="form-footer">
        <button [disableRipple]="true" class="form-undo-button" mat-button (click)="onClose()">Modifica Telaio</button>
        <button [disableRipple]="true" class="form-submit-button" mat-button (click)="redirectToVehicle()">Vedi dettagli</button>
    </div>
</nes-dialog>