import {Injectable} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem("ACCESS_TOKEN")!;
    if (!authToken) return next.handle(request)

    const authRequest = request.clone({
      headers: request.headers.set('Authorization', authToken)
    });

    return next.handle(authRequest);
  }
}
