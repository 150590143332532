import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';

import {MatSidenav} from '@angular/material/sidenav';

import {environment} from '../../../../../environments/environment';
import {ApplicationConfiguration} from '../../../../configuration/application.configuration';

@Component({
  selector: 'nes-sidenav-layout',
  templateUrl: './nes-sidenav-layout.component.html'
})
export class NesSidenavLayoutComponent implements OnInit, OnDestroy {

  @ViewChild(MatSidenav, {static: false}) sidenav: MatSidenav;

  get alfrescoExternalUrl(): string  { return this.applicationConfiguration.alfresco.externalUrl || ''; };

  get configuration(): ApplicationConfiguration { return this.applicationConfiguration; };

  // --

  private breakpointMatching: boolean;

  private breakpointSubscription: Subscription;
  private routerSubscription: Subscription;

  // --

  public lockedSidenav = false;
  public expandedSidenav = false;

  // --

  constructor(private router: Router, private breakpointObserver: BreakpointObserver, private applicationConfiguration: ApplicationConfiguration) {}

  ngOnInit(): void {
    this.breakpointSubscription = this.breakpointObserver
      .observe(['(min-width: 1441px)'])
      .subscribe(breakpoint => this.breakpointMatching = breakpoint.matches);

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {

        if (!this.lockedSidenav) {
          this.sidenav.close();
        }

      }
    });
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  // --

  get applicationVersionNumber(): string {
    return environment.versionNumber || '';
  }

  get applicationVersionYear(): string {
    return environment.versionYear || '';
  }

  get isBreakpointActive(): boolean {
    return this.breakpointMatching;
  }

  get activeMode(): string {
    if (!this.breakpointMatching) {
      return 'over';
    }

    return this.expandedSidenav ? 'side' : 'over';
  }

  // --

  public toggleSidenav(): void {
    this.lockedSidenav = !this.lockedSidenav;
    this.expandedSidenav = !this.expandedSidenav;
  }

}
