import {IUserData} from "../../modules/authentication/shared/interfaces/user-data.interface";
import {UserRoles} from "../../modules/authentication/shared/interfaces/user-roles";

export class RolesGuardHelper {
  public static getUserData(): IUserData {
    return JSON.parse(localStorage.getItem('USER_DATA'));
  }
  public static getUserRoles(): UserRoles[] {
    return this.getUserData().roles;
  }

  public static canWrite(): boolean {
    return this.getUserData().roles.includes(UserRoles.Write);
  }

  public static canRead(): boolean {
    return this.getUserData().roles.includes(UserRoles.Read);
  }

  public static canViewBillingData(): boolean {
    return this.getUserData().roles.includes(UserRoles.Billing);
  }
}
