<nes-portlet [breadcrumb]="['Home', 'Pratiche']" [title]="'Lista pratiche'" >

    <nes-portlet-filter-bar (search)="applyFilters($event)">
      <div class="nes-portlet-filter-bar-col">
        <button [disableRipple]="true" mat-button color="primary" class="flex_center" (click)="openCreationDialog()">Aggiungi pratica</button>
      </div>
    </nes-portlet-filter-bar>


    <nes-portlet-table [dataSource]="dossierList">
      <mat-table [dataSource]="dossierList">
          <ng-container matColumnDef="code">
              <mat-header-cell *matHeaderCellDef>Numero pratica</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <a [routerLink]="['/pratiche/dettaglio']" [queryParams]="{id: row.code}" class="mat-row-link"></a>
                {{ row.code }}
              </mat-cell>
          </ng-container>
          <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef>Tipo pratica</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ getDossierType(row.type)}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="customer">
              <mat-header-cell *matHeaderCellDef>Cliente</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span *ngIf="!!row.customer">{{ row.customer }}</span>
                <span *ngIf="!row.customer" class="placeholder"></span>
              </mat-cell>
          </ng-container>
          <ng-container matColumnDef="vehicles">
              <mat-header-cell *matHeaderCellDef>Numero Telai</mat-header-cell>
              <mat-cell *matCellDef="let row">{{ row.vehicles}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="indicator">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button>
                <nes-icon svgIcon="blue_angle"></nes-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="dossierDetails"></mat-header-row>
          <mat-row class="_selectable" [routerLink]="['/pratiche/dettaglio/' + row.id]" *matRowDef="let row; columns: dossierDetails;"></mat-row>
      </mat-table>
      <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [pageIndex]="dossierFilters.page" (page)="updatePagination($event)" [length]="resultsCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </nes-portlet-table>
</nes-portlet>
