import {Injectable} from "@angular/core";
import {ApplicationConfiguration} from "./application.configuration";
import {ConfigurationSectionProvider} from "@nes/angular-configuration";
import {DossierConfiguration} from "../modules/dossiers/dossier.configuration";
import { VehiclesConfiguration } from "../modules/vehicles/vehicles.configuration";
import { BillingConfiguration } from "../modules/billing/billing.configuration";

@Injectable({providedIn: 'root'})
export class BillingConfigurationProvider implements ConfigurationSectionProvider<ApplicationConfiguration, VehiclesConfiguration> {

  constructor(private applicationConfiguration: ApplicationConfiguration) {}

  get section(): BillingConfiguration { return this.applicationConfiguration.billing; }
}
