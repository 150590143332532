export enum EVehicleStatus {
  Registered,
  Parked,
  Arrived,
  Outbound,
  Lost,
  None,
  WillArrive,
  OutgoingDocument,
  Left
}
export function getVehicleStatusLabel(status: EVehicleStatus): string {
  switch (status) {
    case EVehicleStatus.Registered:
      return 'Da verificare';
    case EVehicleStatus.Parked:
      return 'Parcheggiato';
    case EVehicleStatus.Arrived:
      return 'Arrivato';
    case EVehicleStatus.Outbound:
      return 'In fila di carico';
    case EVehicleStatus.WillArrive:
      return 'Arriverà';
    case EVehicleStatus.OutgoingDocument:
      return 'In DDT';
    case EVehicleStatus.Left:
      return 'Uscito';
    case EVehicleStatus.Lost:
      return 'Perso';
    case EVehicleStatus.None:
      return '';
    default:
      return '';
  }
}
