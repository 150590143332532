import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleDetailsGeneralComponent } from './vehicle-details/vehicle-details-general/vehicle-details-general.component';
import { VEHICLE_DETAILS_SERVICE } from './services/vehicle-details-service.service.interface';
import { VehiclesRoutingModule } from './vehicles-routing.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; // qui sembra non servire, ma serve per la confirmation-dialog

import {NesModule} from '../../shared/nes.module';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import { ComponentModule } from 'src/app/common/components/component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { VehicleModelDialogComponent } from './dialogs/vehicle-model-dialog/vehicle-model-dialog.component';
import { VehicleBrandDialogComponent } from './dialogs/vehicle-brand-dialog/vehicle-brand-dialog.component';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { VehiclesListComponent } from './vehicles-list/vehicles-list.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { VehicleVinTakenDialogComponent } from './dialogs/vehicle-vin-taken-dialog/vehicle-vin-taken-dialog.component';
import { VehicleDamagesDialogComponent } from './dialogs/vehicle-damages-dialog/vehicle-damages-dialog.component';
import { VehicleDetailsDamagesComponent } from './vehicle-details/vehicle-details-damages/vehicle-details-damages.component';
import { VehicleDetailsBlockagesComponent } from './vehicle-details/vehicle-details-blockages/vehicle-details-blockages.component';
import { VehicleDetailsPracticesComponent } from './vehicle-details/vehicle-details-practices/vehicle-details-practices.component';
import { VehicleSelectionDialogComponent } from './dialogs/vehicle-selection-dialog/vehicle-selection-dialog.component';
import { VehicleCreationDialogComponent } from './dialogs/vehicle-creation-dialog/vehicle-creation-dialog.component';
import { VehicleDetailsService } from './services/vehicle-details.service';
import { ImportFromFileComponent } from './import-from-file/import-from-file.component';
import { ImportDamagesComponent } from './import-damages/import-damages.component';
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatListModule} from "@angular/material/list";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { VehiclePositionsHistoryDialogComponent } from './dialogs/vehicle-positions-history-dialog/vehicle-positions-history-dialog.component';
import {LOCAL_DATA_STORAGE_SERVICE} from "../../shared/services/local-data-storage.service.interface";
import {LocalDataStorageService} from "../../shared/services/local-data-storage.service";
import { OilReportComponent } from './oil-report/oil-report.component';
import {RegistryModule} from "../registry/registry.module";
import { VehicleBlockagesComponent } from './vehicle-blockages/vehicle-blockages.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatChipsModule} from "@angular/material/chips";

@NgModule({
  declarations: [
    VehicleDetailsGeneralComponent,
    VehicleModelDialogComponent,
    VehicleBrandDialogComponent,
    VehicleDetailsComponent,
    VehiclesListComponent,
    ConfirmationDialogComponent,
    VehicleVinTakenDialogComponent,
    VehicleDamagesDialogComponent,
    VehicleDetailsDamagesComponent,
    VehicleDetailsBlockagesComponent,
    VehicleDetailsPracticesComponent,
    VehicleSelectionDialogComponent,
    VehicleCreationDialogComponent,
    ImportFromFileComponent,
    ImportDamagesComponent,
    VehiclePositionsHistoryDialogComponent,
    OilReportComponent,
    VehicleBlockagesComponent
  ],
    imports: [
        CommonModule,
        VehiclesRoutingModule,
        NesModule,
        ComponentModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        RegistryModule,
        MatButtonToggleModule,
        MatChipsModule
    ]
})
export class VehiclesModule {
  static forRoot(): ModuleWithProviders<VehiclesModule> {
    return {
      ngModule: VehiclesModule,
      providers: [
        {
          provide: VEHICLE_DETAILS_SERVICE,
          useClass: VehicleDetailsService
        },
        {
          provide: LOCAL_DATA_STORAGE_SERVICE,
          useClass: LocalDataStorageService
        }
      ]
    };
  }
}
