import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import { VehiclesListComponent } from './vehicles-list/vehicles-list.component';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import {ImportFromFileComponent} from "./import-from-file/import-from-file.component";
import { ImportDamagesComponent } from "./import-damages/import-damages.component";
import {OilReportComponent} from "./oil-report/oil-report.component";
import {VehicleBlockagesComponent} from "./vehicle-blockages/vehicle-blockages.component";
import {RolesGuard} from "../authentication/shared/roles.guard";
import {UserRoles} from "../authentication/shared/interfaces/user-roles";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [RolesGuard],
    data: {roles: [UserRoles.Read] },
    component: VehiclesListComponent
  },
  {
    path: 'nuovo',
    canActivate: [RolesGuard],
    data: {roles: [UserRoles.Write] },
    component: VehicleDetailsComponent
  },
  {
    path: 'import',
    canActivate: [RolesGuard],
    data: {roles: [UserRoles.Write] },
    component: ImportFromFileComponent
  },
  {
    path: 'import-danni',
    canActivate: [RolesGuard],
    data: {roles: [UserRoles.Write] },
    component: ImportDamagesComponent
  },
  {
    path: 'blocchi',
    canActivate: [RolesGuard],
    data: {roles: [UserRoles.Write] },
    component: VehicleBlockagesComponent
  },
  {
    path: 'dettaglio/:id',
    canActivate: [RolesGuard],
    data: {roles: [UserRoles.Read] },
    component: VehicleDetailsComponent
  },
  {
    path: 'report-olio',
    canActivate: [RolesGuard],
    data: {roles: [UserRoles.Read] },
    component: OilReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VehiclesRoutingModule {
}
