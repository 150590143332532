<button class="nes_close_btn" mat-icon-button (click)="onClose()"><mat-icon>clear</mat-icon></button>
<nes-dialog [breadcrumb]="breadcrumbs" [title]="title">

    <button color="primary" class="top_right_creation_button flex_center" (click)="onSwitchToCreation()"  *ngIf="dType === 'ingress'" mat-button>Nuovo veicolo</button>
    <nes-portlet-filter-bar (search)="applyFilter($event)">
    </nes-portlet-filter-bar>

    <div class="nes-table-action _forced_padded _reduced_padding">
        <div class="nes-table-action-box">
            <mat-checkbox [disableRipple]="true" (click)="$event.stopPropagation()" (change)="selectAllVehicles()" [checked]="hasFullSelection" [indeterminate]="hasIndeterminateSelection">seleziona tutti</mat-checkbox>
            <mat-icon svgIcon="mda-dots" class="separator"></mat-icon>
        </div>
    </div>

    <nes-portlet-table [dataSource]="vehicles">
        <mat-table [dataSource]="vehicles">
            <ng-container matColumnDef="checkbox">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-checkbox [disableRipple]="true" (change)="toggleVehicleSelection(row)" [checked]="isVehicleSelected(row.id)"></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="licensePlate">
                <mat-header-cell *matHeaderCellDef>Targa</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.licensePlate ? row.licensePlate : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="vin">
                <mat-header-cell *matHeaderCellDef>Telaio</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.vin }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="brand">
                <mat-header-cell *matHeaderCellDef>Marca</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.brand?.name ? row.brand.name : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="model">
                <mat-header-cell *matHeaderCellDef>Modello</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.model?.name ? row.model.name : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef>Colore</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.color?.name ? row.color.name : ''}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="length">
                <mat-header-cell *matHeaderCellDef>Segmento lunghezza</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ row.model?.lengthSegment.name ? row.model.lengthSegment.name : '' }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="vehicleDetails"></mat-header-row>
            <mat-row class="_selectable" *matRowDef="let row; columns: vehicleDetails;" ></mat-row>
        </mat-table>
        <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [pageIndex]="vehicleFilter.pageNumber" (page)="onPaginationChange($event)" [length]="totalVehicles" [pageSize]="vehicleFilter.pageSize" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>

    </nes-portlet-table>

    <div class="nes-dialog-footer-content not-border" dialog-footer>
      <button [disableRipple]="true" class="form-undo-button" mat-button (click)="onClose()">ANNULLA</button>
      <button [disableRipple]="true" class="form-submit-button" mat-button *ngIf="hasTarget" (click)="onSave()">AGGIUNGI</button>
    </div>

</nes-dialog>
