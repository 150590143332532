import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { IDossierVehicleTransportDetails } from '../../interfaces/dossier.type';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { IServiceAreaPositionDetails } from 'src/app/modules/service-area-mapping/position';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Subscription } from 'rxjs';


export interface IMovementVehicle{
  index: number;
  description: string;
}

export interface IDossierMovementSourceAndDestinationControlsOrder {
  vehicleType?: number;
}



@Component({
  selector: 'dossier-movement-source-and-destination-controls',
  templateUrl: './dossier-movement-source-and-destination-controls.component.html',
  styleUrls: ['./dossier-movement-source-and-destination-controls.component.scss']
})

export class DossierMovementSourceAndDestinationControlsComponent implements OnInit, OnDestroy {

  @Input() dossierType: string;
  @Input() dossierMovementDetails: IDossierVehicleTransportDetails;
  @Input() set editable(newValue: boolean){
    this._editable = newValue;
    if (!!this.vehicleTypeControl?.controls['type']?.value) this.changeVehicleType(this.vehicleTypeControl.controls['type'].value)
  }
  get editable(): boolean {return this._editable};

  @Input() vehicleTypeControl: FormGroup = null;
  @Input() isSelectBefore = true;

  @Output() vehicleTypeControlChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  _editable = true;

  get defaultPositionValues(){
    return {
      area: null,
      sector: null,
      row: null
    }
  }

  movementVehicles: IMovementVehicle[] = [
    {
      index: 0,
      description: 'Bisarca'
    },
    {
      index: 1,
      description: 'Nave'
    },
    {
      index: 2,
      description: 'Treno'
    }
  ];
  formData: FormGroup = this.fb.group({
    vehicle: null
  });
  licensePlates: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  private subscriptions: Subscription[] = [];

  // --

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.formData.valueChanges
      .subscribe(() => this.vehicleTypeControlChange.emit(this.vehicleTypeControl))
    );
    if(!!this.vehicleTypeControl && this.vehicleTypeControl.get('details').get('carTransporterDetails').get('licensePlates').value.length > 0) this.licensePlates = this.vehicleTypeControl.get('details').get('carTransporterDetails').get('licensePlates').value;

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  // --
  get isVehicleTypeSelectRequired(): boolean{
    let typeControl = this.vehicleTypeControl.controls['type'];
    return !!typeControl.touched && !typeControl.value;
  }

  get isCarTransporterDetailsFieldRequired(): boolean{
    let field = this.vehicleTypeControl.controls['details'].get('carTransporterDetails').get('fieldValue');
    return field.touched && this.licensePlates.length === 0;
  }

  get isTrainNumberFieldRequired(): boolean {
    let field = this.getVehicleTypeDetailField('trainNumber');
    return field.touched && !field.value;
  }

  get isShipNameFieldRequired(): boolean {
    let field = this.getVehicleTypeDetailField('shipName');
    return field.touched && !field.value;
  }

  private changeVehicleType(index: number): void {
    Object.keys(this.vehicleTypeControl.get('details').value).forEach(field => { 
      const control = this.vehicleTypeControl.get('details').get(field);            
      control.disable();
    });

    if(index === 0){
      this.vehicleTypeControl.get('details').get('carTransporterDetails').enable()
    }

    if(index === 1){
      this.vehicleTypeControl.get('details').get('shipName').enable()
    }

    if(index === 2){
      this.vehicleTypeControl.get('details').get('trainNumber').enable()
    }
  }

  public removeLicensePlate(licensePlate: string){
    const index = this.licensePlates.indexOf(licensePlate);

    if (index >= 0) {
      this.licensePlates.splice(index, 1);
    }
    this.getCarTransporterLicensePlateControl().setValue(this.licensePlates);
    this.emitChanges();
  }

  public addLicensePlates(){
    const value = this.getCarTransporterValueControl().value;

    if ((value || '').trim()) {
      this.licensePlates.push(value.trim());
    }

    this.getCarTransporterValueControl().setValue(null);
    this.getCarTransporterLicensePlateControl().setValue(this.licensePlates);
    this.emitChanges();
  }

  public getVehicleTypeDetailField(field: string): AbstractControl{
    return this
      .vehicleTypeControl
      ?.get('details')
      ?.get(field);
  }

  public getCarTransporterValueControl(): FormControl {
    return this.getCarTransporterControls('fieldValue');
  }

  public getCarTransporterLicensePlateControl(): FormControl {
    return this.getCarTransporterControls('licensePlates');
  }

  public getCarTransporterControls(controlName: string): FormControl {
    return this
      .getVehicleTypeDetailField('carTransporterDetails')
      .get(controlName) as FormControl;
  }

  private emitChanges(): void{
    if(!!this.vehicleTypeControl)
      this.vehicleTypeControlChange.emit(this.vehicleTypeControl);
  }

}
