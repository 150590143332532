import {Component} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('mda-calendar', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-calendar-black.svg'));
    iconRegistry.addSvgIcon('mda-cancel', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-cancel-black.svg'));
    iconRegistry.addSvgIcon('mda-clear', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-clear-black.svg'));
    iconRegistry.addSvgIcon('mda-cross', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-cross-black.svg'));
    iconRegistry.addSvgIcon('mda-dots', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-dots-black.svg'));
    iconRegistry.addSvgIcon('mda-download', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-download-black.svg'));
    iconRegistry.addSvgIcon('mda-download-pdf', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-download-pdf-black.svg'));
    iconRegistry.addSvgIcon('mda-download-xml', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-download-xml-black.svg'));
    iconRegistry.addSvgIcon('mda-edit', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-edit-black.svg'));
    iconRegistry.addSvgIcon('mda-eye', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-eye-black.svg'));
    iconRegistry.addSvgIcon('mda-fax', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-fax-black.svg'));
    iconRegistry.addSvgIcon('mda-filter', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-filter-black.svg'));
    iconRegistry.addSvgIcon('mda-left-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-left-arrow-black.svg'));
    iconRegistry.addSvgIcon('mda-logout', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-logout-black.svg'));
    iconRegistry.addSvgIcon('mda-mail', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-mail-black.svg'));
    iconRegistry.addSvgIcon('mda-mobile', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-mobile-black.svg'));
    iconRegistry.addSvgIcon('mda-options', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-options-black.svg'));
    iconRegistry.addSvgIcon('mda-organization', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-organization-black.svg'));
    iconRegistry.addSvgIcon('mda-person', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-person-black.svg'));
    iconRegistry.addSvgIcon('mda-phone', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-phone-black.svg'));
    iconRegistry.addSvgIcon('mda-reload', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-reload-black.svg'));
    iconRegistry.addSvgIcon('mda-right-indicator', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-right-indicator-black.svg'));
    iconRegistry.addSvgIcon('mda-search', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-search-black.svg'));
    iconRegistry.addSvgIcon('mda-warning', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-warning-black.svg'));
    iconRegistry.addSvgIcon('mda-print', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mda-print-black.svg'));
  }

}
