import {Injectable} from "@angular/core";
import {PersonsRegistryConfiguration} from "../modules/registry/persons-registry.configuration";
import {ApplicationConfiguration} from "./application.configuration";
import {ConfigurationSectionProvider} from "@nes/angular-configuration";

@Injectable({providedIn: 'root'})
export class PersonsRegistryConfigurationProvider implements ConfigurationSectionProvider<ApplicationConfiguration, PersonsRegistryConfiguration> {

  constructor(private applicationConfiguration: ApplicationConfiguration) {}

  get section(): PersonsRegistryConfiguration { return this.applicationConfiguration.personsRegistry; }
}
