<nes-header class="no_print"></nes-header>

<nes-portlet [breadcrumb]="['Home', 'Ordini di uscita']" [title]="title" [loading]="loading" [withoutBorder]="true"
             [class]="'work-orders-details'">
  <ng-container *ngTemplateOutlet="generalFormTab"></ng-container>
</nes-portlet>

<ng-template #generalFormTab>

  <nes-portlet-title title="Destinazione">
    <div class="btn-group no_print" >
      <button [disableRipple]="true" mat-button color="primary" class="flex_center" (click)="backAction()">
        Indietro
      </button>
      <button [disableRipple]="true" class="flex_center __print _spaced" color="accent" mat-button *ngIf="!edit" (click)="triggerPrint()">
        <mat-icon svgIcon="mda-print" class="_light _right" ></mat-icon> Stampa
      </button>
    </div>
  </nes-portlet-title>

  <form class="nes-portlet-form-wr _mt4">
    <div class="nes-portlet-form-col _two">
      <div class="_input_group">
        <service-area-position-input
          (positionChange)="handlePositionChange($event)"
          title="Seleziona fila di carico"
          placeholder="Destinazione"
          [sectorType]="sectorType"
          [enableOccupation]="true"
          [value]="position"
          [editable]="edit"
          [showRows]="true"
        >
        </service-area-position-input>
        <mat-error *ngIf="triedSave && (!this.position.sector || !this.position.lane)" class="standalone"><b>Destinazione</b> richiesta</mat-error>
      </div>
    </div>
    <div class="nes-portlet-form-col _two">
    </div>
  </form>

  <nes-portlet-title [title]="vehicleSectionTitle" [class]="'align-extra-content-right'">
    <mat-error *ngIf="triedSave && vehicleListLength === 0" class="standalone"><b>Lista veicoli</b> deve contenere
      almeno un veicolo
    </mat-error>
  </nes-portlet-title>
  <nes-portlet-table [dataSource]="vehicles" [class]="{'_empty': vehicleListLength === 0}">
    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="vehicles">
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Telaio o targa</mat-header-cell>
          <mat-cell *matCellDef="let row">
              <span *ngIf="row.type === 'input'" class="vehicle_search_list">
                <mat-form-field class="fill-available">
                  <mat-label>Targa o telaio</mat-label>
                  <input matInput
                         [formControl]="vehicleSearch"
                         (paste)="handlePaste($event)"
                         [matAutocomplete]="autocompleteSearch"
                         (blur)="vehicleSearch.updateValueAndValidity()"
                  >
                  <mat-spinner matSuffix [strokeWidth]="2" [diameter]="20" *ngIf="loadingSearch"></mat-spinner>
                </mat-form-field>
                <br>
                <mat-autocomplete #autocompleteSearch="matAutocomplete"
                                  (optionSelected)="handleSearchAutocomplete($event)">
                  <mat-option *ngFor="let option of vehicleSearchResults" [value]="option.id">
                    {{ retrieveVehicleLabel(option) }}
                  </mat-option>
                </mat-autocomplete>
              </span>
              <span *ngIf="row.type !== 'input'">
                {{ retrieveVehicleLabel(row.vehicle) }}
              </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef>Marca e modello</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ retrieveVehicleDetails(row.vehicle) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="" *ngIf="row.type !== 'input'">
              <mat-icon *ngIf="row.vehicle.ticked">done</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef>Posizione</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ retrieveVehiclePosition(row.vehicle) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Colore</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.type !== 'input'">
              {{ row.vehicle.color }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="destination_address">
          <mat-header-cell *matHeaderCellDef>Indirizzo di destinazione</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.type !== 'input'">
              {{ row.vehicle.destinationAddress }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="extra_data">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.type !== 'input'">
              <button
                [disableRipple]="true"
                type="button"
                class="flex_center push-left _input-complimentary "
                color="accent"
                mat-button
                *ngIf="!!row.vehicle.ddt"
                [routerLink]="['/ddt/dettaglio/' + row.vehicle.ddt.id]"
              >
                DDT
              </button>
              <button
                [disableRipple]="true"
                type="button"
                class="flex_center push-left _input-complimentary "
                color="accent"
                mat-button
                *ngIf="!!row.vehicle.orderId && !!edit"
                [routerLink]="['/ordini-di-uscita/' + row.vehicle.orderId]"
              >
                Ordine
              </button>
              <span *ngIf="!!row.vehicle.blockReasons?.length">
                <mat-icon
                  class="accent-icon"
                  [matTooltip]="getVehicleBlockReasonsTooltip(row.vehicle.blockReasons)"
                  matTooltipPosition="above"
                >warning</mat-icon>
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button aria-label="Cancella" *ngIf="row.type !== 'input'"
                    (click)="removeVehicle(row.vehicle)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="vehicleDetails"></mat-header-row>
        <mat-row *matRowDef="let row; columns: vehicleDetails;" [class]="{'_input': row.type === 'input'}"></mat-row>
      </mat-table>
    </div>
  </nes-portlet-table>
  <nes-portlet-footer *ngIf="edit">
    <button [disableRipple]="true" class="flex_center" color="accent" mat-button (click)="save()" [disabled]="shouldDisableSaveButton">Salva</button>
  </nes-portlet-footer>
</ng-template>
