
export class Vertex {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  static absoluteZero = new Vertex(0, 0);

  public isValid(): boolean {
    return null !== this.x && null !== this.y;
  }
}

export class Segment
{
  a: Vertex;
  b: Vertex;
  constructor(a: Vertex, b: Vertex)
  {
    this.a = a;
    this.b = b;
  }

  intersectsWith(segment: Segment)
  {
    return this.intersectsWithLine(segment) && segment.intersectsWithLine(this);
  }

  intersectsWithLine(line: Segment)
  {
    const u = { x: line.b.x - line.a.x, y: line.b.y - line.a.y };
    const v = { x: this.a.x - line.a.x, y: this.a.y - line.a.y };
    const w = { x: this.b.x - line.a.x, y: this.b.y - line.a.y };

    const uxv = u.x * v.y - u.y * v.x;
    const uxw = u.x * w.y - u.y * w.x;

    return uxv * uxw < 0;
  }
}

export class Polygon {
  segments: Segment[];
  constructor(vertices: Vertex[]) {
    this.segments = [];

    for(let i = 0; i < vertices.length; i++) {
      this.segments.push(new Segment(
        vertices[i],
        i < vertices.length - 1 ? vertices[i + 1] : vertices[0]
      ));
    }
  }

  isPointInside(point: Vertex): boolean {
    const test = new Segment(point, Vertex.absoluteZero);
    let count = 0;
    this.segments.forEach((segment) => {

      if (segment.intersectsWith(test)) count++;
    })

    return (count % 2) === 1;
  }
}
