import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  IModelSearchResult,
  ISearchResult, IVehicleBrand,
  IVehicleModelsFilters
} from "../../../vehicles/shared/interfaces/vehicles.type";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
  IVehicleDetailsService,
  VEHICLE_DETAILS_SERVICE
} from "../../../vehicles/services/vehicle-details-service.service.interface";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'vehicle-models-list',
  templateUrl: './vehicle-models-list.component.html',
  styleUrls: ['./vehicle-models-list.component.scss']
})
export class VehicleModelsListComponent implements OnInit, OnDestroy {
  public loading = false;
  public firstLoad = true;
  public shouldUpdate = false;
  public tableFilter: any;
  models: IModelSearchResult[];
  private subscriptions: Subscription[] = [];
  private searchParams: IVehicleModelsFilters = {};
  public brands: ISearchResult[] = [];
  public brandSelect: FormControl = new FormControl();
  private brandSpecific = false;

  constructor(private router: Router,
              @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService) { }

  ngOnInit(): void {
    this.fetchBrandsList()
    this.fetchModelsList()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }


  get tableColumns(): string[] {
    return ['brand', 'label'];
  }

  public handleSearch(event: String): void {
    this.searchParams = Object.assign(
      this.searchParams,
      {search: event}
    );
    this.fetchModelsList();
  }

  public setBrandFilterValue(value: String): void {
    this.brandSelect.setValue(value);
    this.searchParams = Object.assign(
      this.searchParams,
      {manufacturerId: value}
    );
    this.fetchModelsList();
  }

  private checkQueuedRequests(): boolean {
    if (this.loading) {
      this.shouldUpdate = true;
    }

    return this.loading;
  }

  private getLabel(model: ISearchResult): string {
    return model.name || "N/A";
  }

  private getManufacturerName(model: IModelSearchResult): string {
    if (this.brandSpecific) {
      return this.brands.find(brand => brand.id === this.brandSelect.value).name;
    }
    else {
      return model.manufacturerName || "N/A";
    }
  }

  private fetchModelsList(): void {
    if (this.checkQueuedRequests()) return;
    this.loading = true;
    this.subscriptions.push(
      this.vService
        .getVehicleModels(this.searchParams)
        .subscribe(res => {
            if (this.shouldUpdate) {
              this.shouldUpdate = false;
              this.loading = false;
              this.fetchModelsList();
              return;
            }
            else {
              this.models = res;
              this.shouldUpdate = true;
              this.brandSpecific = this.searchParams.manufacturerId != null;
            }
            this.firstLoad = false;
          },
          err => {
            console.log(err)
          },
          () => {
            this.loading = false;
          }
        ));
  }

  private fetchBrandsList(): void {
    this.subscriptions.push(
      this.vService
        .searchBrand("")
        .subscribe(res => {
            this.brands = res;
          },
          err => {
            console.log(err)
          },
          () => {
          }
        ));
  }
}
