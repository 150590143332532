import {InjectionToken} from "@angular/core";

export const LOCAL_DATA_STORAGE_SERVICE: InjectionToken<ILocalDataStorageService> = new InjectionToken<ILocalDataStorageService>("Local storage service implementation");

export interface ILocalDataStorageService {
  save(key: string, value: any): void;
  get(key: string): any;
  shouldUseLocalData(key: string): boolean;
  setUseLocalData(key: string, value: boolean): void;
}
