<nes-header></nes-header>

<nes-portlet [breadcrumb]="['Home', 'Anagrafiche', 'Modelli']" [title]="'Modelli veicoli'" [withoutBorder]="false" [loading]="firstLoad">
  <nes-portlet-filter-bar  class="hide-search" (search)="handleSearch($event)">
    <div class="nes-portlet-filter-bar-col select_field">
      <mat-form-field>
        <mat-label>Marca</mat-label>
        <mat-select #brand [formControl]="brandSelect">
          <mat-option [value]="option.id" *ngFor="let option of brands" (click)="setBrandFilterValue(option.id)">{{option.name}}</mat-option>
        </mat-select>
        <nes-icon svgIcon="blue_angle_bottom"  (click)="brand.toggle()"></nes-icon>
      </mat-form-field>
      <mat-icon *ngIf="brand.value" (click)="setBrandFilterValue(null);brand.close()">clear</mat-icon>
    </div>
  </nes-portlet-filter-bar>

  <nes-portlet-table [dataSource]="models" >
    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="models">
        <ng-container matColumnDef="brand">
          <mat-header-cell *matHeaderCellDef>Marca</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getManufacturerName(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Modello</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getLabel(row) }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: tableColumns;"></mat-row>
      </mat-table>
    </div>
  </nes-portlet-table>
</nes-portlet>
