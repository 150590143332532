<nes-portlet [loading]="loading" [withoutBorder]="true" [class]="'floating-window'" [title]="'Accedi'">
  <h1>Accedi</h1>
  <form class="authentication-form" [formGroup]="form" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
    <mat-form-field class="example-full-width">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher" autocomplete="email">
      <mat-error *ngIf="form.controls['email'].hasError('email') && !form.controls['email'].hasError('required')">
        Email <strong>non valida</strong>
      </mat-error>
      <mat-error *ngIf="form.controls['email'].hasError('required')">
        Email <strong>richiesta</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Password</mat-label>
      <input type="password" matInput formControlName="password"
             placeholder="">
      <mat-error *ngIf="form.controls['password'].hasError('required')">
        Password  <strong>richesta</strong>
      </mat-error>
    </mat-form-field>

    <div class="fake-link"
         [routerLink]="['/auth/forgot-password/']">Password dimenticata?</div>

    <div class="form-footer">
      <button
        [disableRipple]="true"
        type="button"
        class="flex_center "
        color="accent"
        mat-button
        (click)="onSubmit()"
      >
        Accedi
      </button>
    </div>
  </form>
</nes-portlet>
