import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {IVehicleDetailsService, VEHICLE_DETAILS_SERVICE} from "../services/vehicle-details-service.service.interface";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import * as moment from "moment";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MM_YYYY_FORMAT} from "../../billing/shared/interfaces/date-formats";
import {Moment} from "moment/moment";
import {MatDatepicker} from "@angular/material/datepicker";
import {IDdtCustomer} from "../../dossiers/shared/interfaces/ddt";
import {IReportRequest} from "../../billing/shared/interfaces/billing.type";
import {IVehiclesOilReport} from "../shared/interfaces/vehicles.type";

@Component({
  selector: 'app-oil-report',
  templateUrl: './oil-report.component.html',
  styleUrls: ['./oil-report.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MM_YYYY_FORMAT},
  ],
})
export class OilReportComponent implements OnInit {

  public reportTriggered = false;
  public loading = false;
  public vehicles: any[] = [];

  private dateParts = {
    month: null,
    year: null
  };

  public form: FormGroup = new FormGroup({
    date: new FormControl(moment()),
    customer: new FormControl({value: '', id: ''} as IDdtCustomer),
    liters: new FormControl({value: '', disabled: true}),
    operationType: new FormControl('download'),
  });

  public vehicleFilter: any = {
    pageNumber: 1,
    pageSize: 10,
  };

  public today = new Date();
  private csvDownloadEnabled: boolean = true;

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.form.get("date").value;
    ctrlValue.year(normalizedYear.year());
    this.form.get("date").setValue(ctrlValue);
    this.dateParts.year = normalizedYear.year();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.form.get("date").value;
    ctrlValue.month(normalizedMonth.month());
    this.form.get("date").setValue(ctrlValue);
    this.dateParts.month = normalizedMonth.month();
    datepicker.close();
  }

  constructor(
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    this.initDate();
  }

  private initDate() {
    const date = moment(new Date()).subtract(1, 'months').startOf('month');
    this.dateParts.month = date.month();
    this.dateParts.year = date.year();
    this.form.get('date').setValue(date);
  }

  get tableColumns(): string[] {
    return ['date', 'vin', 'vac', 'liters']
  }

  get paginatedVehicles(): any[] {
    return this.vehicles.slice((this.vehicleFilter.pageNumber) * this.vehicleFilter.pageSize, (this.vehicleFilter.pageNumber + 1) * this.vehicleFilter.pageSize);
  }

  onPaginationChange(event: any): void {
    this.vehicleFilter.pageNumber = event.pageIndex;
  }

  async requestOilReport(): Promise<void> {
    if (!!this.form.valid) {
      const formData = this.form.getRawValue();
      const request: IReportRequest = {
        date: {
          year: formData.date.year(),
          month: formData.date.month() + 1
        },
      }
      if (formData.customer) request.customer = formData.customer;
      this.loading = true;
      try {
        const response: IVehiclesOilReport = await this.vService.getVehiclesOilReport(request).toPromise();
        if (formData.operationType === 'download') {
          this.downloadCsv(response, 'report_oil.csv');
        } else {
          this.form.controls.liters.setValue(this.formatAmountColumn(response.totalLiters.toString()));
          this.vehicles = response.vehicles;
          this.reportTriggered = true;
        }
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    } else {
      this.markFormGroupTouched(this.form);
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  convertToCsv(data: IVehiclesOilReport): string {
    // Use ";" as the CSV delimiter
    const csvHeader = "VIN;Liters;Vac;Arrived At;Arrived By";
    const csvRows = data.vehicles.map(
      (vehicle) =>
        `${vehicle.vin};${this.formatAmountColumn(vehicle.liters.toString())};${vehicle.vac || ""};${this.formatDateColumn(vehicle.arrivedAt)};${vehicle.arrivedBy || ""}`
    );

    // Add a meta line indicating the delimiter

    return `${csvHeader}\n${csvRows.join("\n")}\nTotal Liters;${this.formatAmountColumn(data.totalLiters.toString())}`;
  }

  downloadCsv(data: IVehiclesOilReport, fileName: string): void {
    const csvContent = this.convertToCsv(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  formatDateColumn(date: Date): string {
    if (!date) return '';
    return moment(date).format('DD/MM/YYYY HH:mm');
  }

  formatAmountColumn(amount: String): string {
    if (!amount) return '';
    return amount.replace('.', ',');
  }

}
