import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { MatTable } from "@angular/material/table";
import { Observable, of, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { DDT_SERVICE, IDdtService } from "../services/ddt.service.interface";
import { IDdt, IDdtCarrier, IDdtDestination, IDdtVehicle } from "../shared/interfaces/ddt";
import { ActivatedRoute, Router } from "@angular/router";
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from "../../vehicles/services/vehicle-details-service.service.interface";
import { IServiceAreaPositionDetails } from "../../service-area-mapping/position";
import {
  IServiceAreaMappingService,
  SERVICE_AREA_MAPPING_SERVICE
} from "../../service-area-mapping/shared/services/service-area-mapping.service.interface";
import { SectorType } from "../../service-area-mapping/shared/enums/sector-type";
import { RolesGuardHelper } from "../../../shared/utils/roles-guard-helper";
import { IVehicle, IVehicleDamage } from '../../vehicles/shared/interfaces/vehicles.type';



@Component({
  selector: 'nes-dossier-ddt-print',
  templateUrl: './dossier-ddt-print.component.html',
  styleUrls: ['./dossier-ddt-print.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DossierDdtPrintComponent implements OnInit {
  @ViewChild('customerField') customerField: ElementRef;
  @ViewChild('destinationField') destinationField: ElementRef;
  @ViewChild('carrierLicensePlateField') carrierLicensePlateField: ElementRef;
  @ViewChild('carrierField') carrierField: ElementRef;
  @ViewChild('vehiclesField') vehiclesField: ElementRef;
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.customerField.nativeElement.contains(event.target)) {
      this.ddtData.customer.searchTriggered = false;
    }
    if (!this.destinationField.nativeElement.contains(event.target)) {
      this.ddtData.destination.searchTriggered = false;
    }
    if (!this.carrierLicensePlateField.nativeElement.contains(event.target)) {
      this.ddtData.carrierLicensePlate.searchTriggered = false;
    }
    if (!this.carrierField.nativeElement.contains(event.target)) {
      this.ddtData.carrier.searchTriggered = false;
    }
    if (!this.vehiclesField?.nativeElement.contains(event.target)) {
      this.ddtData.vehicles.searchTriggered = false;
    }
  }
  @ViewChildren(MatTable) matTables: QueryList<MatTable<any>>;
  title: string = 'DOCUMENTO DI TRASPORTO';
  sender: string = '';
  ddtData = {
    customer: {
      id: null,
      value: '',
      loading: false,
      searchResults: [],
      searchTriggered: false,
      update: new Subject<string>(),
      textualField: false
    },
    destination: {
      id: null,
      value: '',
      loading: false,
      searchResults: [],
      searchTriggered: false,
      update: new Subject<string>(),
      textualField: false
    },
    carrierLicensePlate: {
      id: null,
      value: '',
      loading: false,
      searchResults: [],
      searchTriggered: false,
      update: new Subject<string>(),
      textualField: true
    },
    carrier: {
      id: null,
      value: '',
      loading: false,
      searchResults: [],
      searchTriggered: false,
      update: new Subject<string>(),
      textualField: false
    },
    vehicles: {
      value: '',
      loading: false,
      update: new Subject<string>(),
      searchResults: [],
      searchTriggered: false,
    }
  }
  edit = true;
  loading = false;
  loadingVehicles = false;
  destination = new Subject<string>();
  ddtDate: Date;
  documentNr: number = 0;
  shippingNumber: string;
  exceptionRoutingCode: string;
  ddtId: string;
  existingDDt = false;
  sectorType = SectorType.Outbound;
  vehicleList: IDdtVehicle[] = [];
  fetchResult: IDdt;
  ddtSign: string;
  printDamages: string = "only-document";
  damages: { [id: string]: { vehicle: IVehicle, damages: IVehicleDamage[] } };

  get damageColumns(): string[] {
    return ['vin', 'licensePlate', 'model', 'positionCode', 'damageCode', 'severityCode', 'note'];
  }



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DDT_SERVICE) private ddtService: IDdtService,
    @Inject(VEHICLE_DETAILS_SERVICE) private vehicleService: IVehicleDetailsService,
    @Inject(SERVICE_AREA_MAPPING_SERVICE) private serviceAreaMappingService: IServiceAreaMappingService
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.ddtId = params.id;
      this.listenForSearchField(this.ddtData.customer, 'searchCustomer');
      this.listenForSearchField(this.ddtData.destination, 'searchDestination');
      this.listenForSearchField(this.ddtData.carrier, 'searchCarrier');
      this.listenForSearchField(this.ddtData.carrierLicensePlate, 'searchCarrierLicensePlates');
      this.listenForSearchField(this.ddtData.vehicles, 'searchVin');

      if (this.ddtId && this.ddtId !== null) {
        this.edit = false;
        this.existingDDt = true;
        this.fetchDdt();
      }
    });
  }

  fetchDdt() {
    if (!this.ddtId) return;
    this.loading = true;
    this.ddtService.fetchDdt(this.ddtId)
      .subscribe((response) => {
        this.fetchResult = response;
        this.mapDdtData();
        this.ddtService.fetchDdtSign(this.ddtId).then(
          async (response) => {
            if (!response.ok) {
              if (response.status === 404) {
                // todo : alert warning document not signed
                this.ddtSign == null;
                return;
              }
              return;
            }
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              this.ddtSign = reader.result.toString();
            }
          }).catch((error) => {
            console.log(error);
          });

        this.damages = {};
        this.ddtService.fetchDdtDamages(this.ddtId).subscribe(
          async damages => {
            let xxx = damages.reduce((result, value) => {
              (result[value['vehicleId'].toString()] = result[value['vehicleId'].toString()] || []).push(value);
              return result;
            }, {});
            Object.keys(xxx).forEach((vehicleId: string) => {
              this.vehicleService.getVehicleDetails(vehicleId).subscribe(
                async vehicle => {
                  const damages = xxx[vehicleId];
                  this.damages[vehicleId] = { vehicle: vehicle, damages: damages };
                }
              );
            });
          });

        this.loading = false;
      })
  }

  getDamageVehicleIds() {
    if (this.damages) return Object.keys(this.damages);
    return null;
  }

  listenForSearchField(field, serviceMethod: string) {
    field.update.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    )
      .subscribe(value => {
        if (value) {
          field.loading = true;
          field.id = null;
          this.searchDebouncedField(serviceMethod, value)
            .subscribe((response) => {
              if (serviceMethod === 'searchVin') {
                response = response.rows.filter((responseVehicle) => {
                  return this.vehicleList.findIndex((vehicle) => vehicle.value === responseVehicle.vin) === -1;
                })
              }
              if (response) {
                field.searchResults = response;
              }
              field.loading = false;
              field.searchTriggered = true;
            })
        }
        else {
          field.searchResults = [];
        }
      });
  }

  formatVehicleSearchIntersection(target: string) {
    const searchTerm = this.ddtData.vehicles.value.toUpperCase();
    return target.replace(searchTerm, '<b>' + searchTerm + '</b>');
  }

  private fetchLaneVehicles(sectorId: string, laneId: number) {
    this.loadingVehicles = true;
    this.serviceAreaMappingService.getLaneVehicles(sectorId, laneId)
      .subscribe((response) => {
        this.vehicleList = [];
        let count = 0;
        if (response.length === 0) this.loadingVehicles = false;
        response.forEach((vehicle) => {
          count++;
          if (count === response.length) this.loadingVehicles = false;
          const vehicleIndex = vehicle.vin || vehicle.licensePlate;
          this.fetchVehicleDetails(vehicleIndex);
        });
      });
  }

  handlePositionChange(event: IServiceAreaPositionDetails) {
    this.fetchLaneVehicles(event.sector.toString(), event.lane);
  }

  searchDebouncedField(methodName: string, term: string): Observable<any> {
    switch (methodName) {
      case 'searchCustomer':
        return this.ddtService.searchCustomer(term);
      case 'searchDestination':
        return this.ddtService.searchDestination(term);
      case 'searchCarrierLicensePlates':
        return this.ddtService.searchCarrierLicensePlates(term);
      case 'searchCarrier':
        return this.ddtService.searchCarrier(term);
      case 'searchVin':
        return this.ddtService.searchVin(term);
      default:
        return of(null)
    }
  }

  addVin(vehicle: any) {
    const modelParts = [];
    if (vehicle.brand) modelParts.push(vehicle.brand);
    if (vehicle.model) modelParts.push(vehicle.model);
    this.vehicleList.push({
      id: vehicle.id,
      customer: vehicle.customer,
      address: vehicle.destinationAddress,
      value: this.getVehicleLabel(vehicle),
      model: modelParts.length ? modelParts.join(' ') : ''
    });
    if (!!vehicle.customer && !this.ddtData.customer.value) this.applyCustomerFromVehicle(vehicle.customer);
    if (!!vehicle.destinationAddress && !this.ddtData.destination.value) this.applyDestinationFromVehicle(vehicle.destinationAddress);
    this.ddtData.vehicles.searchResults = [];
    this.ddtData.vehicles.value = null;
  }

  applyCustomerFromVehicle(customer: string) {
    this.ddtData.customer.loading = true;

    this.searchDebouncedField('searchCustomer', customer)
      .subscribe(response => {
        this.ddtData.customer.loading = false;
        if (!response.length) {
          this.ddtData.customer.value = customer;
          return;
        }
        this.applySearchResult(response[0], this.ddtData.customer)
      });
  }

  applyDestinationFromVehicle(destination: string) {
    this.ddtData.destination.loading = true;

    this.searchDebouncedField('searchDestination', destination)
      .subscribe(response => {
        this.ddtData.destination.loading = false;
        if (!response.length) {
          this.ddtData.destination.value = destination;
          return;
        }
        this.applySearchResult(response[0], this.ddtData.destination)
      });
  }

  public checkVehicleCustomer(customer: string) {
    if (!customer) return false;
    if (!this.ddtData.customer.value) return false;
    return customer !== this.ddtData.customer.value;
  }

  public checkVehicleDestination(destination: string) {
    if (!destination) return false;
    if (!this.ddtData.destination.value) return false;
    return destination !== this.ddtData.destination.value;
  }

  private fetchVehicleDetails(vin: string) {
    this.serviceAreaMappingService
      .searchForVehicleByVin(vin)
      .subscribe(result => {
        if (!result?.rows.length) return;
        this.addVin(result.rows[0]);
      })
  }

  applySearchResult(result, obj) {
    if (obj.textualField) {
      Object.assign(
        obj,
        {
          value: result,
          searchResults: []
        }
      );
    }
    else {
      Object.assign(
        obj,
        {
          ...result,
          searchResults: []
        }
      );
    }
  }

  get vehicleDetails(): string[] {
    return [
      'nr',
      'manufacturer',
      'branch',
      'type',
      'vin'
    ];
  }

  get leftoverVinList(): any[] {
    const length = 8 - this.vehicleList.length;
    const result = [];
    for (let i = 0; i < length; i++) {
      result.push([])
    }

    return result;
  }

  save() {
    const requestBody: any = {};
    if (!!this.ddtData.customer.id) {
      requestBody.CustomerId = this.ddtData.customer.id;
    }
    else if (!!this.ddtData.customer.value) {
      requestBody.Customer = this.ddtData.customer.value;
    }
    else {
      return;
    }

    if (!!this.ddtData.destination.id) {
      requestBody.DestinationId = this.ddtData.destination.id;
    }
    else if (!!this.ddtData.destination.value) {
      requestBody.Destination = this.ddtData.destination.value;
    }
    else {
      return;
    }

    if (!!this.ddtData.carrier.id) {
      requestBody.CarrierId = this.ddtData.carrier.id;
    }
    else if (!!this.ddtData.carrier.value) {
      requestBody.Carrier = this.ddtData.carrier.value;
    }
    else {
      return;
    }

    if (!!this.shippingNumber) {
      requestBody.ShippingNumber = this.shippingNumber;
    }
    else {
      return;
    }

    if (!!this.ddtData.carrierLicensePlate.value) {
      requestBody.CarrierLicensePlate = this.ddtData.carrierLicensePlate.value;
    }
    else {
      return;
    }

    if (!!this.exceptionRoutingCode) {
      requestBody.exceptionRoutingCode = this.exceptionRoutingCode;
    }

    if (this.vehicleList.length) {
      requestBody.VehicleIds = this.vehicleList.map((vehicle) => {
        return vehicle.id.toString()
      })
    }
    else {
      requestBody.VehicleIds = [];
    }

    if (this.existingDDt) {
      this.ddtEdit(this.ddtId, requestBody);
    } else {
      this.ddtCreate(requestBody);
    }
  }

  async ddtCreate(body) {
    this.loading = true;
    try {
      this.fetchResult = await this.ddtService.submitDdt(body).toPromise();
      this.mapDdtData();
      setTimeout(() => {
        this.triggerPrint();
      }, 300);
      this.edit = false;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  async ddtEdit(id, body) {
    this.loading = true;
    try {
      await this.ddtService.updateDdt(id, body).toPromise();
      this.edit = false;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  cancelEdit() {
    this.mapDdtData();
    this.edit = false;
  }

  mapDdtData() {
    const data = this.fetchResult;
    this.ddtService.searchCustomer(data.customer).subscribe((results) =>
      this.applySearchResult(results[0], this.ddtData.customer)
    );
    this.ddtService.searchDestination(data.destination).subscribe((results) => {
      const foundResult: IDdtDestination = results.find((result) => result.value === data.destination);
      if (!!foundResult) {
        this.applySearchResult(foundResult, this.ddtData.destination)
      }
    }
    );
    this.ddtService.searchCarrier(data.carrier).subscribe((results) => {
      const foundResult: IDdtCarrier = results.find((result) => result.value === data.carrier);
      if (!!foundResult) {
        this.applySearchResult(foundResult, this.ddtData.carrier)
      }
    });
    this.ddtService.searchCarrierLicensePlates(data.carrierLicensePlate).subscribe((results) =>
      this.applySearchResult(results[0], this.ddtData.carrierLicensePlate)
    );
    this.shippingNumber = data.shippingNumber;
    this.sender = data.sender;
    this.documentNr = parseInt(data.number);
    this.ddtDate = new Date(data.createdAt);

    this.vehicleList = data.vehicles.map((vehicle) => {
      const modelParts = [];
      if (vehicle.manufacturer) modelParts.push(vehicle.manufacturer);
      if (vehicle.model) modelParts.push(vehicle.model);
      return {
        customer: vehicle.customer,
        address: vehicle.destinationAddress,
        id: vehicle.id,
        value: this.getVehicleLabel(vehicle),
        model: modelParts.length ? modelParts.join(' ') : ''
      }
    });
    this.edit = false;
  }

  parseLineBreaks(val: string): string {
    const regex = /\n/ig;
    return val.replace(regex, '<br>');
  }

  passToEdit() {
    this.edit = true;
  }

  triggerPrint() {
    const router = this.router;
    setTimeout(function () {
      window.print();
    }, 500);
  }

  getVehicleLabel(vehicle: any): string {
    const parts = [];
    if (vehicle.vin) parts.push(vehicle.vin);
    if (vehicle.licensePlate) parts.push(vehicle.licensePlate);
    return parts.join(' - ');
  }

  readonly RolesGuardHelper = RolesGuardHelper;
}
