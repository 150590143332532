import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatAccordion} from "@angular/material/expansion";
import {
  IMapSectorInteractionStatus,
  IMapSidebarVehicleDetails,
} from "../shared/interfaces/map.type";
import {IServiceAreaMappingService, SERVICE_AREA_MAPPING_SERVICE} from "../shared/services/service-area-mapping.service.interface";
import {Subject, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {CanvasMapComponent} from "../canvas-map/canvas-map.component";
import MapSector from "../classes/sector";
import {ICanvasMapInteractionTarget, ILaneDetails, ISectorGroup} from "../types/service-area-mapping";
import {mapLaneAvailability} from "../enums/canvas-map";
import {IVehicle} from "../../vehicles/shared/interfaces/vehicles.type";
import {MatList} from "@angular/material/list";
import {
  IVehicleDetailsService,
  VEHICLE_DETAILS_SERVICE
} from "../../vehicles/services/vehicle-details-service.service.interface";
import {EVehicleStatus} from "../../vehicles/shared/enums/vehicles";

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapViewComponent implements OnInit, OnDestroy {
  @ViewChild('map') map: CanvasMapComponent;
  @ViewChild('sidenavBody') sidenavBody: ElementRef;
  @ViewChild('sectionList') sectionList: MatAccordion;
  @ViewChild('searchResultList') searchResultList: ElementRef;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.searchResultList.nativeElement.contains(event.target)) {
      this.vinSearchResults = [];
    }
  }

  public sectors: MapSector[] = [];
  public sectorGroups: ISectorGroup[] = [];
  public lanesAvailability: ILaneDetails[] = [];
  public lanesAnomalies: any[] = [];
  public selectedSector: string = null;
  public selectedLane: number = null;
  public vehicles: IMapSidebarVehicleDetails[] = [];
  public vehicleDetails: IMapSidebarVehicleDetails;
  public interactionStatus: IMapSectorInteractionStatus = {
    sector: null,
    lane: 0
  };
  public searchTerm: string;
  public searchTermUpdate = new Subject<string>();
  public lanes: any[] = []
  public vinSearchResults: IVehicle[] = [];
  public laneVehicles: IMapSidebarVehicleDetails[] = [];
  private subscriptions: Subscription[] = [];

  get visibleSectors(): string[] {
    const selectedSector = this.sectors.find((sector) => sector.id === this.selectedSector);
    return this.sectors
      .filter((sector) => {
        return (!selectedSector || !selectedSector.groupId || sector.groupId !== selectedSector.groupId) &&
          (
            sector.parentId === this.selectedSector ||
            (
              sector.parentId === null &&
              sector.id !== this.selectedSector &&
              !this.sectors.find((innerSector) => innerSector.id === this.selectedSector && innerSector.parentId === sector.id)
            ) ||
            !!this.sectors.find((innerSector) => innerSector.id === this.selectedSector && innerSector.parentId === sector.parentId && innerSector.id != sector.id))
      })
      .map((sector) => sector.id)
  }

  get mixedSidebarSectors() {
    let results = (this.sidebarSectors as any[]).concat(this.sidebarGroups);
    results.forEach((sector, index) => {
      results[index] = Object.assign(sector, {
        type:  sector instanceof MapSector ? 'sector' : 'group'
      });
    });

    results.sort((a, b) => a.order > b.order ? 1 : -1);

    const foundFirstOutboundSectorIndex = results.findIndex((sector) => sector.isOutboundSector);
    results.splice(foundFirstOutboundSectorIndex, 0 , {type: 'separator'});

    return results;
  }

  get mixedSectorsAvailability() {
    const sectors = this.mixedSidebarSectors;
    let vCount = 0;
    let vCap = 0;
    sectors.forEach((s) => {
        let sAvailability = {
          filled: 0,
          total: 0
        }
        if (!s.isOutboundSector) switch (s.type) {
          case 'sector':
            sAvailability = this.getSectorLaneAvailability(s.id);
            break;
          case 'group':
            sAvailability = this.getGroupAvailability(s.id);
            break;
        }
        vCount += sAvailability?.filled || 0
        vCap   += sAvailability?.total || 0
    });
    return {
      filled: vCount,
      total: vCap
    };
  }

  get sidebarSectors(): MapSector[] {
    return this.sectors
      .filter((sector) => {
        return sector.parentId === this.selectedSector && !sector.groupId && !sector.parentId && !!sector.laneCount
      })
  }

  get sidebarGroups(): ISectorGroup[] {
    const groups = [];
    if (!this.selectedSector) this.sectorGroups.forEach((group) => {
      const foundSectors = this.sectors.filter((sector) => sector.groupId === group.id);
      const foundOutboundSector = foundSectors.find((sector) => sector.isOutboundSector);
      if (!!foundOutboundSector) group.isOutboundSector = true;
      if (!!foundSectors.length) groups.push(group);
    })
    return groups.sort((a, b) => a.label > b.label ? 1 : -1);
  }

  get selectedSectorDetails(): MapSector {
    return this.sectors.find((sector) => sector.id === this.selectedSector);
  }

  get selectedLaneDetails(): any {
    return this.lanes.find((lane) => lane.label === this.selectedLane);
  }

  get selectedSectorAvailability() {
    const sInstance = this.sectors.find((innerSector) => innerSector.id  === this.selectedSector);
    if (!sInstance) return {filled: 0, total: 0};
    if (!!sInstance.groupId) {
      return this.getGroupAvailability(sInstance.groupId) || {filled: 0, total: 0};
    }
    else {
      return this.getSectorLaneAvailability(this.selectedSector) || {filled: 0, total: 0};
    }
  }

  constructor(
    @Inject(SERVICE_AREA_MAPPING_SERVICE) private serviceAreaMappingService: IServiceAreaMappingService,
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService,
  ) {
    this.searchTermUpdate.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(value => {
        if (!value) this.vinSearchResults = [];
        else this.vService
          .getVehicles({
            text: value,
            pageNumber: 0
          })
          .subscribe((result) => {
            if (!result) return;
            this.vinSearchResults = result.rows;
          });
      });
  }

  public ngOnInit(): void {
    this.fetchSectorGroups();
    this.fetchSectors();
    setTimeout(() => this.map.drawMap(), 300);
  }

  public getGroupAvailability(id) {
    const sInstances = this.sectors.filter((innerSector) => innerSector.groupId  === id );
    let vCount = 0;
    let vCap = 0;
    sInstances.forEach((innerSector) => {
      vCount += innerSector.vehicleCount;
      vCap += innerSector.slotCount;
    })
    if (!vCount && !vCap) return null;
    return {filled: vCount, total: vCap};
  }

  public getSectorLaneAvailability(id) {
    const sInstance = this.sectors.find((innerSector) => innerSector.id  === id);
    let vCount = sInstance.vehicleCount;
    let vCap = sInstance.slotCount;
    if (!vCount && !vCap) return null;
    return {filled: vCount, total: vCap};
  }

  fetchVehicleDetails(vin: string) {
    this.subscriptions.push(
      this.vService
        .getVehicles({
          text: vin,
          pageNumber: 0
        })
        .subscribe((result) => {
          if (!result || 0 === result.rows.length) return;
          const vehicle = result.rows[0];
          if (vehicle.sector) this.selectedSector = vehicle.sectorId;
          if (vehicle.lane) this.selectedLane = vehicle.lane;
          this.vinSearchResults = []
          this.fetchLanesStructureAnomaliesAndAvailability();
          this.vehicleDetails = {
            vin: vin,
            licensePlate: vehicle.licensePlate,
            sector: vehicle.sector,
            lane: vehicle.lane,
            isLost: vehicle.status === EVehicleStatus.Lost,
            costumer: vehicle.customer,
            brand: vehicle.brand,
            model: vehicle.model,
            destinationAddress: vehicle.destinationAddress,
            destinationCode: vehicle.destinationCode
          }
        })
    );
  }

  private fetchSectorGroups() {
    this.subscriptions.push(
      this.serviceAreaMappingService.getSectorGroups()
        .subscribe(response => {
          this.sectorGroups = response;
        })
    );
  }

  private fetchSectors() {
    this.subscriptions.push(
      this.serviceAreaMappingService.getSectorsWithDetail()
        .subscribe(response => {
          this.sectors = response;
          this.map.initMap();
        })
    );
  }

  public setSelectedEntity(value: ICanvasMapInteractionTarget) {
    this.sidenavBody.nativeElement.scrollTo({
      top: 0
    });

    this.vehicleDetails = null;
    if (!value.sector && !value.lane) {
      this.selectedSector = value.sector;
      this.selectedLane = null;
      this.calcLanes(value.sector);
    }
    if (!value.sector && !!value.lane) {
      this.selectedLane = value.lane;
      this.fetchLaneVehicles();
      return;
    }
    if (!!this.sectors.find((sector) => {
      return sector.id === value.sector &&
        (
          sector.laneCount ||
          this.sectorHasChildSectors(sector.id)
        )
    })) {
      this.selectedLane = null;
      const newSelectedSector = this.sectors.find((sector) => sector.id === value.sector);
      const shouldReloadLanes = (this.selectedSector === value.sector) || (!this.selectedSectorDetails || !newSelectedSector) || (!this.selectedSectorDetails.groupId || !newSelectedSector.groupId) || !!this.selectedSectorDetails && this.selectedSectorDetails.groupId !== newSelectedSector?.groupId;
      this.selectedSector = value.sector;
      if (shouldReloadLanes) this.fetchLanesStructureAnomaliesAndAvailability();
      this.calcLanes(value.sector);
    }
  }

  public setSelectedGroup(groupId: string) {
    const foundSector = this.sectors.find((sector) => sector.groupId === groupId);
    if (!!foundSector) this.setSelectedEntity({sector: foundSector.id, lane: null});
  }

  public calcLanes(val: string) {
    if (!val) {
      this.lanes = [];
      return;
    }
    const sector = this.selectedSectorDetails;
    if (!sector) return;
    let lanes = []
    if (sector.groupId) {
      const sectors = this.sectors.filter((innerSector) => innerSector.groupId === sector.groupId);
      sectors.forEach((sector) => lanes = lanes.concat(this.calcSectorLanes(sector)));
      this.lanes = lanes.sort((a, b) => a.label > b.label ? 1 : -1);
    } else {
      this.lanes = this.calcSectorLanes(sector);
    }
  }

  private calcSectorLanes(sector: MapSector): any[] {
    const lanes = [];

    let laneNumber = sector.numerationStart || (sector.numerationType === 'Even' ? 2 : 1);
    for (let i = 0; i < sector.laneCount; i++) {
      lanes.push(
        {
          label: laneNumber,
          comment: '',
          availability: 'pending',
          sectorId: sector.id,
          length: sector.laneLength
        }
      )
      sector.numerationType === 'Both' ? laneNumber++ : laneNumber += 2;
    }
    return lanes;
  }

  public sectorHasChildSectors(id: string): boolean {
    return !!this.sectors.find((sector) => sector.id !== id && sector.parentId === id)
  }

  private fetchLanesAvailability() {
    const sector = this.selectedSectorDetails;
    if (!sector) return;
    let sectors: MapSector[] = [sector];
    let count = 0;
    let availability = [];
    if (sector.groupId) {
      sectors = this.sectors.filter((innerSector) => innerSector.groupId === sector.groupId);
    }
    sectors.forEach((sector) => {
      this.subscriptions.push(
        this.serviceAreaMappingService.getSectorLanes(sector.id)
          .subscribe((response) => {
            availability = availability.concat(response);
            count++;
            if (count === sectors.length) {
              this.lanesAvailability = this.addLengthToLanesAvailability(availability);
              this.updateLanesAvailability(this.lanesAvailability);
            }
          })
      );
    });
  }

  private addLengthToLanesAvailability(availabilities) {
    const result = [];
    for (let availability of availabilities) {
      result.push({
        ...availability,
        length: this.lanes.find((lane) => lane.label === availability.laneNumber)?.length
      });
    }

    return result;
  }

  private fetchLanesStructureAnomaliesAndAvailability() {
    const sector = this.selectedSectorDetails;
    if (!sector) return;
    let sectors: MapSector[] = [sector];
    let count = 0;
    let anomalies = [];
    if (sector.groupId) {
      sectors = this.sectors.filter((innerSector) => innerSector.groupId === sector.groupId);
    }
    sectors.forEach((sector) => {
      this.subscriptions.push(
        this.serviceAreaMappingService.getSectorAnomalies(sector.id)
          .subscribe((response) => {
            anomalies.push({sector: sector.id, exceptions: response});
            count++;
            if (count === sectors.length) {
              this.lanesAnomalies = anomalies;
              this.updateLanesAnomalies(anomalies);
              this.fetchLanesAvailability();
            }
          })
      );
    });
  }

  private updateLanesAnomalies(groupsAnomalies: any[]) {
    let anomalies = [];
    groupsAnomalies.forEach((groupAnomaly) => {
      anomalies = anomalies.concat(groupAnomaly.exceptions)
    });

    anomalies.forEach((anomaly) => {
      if (anomaly.anomalyType === 'SkipLane') {
        let foundIndex = -1;
        let highest = 0;
        this.lanes.forEach((lane, index) => {
          if (lane.sectorId === anomaly.sectorId && lane.label > highest) {
            highest = lane.label;
            foundIndex = index;
          }
        })

        if (foundIndex > -1) this.lanes.splice(foundIndex, 1);
      } else if (anomaly.anomalyType === 'SkipLaneAndSkipNumber') {
        let foundIndex = this.lanes.findIndex((lane, index) => lane.label == anomaly.laneNumber);
        if (foundIndex > -1) this.lanes.splice(foundIndex, 1);
      } else if (anomaly.anomalyType === 'DifferentLength') {
        let foundIndex = this.lanes.findIndex((lane, index) => lane.label == anomaly.laneNumber);
        if (foundIndex > -1) {
          this.lanes[foundIndex].length = anomaly.laneLength;
        }
      }
    })
  }

  private fetchLaneVehicles() {
    this.laneVehicles = [];
    const lane = this.lanes.find((lane) => lane.label === this.selectedLane);
    if (!lane) return;
    this.subscriptions.push(
      this.serviceAreaMappingService.getLaneVehicles(lane.sectorId, this.selectedLane)
        .subscribe((response) => {
          this.laneVehicles = response;
        })
    );
  }

  public updateLanesAvailability(availabilities: ILaneDetails[]) {
    this.lanes.forEach((laneObject, index) => {
      const laneOccupation = availabilities.find((availability) => availability.laneNumber === laneObject.label);
      if (laneOccupation?.note) this.lanes[index].comment = laneOccupation.note;
      this.lanes[index].availability = mapLaneAvailability(laneOccupation);
      this.lanes[index].vehicleCount = laneOccupation?.vehicleCount || 0;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public setInteractionStatus(sector = null, lane = null, silent=  false) {
    if (!silent && !!lane) {
      const laneObject = document.getElementById('lane-' + lane);
      const laneList: HTMLElement = document.querySelector('.lane-list');
      if (!!laneObject && !!laneList) this.sidenavBody.nativeElement.querySelector('.scrollable').scrollTo({
        top: (laneObject.offsetTop - laneList.offsetTop) - this.sidenavBody.nativeElement.querySelector('.scrollable').offsetHeight / 2,
      })
    }
    if (!silent && !!sector) {
      const sectorObj = this.sectors.find((innerSector) => innerSector.id === sector)
      if (sectorObj.groupId) sector = sectorObj.groupId;
      const sectorObject = document.getElementById('sector-' + sector);
      const sectorList: HTMLElement = document.querySelector('.sector-list');
      if (!!sectorObject && !!sectorList) this.sidenavBody.nativeElement.querySelector('.scrollable').scrollTo({
        top: (sectorObject.offsetTop - sectorList.offsetTop) - this.sidenavBody.nativeElement.querySelector('.scrollable').offsetHeight / 2
      })
    }
    this.interactionStatus = {
      sector,
      lane
    }
  }

  public getSector(id: string) {
    return this.sectors.find((sector) => sector.id == id);
  }

  public getListElementClasses(sector = null, lane = null, options = null): string {
    const result = ['hoverable', 'mat-list-item', 'mat-focus-indicator', 'ng-star-inserted'];
    if (options?.separator === true) result.push('sector-separator')
    if (
      (null !== sector && sector === this.interactionStatus.sector) ||
      (null !== lane && lane === this.interactionStatus.lane)
    ) {
      result.push('hovered');
    }
    if (lane) {
      result.push('lane-list-item');
      result.push(options.availability)
    }
    if (sector) {
      result.push('sector-list-item');
      let vCount = 0;
      let vCap = 0;
      if (options?.group) {
        const sInstances = this.sectors.filter((innerSector) => innerSector.groupId  === sector);
        sInstances.forEach((innerSector) => {
          vCount += innerSector.vehicleCount;
          vCap += innerSector.laneCount * innerSector.laneLength;
        })
      }
      else {
        const sInstance = this.sectors.find((innerSector) => innerSector.id  === sector);
        vCount = sInstance.vehicleCount;
        vCap = sInstance.laneCount * sInstance.laneLength;
      }
      let availability = '';
      if (vCount === 0) {
        availability = 'empty';
      }
      else if(vCount === vCap)
      {
        availability = 'full';
      }
      else {
        availability = 'available';
      }
      result.push(availability);
    }
    return result.join(' ');
  }

  backFromVehicleDetails() {
    const sector = this.sectors.find((sector) => sector.id === this.selectedSector);

    if (!sector) this.setSelectedEntity({sector: null, lane: null});
    else this.setSelectedEntity({sector: null, lane: this.selectedLane});

    this.vehicleDetails = null;
  }

  getVehicleLabel(vehicle: { vin?: string, licensePlate: string }): string {
    const parts = [];
    if (vehicle.vin) parts.push(vehicle.vin);
    if (vehicle.licensePlate) parts.push(vehicle.licensePlate);
    return parts.join(' - ');
  }
}
