import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'nes-portlet-form',
  templateUrl: './nes-portlet-form.component.html'
})
export class NesPortletFormComponent implements OnInit, OnDestroy {

  @Input() offCanvas = false;

  // --

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  // --

}
