import {Component, OnInit, Inject, ViewChild, OnDestroy, ElementRef, AfterViewInit} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from '../services/vehicle-details-service.service.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {fromEvent, Subscription} from 'rxjs';
import {ISearchResult, IVehicle, IVehicleFilters} from '../shared/interfaces/vehicles.type';
import {EVehicleStatus, getVehicleStatusLabel} from "../shared/enums/vehicles";
import {FormControl, Validators} from "@angular/forms";
import {
  ILocalDataStorageService,
  LOCAL_DATA_STORAGE_SERVICE
} from "../../../shared/services/local-data-storage.service.interface";
import {NesPortletFilterBarComponent} from "@nes/nes-common-components";
import {debounceTime, distinctUntilChanged, filter, tap} from "rxjs/operators";



interface IStatusFilterChoice {
  value: EVehicleStatus;
  label: string;
  code: number;
}

@Component({
  selector: 'vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.scss']
})
export class VehiclesListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatPaginator, {static: false}) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) matSort: MatSort;
  @ViewChild(NesPortletFilterBarComponent, {static: false}) filterBar: NesPortletFilterBarComponent;
  @ViewChild('destinationCodeFilter') destinationCodeFilter: ElementRef;

  // --

  public loading = false;
  public requestChainRunning = false;
  public firstLoad = true;
  public shouldUpdate = false;
  public recoverFilters = false;
  public tableFilter: any;
  public vehicleFilter: IVehicleFilters;
  private text = '';
  public destinationCode = '';
  private filtersKey = 'vehicles-list-filters';
  vehicles: IVehicle[];
  vehicleBrandsAndModels: any;
  private filterUpdatedTimes = 0;
  totalVehicles: number = 0;
  private subscriptions: Subscription[] = [];
  statusSelect = new FormControl(null)
  customerFormControl: FormControl = new FormControl(null);
  contractFormControl: FormControl = new FormControl(null);
  statusFilterChoices: IStatusFilterChoice[] = [
    {
      value: EVehicleStatus.Registered,
      label: getVehicleStatusLabel(EVehicleStatus.Registered),
      code: 1
    },
    {
      value: EVehicleStatus.WillArrive,
      label: getVehicleStatusLabel(EVehicleStatus.WillArrive),
      code: 2
    },
    {
      value: EVehicleStatus.Arrived,
      label: getVehicleStatusLabel(EVehicleStatus.Arrived),
      code: 3
    },
    {
      value: EVehicleStatus.Parked,
      label: getVehicleStatusLabel(EVehicleStatus.Parked),
      code: 4
    },
    {
      value: EVehicleStatus.Lost,
      label: getVehicleStatusLabel(EVehicleStatus.Lost),
      code: 5
    },
    {
      value: EVehicleStatus.Left,
      label: getVehicleStatusLabel(EVehicleStatus.Left),
      code: 6
    }
  ];
  public customerOptions;
  public contractOptions;

  // --

  constructor(
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService,
    @Inject(LOCAL_DATA_STORAGE_SERVICE) private localStorageService: ILocalDataStorageService)
    {}

  ngOnDestroy(): void {

    this.subscriptions.forEach(subscription => {

      subscription.unsubscribe();
    })
  }

  ngOnInit(): void {
    this.recoverFilters = this.localStorageService.shouldUseLocalData(this.filtersKey);
    this.localStorageService.setUseLocalData(this.filtersKey, false);
    this.initPage();
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.destinationCodeFilter.nativeElement,'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap((text) => {
          this.applyDestinationCodeFilter(this.destinationCodeFilter.nativeElement.value)
        })
      )
      .subscribe();
    this.initCustomerControl();
    this.initContractControl();
  }

  // --

  get defaultFilter(): IVehicleFilters {
    return {
      text: '',
      customerValue: null,
      contractValue: null,
      pageNumber: 0,
      pageSize: 10
    };
  }

  get defaultTableFilter(): any {
    return {
      vehicleBrand: null,
      vehicleModel: null,
    };
  }

  get vehicleDetails(): string[] {
    return ['label', 'contract', 'destination-address', 'manufacturer-and-model', 'color', 'sector-and-lane', 'status', 'date'];
  }

  // --

  private initCustomerControl(): void {
    this.customerFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        if (!res) {
          this.vehicleFilter.customerValue = null;
          this.saveFilters();
          this.fetchVehicleList();
        } else {
          this.vehicleFilter.customerValue = this.filterAgainstResults(this.customerOptions, res, this.vehicleFilter.customerValue);
        }
        this.updateCustomerAutocompleteResults(res);
      });

  }

  private initContractControl(): void {
    this.contractFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        if (!res) {
          this.vehicleFilter.contractValue = null;
          this.saveFilters();
          this.fetchVehicleList();
        } else {
          this.vehicleFilter.contractValue = this.filterAgainstResults(this.contractOptions, res, this.vehicleFilter.contractValue);
        }
        this.updateContractAutocompleteResults(res);
      });

  }

  public updateCustomerAutocompleteResults(term: string): void {
    if (!term) {
      this.vehicleFilter.customerValue = null;
      return;
    }
    this.vService.searchCustomer(term).subscribe((options) => this.customerOptions = options.map((option) => {
      return {
        id: option.id,
        name: option.name.split("\n")[0].trim(),
      } as ISearchResult;
    }));

  }

  public updateContractAutocompleteResults(term: string): void {
    if (!term) {
      this.vehicleFilter.contractValue = null;
      return;
    }
    this.vService.searchContract(term).subscribe((options) => this.contractOptions = options.map((option) => {
      return {
        id: option.id,
        name: option.name.split("\n")[0].trim(),
      } as ISearchResult;
    }));
  }

  private filterAgainstResults(options: any, term: string, value: string): string {
    let result = null;
    if (!options) return value;
    options.forEach(
      (option) => {
        if (option.name === term) result = value;
      })
    return result;
  }

  public handleCustomerAutocomplete(event): void {
    const foundValue = this.customerOptions.find((value) => value.name === event.option.value);
    this.customerFormControl.setValue(event.option.value);
    if (foundValue) {
      this.vehicleFilter.customerValue = foundValue.id;
      this.saveFilters();
      this.fetchVehicleList();
    }
  }

  public handleContractAutocomplete(event): void {
    const foundValue = this.contractOptions.find((value) => value.name === event.option.value);
    this.contractFormControl.setValue(event.option.value);
    if (foundValue) {
      this.vehicleFilter.contractValue = foundValue.id;
      this.saveFilters();
      this.fetchVehicleList();
    }
  }

  setVehicleStatusFilter(event: any): void {
    Object.assign(this.vehicleFilter, {
      text: this.vehicleFilter.text,
      vehicleStatus: event,
      pageNumber: 0,
      pageSize: this.vehicleFilter.pageSize
    });
    this.saveFilters();
    this.fetchVehicleList();
  }

  clearVehicleStatusSelect(): void {
    this.statusSelect.setValue(null);
    if (!!this.vehicleFilter.vehicleStatus) this.vehicleFilter.vehicleStatus = null;
    this.saveFilters();
    this.fetchVehicleList();
  }

  private checkQueuedRequests(): boolean {
    if (this.requestChainRunning) {
      this.shouldUpdate = true;
    }

    return this.requestChainRunning;
  }

  public applyFilter(event: string): void {
    this.filterUpdatedTimes++;
    if ((this.recoverFilters && this.filterUpdatedTimes == 1) || this.text === event) return;
    this.text = event;
    Object.assign(this.vehicleFilter, {
      text: event,
      pageNumber: 0,
      pageSize: this.vehicleFilter.pageSize
    });
    this.saveFilters();
    this.fetchVehicleList();
  }

  public applyDestinationCodeFilter(value: string): void {
    this.filterUpdatedTimes++;
    if ((this.recoverFilters && this.filterUpdatedTimes == 1) || this.destinationCode === value) return;
    this.destinationCode = value;
    Object.assign(this.vehicleFilter, {
      destinationCode: value,
      pageNumber: 0,
      pageSize: this.vehicleFilter.pageSize
    });
    this.saveFilters();
    this.fetchVehicleList();
  }

  private initPage(): void {
    this.tableFilter = Object.assign({}, this.defaultTableFilter);
    this.vehicleFilter = Object.assign({}, this.defaultFilter);
    if (this.recoverFilters) this.loadFilters();
    else this.saveFilters();
    this.fetchVehicleList();
  }

  public getLicencePlateAndVinCombination(vehicle: IVehicle): string {
    const parts = [];
    if (vehicle.vin) parts.push(vehicle.vin);
    if (vehicle.licensePlate) parts.push(vehicle.licensePlate);
    return parts.join(' - ');
  }

  public getSectorAndLaneCombination(vehicle: IVehicle): string {
    const parts = [];
    if (vehicle.sector) parts.push(vehicle.sector);
    if (vehicle.lane) parts.push(vehicle.lane);
    return parts.join(' - ');
  }

  public getManufacturerAndModelCombination(vehicle: IVehicle): string {
    const parts = [];
    if (vehicle.brand) parts.push(vehicle.brand);
    if (vehicle.model) parts.push(vehicle.model);
    return parts.join(' ');
  }

  private fetchVehicleList(): void {
    if (this.checkQueuedRequests()) return;
    this.loading = true;
    this.requestChainRunning = true;
    const term = this.vehicleFilter.text;
    const destinationCode = this.vehicleFilter.destinationCode;
    let interruptLoading = true;
    this.subscriptions.push(
      this.vService
      .getVehicles(Object.assign({}, this.vehicleFilter))
      .subscribe(res => {
        if (!!this.shouldUpdate || term !== this.vehicleFilter.text || destinationCode !== this.vehicleFilter.destinationCode) {
          this.shouldUpdate = false;
          this.requestChainRunning = false;
          this.fetchVehicleList();
          interruptLoading = false;
          return;
        }
        else {
          this.vehicles = res.rows;
          this.totalVehicles = res.totalRows;
          this.shouldUpdate = false;
        }
        this.firstLoad = false;
      },
      err => {
        console.log(err)
      },
      () => {
        if (interruptLoading) this.loading = false;
        this.requestChainRunning = false;
      }
    ));
  }

  public downloadCSV(): void {
    this.firstLoad = true;
    const filters = Object.assign({}, this.vehicleFilter);
    delete filters.pageNumber;
    delete filters.pageSize;
    if (!filters.text) delete filters.text;
    if (!filters.vehicleStatus) delete filters.vehicleStatus;

    this.subscriptions.push(
      this.vService
      .getVehiclesCSV(filters)
      .subscribe(res => {
        this.firstLoad = false;
        this.stringToCsvDownload(res);
      },
      err => {
        console.log(err)
      },
      () => {
        this.firstLoad = false;
      }
    ));
  }

  public stringToCsvDownload(data: string): void {
    const blob = new Blob([data], { type: 'application/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const date = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}-${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
    link.setAttribute('href', url);
    link.setAttribute('download', `tencara-vehicles-${dateString}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public getVehicleStatusLabel(status: EVehicleStatus): string {
    return getVehicleStatusLabel(status);
  }

  public onPaginationChange(e): void {
    let page = this.vehicleFilter.pageSize !== e.pageSize ? 0 : e.pageIndex;

    Object.assign(this.vehicleFilter, {
      text: this.vehicleFilter.text,
      pageNumber: page,
      pageSize: e.pageSize
    });
    this.saveFilters();
    this.fetchVehicleList();
  }

  private saveFilters(): void {
    this.localStorageService.save(this.filtersKey, this.vehicleFilter);
  }

  private loadFilters(): void {
    this.vehicleFilter = this.localStorageService.get(this.filtersKey) || this.defaultFilter;
    if (this.vehicleFilter.hasOwnProperty('vehicleStatus') && this.vehicleFilter.vehicleStatus !== null) {
      this.statusSelect.setValue(this.statusFilterChoices.find(choice => choice.value === this.vehicleFilter.vehicleStatus).code);
    }
    if (this.vehicleFilter.hasOwnProperty('destinationCode') && this.vehicleFilter.destinationCode !== null) {
      this.destinationCode = this.vehicleFilter.destinationCode;
    }
    if (this.vehicleFilter.hasOwnProperty('customerValue') && this.vehicleFilter.customerValue !== null) {
      this.vService.searchCustomer("").subscribe((options) => this.customerFormControl.setValue(
        options.find((option) => option.id === this.vehicleFilter.customerValue).name || ""
      ));
    }
    if (this.vehicleFilter.hasOwnProperty('contractValue') && this.vehicleFilter.contractValue !== null) {
      this.vService.searchContract("").subscribe((options) => this.contractFormControl.setValue(
        options.find((option) => option.id === this.vehicleFilter.contractValue).name || ""
      ));
    }
    if (this.vehicleFilter.text) {
      this.text = this.vehicleFilter.text;
      setTimeout(() => {
        this.filterBar.searchFilter.nativeElement.value = this.vehicleFilter.text;
      }, 30);
    }
  }

  getFirstRow(value: string) {
    if (!value) return '';
    if (value.includes('\n')) {
      return value.split('\n')[0];
    }

    return value;
  }
}
