<nes-portlet [loading]="loading" [withoutBorder]="true" [class]="'floating-window'" [title]="'Reset Password'">
  <h1>Reset Password</h1>
  <h4
    *ngIf="status == 'success'">
   {{messages.success}}
  </h4>
  <mat-error *ngIf="status == 'emailNotFound'" class="standalone">
    {{messages.emailNotFound}}
  </mat-error>
  <form class="authentication-form" [formGroup]="form" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
    <mat-form-field class="example-full-width"
                    *ngIf="status != 'success'">
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher" autocomplete="email">
      <mat-error *ngIf="form.controls['email'].hasError('email') && !form.controls['email'].hasError('required')">
        Email <strong>non valida</strong>
      </mat-error>
      <mat-error *ngIf="form.controls['email'].hasError('required')">
        Email <strong>richiesta</strong>
      </mat-error>
    </mat-form-field>
    <div class="fake-link"
         *ngIf="status != 'success'"
         [routerLink]="['/auth/login/']">Torna al login</div>
    <div class="form-footer">
      <button
        [disableRipple]="true"
        type="button"
        class="flex_center "
        color="accent"
        mat-button
        (click)="onSubmit()"
        *ngIf="status != 'success'"
      >
        Invia
      </button>

      <button
        [disableRipple]="true"
        type="button"
        class="flex_center "
        color="accent"
        mat-button
        *ngIf="status == 'success'"
        [routerLink]="['/auth/login/']"
      >
        Torna al login
      </button>
    </div>
  </form>
</nes-portlet>
