<nes-portlet [loading]="loading" *ngIf="RolesGuardHelper.canWrite() || vehicleDamages.length > 0">
  <nes-portlet-title [title]="'DANNI'">
  </nes-portlet-title>
  <!--
  <button [disableRipple]="true" type="button" class="flex_center icon-btn push-left _input-complimentary print-damages-button"
    color="accent" mat-button (click)="printDamages()">
    Visualizza modulo danni
  </button>
  -->
  <nes-portlet-table [dataSource]="vehicleDamages" *ngIf="undefined !== vehicleDamages && vehicleDamages.length">
    <mat-table [dataSource]="vehicleDamages">
      <ng-container matColumnDef="positionCode">
        <mat-header-cell *matHeaderCellDef>Codice posizione</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.positionCode }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="damageCode">
        <mat-header-cell *matHeaderCellDef>Codice danno</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.damageCode }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="severityCode">
        <mat-header-cell *matHeaderCellDef>Codice gravità</mat-header-cell>
        <mat-cell *matCellDef="let row">{{ row.severityCode }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="note">
        <mat-header-cell *matHeaderCellDef>Annotazioni</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.note }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="isImported">
        <mat-header-cell *matHeaderCellDef>Preesistente</mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ row.isImported ? 'Sì' : 'No' }}
          <span class="blank_placeholder" *ngIf="!row.to">
            <button [disableRipple]="true" type="button" class="flex_center icon-btn" color="accent"
              [disabled]="!RolesGuardHelper.canWrite()" mat-button (click)="removeDamage(row.id)">
              Rimuovi
            </button>
          </span>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="vehicleDamagesDetails"></mat-header-row>
      <mat-row *matRowDef="let row; columns: vehicleDamagesDetails;"></mat-row>
    </mat-table>
  </nes-portlet-table>
  <nes-portlet-form *ngIf="RolesGuardHelper.canWrite()">
    <form [formGroup]="damageInsertForm" autocomplete="off" (ngSubmit)="saveDamage()">
      <div class="nes-portlet-form-wr">
        <div class="nes-portlet-form-col">
          <div class="_input_group _auto-flex">
            <mat-form-field class="fill-available">
              <mat-label>Codice posizione</mat-label>
              <input matInput placeholder="" formControlName="positionCode"
                (blur)="damageInsertForm.controls['positionCode'].updateValueAndValidity()">
            </mat-form-field>
            <mat-form-field class="fill-available">
              <mat-label>Codice danno</mat-label>
              <input matInput placeholder="" formControlName="damageCode"
                (blur)="damageInsertForm.controls['damageCode'].updateValueAndValidity()">
            </mat-form-field>
            <mat-form-field class="fill-available">
              <mat-label>Codice gravità</mat-label>
              <input matInput placeholder="" formControlName="severityCode"
                (blur)="damageInsertForm.controls['severityCode'].updateValueAndValidity()">
            </mat-form-field>
            <mat-form-field class="fill-available">
              <mat-label>Annotazioni</mat-label>
              <input matInput placeholder="" formControlName="note"
                (blur)="damageInsertForm.controls['note'].updateValueAndValidity()">
            </mat-form-field>
            <button [disableRipple]="true" type="button" class="flex_center icon-btn push-left _input-complimentary "
              color="accent" mat-button (click)="saveDamage()">
              Aggiungi
            </button>
          </div>
        </div>
      </div>
    </form>
  </nes-portlet-form>
</nes-portlet>