import { Guid } from "guid-typescript";
import { InjectionToken } from "@angular/core";
import { Observable } from 'rxjs';
import { ADossier, IDossierBillingSetting, IDossierDTO, IDossierFilters, DossierFilteredResults, IDossiersPaginatedList, IDossierActivity } from '../shared/interfaces/dossier.type';
import {IDdtReportResponseDTO, IVehicleDTO} from "../../vehicles/shared/interfaces/vehicles.type";

export interface IDossierType{
    slug: string;
    name: string;
    key: string;
}

export const DOSSIERS_SERVICE: InjectionToken<IDossiersService> = new InjectionToken<IDossiersService>("Dossiers Service Implementation");

export interface IDossiersService {
    getDossiers(filters: IDossierFilters): Observable<IDossiersPaginatedList<IDossierDTO>>;
    getDossierActivities(): Observable<IDossierActivity[]>;
    getDossierTypes(): Observable<IDossierType[]>;
    getDossier(id: string): Observable<ADossier>;
    requestDossierDDT(id: string): Observable<IDdtReportResponseDTO>;
    // getDDTList(id: string): Observable<IDdtReportResponseDTO>;
    getDossierVehicles(id: string): Observable<IVehicleDTO[]>;
    createDossierBillingSetting(setting: IDossierBillingSetting): Observable<IDossierBillingSetting>;
    getDossierBillingSettings(dossierId: string): Observable<IDossierBillingSetting[]>;
    editDossierSettings(dossierId: string, settings: IDossierBillingSetting[]): Observable<boolean>;
    removeDossierBillingSettings(settings: Guid[]): Observable<boolean>;
    saveDossier(dossier: ADossier, vehicles: Guid[]):  Observable<ADossier>;
    editDossier(dossier: ADossier, vehicles: Guid[]):  Observable<ADossier>;
}

