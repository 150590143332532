import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DossierTypeSelectionDialogComponent } from './dialogs/dossier-type-selection-dialog/dossier-type-selection-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DossierDialogsService {

  constructor( private dialog: MatDialog,
               private _router: Router) { }

  public openDossierTypeSelectionDialog(): void{

    this.dialog
        .open(DossierTypeSelectionDialogComponent, {
          panelClass: 'dossier-type-selection-model-dialog',
          autoFocus: false
        })
        .afterClosed()
        .subscribe((result) => {
          if (!!result) {
            this._router.navigate(["/pratiche", "nuova"], { queryParams: { type: result.type } })
          }
        })
  }
}
