<link rel="preload" as="font" href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap" rel="stylesheet" rel="stylesheet">
<canvas
  #canvas
  (mousedown)="onMouseDown($event)"
  (mouseup)="onMouseUp($event)"
  (mouseleave)="panning = false"
  (mousemove)="onMouseMove($event)"
  (wheel)="onWheel($event)"
>
</canvas>
