import {Observable} from 'rxjs';
import {InjectionToken} from '@angular/core';
import {IMapSidebarVehicleDetails} from "../interfaces/map.type";
import MapSector from "../../classes/sector";
import {ILaneDetails, ISectorGroup} from "../../types/service-area-mapping";
import {IVehicle, IVehiclePaginatedList} from "../../../vehicles/shared/interfaces/vehicles.type";
import {SectorType} from "../enums/sector-type";

export const SERVICE_AREA_MAPPING_SERVICE: InjectionToken<IServiceAreaMappingService> = new InjectionToken<IServiceAreaMappingService>('Service Area Mapping Service implementation');

export interface IServiceAreaMappingService {
  getSectorsWithDetail(type?: SectorType): Observable<MapSector[]>;

  getLaneVehicles(sectorId: string, laneId: number): Observable<IMapSidebarVehicleDetails[]>;

  searchForVehicleByVin(vin: string): Observable<IVehiclePaginatedList<IVehicle>>;

  getVehicleDetails(vin: string): Observable<any>;

  getSectorLanes(sectorId: string): Observable<ILaneDetails[]>;

  getSectorAnomalies(sectorId: string): Observable<any[]>;

  getSectorGroups(): Observable<ISectorGroup[]>;
}
