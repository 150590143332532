<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'DDT']" [title]="'Lista DDT'" [loading]="firstLoad" class="dossier-ddt-list">
  <nes-portlet-filter-bar (search)="applyFilters($event)" class="hide-search">
    <div class="nes-portlet-filter-bar-col">
      <button [disableRipple]="true" mat-button color="primary" class="flex_center" [routerLink]="['/ddt/nuovo/']" *ngIf="RolesGuardHelper.canWrite()">Nuovo DDT</button>
    </div>
  </nes-portlet-filter-bar>
  <nes-portlet-table [dataSource]="ddtList">
    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="ddtList">
        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef>Numero</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <a [routerLink]="['/ddt/dettaglio/' + row.id]" [queryParams]="{id: row.id}" class="mat-row-link"></a>
            {{ row.createdAt|date:"MM"}} / {{ row.number }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef>Data inserimento</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.createdAt|date:"dd/MM/yyyy"}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="carrier">
          <mat-header-cell *matHeaderCellDef>Vettore</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.carrier}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicles">
          <mat-header-cell *matHeaderCellDef>Veicoli</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.vehicles.length}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="indicator">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button>
              <nes-icon svgIcon="blue_angle"></nes-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="ddtDetails"></mat-header-row>
        <mat-row class="_selectable" [routerLink]="['/ddt/dettaglio/' + row.id]" *matRowDef="let row; columns: ddtDetails;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [pageIndex]="ddtFilters.page" (page)="updatePagination($event)" [length]="resultsCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </nes-portlet-table>
</nes-portlet>
