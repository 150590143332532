import {IWorkOrdersListFilters} from "./work-orders-list-filters";
import {Observable} from "rxjs";
import {IPaginatedList} from "../../../../shared/interfaces/paginated-list.interface";
import {WorkOrder} from "../classes/work-order";
import {InjectionToken} from "@angular/core";

export const WORK_ORDERS_SERVICE: InjectionToken<IWorkOrdersService> = new InjectionToken<IWorkOrdersService>("Work Orders Service Implementation");

export interface IWorkOrdersService {
  getWorkOrders(filters: IWorkOrdersListFilters): Observable<IPaginatedList<WorkOrder>>;
  createWorkOrder(workOrder: WorkOrder): Observable<WorkOrder>;
  getWorkOrder(id: string): Observable<WorkOrder>;
}
