import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserRoles } from "../authentication/shared/interfaces/user-roles";
import { RolesGuard } from "../authentication/shared/roles.guard";
import { WorkOrdersListComponent } from "./views/work-orders-list/work-orders-list.component";
import { WorkOrdersDetailsComponent } from "./views/work-orders-details/work-orders-details.component";
import { OutgoingVehiclesComponent } from './views/outgoing-vehicles/outgoing-vehicles.component';



const routes: Routes = [
  {
    path: 'veicoli-in-uscita',
    canActivate: [RolesGuard],
    data: { roles: [UserRoles.Read] },
    component: OutgoingVehiclesComponent
  },
  {
    path: '',
    canActivate: [RolesGuard],
    data: { roles: [UserRoles.Read] },
    component: WorkOrdersListComponent
  },
  {
    path: 'nuovo',
    canActivate: [RolesGuard],
    data: { roles: [UserRoles.Write] },
    component: WorkOrdersDetailsComponent
  },
  {
    path: ':id',
    canActivate: [RolesGuard],
    data: { roles: [UserRoles.Read] },
    component: WorkOrdersDetailsComponent
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkOrdersRoutingModule {
}
