import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthErrorStateMatcher} from "../../shared/classes/auth-error-state-matcher";
import {AUTHENTICATION_SERVICE, IAuthenticationService} from "../../shared/services/authentication.service.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {RolesGuardHelper} from "../../../../shared/utils/roles-guard-helper";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });
  public loading = false;
  public matcher = new AuthErrorStateMatcher();
  private autoLoginToken: string = null;

  constructor(
    private router: Router,
    @Inject(AUTHENTICATION_SERVICE) private authService: IAuthenticationService,
    private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
        if (params['auto-login-token']) {
          this.autoLoginToken = params['auto-login-token'];
        }
      });
  }

  ngOnInit(): void {
    if (this.autoLoginToken) {
      this.autoLogin();
    }
  }

  public async onSubmit(): Promise<void> {
    if (!this.form.valid) return;
    this.loading = true;
    try {
      await this.authService.login({
        eMailAddress: this.form.value.email,
        password: this.form.value.password
      });
      if (RolesGuardHelper.canRead()) await this.router.navigate(['/mappa']);
      else if (RolesGuardHelper.canWrite()) await this.router.navigate(['/ordini-di-uscita/nuovo']);
      else if (RolesGuardHelper.canViewBillingData()) await this.router.navigate(['/rendicontazione']);
      else await this.router.navigate(['/blank']);
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  private async autoLogin(): Promise<void> {
    this.loading = true;
    try {
      await this.authService.autoLogin({
        token: this.autoLoginToken
      });
      this.router.navigate(['/auth/change-password']);
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }
}
