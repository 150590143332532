import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ISearchResult } from "../../../vehicles/shared/interfaces/vehicles.type";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from "../../../vehicles/services/vehicle-details-service.service.interface";



@Component({
  selector: 'contracts-list',
  templateUrl: './contracts-list.component.html',
  styleUrls: ['./contracts-list.component.scss']
})
export class ContractsListComponent implements OnInit, OnDestroy {
  public loading = false;
  public firstLoad = true;
  public shouldUpdate = false;
  public tableFilter: any;
  contracts: ISearchResult[];
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService) { }

  ngOnInit(): void {
    this.fetchContractsList()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }


  get tableColumns(): string[] {
    return ['label'];
  }

  private checkQueuedRequests(): boolean {
    if (this.loading) {
      this.shouldUpdate = true;
    }

    return this.loading;
  }

  private getLabel(customer: ISearchResult): string {
    return customer.name || "N/A";
  }

  private fetchContractsList(): void {
    if (this.checkQueuedRequests()) return;
    this.loading = true;
    this.subscriptions.push(
      this.vService
        .searchContract("")
        .subscribe(res => {
          if (this.shouldUpdate) {
            this.shouldUpdate = false;
            this.loading = false;
            this.fetchContractsList();
            return;
          }
          else {
            this.contracts = res;
            this.shouldUpdate = true;
          }
          this.firstLoad = false;
        },
          err => {
            console.log(err)
          },
          () => {
            this.loading = false;
          }
        ));
  }
}
