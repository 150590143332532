<mat-form-field
[ngClass]="{'hasError': !!requiredError}" >
    <input matInput [placeholder]="placeholder"
    [formControl]="nameControl"
    readonly
    (click)="inputAction()"
    class="nes-action-input"
    [ngClass]="inputClass"
    >
    <span matSuffix (click)="inputAction(false)" class="nes-action-input" *ngIf="isEnabled">
      <mat-icon [svgIcon]="icon"></mat-icon>
    </span>
    <mat-error *ngIf="!!isRequired">Campo <b>obbligatorio</b></mat-error>
</mat-form-field>
