import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VEHICLE_DETAILS_SERVICE, IVehicleDetailsService } from '../../services/vehicle-details-service.service.interface';
import { VehicleDetailsGeneralComponent } from '../../vehicle-details/vehicle-details-general/vehicle-details-general.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IVehicleDTO } from '../../shared/interfaces/vehicles.type';

@Component({
  selector: 'app-vehicle-creation-dialog',
  templateUrl: './vehicle-creation-dialog.component.html',
  styleUrls: ['./vehicle-creation-dialog.component.scss']
})
export class VehicleCreationDialogComponent implements OnInit {

  @ViewChild('vehicleCreationForm') form: VehicleDetailsGeneralComponent;
  title: string = '';
  breadcrumbs: string[];

  constructor(
    public dialogRef: MatDialogRef<VehicleCreationDialogComponent>,
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
    ) {
      if(!!data && !!data.title) this.title = data.title;
      if(!!data && !!data.breadcrumbs) this.breadcrumbs = data.breadcrumbs;
    }

  ngOnInit(): void {
  }

  public onSave(): void{
    this.form.saveVehicle();
  }

  public handleSave(newVehicle: IVehicleDTO): void {
    let args = {action: 'create', data: [newVehicle]};
    this.onClose(args);
    const snack = (message) => this.snackBar.open(message, '', {
      panelClass: 'nes-snackbar',
      duration: 4000,
      horizontalPosition: 'right'
    });
    snack('Telaio aggiunto');
  }

  public onSwitchToSelection(): void {
    let args = {action: 'select', data: null};
    this.onClose(args);
  }

  public onClose(args: {action: string, data: IVehicleDTO[] } = null): void {
    this.dialogRef.close(args);
  }

}
