import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import MapSector from "../classes/sector";
import {Polygon, Vertex} from "../classes/geometry";
import {IMapSectorInteractionStatus} from "../shared/interfaces/map.type";
import {
  ICanvasLaneDetails,
  ICanvasMapInteractionTarget,
  ILaneDetails, IRectVertexGroup, ISectorGroup
} from "../types/service-area-mapping";
import {colorManipulation} from "../shared/utils/color-manipulation";
import { EMapColor, EMapLabelTypes, mapLaneAvailability, mapLaneColor } from "../enums/canvas-map";

@Component({
  selector: 'mg-canvas-map',
  templateUrl: './canvas-map.component.html',
  styleUrls: ['./canvas-map.component.scss']
})
export class CanvasMapComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;

  @Input() private sectors: MapSector[] = [];
  @Input() private sectorGroups: ISectorGroup[] = [];
  @Input() private activeLane: number;
  @Input() private visibleSectors: string[] = [];
  get renderebleSectors(): string[] {
    const totalPool = this.sectors.filter((sector) => sector.hasBoundingBox());
    return this.visibleSectors.filter((sector) => !!totalPool.find((sectorObj) => sector === sectorObj.id));
  }
  @Input() set selectedSector(value: string) {
    if (!this.canvas) return;
    if (!value) {
      this.animateZoom(
        1,
        Math.floor((this.canvas.nativeElement.width - this.img.width * (this.ratio / this.zoom)) / 2),
        Math.floor((this.canvas.nativeElement.height - this.img.height * (this.ratio / this.zoom)) / 2),
        5
      );
      this.lanes = [];
      this.drawMap();
    }
    this._selectedSector = value;
    if (
      !this.sectors
        .filter((sector) => sector.hasBoundingBox())
        .map((sector) => sector.id).includes(value)
    ) return;
    this.drawLaneLabels = false;
    this.calcLanes(value);
    this.zoomToFit(value);
    this.drawMap();
  };
  private _selectedSector: string;
  @Input() set selectedLane(value: number) {
    this._selectedLane = value;
    this.drawMap();
  };
  private _selectedLane: number;
  @Input() set lanesAvailability(value: ILaneDetails[]) {
    this.updateLanesAvailability(value);
  };
  @Input() set lanesAnomalies(value: any[]) {
    this.drawLaneLabels = true;
    this.updateLanesStructure(value);
  };
  @Input() set interactionStatus(value: IMapSectorInteractionStatus) {
    this._interactionStatus = value;
    this.drawMap();
  };
  private _interactionStatus: IMapSectorInteractionStatus;

  @Output() private mapInteractionsStatusChange: EventEmitter<ICanvasMapInteractionTarget> = new EventEmitter<ICanvasMapInteractionTarget>();
  @Output() private targetChanged: EventEmitter<ICanvasMapInteractionTarget> = new EventEmitter<ICanvasMapInteractionTarget>();

  private centerShiftX: number = 0;
  private centerShiftY: number = 0;
  private centerOffsetX: number = 0;
  private centerOffsetY: number = 0;
  private ratio: number = 0;
  private zoom: number = 1;
  private animating = false;
  private zoomAnimationStatus = {
    id: 0,
    framesLeft: 0,
    totalFrames: 0,
    totalOffsetX: 0,
    totalOffsetY: 0,
    totalZoomOffset: 0
  };
  private panningOriginalPositions: Vertex = new Vertex(0, 0);
  public panning = false;
  private drawLaneLabels = true;
  private ctx = null;
  private mapBackground = "/assets/images/map-v2.jpg";
  private img: HTMLImageElement = new Image();
  private lanes: ICanvasLaneDetails[] = []
  private animationEaseValues: number[] = [];

  initMap() {
    this.ctx = this.canvas.nativeElement.getContext("2d");
    // canvas buffer initial + dynamic resize
    this.normalizeCanvasBuffer();
    window.addEventListener('resize', (e) => {
      this.drawMap();
    })

    // loading map background
    this.img.addEventListener("load", () => {
      this.drawMap();
      window.dispatchEvent(new Event('resize'));
    });
    this.img.src = this.mapBackground;
  }

  normalizeCanvasBuffer() {
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    this.ctx.canvas.width = rect.width;
    this.ctx.canvas.height = rect.height;
  }

  onWheel(event) {
    if (this.animating) return;
    this.panning = false;
    const beforeZoom = this.zoom;
    const initialRatio = this.getImageRenderRatio();
    let xs = (event.clientX - this.centerShiftX) / this.ratio,
        ys = (event.clientY - this.centerShiftY) / this.ratio;

    this.zoom *= event.deltaY > 0 ? 0.9 : 1.1;
    this.ratio = initialRatio * this.zoom;

    // capping zoom
    if (this.zoom < 1) this.zoom = 1;
    if (this.zoom > 5) this.zoom = 5;

    // preventing panning if map did not zoom
    if (this.zoom - beforeZoom !== 0) {
      this.centerOffsetX = event.clientX - xs * this.ratio;
      this.centerOffsetY = event.clientY - ys * this.ratio;
    }
    this.drawMap();
  }

  onMouseDown(event) {
    if (event.button == 0) {
      this.panningOriginalPositions = new Vertex(this.centerShiftX, this.centerShiftY)
      this.panning = true;
    }
  }

  onMouseUp(event) {
    if (event.button == 0) {
      const rect = this.canvas.nativeElement.getBoundingClientRect();
      const canvasMouseCoordinateX = event.clientX - rect.left;
      const canvasMouseCoordinateY = event.clientY - rect.top;

      const clickResult = this.getInteractionTarget(
        this.calculateAbsoluteXCoordinate(canvasMouseCoordinateX),
        this.calculateAbsoluteYCoordinate(canvasMouseCoordinateY)
      );

      const panningDistanceRequired = 2;
      const maxPannedDistance = Math.max(
        Math.abs(this.panningOriginalPositions.x - this.centerShiftX),
        Math.abs(this.panningOriginalPositions.y - this.centerShiftY)
      );
      const hasNotPanned = maxPannedDistance < panningDistanceRequired;

      if (hasNotPanned) this.targetChanged.emit(clickResult);
      this.panning = false;
    }
  }

  onMouseMove(event) {
    if (this.animating) return;
    const interactionTarget = this.getEventInteractionTarget(event);
    this.canvas.nativeElement.style.cursor = interactionTarget.sector || interactionTarget.lane ? 'pointer' : 'default';

    if (this.panning) {
      this.centerOffsetX = this.centerOffsetX + event.movementX;
      this.centerOffsetY = this.centerOffsetY + event.movementY;
      this.drawMap();
    } else {
      this.mapInteractionsStatusChange.emit(interactionTarget)
    }
  }

  drawMap() {
    if (!this.canvas?.nativeElement || !this.ctx) return;

    this.normalizeCanvasBuffer();
    const canvas = this.canvas.nativeElement;
    const initialRatio = this.getImageRenderRatio();
    this.ratio = initialRatio * this.zoom;
    this.centerShiftX = this.centerOffsetX !== null ? this.centerOffsetX : Math.floor((canvas.width - this.img.width * this.ratio) / 2);
    this.centerShiftY = this.centerOffsetY !== null ? this.centerOffsetY : Math.floor((canvas.height - this.img.height * this.ratio) / 2);
    if (!this.animating) this.normalizeOffsets();

    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.ctx.drawImage(
      this.img,
      0,
      0,
      this.img.width,
      this.img.height,
      this.centerShiftX,
      this.centerShiftY,
      Math.floor(this.img.width * this.ratio),
      Math.floor(this.img.height * this.ratio)
    );

    this.drawSectors();
    this.drawLanes();
  }

  generateEaseInOutValues(length) {
    var values = [];

    // Calculate increment
    var increment = 2 / (length - 1);

    // Generate values
    for (var i = 0; i < length; i++) {
      var value = 1 - Math.abs(i * increment - 1);
      values.push(value);
    }

    // Calculate sum
    var sum = values.reduce(function (a, b) {
      return a + b;
    }, 0);

    // Normalize values
    values = values.map(function (value) {
      return value / sum;
    });

    return values;
  }

  drawSectors() {
    const labels = [];
    this.ctx.font = EMapLabelTypes.Regular;
    var length = 15; // Change this to the desired length
    var easeInOutValues = this.generateEaseInOutValues(length);
    let total = 0;
    easeInOutValues.forEach((value) => {
      total += value;
    });
    this.sectors.forEach((sector) => {
      if (
        this.renderebleSectors.includes(sector.id)
      ) {
        let sectorColor = '#' + sector.color;
        if (this._interactionStatus.sector !== sector.id && (!sector.groupId || this._interactionStatus.sector !== sector.groupId)) {
          this.ctx.globalAlpha = 0.85;
        }
        // if ((this._interactionStatus.sector !== sector.id && (!sector.groupId || this._interactionStatus.sector !== sector.groupId)) && !!this._selectedSector && this._selectedSector !== sector.id && (!sector.groupId || this._selectedSector !== sector.groupId)) {
        //   sectorColor = colorManipulation(sectorColor, 140);
        //   this.ctx.globalAlpha = 0.6;
        // }

        // Draw sector rect

        this.ctx.lineWidth = 2;
        this.ctx.fillStyle = sectorColor;
        this.drawLocalOutlinedRect(sector.vertices);
        this.ctx.globalAlpha = 1;

        // Push sector label
        const bB = this.getEntityBoundingRect(Object.values(sector.vertices));
        const textSize = this.ctx.measureText(sector.label);
        if  (sector.label === null || sector.label === 'null') sector.label = '';
        labels.push({
          value: sector.label,
          x: this.calculateLocalXCoordinate(bB.center.x) - (textSize.width / 2),
          y: this.calculateLocalYCoordinate(bB.center.y) + (textSize.actualBoundingBoxAscent / 2)
        });
      }
    });
    this.ctx.fillStyle = EMapColor.LabelRegular;
    labels.forEach((label) => {
      this.ctx.strokeText(label.value,label.x,label.y);
      this.ctx.fillText(label.value,label.x,label.y);
    })
  }

  drawLanes() {
    const laneLabels: any[] = [];

    if (
      !this.sectors
        .filter((sector) => sector.hasBoundingBox())
        .map((sector) => sector.id).includes(this._selectedSector)
    ) return;

    // Drawing lanes
    this.lanes.forEach((lane, index) => {
      this.ctx.fillStyle = mapLaneColor(lane.availability);
      this.drawLocalOutlinedRect(lane.vertices);

      if (
        lane.type === 'extremity' ||
        // index === this.lanes.length - 1 ||
        (lane.type !== 'extremity' && (this.lanes[index + 1].availability === 'skipped' || this.lanes[index - 1].availability === 'skipped')) ||
        lane.label === this._selectedLane ||
        lane.label === this._interactionStatus.lane
      ) {
        const bB = this.getEntityBoundingRect(Object.values(lane.vertices));
        const textSize = this.ctx.measureText(lane.label.toString());
        laneLabels.push({
          value: lane.label,
          x: this.calculateLocalXCoordinate(bB.center.x) - (textSize.width / 2),
          y: this.calculateLocalYCoordinate(bB.center.y) + (textSize.actualBoundingBoxAscent / 2),
          importance: lane.label === this._interactionStatus.lane ? 3 : lane.label === this._selectedLane ? 2 : 1
        })
      }
    })

    // this.drawFirstLaneLabel();
    // this.drawLastLaneLabel();

    //Sorting labels
    laneLabels.sort((a, b) => a.importance > b.importance ? 1 : -1)

    // Drawing labels
    this.ctx.fillStyle = EMapColor.LabelRegular;
    this.ctx.strokeStyle = EMapColor.OutlineRegular;
    this.ctx.lineWidth = 2;
    if (this.drawLaneLabels) laneLabels.forEach((label) => {
      this.ctx.fillStyle = EMapColor.LabelRegular;
      this.ctx.strokeStyle = EMapColor.OutlineRegular;
      this.ctx.lineWidth = 2;
      const specialLabel = label.value === this._selectedLane || label.value === this._interactionStatus.lane;
      this.ctx.font = specialLabel ? EMapLabelTypes.Regular : EMapLabelTypes.Smaller;
      this.ctx.strokeText(label.value, label.x, label.y);
      this.ctx.fillText(label.value, label.x, label.y);
    })
  }

  drawLocalOutlinedRect(vertices: IRectVertexGroup) {
    this.ctx.beginPath();
    this.ctx.moveTo(
      this.calculateLocalXCoordinate(vertices.tl.x),
      this.calculateLocalYCoordinate(vertices.tl.y)
    );
    this.ctx.lineTo(
      this.calculateLocalXCoordinate(vertices.tr.x),
      this.calculateLocalYCoordinate(vertices.tr.y)
    );
    this.ctx.lineTo(
      this.calculateLocalXCoordinate(vertices.br.x),
      this.calculateLocalYCoordinate(vertices.br.y)
    );
    this.ctx.lineTo(
      this.calculateLocalXCoordinate(vertices.bl.x),
      this.calculateLocalYCoordinate(vertices.bl.y)
    );
    this.ctx.closePath();
    this.ctx.stroke();
    this.ctx.fill();
  }

  drawFirstLaneLabel() {
    const firstLane = this.lanes[0];
    const centerPoint = {
      x: 0,
      y: 0
    }
    centerPoint.x = firstLane.vertices.tl.x + (firstLane.vertices.bl.x - firstLane.vertices.tl.x) / 2;
    centerPoint.y = firstLane.vertices.tl.y + (firstLane.vertices.bl.y - firstLane.vertices.tl.y) / 2;

    this.ctx.beginPath();
    this.ctx.arc(this.calculateLocalXCoordinate(centerPoint.x), this.calculateLocalYCoordinate(centerPoint.y), 10, 0, 2 * Math.PI, false);
    this.ctx.fillStyle = EMapColor.LabelRegular;
    this.ctx.fill();
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = EMapColor.OutlineRegular;
    this.ctx.stroke();
    this.ctx.fillStyle = EMapColor.OutlineRegular;
    this.ctx.font = EMapLabelTypes.Smaller;
    const textSize = this.ctx.measureText(firstLane.label.toString());
    this.ctx.strokeText(firstLane.label, this.calculateLocalXCoordinate(centerPoint.x) - (textSize.width / 2), this.calculateLocalYCoordinate(centerPoint.y) + (textSize.actualBoundingBoxAscent / 2));
  }

  drawLastLaneLabel() {
    const firstLane = this.lanes[this.lanes.length - 1];
    const centerPoint = {
      x: 0,
      y: 0
    }
    centerPoint.x = firstLane.vertices.tr.x + (firstLane.vertices.br.x - firstLane.vertices.tr.x) / 2;
    centerPoint.y = firstLane.vertices.tr.y + (firstLane.vertices.br.y - firstLane.vertices.tr.y) / 2;

    this.ctx.beginPath();
    this.ctx.arc(this.calculateLocalXCoordinate(centerPoint.x), this.calculateLocalYCoordinate(centerPoint.y), 10, 0, 2 * Math.PI, false);
    this.ctx.fillStyle = EMapColor.LabelRegular;
    this.ctx.fill();
    this.ctx.lineWidth = 2;
    this.ctx.strokeStyle = EMapColor.OutlineRegular;
    this.ctx.stroke();
    this.ctx.fillStyle = EMapColor.OutlineRegular;
    this.ctx.font = EMapLabelTypes.Smaller;
    const textSize = this.ctx.measureText(firstLane.label.toString());
    this.ctx.strokeText(firstLane.label, this.calculateLocalXCoordinate(centerPoint.x) - (textSize.width / 2), this.calculateLocalYCoordinate(centerPoint.y) + (textSize.actualBoundingBoxAscent / 2));
  }

  normalizeOffsets() {
    const canvas = this.canvas.nativeElement;
    const originalRatio = this.getImageRenderRatio();

    const maxShiftX = Math.floor((canvas.width - this.img.width * originalRatio) / 2);
    const minShiftX = Math.floor((this.img.width * this.ratio - canvas.width) * -1);
    const maxShiftY = Math.floor((canvas.height - this.img.height * originalRatio) / 2);
    const minShiftY = Math.floor((this.img.height * this.ratio - canvas.height) * -1);

    if (this.centerShiftX < minShiftX) this.centerShiftX = minShiftX;
    if (this.centerShiftX > maxShiftX) this.centerShiftX = maxShiftX;
    if (this.centerShiftY < minShiftY) this.centerShiftY = minShiftY;
    if (this.centerShiftY > maxShiftY) this.centerShiftY = maxShiftY;
  }

  private getNormalizeOffsets(offsetX, offsetY, zoom) {
    const canvas = this.canvas.nativeElement;
    const originalRatio = this.getImageRenderRatio();
    const ratio = originalRatio * zoom;

    const maxShiftX = Math.floor((canvas.width - this.img.width * originalRatio) / 2);
    const minShiftX = Math.floor((this.img.width * ratio - canvas.width) * -1);
    const maxShiftY = Math.floor((canvas.height - this.img.height * originalRatio) / 2);
    const minShiftY = Math.floor((this.img.height * ratio - canvas.height) * -1);
    let result = {
      x: offsetX,
      y: offsetY
    };
    if (result.x < minShiftX) result.x = minShiftX;
    if (result.x > maxShiftX) result.x = maxShiftX;
    if (result.y < minShiftY) result.y = minShiftY;
    if (result.y > maxShiftY) result.y = maxShiftY;

    return result;
  }

  getInteractionTarget(x: number, y: number): ICanvasMapInteractionTarget {
    for (let i = 0; i < this.sectors.length; i++) {
      if (
        this.renderebleSectors.includes(this.sectors[i].id) &&
        this.sectors[i].isPointInside(new Vertex(x, y))
      ) {
        return {
          sector: this.sectors[i].id,
          lane: null
        }
      }
    }
    for (let i = 0; i < this.lanes.length; i++) {
      if (
        this.lanes[i].availability !== 'skipped' &&
        this.lanes[i].collider.isPointInside(new Vertex(x, y))
      ) {
        return {
          sector: null,
          lane: this.lanes[i].label
        }
      }
    }
    return {
      sector: null,
      lane: null
    }
  }

  getEventInteractionTarget(event): ICanvasMapInteractionTarget {
    const rect = this.canvas.nativeElement.getBoundingClientRect();
    const canvasMouseCoordinateX = event.clientX - rect.left;
    const canvasMouseCoordinateY = event.clientY - rect.top;

    return  this.getInteractionTarget(
      this.calculateAbsoluteXCoordinate(canvasMouseCoordinateX),
      this.calculateAbsoluteYCoordinate(canvasMouseCoordinateY)
    );
  }

  getEntityBoundingRect(vertices: Vertex[]) {
    let lowestX = vertices[0].x;
    let highestX = vertices[0].x;
    let lowestY = vertices[0].y;
    let highestY = vertices[0].y;

    vertices.forEach((vertex) => {
      if (vertex.x < lowestX) lowestX = vertex.x;
      if (vertex.x > highestX) highestX = vertex.x;
      if (vertex.y < lowestY) lowestY = vertex.y;
      if (vertex.y > highestY) highestY = vertex.y;
    })
    const xSurplus = (highestX - lowestX) * .1;
    const ySurplus = (highestY - lowestY) * .1;
    lowestX -= xSurplus;
    highestX += xSurplus;
    lowestY -= ySurplus;
    highestY += ySurplus;

    let boundingRect = {
      tl: {
        x: lowestX,
        y: lowestY
      },
      tr: {
        x: highestX,
        y: lowestY
      },
      bl: {
        x: lowestX,
        y: highestY
      },
      br: {
        x: highestX,
        y: highestY
      },
      width: highestX - lowestX,
      height: highestY - lowestY,
      center: {
        x: lowestX + ((highestX - lowestX) / 2),
        y: lowestY + ((highestY - lowestY) / 2)
      },
      orientation: highestX - lowestX > highestY - lowestY ? 'horizontal' : 'vertical'
    }
    return boundingRect;
  }

  calcLanes(val: string, anomalies: any[] = []) {
    if (!val) {
      this.lanes = [];
      return;
    }
    const sector = this.sectors.find((sector) => sector.id === val);
    if (!sector) return;
    let lanes = []
    if (sector.groupId) {
      const sectors = this.sectors.filter((innerSector) => innerSector.groupId === sector.groupId);
      sectors.forEach((sector) => lanes = lanes.concat(this.calcSectorLanes(sector, anomalies.find((innerSector) => innerSector.sector === sector.id))));
      this.lanes = lanes;
    }
    else {
      this.lanes = this.calcSectorLanes(sector, anomalies.find((innerSector) => innerSector.sector === sector.id));
    }
  }

  private calcSectorLanes(sector: MapSector, anomalies: any): ICanvasLaneDetails[] {
    const lanes = [];
    const topEdgeSegment = {
      x: (sector.vertices.tr.x - sector.vertices.tl.x) / sector.laneCount,
      y: (sector.vertices.tr.y - sector.vertices.tl.y) / sector.laneCount
    }
    const bottomEdgeSegment = {
      x: (sector.vertices.br.x - sector.vertices.bl.x) / sector.laneCount,
      y: (sector.vertices.br.y - sector.vertices.bl.y) / sector.laneCount
    }
    let laneNumber = sector.numerationStart || (sector.numerationType === 'Even' ? 2 : 1);
    let skipTimer = 0;
    for (let i = 0; i < sector.laneCount; i++) {
      const anomaly = !!anomalies ? anomalies.exceptions.find((anomaly) => anomaly.laneNumber === laneNumber) : null;
      skipTimer = !!anomaly && anomaly.anomalyType === 'SkipLane' && skipTimer === 0 ? anomaly.laneCount : skipTimer > 0 ? skipTimer - 1 : skipTimer;
      let lane = {
        label: !!anomaly && anomaly.anomalyType === 'SkipLane' && skipTimer > 0  ? -1 : laneNumber,
        vertices: {
          tl: {
            x: sector.vertices.tl.x + (topEdgeSegment.x * i),
            y: sector.vertices.tl.y + (topEdgeSegment.y * i)
          },
          tr: {
            x: sector.vertices.tl.x + (topEdgeSegment.x * (i + 1)),
            y: sector.vertices.tl.y + (topEdgeSegment.y * (i + 1))
          },
          bl: {
            x: sector.vertices.bl.x + (bottomEdgeSegment.x * i),
            y: sector.vertices.bl.y + (bottomEdgeSegment.y * i)
          },
          br: {
            x: sector.vertices.bl.x + (bottomEdgeSegment.x * (i + 1)),
            y: sector.vertices.bl.y + (bottomEdgeSegment.y * (i + 1))
          }
        },
        availability: !!anomaly && (anomaly.anomalyType === 'SkipLaneAndSkipNumber' || (anomaly.anomalyType === 'SkipLane' && skipTimer > 0))  ? 'skipped' : 'pending',
        sectorId: sector.id,
        type: i == 0 || i == sector.laneCount - 1 ? 'extremity' : null
      };
      lanes.push(
        Object.assign(
          lane,
          {
            collider: new Polygon([
              new Vertex(lane.vertices.tl.x, lane.vertices.tl.y),
              new Vertex(lane.vertices.tr.x, lane.vertices.tr.y),
              new Vertex(lane.vertices.br.x, lane.vertices.br.y),
              new Vertex(lane.vertices.bl.x, lane.vertices.bl.y)
            ])
          })
      );
      if (!anomaly || anomaly.anomalyType !== 'SkipLane' || skipTimer === 0) {
        sector.numerationType === 'Both' ? laneNumber++ : laneNumber += 2;
      }
    }
    return lanes;
  }

  updateLanesAvailability(availabilities: ILaneDetails[]) {
    const sector = this.sectors.find((sector) => sector.id === this._selectedSector);
    this.lanes.forEach((laneObject, index) => {
      const laneOccupation = availabilities.find((availability) => availability.laneNumber === laneObject.label);
      this.lanes[index].availability = this.lanes[index].availability !== 'skipped' ? mapLaneAvailability(laneOccupation) : 'skipped';
    });
    this.drawMap();
  }

  updateLanesStructure(anomalies: any[]) {
    this.calcLanes(this._selectedSector, anomalies)
    if (this.animating) return;
    this.drawMap();
  }

  zoomToFit(val: string) {
    const sector = this.sectors.find((sector) => sector.id === val);
    if (!sector ) return;
    let zoomLevel;
    let offsetX;
    let offsetY;
    let bB;
    if (sector.groupId) {
      let vertices = [];
      const sectors = this.sectors.filter((innerSector) => innerSector.groupId === sector.groupId);
      sectors.forEach((sector) => vertices = vertices.concat(Object.values(sector.vertices)));
      bB = this.getEntityBoundingRect(vertices);
    }
    else {
      bB = this.getEntityBoundingRect(Object.values(sector.vertices));
    }
    const imageRatio = this.getImageRenderRatio();
    if (bB.orientation === 'vertical') {
      zoomLevel = (this.img.height * imageRatio) / (bB.height * imageRatio * 10);
    } else {
      zoomLevel = (this.img.width * imageRatio) / (bB.width * imageRatio * 10);
    }
    if (zoomLevel < 1) zoomLevel = 1;
    if (zoomLevel > 2) zoomLevel = 2;
    offsetX = Math.abs(bB.tl.x * zoomLevel * imageRatio * 10) * -1;
    offsetY = Math.abs(bB.tl.y * zoomLevel * imageRatio * 10) * -1;

    offsetX += (this.ctx.canvas.width / 2) - ((bB.width / 2) * imageRatio * zoomLevel * 10);
    offsetY += (this.ctx.canvas.height / 2) - ((bB.height / 2) * imageRatio * zoomLevel * 10);
    const normalizedOffsets = this.getNormalizeOffsets(offsetX, offsetY, zoomLevel);
    offsetX = normalizedOffsets.x;
    offsetY = normalizedOffsets.y;
    this.animateZoom(zoomLevel, offsetX, offsetY);
  }

  animateZoom(zoom: number, offsetX: number, offsetY:number, frames: number = null ) {
    const totalOffsetX = offsetX - this.centerOffsetX;
    const totalOffsetY = offsetY - this.centerOffsetY;
    let maxOffset = Math.max(Math.abs(totalOffsetX), Math.abs(totalOffsetY)) / zoom;
    maxOffset = Math.min(maxOffset, 600);
    if (!frames) {
      frames = Math.round(10 * (maxOffset / 200));
      if (frames < 8) frames = 8;
    }
    const animationId = this.zoomAnimationStatus.id + 1;
    this.zoomAnimationStatus = {
      id: animationId,
      framesLeft: frames,
      totalFrames: frames,
      totalOffsetX,
      totalOffsetY,
      totalZoomOffset: zoom - this.zoom
    }
    this.animationEaseValues = this.generateEaseInOutValues(frames);
    this.animating = true;
    this.executeZoomAnimationFrame(animationId);
  }

  executeZoomAnimationFrame(id) {
    if (this.zoomAnimationStatus.id !== id) return;
    const frameNumber = this.zoomAnimationStatus.totalFrames - this.zoomAnimationStatus.framesLeft;
    this.zoom += this.animationEaseValues[frameNumber] * this.zoomAnimationStatus.totalZoomOffset;
    this.centerOffsetX += this.animationEaseValues[frameNumber] * this.zoomAnimationStatus.totalOffsetX;
    this.centerOffsetY += this.animationEaseValues[frameNumber] * this.zoomAnimationStatus.totalOffsetY;
    this.zoomAnimationStatus.framesLeft--;
    this.drawMap();
    if (this.zoomAnimationStatus.framesLeft > 0) {
      setTimeout(() => this.executeZoomAnimationFrame(id), 20);
    } else {
      this.animating = false;
    }
  }

  calculateLocalXCoordinate(val: number) {
    return this.centerShiftX + ((val * this.ratio) * 10);
  }

  calculateAbsoluteXCoordinate(val: number) {
    return (val - this.centerShiftX) / (10 * this.ratio);
  }

  calculateLocalYCoordinate(val: number) {
    return this.centerShiftY + ((val * this.ratio) * 10);
  }

  calculateAbsoluteYCoordinate(val: number) {
    return (val - this.centerShiftY) / (10 * this.ratio);
  }

  getImageRenderRatio() {
    const hRatio = this.canvas.nativeElement.width / this.img.width;
    const vRatio = this.canvas.nativeElement.height / this.img.height;
    return Math.min(hRatio, vRatio);
  }

  ngOnInit(): void {
    setTimeout(this.drawMap, 200);
  }
}
