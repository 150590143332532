<button class="nes_close_btn" mat-icon-button (click)="onClose()"><mat-icon>clear</mat-icon></button>
<nes-dialog [title]="title" [withoutBorder]="false">


  <form [formGroup]="positionForm">
    <div class="nes-portlet-form-wr">

      <div class="nes-portlet-form-col _two select_field">
        <mat-form-field>
          <mat-label>Settore</mat-label>
          <mat-select #sectorPositionField formControlName="sector">
            <mat-option *ngFor="let sector of groupedSectors;" [value]="sector.id" (click)="generateSectorLanes(sector.id);">{{sector.label}}</mat-option>
          </mat-select>
          <nes-icon svgIcon="blue_angle_bottom"  (click)="sectorPositionField.toggle()"></nes-icon>
        </mat-form-field>

      </div>

      <div class="nes-portlet-form-col _two select_field">
        <mat-form-field *ngIf="!!showRows">
          <mat-label>Fila</mat-label>
          <mat-select #rowPositionField formControlName="row">
            <mat-option *ngFor="let row of lanes;" [value]="row">{{row.label}}</mat-option>
          </mat-select>
          <nes-icon svgIcon="blue_angle_bottom"  (click)="rowPositionField.toggle()"></nes-icon>
        </mat-form-field>
      </div>
    </div>

  </form>
  <div class="form-footer">
    <button [disableRipple]="true" class="form-submit-button" mat-button (click)="onSave()">OK</button>
  </div>
</nes-dialog>
