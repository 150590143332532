import { Component, OnInit, Inject } from '@angular/core';
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from '../../services/vehicle-details-service.service.interface';
import { Guid } from 'guid-typescript';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { IVehicleDTO, IVehicleFilters } from '../../shared/interfaces/vehicles.type';

@Component({
  selector: 'app-vehicle-selection-dialog',
  templateUrl: './vehicle-selection-dialog.component.html',
  styleUrls: ['./vehicle-selection-dialog.component.scss']
})
export class VehicleSelectionDialogComponent implements OnInit {

  public tableFilter: any;
  public vehicleFilter: IVehicleFilters;
  private text = '';
  title: string = '';
  breadcrumbs: string[] = [''];
  selectedVehicles: IVehicleDTO[] = [];
  vehicles: IVehicleDTO[] = [];
  totalVehicles: number = 0;
  dType: string = '';

  //--

  constructor(
    public dialogRef: MatDialogRef<VehicleSelectionDialogComponent>,
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(!!data && !!data.title) this.title = data.title;
    if(!!data && !!data.breadcrumbs) this.breadcrumbs = data.breadcrumbs;
    if(!!data && !!data.dType) this.dType = data.dType;
  }


  ngOnInit(): void {
    this.initPage();
  }

  ngAfterViewInit(): void {
  }

  // --

  get defaultFilter(): IVehicleFilters {
    return {
      text: '',
      pageNumber: 0,
      pageSize: 10
    };
  }

  get defaultTableFilter(): any {
    return {
      vehicleBrand: null,
      vehicleModel: null
    };
  }

  get vehicleDetails(): string[] {
    return ['checkbox', 'licensePlate', 'vin'];
  }

  get hasTarget(): boolean {
    if (this.selectedVehicles.length > 0) {
      return true;
    }

    return false;
  }

  get hasFullSelection(): boolean {
    if (this.vehicles.length === 0) {
      return false;
    }

    return this.vehicles.length === this.selectedVehicles.length ;
  }


  get hasIndeterminateSelection(): boolean {
    if (this.vehicles.length === 0) {
      return false;
    }

    return this.selectedVehicles.length > 0 && this.vehicles.length > this.selectedVehicles.length ;
  }
  // --

  public applyFilterSelect(): void {
    this.applyFilter(this.text);
  }

  public applyFilter(event: string): void {

    this.text = event;
    Object.assign(this.vehicleFilter, {
      text: event,
      pageNumber: 0,
      pageSize: this.vehicleFilter.pageSize
    });
    this.fetchVehicleList();
  }

  public toggleVehicleSelection(vehicle: IVehicleDTO){
    let foundIndex = !!this.selectedVehicles  ? this.selectedVehicles.findIndex(selectedVehicle => selectedVehicle.id.equals(vehicle.id)) : -1;
    if(foundIndex < 0){
      this.selectedVehicles.push(vehicle);
    }else{
      this.selectedVehicles.splice(foundIndex, 1);
    }
  }

  private initPage(): void {
    this.tableFilter = Object.assign({}, this.defaultTableFilter);
    this.vehicleFilter = Object.assign({}, this.defaultFilter);

    this.fetchVehicleList()
  }

  public selectAllVehicles(): void {
      if(this.hasFullSelection){
        this.selectedVehicles = [];
      }else{
        this.selectedVehicles = [];
        this.vehicles.forEach(vehicle => this.selectedVehicles.push(vehicle));
      }
  }

  public isVehicleSelected(id: Guid): boolean{
    return this.selectedVehicles.findIndex(sVehicle => sVehicle.id.equals(id)) >= 0;
  }

  private fetchVehicleList(): void {
    this.vService.getVehiclesWithDetails(
      Object.assign(
        {},
        this.vehicleFilter,
        this.tableFilter
      ))
      .subscribe(res => {
        this.selectedVehicles = [];
        this.totalVehicles = res.totalRows;
        this.vehicles = res.rows;
      });
  }

  public onPaginationChange(e): void {

    let page = this.vehicleFilter.pageSize !== e.pageSize ?  0 : e.pageIndex;
    Object.assign(this.vehicleFilter, {
      text: this.vehicleFilter.text,
      pageNumber: page,
      pageSize: e.pageSize
    });

    this.fetchVehicleList();
  }

  public onSave(): void{
    let args = {action: 'select', data:this.selectedVehicles};
    this.onClose(this.selectedVehicles.length > 0 ? args : null);
  }

  public onSwitchToCreation(): void {
    let args = {action: 'create', data: null};
    this.onClose(args);
  }

  public onClose(args: {action: string, data: IVehicleDTO[] } = null){
    this.dialogRef.close(args);
  }
}
