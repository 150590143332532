import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const passwordControl = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (passwordControl.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordMatch: true });
      return { passwordMatch: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };
}
