import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthErrorStateMatcher} from "../../shared/classes/auth-error-state-matcher";
import {ActivatedRoute, Router} from "@angular/router";
import {AUTHENTICATION_SERVICE, IAuthenticationService} from "../../shared/services/authentication.service.interface";
import {passwordMatchValidator} from "../../shared/validators/passwords-match.validator";

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  public form: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required])
  }, {
    validators: passwordMatchValidator('password', 'confirmPassword')
  });
  public loading = false;
  public submitted = false;
  public message: string = "La password è stata cambiata con successo.";

  constructor(
    private router: Router,
    @Inject(AUTHENTICATION_SERVICE) private authService: IAuthenticationService) {
  }

  public async onSubmit(): Promise<void> {
    if (!this.form.valid) return;
    this.loading = true;
    try {
      await this.authService.changePassword({
        newPassword: this.form.value.password
      });
      this.submitted = true;
    } catch (e) {
      console.error(e);
    }
    this.loading = false;
  }

  onBack() {
    this.router.navigate(['..']);
  }
}
