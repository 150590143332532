<div class="nes-sidenav-toggle" [class.opened]="sidenav.opened">
  <button mat-icon-button [disableRipple]="true" (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
</div>

<mat-sidenav-container>
  <mat-sidenav #sidenav [mode]="activeMode" [fixedInViewport]="false" [opened]="expandedSidenav">

    <div class="nes-sidenav-brand">
      <span class="sprite">
          <img src="/assets/icons/logo-tencara.svg"/>
      </span>
    </div>

    <!-- <div class="nes-sidenav-link">
      <a mat-button [disableRipple]="true" [href]="alfrescoExternalUrl" [target]="'_blank'">
        <img src="/assets/images/mda-alfresco.png" alt="Alfresco">
      </a>
    </div> -->

    <nes-navigation></nes-navigation>

    <div class="nes-sidenav-version">
      <user-panel></user-panel>
      <div>
        <div>
        </div>
        <mat-slide-toggle *ngIf="isBreakpointActive" [labelPosition]="'before'" [disableRipple]="false" (change)="toggleSidenav()" [checked]="expandedSidenav">{{ expandedSidenav ? 'Sblocca' : 'Blocca' }}</mat-slide-toggle>
      </div>

    </div>

  </mat-sidenav>
  <mat-sidenav-content [class.toggled]="expandedSidenav">

    <router-outlet></router-outlet>
    <nes-footer></nes-footer>

  </mat-sidenav-content>
</mat-sidenav-container>
