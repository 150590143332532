import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { VehicleSelectionDialogComponent } from './vehicle-selection-dialog/vehicle-selection-dialog.component';
import { VehicleCreationDialogComponent } from './vehicle-creation-dialog/vehicle-creation-dialog.component';
import { VehiclePositionsHistoryDialogComponent } from "./vehicle-positions-history-dialog/vehicle-positions-history-dialog.component";
import { VehicleDamagesDialogComponent } from "./vehicle-damages-dialog/vehicle-damages-dialog.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class VehicleDialogsService {

  constructor(private dialog: MatDialog) { }

  public openVehicleSelectionDialog(args = {}, dialogClass: string = ''): Observable<any> {
    return this.dialog.open(VehicleSelectionDialogComponent,{
      data: args,
      panelClass: dialogClass || null,
      autoFocus: false
    }).afterClosed()
  }

  public openVehicleCreationDialog(args = {}, dialogClass: string = ''): Observable<any> {
    return this.dialog.open(VehicleCreationDialogComponent,{
      data: args,
      panelClass: dialogClass || null,
      autoFocus: false
    }).afterClosed()
  }

  public openVehiclePositionsHistoryDialog(args = {}, dialogClass: string = ''): Observable<any> {
    return this.dialog.open(VehiclePositionsHistoryDialogComponent,{
      data: args,
      panelClass: dialogClass || null,
      autoFocus: false
    }).afterClosed()
  }

  public openVehicleDamagesDialog(args = {}, dialogClass: string = ''): Observable<any> {
    return this.dialog.open(VehicleDamagesDialogComponent,{
      data: args,
      panelClass: dialogClass || null,
      autoFocus: false
    }).afterClosed()
  }

  public openConfirmationDialog(args = {}, dialogClass: string = ''): Observable<any> {
    return this.dialog.open(ConfirmationDialogComponent,{
      data: args,
      panelClass: dialogClass || null,
      autoFocus: false
    }).afterClosed()
  }
}
