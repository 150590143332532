import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthErrorStateMatcher} from "../../shared/classes/auth-error-state-matcher";
import {AUTHENTICATION_SERVICE, IAuthenticationService} from "../../shared/services/authentication.service.interface";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  public form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });
  public matcher = new AuthErrorStateMatcher();
  public loading = false;
  public messages = {
    success: "Link per il reset della password è stato inviato alla tua email",
    emailNotFound: "Email non trovata"
  };
  public status: "success" | "emailNotFound" | null = null;
  public title = "Reset Password";

  constructor(@Inject(AUTHENTICATION_SERVICE) private authService: IAuthenticationService) {}

  public async onSubmit(): Promise<void> {
    if (!this.form.valid) return;
    this.loading = true;
    try {
      await this.authService.resetPassword({
        eMailAddress: this.form.value.email
      })
      this.status = "success";
    } catch (e) {
      console.error(e);
      if (e.status === 400) {
        this.status = "emailNotFound";
      }
    }
    this.loading = false;
  }
}
