<nes-portlet-title [title]="'Elenco attività'"></nes-portlet-title>
<div class="nes-table-action _forced_padded _reduced_padding" *ngIf="!!editable">
    <div class="nes-table-action-box">
        <mat-checkbox [disableRipple]="true" (click)="$event.stopPropagation()" (change)="selectAllActivities()" [checked]="hasFullSelection" [indeterminate]="hasIndeterminateSelection">seleziona tutti</mat-checkbox>
        <mat-icon svgIcon="mda-dots" class="separator"></mat-icon>
    </div>
</div>

<nes-portlet-table [dataSource]="activities | async">
    <mat-table [dataSource]="activities | async">
        <ng-container matColumnDef="checkbox">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-checkbox [disableRipple]="true" (change)="toggleActivitySelection(row.id)" [checked]="isActivitySelected(row.id)" [disabled]="!editable"></mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Attività</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.name ? row.name : ''}}</mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="activitiesDetails"></mat-header-row>
        <mat-row *matRowDef="let row; columns: activitiesDetails;"></mat-row>
    </mat-table>
</nes-portlet-table>
