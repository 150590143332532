import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'nes-footer',
  templateUrl: './nes-footer.component.html'
})
export class NesFooterComponent implements OnInit, OnDestroy {

  // --

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  // --

}
