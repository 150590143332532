import {Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {IVehicle, IVehicleSelectionListItem} from "../shared/interfaces/vehicles.type";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {IWorkOrdersService, WORK_ORDERS_SERVICE} from "../../work-orders/shared/interfaces/work-orders.interface";
import {IVehicleDetailsService, VEHICLE_DETAILS_SERVICE} from "../services/vehicle-details-service.service.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import * as moment from "moment/moment";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {MatTable} from "@angular/material/table";
import {WorkOrderVehicle} from "../../work-orders/shared/classes/work-order";

@Component({
  selector: 'app-vehicle-blockages',
  templateUrl: './vehicle-blockages.component.html',
  styleUrls: ['./vehicle-blockages.component.scss']
})
export class VehicleBlockagesComponent implements OnInit, OnDestroy {
  @ViewChildren(MatTable) matTables : QueryList<MatTable<any>>;
  public loading = false;
  public vehicles: IVehicleSelectionListItem[] = [{type: 'input'}];
  public vehicleSearchResults: IVehicle[] = [];
  public loadingSearch: boolean = false;
  public vehicleSearch: FormControl = new FormControl('');
  public operationType: FormControl = new FormControl('block');
  public blockageCode: FormControl = new FormControl('', [Validators.required]);
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.initSearchControlDebounce();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  get vehicleDetails(): string[] {
    return ['label', 'details', 'color', 'position', 'destination_address','actions'];
  }

  get vehicleListLength(): number {
    return this.vehicles.filter((listItem) => listItem.type === 'vehicle').length;
  }

  get vehicleSectionTitle(): string {
    if (this.vehicleListLength === 1) return '1 veicolo';
    return this.vehicleListLength + ' veicoli';
  }

  get title(): string {
    return this.operationType.value === 'block' ? 'Blocca veicoli' : 'Sblocca veicoli';
  }

  private triggerSnackbar(message: string): void {
    this.snackBar.open(message, '', {
      panelClass: 'nes-snackbar-error',
      duration: 4000,
      horizontalPosition: 'right'
    });
  }

  private initSearchControlDebounce(): void {
    this.vehicleSearch.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged()
      )
      .subscribe(res => {
        if (!res) {
          this.vehicleSearchResults = [];
          return;
        }
        this.fetchSearchAutocompleteResults(res);
      });
  }

  public handleSearchAutocomplete(event): void {
    const foundValue = this.vehicleSearchResults.find((value) => value.vin === event.option.value);
    if (foundValue) {
      this.vehicles.splice(this.vehicles.length - 1, 0,
        {
          vehicle: this.mapVehicleToWorkOrderVehicle(foundValue),
          type: 'vehicle'
        });
    }
    this.vehicleSearch.setValue('');
    this.matTables.toArray().forEach(each => each.renderRows());
  }

  public removeVehicle(vehicle: IVehicle): void {
    this.vehicles = this.vehicles.filter((listItem) => listItem.vehicle?.vin !== vehicle.vin);
  }

  public fetchSearchAutocompleteResults(term: string): void {
    this.loadingSearch = true;
    this.vService.getVehicles({
      text: term,
      pageNumber: 0
    }).subscribe((options) => {
      this.loadingSearch = false;
      options.rows.forEach((vehicle) => {
        console.log(vehicle.vin, vehicle.color);
      });
      this.vehicleSearchResults = options.rows
    })
  }

  public retrieveVehicleLabel(vehicle: IVehicle): string {
    if (!vehicle) return '';

    let result = [];
    if (vehicle.vin) result.push(vehicle.vin);
    if (vehicle.licensePlate) result.push(vehicle.licensePlate);
    return result.join(' - ');
  }

  public retrieveVehicleDetails(vehicle: WorkOrderVehicle): string {
    if (!vehicle) return '';

    let result = [];
    if (vehicle.manufacturer) result.push(vehicle.manufacturer);
    if (vehicle.model) result.push(vehicle.model);
    return result.join(' ');
  }

  public retrieveVehiclePosition(vehicle: IVehicle): string {
    if (!vehicle) return '';

    let result = [];
    if (vehicle.sector) result.push(vehicle.sector);
    if (vehicle.lane) result.push(vehicle.lane);
    return result.join(' - ');
  }

  public handlePaste($event: ClipboardEvent) {
    const clipboardData = $event.clipboardData.getData('text/plain');

    $event.stopPropagation();
    $event.preventDefault();
    const vins = clipboardData.split(/\r?\n|\r|\n/g).filter((vin) => vin.length > 0);

    if (vins.length > 0) {
      const foundError = vins.find((vin) => vin.length !== 17);
      if (foundError) {
        this.triggerSnackbar('Invalid VIN sequence pasted');
      }
      else {
        let count = 0;
        this.loading = true;
        const notFoundVins = [];

        vins.forEach((vin) => {
          count++;

          this.vService.getVehicles({
            text: vin,
            pageNumber: 0
          }).subscribe((result) => {
            if (!result?.rows.length) {
              notFoundVins.push(vin);
            } else {
              this.vehicles.splice(this.vehicles.length - 1, 0,
                {
                  vehicle: this.mapVehicleToWorkOrderVehicle(result.rows[0]),
                  type: 'vehicle'
                });
              this.matTables.toArray().forEach(each => each.renderRows());

            }

            if (count === vins.length) {
              this.loading = false;

              if (notFoundVins.length) {
                this.triggerSnackbar('Some VINs were not found: ' + notFoundVins.join(', '));
              }
            }

          })
        });
      }
    }
  }

  private mapVehicleToWorkOrderVehicle(vehicle: IVehicle): WorkOrderVehicle {
    return {
      id: vehicle.id.toString(),
      vin: vehicle.vin,
      sector: vehicle.sector,
      lane: vehicle.lane,
      model: vehicle.model,
      manufacturer: vehicle.brand,
      color: vehicle.color,
      licensePlate: vehicle.licensePlate,
      destinationAddress: vehicle.destinationAddress,
      ddt: vehicle.ddt,
      ticked: vehicle.ticked,
      orderId: vehicle.orderId
    }
  }

  public async submit(): Promise<void> {
    try {
      this.loading = true;
      const vehicles = this.vehicles
        .filter((listItem) => listItem.type === 'vehicle')
        .map((listItem) => listItem.vehicle.id);
      const blockageCode = this.blockageCode.value;
      if (this.operationType.value === 'block') {
        await this.vService.bulkAddVehicleBlockage(vehicles, blockageCode)
          .toPromise();
      } else {
        await this.vService.bulkCloseVehicleBlockage(vehicles, blockageCode)
          .toPromise();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }
}
