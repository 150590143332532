import {UserRoles} from "../../../modules/authentication/shared/interfaces/user-roles";

export enum NavigationItemType {
  Route,
  Action,
  Submenu
};

export abstract class ANavigationItem {
  get type(): NavigationItemType { return this._type; };
  get label(): string { return this._label; }
  get roles(): UserRoles[] { return this._roles; }

  protected constructor (private _type: NavigationItemType, private _label: string, private _roles: UserRoles[]) {}
};

export class RouteNavigationItem extends ANavigationItem {
  get route(): string | Array<string | { outlets: {} }> { return this._route; };

  constructor (label: string, private _route: string | Array<string | { outlets: {} }>, roles: UserRoles[] = []) {
    super(NavigationItemType.Route, label, roles);
  }
}

export class ActionNavigationItem extends ANavigationItem {
  get action(): () => void { return this._action; };

  constructor (label: string, private _action: () => void, roles: UserRoles[] = []) {
    super(NavigationItemType.Action, label, roles);
  }
}

export class SubmenuNavigationItem extends ANavigationItem {
  private _children: Array<ANavigationItem>;
  get children(): Array<ANavigationItem> { return this._children; };

  constructor (label: string, children: Array<ANavigationItem>, roles: UserRoles[] = []) {
    super(NavigationItemType.Submenu, label, roles);
    this._children = children;
  }
}

export interface NavigationRoot {
  label: string;
  route?: string;
  isParent?: boolean;
  child?: ANavigationItem[];
  roles?: UserRoles[];
}

