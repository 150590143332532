import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";
import { IReportRequest } from "../interfaces/billing.type";



export const BILLING_SERVICE: InjectionToken<IBillingService> = new InjectionToken<IBillingService>("Billing Service Implementation");

export interface IBillingService {
    requestDownload(request: IReportRequest): Observable<Blob | ArrayBuffer>;
    requestStatistics(): Observable<any>;
}
