import { Component, OnInit, Input, Inject } from '@angular/core';
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from '../../services/vehicle-details-service.service.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { RolesGuardHelper } from "../../../../shared/utils/roles-guard-helper";
import { IVehicle, IVehicleDamage } from '../../shared/interfaces/vehicles.type';
import { Subscription } from 'rxjs';
import { VehicleDialogsService } from '../../dialogs/vehicle-dialogs.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'; // qui sembra non servire, ma serve per la vehicle-damages-dialog

@Component({
  selector: 'vehicle-details-damages',
  templateUrl: './vehicle-details-damages.component.html',
  styleUrls: ['./vehicle-details-damages.component.scss']
})
export class VehicleDetailsDamagesComponent implements OnInit {
  @Input() vehicleId: Guid = null;
  vService: IVehicleDetailsService;
  vehicleDetails: IVehicle;
  vehicleDamages: IVehicleDamage[];
  typeValue: string = '';
  public customerOptions;
  public loading = false;
  damageInsertForm: FormGroup = this.fb.group({
    positionCode: [''],
    damageCode: [''],
    severityCode: [''],
    note: ['']
  });
  private subscriptions: Subscription[] = [];

  constructor(
    private dialogService: VehicleDialogsService,
    @Inject(VEHICLE_DETAILS_SERVICE) vService: IVehicleDetailsService,
    private fb: FormBuilder,
  ) {
    this.vService = vService;
  }

  ngOnInit(): void {
    if (this.vehicleId === null) return;
    this.fetchVehicleDetails();
    this.fetchVehicleDamages();
  }

  private fetchVehicleDetails(): void {
    this.loading = true;
    this.vService.getVehicleDetails(this.vehicleId.toString()).subscribe(
      response => {
        this.vehicleDetails = response;
      },
      error => { console.log(error) },
      () => {
        this.loading = false;
      });
  }

  private fetchVehicleDamages(): void {
    this.loading = true;
    this.vService.getVehicleDamages(this.vehicleId).subscribe(
      response => {
        this.vehicleDamages = response;
      },
      error => { console.log(error) },
      () => {
        this.loading = false;
      });
  }

  public updateTypeAutocompleteResults(term: string): void {
    if (!term) {
      this.typeValue = null;
      return;
    }
  }

  private initTypeControl(): void {
    this.damageInsertForm.controls['positionCode'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        this.typeValue = this.filterAgainstResults(this.customerOptions, res, this.typeValue);
        this.updateTypeAutocompleteResults(res);
      });

  }

  private filterAgainstResults(options: any, term: string, value: string): string {
    let result = null;
    if (!options) return value;
    options.forEach(
      (option) => {
        if (option.name === term) result = value;
      })
    return result;
  }

  public async saveDamage() {
    const positionCode = this.damageInsertForm.controls['positionCode'].value;
    const damageCode = this.damageInsertForm.controls['damageCode'].value;
    const severityCode = this.damageInsertForm.controls['severityCode'].value;
    const note = this.damageInsertForm.controls['note'].value;

    if (!positionCode || !damageCode) return;

    this.loading = true;

    try {
      await this.vService.addVehicleDamage(this.vehicleId.toString(), positionCode, damageCode, severityCode, note).toPromise();
    }
    catch (e) {
      this.loading = false;
    }
    finally {
      this.fetchVehicleDamages();
    }
  }

  public async removeDamage(damageId: Guid) {
    this.loading = true;
    try {
      await this.vService.removeVehicleDamage(damageId).toPromise();
    }
    catch (e) {
      this.loading = false;
    }
    finally {
      this.fetchVehicleDamages();
    }
  }

  public printDamages(): void {
    const args = { vehicle: this.vehicleDetails };
    this.subscriptions.push(
      this.dialogService
        .openVehicleDamagesDialog(args, 'vehicle-damages-dialog')
        .subscribe()
    );
  }

  get vehicleDamagesDetails(): string[] {
    return [
      'positionCode',
      'damageCode',
      'severityCode',
      'note',
      'isImported'
    ];
  }

  handleTypeAutocomplete($event: MatAutocompleteSelectedEvent) {

  }

  readonly RolesGuardHelper = RolesGuardHelper;
}
