import {Component, Inject, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ITerminalPosition} from "../../../service-area-mapping/types/service-area-mapping";
import {IVehicle, IVehicleSelectionListItem} from "../../../vehicles/shared/interfaces/vehicles.type";
import {IWorkOrdersService, WORK_ORDERS_SERVICE} from "../../shared/interfaces/work-orders.interface";
import {Subscription} from "rxjs";
import {SectorType} from "../../../service-area-mapping/shared/enums/sector-type";
import {FormBuilder, FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {
  IVehicleDetailsService,
  VEHICLE_DETAILS_SERVICE
} from "../../../vehicles/services/vehicle-details-service.service.interface";
import {EVehicleStatus} from "../../../vehicles/shared/enums/vehicles";
import {MatTable} from "@angular/material/table";
import {ActivatedRoute, Router} from "@angular/router";
import {WorkOrder, WorkOrderVehicle} from "../../shared/classes/work-order";
import * as moment from 'moment';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-work-orders-details',
  templateUrl: './work-orders-details.component.html',
  styleUrls: ['./work-orders-details.component.scss']
})
export class WorkOrdersDetailsComponent implements OnInit, OnDestroy {

  @ViewChildren(MatTable) matTables : QueryList<MatTable<any>>;
  public position: ITerminalPosition = {sector: null, lane: null};
  public vehicles: IVehicleSelectionListItem[] = [
    {
      type: 'input'
    }
  ];
  public vehicleSearchResults: IVehicle[] = [];
  public workOrderDetails: WorkOrder = null;
  public loading: boolean = false;
  public loadingSearch: boolean = false;
  public edit: boolean = true;
  public triedSave: boolean = false;
  public sectorType: SectorType = SectorType.Outbound;
  public vehicleSearch: FormControl = new FormControl('');
  private subscriptions: Subscription[] = [];
  private workOrderId: string = null;

  constructor(
    @Inject(WORK_ORDERS_SERVICE) private workOrdersService: IWorkOrdersService,
    @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route:ActivatedRoute) {
      if(!!route.snapshot.paramMap.get('id')) {
        this.workOrderId = route.snapshot.paramMap.get('id');
        if(!!this.workOrderId) this.edit = false;
      }
  }

  // -- Lifecycle Hooks

  ngOnInit(): void {
    this.initSearchControlDebounce();
    if (!!this.workOrderId) this.fetchWorkOrderDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // -- Computed Properties

  get vehicleDetails(): string[] {
    if (!this.edit) return ['status', 'label', 'details', 'color', 'position', 'destination_address', 'extra_data'];
    return ['status', 'label', 'details', 'color', 'position', 'destination_address', 'extra_data', 'actions'];
  }

  get vehicleListLength(): number {
    return this.vehicles.filter((listItem) => listItem.type === 'vehicle').length;
  }

  get vehicleSectionTitle(): string {
    if (this.vehicleListLength === 1) return '1 veicolo';
    return this.vehicleListLength + ' veicoli';
  }

  get title(): string {
    if (this.workOrderDetails) return moment(this.workOrderDetails.date).format('DD/MM/YYYY');
    return this.edit ? 'Nuovo ordine di uscita' : 'Dettagli ordine di uscita';
  }
  // -- Public Methods
  get shouldDisableSaveButton(): boolean {
    return !this.position.sector ||
      !this.position.lane ||
      this.vehicles.length === 1 ||
      !!this.vehicles.find((listItem) => listItem.type !== 'input' && listItem.vehicle.blockReasons?.length > 0);
  };

  public async save() {
    if (!this.position.sector || !this.position.lane || this.vehicleListLength === 0) {
      this.triedSave = true;
      return;
    }
    this.loading = true;
    try {
      const order: WorkOrder = await this.workOrdersService.createWorkOrder({
        sector: this.position.sector,
        lane: this.position.lane,
        ticked: false,
        vehicles: this.vehicles
          .filter((listItem) => listItem.type === 'vehicle')
          .map((listItem) => listItem.vehicle)
      }).toPromise();
      this.applyWorkOrderDetails(order);
      this.edit = false;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  public triggerPrint() {
    window.print();
  }

  public backAction() {
    this.router.navigate(["/ordini-di-uscita"]);
  }

  public handlePositionChange(position: any) {
    this.position = position;
  }

  private triggerSnackbar(message: string): void {
    this.snackBar.open(message, '', {
      panelClass: 'nes-snackbar-error',
      duration: 4000,
      horizontalPosition: 'right'
    });
  }

  private initSearchControlDebounce(disabled = false): void {
    this.vehicleSearch.valueChanges
      .pipe(
        debounceTime(600),
        distinctUntilChanged()
      )
      .subscribe(res => {
        if (!res) {
          this.vehicleSearchResults = [];
          return;
        }
        this.fetchSearchAutocompleteResults(res);
      });
  }

  public handleSearchAutocomplete(event): void {
    const foundValue = this.vehicleSearchResults.find((value) => value.id === event.option.value);
    if (foundValue) {
      this.vehicles.splice(this.vehicles.length - 1, 0,
        {
          vehicle: this.mapVehicleToWorkOrderVehicle(foundValue),
          type: 'vehicle'
        });
    }
    this.vehicleSearch.setValue('');
    this.matTables.toArray().forEach(each => each.renderRows());
  }

  public removeVehicle(vehicle: IVehicle): void {
    this.vehicles = this.vehicles.filter((listItem) => listItem.vehicle?.id !== vehicle.id.toString());
  }

  public fetchWorkOrderDetails(): void {
    this.loading = false;
    this.workOrdersService.getWorkOrder(this.workOrderId).subscribe((workOrder) => {
      this.applyWorkOrderDetails(workOrder);
    }, (error) => {}, () => {this.loading = false;})
  }

  public applyWorkOrderDetails(workOrder: WorkOrder): void {
    this.position = {
      sector: workOrder.sector,
      lane: workOrder.lane
    };
    this.vehicles = workOrder.vehicles.map((vehicle) => {
      return {
        vehicle: vehicle,
        type: 'vehicle'
      } as IVehicleSelectionListItem
    });
    this.workOrderDetails = workOrder;
  }

  public fetchSearchAutocompleteResults(term: string): void {
    this.loadingSearch = true;
    this.vService.getVehicles({
      text: term,
      pageNumber: 0
    }).subscribe((options) => {
      this.loadingSearch = false;
      this.vehicleSearchResults = options.rows
    })
  }

  public retrieveVehicleLabel(vehicle: IVehicle): string {
    if (!vehicle) return '';

    let result = [];
    if (vehicle.vin) result.push(vehicle.vin);
    if (vehicle.licensePlate) result.push(vehicle.licensePlate);
    return result.join(' - ');
  }

  public retrieveVehicleDetails(vehicle: WorkOrderVehicle): string {
    if (!vehicle) return '';

    let result = [];
    if (vehicle.manufacturer) result.push(vehicle.manufacturer);
    if (vehicle.model) result.push(vehicle.model);
    return result.join(' ');
  }

  public retrieveVehiclePosition(vehicle: IVehicle): string {
    if (!vehicle) return '';

    let result = [];
    if (vehicle.sector) result.push(vehicle.sector);
    if (vehicle.lane) result.push(vehicle.lane);
    return result.join(' - ');
  }

  public handlePaste($event: ClipboardEvent) {
    const clipboardData = $event.clipboardData.getData('text/plain');

    $event.stopPropagation();
    $event.preventDefault();
    const vins = clipboardData.split(/\r?\n|\r|\n/g).filter((vin) => vin.length > 0);

    if (vins.length > 0) {
      const foundError = vins.find((vin) => (vin.length !== 17 && vin.length !== 7));
      const foundErrors = vins.filter((vin) => (vin.length !== 17 && vin.length !== 7));
      foundErrors.forEach((vin) => {
        console.log(vin, vin.length);
      });
      if (foundError) {
        this.triggerSnackbar('Invalid VIN or license plate sequence pasted');
      }
      else {
        let count = 0;
        this.loading = true;
        const notFoundVins = [];

        vins.forEach((vin) => {
          count++;

          this.vService.getVehicles({
            text: vin,
            pageNumber: 0
          }).subscribe((result) => {
            if (!result?.rows.length) {
              notFoundVins.push(vin);
            } else {
              this.vehicles.splice(this.vehicles.length - 1, 0,
                {
                  vehicle: this.mapVehicleToWorkOrderVehicle(result.rows[0]),
                  type: 'vehicle'
                });
              this.matTables.toArray().forEach(each => each.renderRows());

            }

            if (count === vins.length) {
              this.loading = false;

              if (notFoundVins.length) {
                this.triggerSnackbar('Some VIN or license plate were not found: ' + notFoundVins.join(', '));
              }
            }

          })
        });
      }
    }
  }

  private mapVehicleToWorkOrderVehicle(vehicle: IVehicle): WorkOrderVehicle {
    return {
      id: vehicle.id.toString(),
      vin: vehicle.vin,
      blockReasons: vehicle.blockReasons,
      sector: vehicle.sector,
      lane: vehicle.lane,
      model: vehicle.model,
      manufacturer: vehicle.brand,
      color: vehicle.color,
      licensePlate: vehicle.licensePlate,
      destinationAddress: vehicle.destinationAddress,
      ddt: vehicle.ddt,
      ticked: vehicle.ticked,
      orderId: vehicle.orderId
    }
  }

  getVehicleBlockReasonsTooltip(blockReasons: string[]) {
    const phrase = "Veicolo bloccato con ";
    if (blockReasons.length === 1) {
      return phrase + 'il seguente codice: ' + blockReasons[0].toUpperCase();
    }
    return phrase + 'i seguenti codici: ' + blockReasons.map(reason => reason.toUpperCase()).join(', ');
  }
}
