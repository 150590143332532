import { Inject, Injectable } from '@angular/core';
import { PositionInputDialogComponent } from './position-input-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IServiceAreaPositionDetails, IPositionInputDialogData } from '../position';
import {SectorType} from "../shared/enums/sector-type";

@Injectable({
    providedIn: 'root'
})
export class PositionInputDialogService {

    defaultPositionDetails: IServiceAreaPositionDetails = {
        sector: null,
        lane: null
      }

    constructor(
        private dialog: MatDialog
    ) { }

    public open(
        position: IServiceAreaPositionDetails = this.defaultPositionDetails,
        showRows: boolean = false,
        title: string = 'Seleziona Posizione',
        sectorType: SectorType = SectorType.All,
        dialogClass: string = 'position-input-dialog',
        enableOccupation: boolean = false
    ): Observable<IServiceAreaPositionDetails> {

        let dialogArgs: IPositionInputDialogData = {
            showRows: showRows,
            position: position,
            title,
            sectorType,
            enableOccupation
        }

        return this
            .dialog
            .open(
                PositionInputDialogComponent,
                {
                    data: dialogArgs,
                    panelClass: dialogClass,
                    autoFocus: false
                }
            )
            .afterClosed();
    }
}
