import {ILaneDetails} from "../types/service-area-mapping";


export enum EMapColor {
  LabelRegular = '#fff',
  OutlineRegular = '#000',
  LanePending = '#b4b4b4',
  LaneEmpty = '#9EDC4A',
  LaneAvailable = '#E7D56C',
  LaneFull = '#E74E4E'
}

export enum EMapLabelTypes {
  Regular = "600 1rem 'Montserrat', Arial",
  Smaller = "600 0.9rem 'Montserrat', Arial"
}

export enum ELaneAvailability {
  Full = 'full',
  Available = 'available',
  Empty = 'empty'
}

export function mapLaneColor(availability: string): EMapColor {
  switch (availability) {
    case 'skipped':
      return EMapColor.LanePending;
    case 'pending':
      return EMapColor.LanePending;
    case 'empty':
      return EMapColor.LaneEmpty;
    case 'full':
      return EMapColor.LaneFull;
    case 'available':
      return EMapColor.LaneAvailable;
    default:
      return EMapColor.LanePending;
  }
}

export function mapLaneAvailability(occupation: ILaneDetails): ELaneAvailability {
  const max = occupation?.length || 0;
  if (!occupation?.vehicleCount || occupation.vehicleCount === 0 ) return ELaneAvailability.Empty;
  else if (occupation.vehicleCount < max) return ELaneAvailability.Available;
  else return ELaneAvailability.Full;
}
