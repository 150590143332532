import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';



import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';

import {ComponentModule} from '../../common/components/component.module';

import {RegistryRoutingModule} from './registry-routing.module';
import {RegistryListComponent} from './registry-list/registry-list.component';

import {NesModule} from '../../shared/nes.module';


import {RegistryDetailComponent} from './registry-detail/registry-detail.component';
import {RegistryDetailGeneralComponent} from './registry-detail/registry-detail-general/registry-detail-general.component';
import {RegistryDetailAddressComponent} from './registry-detail/registry-detail-address/registry-detail-address.component';
import {RegistryDetailNoteComponent} from './registry-detail/registry-detail-note/registry-detail-note.component';
import {RegistryDetailContactComponent} from './registry-detail/registry-detail-contact/registry-detail-contact.component';
import {RegistryListGenericComponent} from './common/components/registry-list-generic/registry-list-generic.component';
import {PriceListUiComponentsModule} from '@nes/price-list-ui-components';
import { RegistryDetailPriceListComponent } from './registry-detail/registry-detail-price-list/registry-detail-price-list.component';
import { RegistrySubjectSelectionInputComponent } from './common/components/registry-subject-selection-input/registry-subject-selection-input.component';
import { RegistrySubjectSelectionDialogComponent } from './common/components/registry-subject-selection-dialog/registry-subject-selection-dialog.component';
import { VehicleBrandsListComponent } from './lists/vehicle-brands-list/vehicle-brands-list.component';
import { VehicleModelsListComponent } from './lists/vehicle-models-list/vehicle-models-list.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { CustomersListComponent } from './lists/customers-list/customers-list.component';
import { ContractsListComponent } from './lists/contracts-list/contracts-list.component';
import { DestinationsListComponent } from './lists/destinations-list/destinations-list.component';
import { CarriersListComponent } from './lists/carriers-list/carriers-list.component';
import {
  CustomerSelectionInputComponent
} from "./common/components/customer-selection-input/customer-selection-input.component";
import {
  CustomerSelectionDialogComponent
} from "./common/components/customer-selection-dialog/customer-selection-dialog.component";
import {CustomerListGenericComponent} from "./common/components/customer-list-generic/customer-list-generic.component";

@NgModule({
  declarations: [
    RegistryDetailComponent,
    RegistryDetailGeneralComponent,
    RegistryDetailAddressComponent,
    RegistryDetailNoteComponent,
    RegistryDetailContactComponent,
    RegistryListComponent,
    RegistryListGenericComponent,
    RegistryDetailPriceListComponent,
    RegistrySubjectSelectionInputComponent,
    RegistrySubjectSelectionDialogComponent,
    VehicleBrandsListComponent,
    VehicleModelsListComponent,
    CustomersListComponent,
    ContractsListComponent,
    DestinationsListComponent,
    CarriersListComponent,
    CustomerSelectionInputComponent,
    CustomerSelectionDialogComponent,
    CustomerListGenericComponent
  ],
  exports: [
    RegistrySubjectSelectionInputComponent,
    CustomerSelectionInputComponent
  ],
  imports: [
    CommonModule,
    ComponentModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    NesModule,
    ReactiveFormsModule,
    RegistryRoutingModule,
    PriceListUiComponentsModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
    RegistryListGenericComponent,
    CustomerListGenericComponent
  ]
})
export class RegistryModule {
}
