import { Injectable } from '@angular/core';
import {IWorkOrdersService} from "../interfaces/work-orders.interface";
import {IWorkOrdersListFilters} from "../interfaces/work-orders-list-filters";
import {Observable} from "rxjs";
import {IPaginatedList} from "../../../../shared/interfaces/paginated-list.interface";
import {WorkOrder} from "../classes/work-order";
import {HttpClient, HttpParams} from "@angular/common/http";
import {VehiclesConfiguration} from "../../../vehicles/vehicles.configuration";
import {map} from "rxjs/operators";
import {PaginatedWorkOrdersDTO, WorkOrderDTO} from "../interfaces/work-order-dto.interface";
import {vehicleDdtToVehicle} from "../../../vehicles/shared/mappers/vehicle.mapper";

@Injectable({
  providedIn: 'root'
})
export class WorkOrdersService implements IWorkOrdersService {
  constructor(private http: HttpClient, private configuration: VehiclesConfiguration) {}

  public getWorkOrders(filters: IWorkOrdersListFilters): Observable<IPaginatedList<WorkOrder>> {
    const params: any = {
      PageNumber: filters.pageNumber + 1,
      PageSize: filters.pageSize | 10
    }
    return this
      .http
      .get<
        PaginatedWorkOrdersDTO
      >(
        this.configuration.service.baseUrl + 'orders',
        { params: new HttpParams({ fromObject: params }) }
      )
      .pipe(
        map(r => {
          const rows = r.rows.map((row) => {
            return this.mapWorkOrderDtoToLocalModel(row);
          });
          return {
            rows,
            totalRows: r.totalRowCount
          }
        })
      );
  }

  public createWorkOrder(workOrder: WorkOrder): Observable<WorkOrder> {
    const body = {
      sectorId: workOrder.sector,
      laneNumber: workOrder.lane,
      vehicleIds: workOrder.vehicles.map((vehicle) => vehicle.id)
    };
    return this
      .http
      .post<
        any
      >(
        this.configuration.service.baseUrl + 'orders',
        body
      )
      .pipe(
        map(response => {
          return this.mapWorkOrderDtoToLocalModel(response);
        })
      );
  }

  public getWorkOrder(id: string): Observable<WorkOrder> {
    return this
      .http
      .get<
        WorkOrderDTO
      >(
        this.configuration.service.baseUrl + 'orders/' + id
      )
      .pipe(
        map(row => {
          return this.mapWorkOrderDtoToLocalModel(row);
        })
      );
  }

  private mapWorkOrderDtoToLocalModel(workOrder: WorkOrderDTO): WorkOrder {
    return {
      id: workOrder.id,
      date: new Date(workOrder.createdAt),
      lane: workOrder.laneNumber,
      sector: workOrder.sectorId,
      // sectorId: workOrder.sector,,
      ticked: workOrder.ticked,
      vehicles: workOrder.vehicles.map((vehicle) => {
        const parsed = vehicleDdtToVehicle(vehicle);
        return {
          vin: parsed.vin,
          licensePlate: parsed.licensePlate,
          manufacturer: parsed.brand,
          model: parsed.model,
          color: parsed.color,
          sector: parsed.sector,
          lane: parsed.lane,
          destinationAddress: parsed.destinationAddress,
          ddt: parsed.ddt,
          ticked: parsed.ticked,
          orderId: parsed.orderId
        }
      })
    }
  }
}
