import { Component, Inject, OnInit } from '@angular/core';
import { IBillingService, BILLING_SERVICE } from '../../shared/services/billing.service.interface';



@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  public statistics = null;

  public contracts = [];
  public contractsCount = 0;

  public arrivedToday = [];
  public arrivedYesterday = [];
  public arrivedThisMonth = [];
  public arrivedLastMonth = [];
  public arrivedThisYear = [];
  public arrivedLastYear = [];

  public leftToday = [];
  public leftYesterday = [];
  public leftThisMonth = [];
  public leftLastMonth = [];
  public leftThisYear = [];
  public leftLastYear = [];



  constructor(@Inject(BILLING_SERVICE) private service: IBillingService) { }

  ngOnInit(): void {
    this.requestStatistics();
  }

  requestStatistics() {
    this.statistics = null;
    this.service.requestStatistics().subscribe(response => {
      this.statistics = response;

      this.contracts = Object.keys(response.arrivedToday);
      this.contractsCount = this.contracts.length;

      this.arrivedToday = Object.values(response.arrivedToday);
      this.arrivedYesterday = Object.values(response.arrivedYesterday);
      this.arrivedThisMonth = Object.values(response.arrivedThisMonth);
      this.arrivedLastMonth = Object.values(response.arrivedLastMonth);
      this.arrivedThisYear = Object.values(response.arrivedThisYear);
      this.arrivedLastYear = Object.values(response.arrivedLastYear);

      this.leftToday = Object.values(response.leftToday);
      this.leftYesterday = Object.values(response.leftYesterday);
      this.leftThisMonth = Object.values(response.leftThisMonth);
      this.leftLastMonth = Object.values(response.leftLastMonth);
      this.leftThisYear = Object.values(response.leftThisYear);
      this.leftLastYear = Object.values(response.leftLastYear);
    });
  }
}
