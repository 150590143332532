import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent implements OnInit {

  detailsMode: boolean = false;
  vehicleId: Guid = null;

  constructor(private route:ActivatedRoute) {
    if(!!route.snapshot.paramMap.get('id')){
      this.vehicleId = Guid.parse(route.snapshot.paramMap.get('id'));
      if(!!this.vehicleId) this.detailsMode = true;
    }
  }

  ngOnInit(): void {
  }

}
