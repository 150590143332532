import { Guid } from "guid-typescript";
import { InjectionToken } from "@angular/core";
import { Observable } from 'rxjs';
import {
  ILengthSegments, IModelSearchResult, IPartialVehicle,
  ISearchResult,
  IVehicle,
  IVehicleDamage,
  IVehicleBlockage,
  IVehicleBlockageType,
  IVehicleBrand,
  IVehicleColor,
  IVehicleDTO,
  IVehicleFilters,
  IVehicleModel, IVehicleModelsFilters, IVehicleMovement,
  IVehiclePaginatedList, IVehiclesOilReport,
  IOutgoingVehicle
} from "../shared/interfaces/vehicles.type";
import {IReportRequest} from "../../billing/shared/interfaces/billing.type";

export const VEHICLE_DETAILS_SERVICE: InjectionToken<IVehicleDetailsService> = new InjectionToken<IVehicleDetailsService>("Vehicle Details Service Implementation");

export interface IVehicleDetailsService {
  addVehicle(newVehicle: IPartialVehicle): Observable<IVehicleDTO>;
  addVehicleModel(newModel: IVehicleModel): Observable<Guid>;
  deleteVehicle(vehicleId: Guid): Observable<boolean>;
  removeArrive(vehicleId: Guid): Observable<boolean>;
  removeLocations(vehicleId: Guid): Observable<boolean>;
  addVehicleBrand(newBrand: IVehicleBrand): Observable<Guid>;
  getVehicleDamages(id: Guid): Observable<IVehicleDamage[]>;
  addVehicleDamage(vehicleId: String, positionCode: Number, damageCode: Number, severityCode: Number, note: String): Observable<IVehicleDamage>;
  removeVehicleDamage(damageId: Guid): Observable<IVehicleDamage>;
  addVehicleBlockage(vehicleId: String, reason: String): Observable<IVehicleBlockage>;
  closeVehicleBlockage(vehicleId: String, blockageId: String): Observable<IVehicleBlockage>;
  bulkAddVehicleBlockage(vehicleIds: String[], reason: String): Observable<null>;
  bulkCloseVehicleBlockage(vehicleIds: String[], reason: String): Observable<null>;
  editVehicle(vehicle: IPartialVehicle): Observable<boolean>;
  getVehicleBlockages(id: Guid): Observable<IVehicleBlockage[]>;
  editVehicleDestinationCode(vehicleId: string, destinationCode: string ): Observable<any>;
  searchCustomer(term: string): Observable<ISearchResult[]>;
  searchContract(term: string): Observable<ISearchResult[]>;
  searchBrand(term: string): Observable<ISearchResult[]>;
  searchBrandModel(brandId: string, term: string): Observable<ISearchResult[]>;
  uploadVehicles(file: File, contract: string, arrivedAt?: Date, arrivedBy?: string): Observable<any>;
  uploadDamages(file: File): Observable<any>;
  getVehicleColors(): Observable<IVehicleColor[]>;
  getLengthSegments(): Observable<ILengthSegments[]>;
  getVehicleBrands(): Observable<IVehicleBrand[]>;
  getVehicleModelsByBrandId(id: Guid): Observable<IVehicleModel[]>;
  getVehiclesOilReport(request: IReportRequest): Observable<IVehiclesOilReport>;
  isBrandExistent(name: string): Observable<boolean>;
  isVinRegistered(name: string, id: Guid): Observable<IVehicle>;
  getVehicles(filters: IVehicleFilters): Observable<IVehiclePaginatedList<IVehicle>>;
  getVehiclesCSV(filters: IVehicleFilters): Observable<string>;
  getVehiclesWithDetails(filters: IVehicleFilters): Observable<IVehiclePaginatedList<IVehicleDTO>>;
  getVehicleDetails(id: string): Observable<IVehicle>;
  getVehicleBrandsAndModels(): Observable<{brand:IVehicleBrand;models: IVehicleModel[]}[]>;
  getVehicleBlockages(id: Guid): Observable<IVehicleBlockage[]>;
  getVehicleBlockageDetails(id: Guid): Observable<IVehicleBlockage>
  getVehicleBlockageTypes(): Observable<IVehicleBlockageType[]>;
  getVehicleWithDetails(id: Guid): Observable<IVehicleDTO>;
  getVehicleModels(params: IVehicleModelsFilters): Observable<IModelSearchResult[]>;
  getVehicleMovements(id: string): Observable<IVehicleMovement[]>;
  getOutgoingVehiclesPage(filters: IVehicleFilters): Observable<IVehiclePaginatedList<IOutgoingVehicle>>;
  getOutgoingVehiclesList(): Observable<IOutgoingVehicle[]>;
}
