import { Polygon } from "./geometry";
import {IRectVertexGroup, IStructuredSector} from "../types/service-area-mapping";

export default class MapSector extends Polygon implements IStructuredSector {
  constructor(data: IStructuredSector) {
    const v = data.vertices;
    super([v.tl,v.tr,v.br,v.bl]);
    this.color = data.color;
    this.id = data.id;
    this.isOutboundSector = data.isOutboundSector;
    this.label = data.label;
    this.laneCount = data.laneCount;
    this.lanes = data.lanes;
    this.numerationType = data.numerationType;
    this.parentId = data.parentId;
    this.vertices = data.vertices;
    this.laneLength = data.laneLength;
    this.numerationStart = data.numerationStart;
    this.groupId = data.groupId;
    this.vehicleCount = data.vehicleCount;
    this.order = data.order;
    this.slotCount = data.slotCount;
  }
  slotCount: number;
  laneLength: number;
  numerationStart: number;
  color: string;
  groupId: string;
  id: string;
  isOutboundSector: boolean;
  order: number;
  label: string;
  laneCount: number;
  lanes: any;
  numerationType: string;
  parentId: any;
  vehicleCount: number;
  vertices: IRectVertexGroup;

  public hasBoundingBox() {
    let invalidVertices = 0;
    Object.keys(this.vertices).forEach((index) => {
      if (!this.vertices[index].isValid()) ++invalidVertices;
    })
    return invalidVertices === 0;
  }
}
