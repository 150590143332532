import {Injectable} from "@angular/core";
import {ApplicationConfiguration} from "./application.configuration";
import {ConfigurationSectionProvider} from "@nes/angular-configuration";
import {DossierConfiguration} from "../modules/dossiers/dossier.configuration";
import { VehiclesConfiguration } from "../modules/vehicles/vehicles.configuration";
import {AuthenticationConfiguration} from "../modules/authentication/authentication.configuration";

@Injectable({providedIn: 'root'})
export class AuthenticationConfigurationProvider implements ConfigurationSectionProvider<ApplicationConfiguration, AuthenticationConfiguration> {

  constructor(private applicationConfiguration: ApplicationConfiguration) {}

  get section(): AuthenticationConfiguration { return this.applicationConfiguration.authentication; }
}
