import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AUTHENTICATION_SERVICE} from "./shared/services/authentication.service.interface";
import {AuthenticationService} from "./shared/services/authentication.service";
import { AuthViewComponent } from './components/auth-view/auth-view.component';
import {AuthenticationRoutingModule} from "./authentication-routing.module";
import {NesCommonComponentsModule} from "@nes/nes-common-components";
import { LoginComponent } from './components/login/login.component';
import {NesModule} from "../../shared/nes.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import { UserPanelComponent } from './components/user-panel/user-panel.component';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {AuthInterceptor} from "./shared/interceptors/auth.interceptor";
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import {MatTooltipModule} from "@angular/material/tooltip";



@NgModule({
  declarations: [
    AuthViewComponent,
    LoginComponent,
    ResetPasswordComponent,
    UserPanelComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    NesCommonComponentsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    NesModule
  ],
  exports: [
    UserPanelComponent
  ]
})
export class AuthenticationModule {

  static forRoot(): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: AUTHENTICATION_SERVICE,
          useClass: AuthenticationService
        }
      ]
    };

  }
}
