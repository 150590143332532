import {IAuthenticationService} from "./authentication.service.interface";
import {AuthenticationConfiguration} from "../../authentication.configuration";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {IUserData} from "../interfaces/user-data.interface";
import {ILoginRequest} from "../interfaces/request/login-request.interface";
import {IResetPasswordRequest} from "../interfaces/request/reset-password-request.interface";
import {IChangePasswordRequest} from "../interfaces/request/change-password-request.interface";
import {IAutoLoginRequest} from "../interfaces/request/auto-login-request.interface";
import {ILoginResponse} from "../interfaces/response/login-response.interface";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements IAuthenticationService {
  constructor(
    private http: HttpClient,
    private authenticationConfiguration: AuthenticationConfiguration) {
  }
    getToken(): string {
      return localStorage.getItem("ACCESS_TOKEN");
    }
    getUserData(): IUserData {
      return JSON.parse(localStorage.getItem("USER_DATA"));
    }
    saveToken(token: string): void {
      localStorage.setItem("ACCESS_TOKEN", token);
    }
    saveUserData(userData: IUserData): void {
      localStorage.setItem("USER_DATA", JSON.stringify(userData));
    }
    removeToken(): void {
      localStorage.removeItem("ACCESS_TOKEN");
    }
    removeUserData(): void {
      localStorage.removeItem("USER_DATA");
    }
    isAuthenticated(): boolean {
        return !!this.getToken();
    }
    async login(request: ILoginRequest): Promise<any> {
      const url = this.authenticationConfiguration.service.baseUrl + 'login';
      try {
        const response: ILoginResponse = await this.http.post<ILoginResponse>(url, request).toPromise();
        this.saveLoginResponse(response);
      } catch (e) {
        throw e;
      }
    }
    async logout(): Promise<any> {
      const url = this.authenticationConfiguration.service.baseUrl + 'logout';
      try {
        await this.http.post(url, null).toPromise();
        this.removeToken();
        this.removeUserData();
      } catch (e) {
        console.error(e);
      }
    }
    async resetPassword(request: IResetPasswordRequest): Promise<any> {
      const url = this.authenticationConfiguration.service.baseUrl + 'reset-password';
      return await this.http.post(url, request).toPromise();
    }
    async changePassword(request: IChangePasswordRequest): Promise<any> {
      const url = this.authenticationConfiguration.service.baseUrl + 'change-password';
      return await this.http.post(url, request).toPromise();
    }
    async autoLogin(request: IAutoLoginRequest): Promise<any> {
      const url = this.authenticationConfiguration.service.baseUrl + 'auto-login';
      try {
        const response: ILoginResponse = await this.http.post<ILoginResponse>(url, request).toPromise();
        this.saveLoginResponse(response);
      } catch (e) {
        throw e;
      }
    }
    private saveLoginResponse(response: ILoginResponse): void {
      this.saveToken(response.token);
      this.saveUserData(response.user);
    }
}
