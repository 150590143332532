import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RegistryFilter, CompanyDTOResponse, IndividualDTOResponse, PublicAdministrationDTOResponse } from '../../interfaces/registry.type';
import { RegistryDatasource } from '../../datasources/registry.datasource';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegistryListGenericComponent } from '../registry-list-generic/registry-list-generic.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { merge } from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RegistryService} from "../../services/registry.service";
import {
  IVehicleDetailsService,
  VEHICLE_DETAILS_SERVICE
} from "../../../../vehicles/services/vehicle-details-service.service.interface";
import {CustomerListGenericComponent} from "../customer-list-generic/customer-list-generic.component";
import {IDdtCustomer} from "../../../../dossiers/shared/interfaces/ddt";

@Component({
  selector: 'app-customer-selection-dialog',
  templateUrl: './customer-selection-dialog.component.html',
  styleUrls: ['./customer-selection-dialog.component.scss']
})
export class CustomerSelectionDialogComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) matSort: MatSort;

  // --


  public tableFilter: any;

  public registryFilter: RegistryFilter;
  public registryDatasource: RegistryDatasource;

  // --

  customers: IDdtCustomer[] = [];
  private registry: any = {};

  private text = '';
  private displayMode: boolean;

  private target: IDdtCustomer;

  constructor(public dialogRef: MatDialogRef<CustomerListGenericComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService) {
  }

  ngOnInit(): void {
    this.initPage();
  }

  // --

  get service(): IVehicleDetailsService {
    return this.vService;
  }

  get title(): string {
    if (this.data && this.data.title) {
      return this.data.title;
    }
    return '';
  }

  get breadcrumbs(): string[] {
    if (this.data && this.data.breadcrumbs) {
      return this.data.breadcrumbs;
    }
    return ['Home', 'Anagrafica'];
  }

  // --

  get customerColumns(): string[] {
    return ['name'];
  }

  get hasTarget(): boolean {
    if (this.target) {
      return true;
    }

    return false;
  }

  // --

  public applyFilterCheckbox(): void {
    this.applyFilter(this.text);
  }

  public applyFilter(event: string): void {
    this.text = event;
    this.fetchCustomer();
  }

  public selectCustomer($event: Event, target: IDdtCustomer): void {
    $event.stopPropagation();
    this.target = target;
  }

  public hasToHighlight(target: IDdtCustomer): boolean {
    if (this.target && this.target.id === target.id) {
      return true;
    }

    return false;
  }

  public activateCancel(): void {
    this.dialogRef.close();
  }

  public activateConfirm(): void {
    if (this.target) {
      this.dialogRef.close(this.target);
    }
  }

  // --

  private initPage(): void {
    this.fetchCustomer();
  }

  // --

  private async fetchCustomer(): Promise<void> {
    this.customers = await this.vService.searchCustomer(this.text).pipe(map(results => {
      return results.map(res => {
        return {
          id: res.id,
          value: res.name
        } as IDdtCustomer
      });
    })).toPromise();
  }
}
