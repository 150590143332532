import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
  IVehicleDetailsService,
  VEHICLE_DETAILS_SERVICE
} from "../../../vehicles/services/vehicle-details-service.service.interface";
import {ISearchResult, IVehicleBrand} from "../../../vehicles/shared/interfaces/vehicles.type";

@Component({
  selector: 'vehicle-brands-list',
  templateUrl: './vehicle-brands-list.component.html',
  styleUrls: ['./vehicle-brands-list.component.scss']
})
export class VehicleBrandsListComponent implements OnInit, OnDestroy {
  public loading = false;
  public firstLoad = true;
  public shouldUpdate = false;
  public tableFilter: any;
  brands: ISearchResult[];
  vehicleBrandsAndModels: any;
  totalVehicles: number = 0;
  private subscriptions: Subscription[] = [];

  constructor(private router: Router,
              @Inject(VEHICLE_DETAILS_SERVICE) private vService: IVehicleDetailsService) { }

  ngOnInit(): void {
    this.fetchBrandsList()
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }


  get tableColumns(): string[] {
    return ['label'];
  }

  private checkQueuedRequests(): boolean {
    if (this.loading) {
      this.shouldUpdate = true;
    }

    return this.loading;
  }

  private getLabel(brand: ISearchResult): string {
    return brand.name || "N/A";
  }

  private fetchBrandsList(): void {
    if (this.checkQueuedRequests()) return;
    this.loading = true;
    this.subscriptions.push(
      this.vService
        .searchBrand("")
        .subscribe(res => {
            if (this.shouldUpdate) {
              this.shouldUpdate = false;
              this.loading = false;
              this.fetchBrandsList();
              return;
            }
            else {
              this.brands = res;
              this.totalVehicles = res.length;
              this.shouldUpdate = true;
            }
            this.firstLoad = false;
          },
          err => {
            console.log(err)
          },
          () => {
            this.loading = false;
          }
        ));
  }
}
