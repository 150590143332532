import { Component, OnInit, Input, Inject, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VEHICLE_DETAILS_SERVICE, IVehicleDetailsService } from '../../services/vehicle-details-service.service.interface';
import { INesPortletTag } from '@nes/nes-common-components';
import { MatDialog } from '@angular/material/dialog';
import { VehicleModelDialogComponent, IVehicleModelDialogExitData } from '../../dialogs/vehicle-model-dialog/vehicle-model-dialog.component';
import { IVehicleModelDialogData } from '../../dialogs/vehicle-model-dialog/vehicle-model-dialog.component';
import { Guid } from 'guid-typescript';
import { VehicleBrandDialogComponent } from '../../dialogs/vehicle-brand-dialog/vehicle-brand-dialog.component';
import { VehicleValidators } from '../../validators/vehicle-validators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VehicleVinTakenDialogComponent } from '../../dialogs/vehicle-vin-taken-dialog/vehicle-vin-taken-dialog.component';
import { of, Subscription } from 'rxjs';
import {
  ILengthSegments,
  IPartialVehicle,
  ISearchResult,
  IVehicle,
  IVehicleBrand,
  IVehicleColor,
  IVehicleDTO,
  IVehicleModel,
  IVehicleMovement
} from '../../shared/interfaces/vehicles.type';
import {catchError, debounceTime, distinctUntilChanged} from "rxjs/operators";
import {getVehicleStatusLabel} from "../../shared/enums/vehicles";
import {VehicleDialogsService} from "../../dialogs/vehicle-dialogs.service";
import {
  ILocalDataStorageService,
  LOCAL_DATA_STORAGE_SERVICE
} from "../../../../shared/services/local-data-storage.service.interface";
import * as moment from "moment";
import {RolesGuardHelper} from "../../../../shared/utils/roles-guard-helper";

@Component({
  selector: 'vehicle-details-general',
  templateUrl: './vehicle-details-general.component.html',
  styleUrls: ['./vehicle-details-general.component.scss']
})
export class VehicleDetailsGeneralComponent implements OnInit, OnDestroy {
  @Input() vehicleId: Guid = null;
  @Input() detailMode: boolean = false;
  @Input() formOnly: boolean = false;
  @Input() externalControls: boolean = false;
  @Output() onSaveResult: EventEmitter<IVehicleDTO> = new EventEmitter<IVehicleDTO>();
  vehicleDetailsForm: FormGroup;
  vehicleColors: IVehicleColor[];
  lengthSegments: ILengthSegments[];
  vehicleBrands: IVehicleBrand[];
  vehicleModels: IVehicleModel[] = [];
  dateNow: Date = new Date();
  vehicleLabel: string;
  ddtId: string;
  editable: boolean;
  editingDestinationCode: boolean = false;
  backupDestinationCode: string;
  public brandOptions;
  public brandValue;
  public modelOptions;
  public modelValue;
  public customerOptions;
  tags: INesPortletTag[] = [
    /* {
      body: 'BLOCCATA',
      type: 'warn'
    } */
  ]
  public customerValue;
  public isAtLeastOneIdentifyingFieldPresent: any = null;
  private subscriptions: Subscription[] = [];
  private filtersKey = 'vehicles-list-filters';
  public loading = false;

  get idString() {
    return this.vehicleId?.toString() || '';
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    @Inject(VEHICLE_DETAILS_SERVICE) private service: IVehicleDetailsService,
    private dialog: MatDialog,
    private dialogService: VehicleDialogsService,
    @Inject(LOCAL_DATA_STORAGE_SERVICE) private localStorageService: ILocalDataStorageService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    })
  }

  ngOnInit(): void {
    this.vehicleDetailsForm = this.fb.group({
      vin:            ['', [Validators.minLength(17), Validators.maxLength(17)]],
      licensePlate:   ['', [Validators.minLength(6)]],
      creationDate:   [{value: new Date(), disabled: true}],
      arrivedAt:      [{value: null, disabled: true}],
      leftAt:         [{value: null, disabled: true}],
      arrivedBy:      [{value: null, disabled: true}],
      leftBy:         [{value: null, disabled: true}],
      destinationCode:[''],
      color:          [{value: '', disabled: true}],
      ddt:            [{value: '', disabled: true}],
      position:       [{value: '', disabled: true}],
      status:         [{value: null, disabled: true}],
      customer:       ["", [Validators.required]],
      brand:          ["", [Validators.required]],
      model:          [{value: ""}, [Validators.required]],
      length:         [{value: null, disabled: true}],
      destinationAddress: [{value: '', disabled: true}],
    });

    this.initCustomerControl();
    this.initBrandControl();
    this.initModelControl(true);
    this.getVehicleData();
  }

  async getVehicleData(): Promise<void> {
    if (!!this.detailMode && !!this.vehicleId) {
        const response: IVehicle = await this.service.getVehicleDetails(this.vehicleId.toString()).toPromise();
        if(response !== null){
          if (!!response.ddt) this.ddtId = response.ddt.id;
          this.vehicleDetailsForm = this.fb.group({
            vin:            [response.vin, [Validators.minLength(17), Validators.maxLength(17)]],
            licensePlate:   [response.licensePlate, [Validators.minLength(6)]],
            creationDate:   [response.date],
            arrivedAt:      [{value: response.arrivedAt, disabled: true}],
            arrivedBy:      [{value: response.arrivedBy, disabled: true}],
            leftAt:         [{value: response.leftAt, disabled: true}],
            leftBy:         [{value: response.leftBy, disabled: true}],
            destinationCode:[{value: response.destinationCode, disabled: true}],
            color:          [{value: response.color || '', disabled: true}],
            ddt:            [{value: this.generateDdtLabelresponse(response.ddt) || '', disabled: true}],
            brand:          [response.brand, [Validators.required]],
            position:       [{value: this.generatePositionString(response), disabled: true}],
            model:          [response.model, [Validators.required]],
            length:         [{value: '', disabled: true}],
            status:         [{value: getVehicleStatusLabel(response.status), disabled: true}],
            customer:       [response.customer.split("\n")[0].trim(), [Validators.required]],
            destinationAddress: [{value: response.destinationAddress || '', disabled: true}],
          });
          this.vehicleLabel = this.getVehicleLabel(response);
          setTimeout(()=> this.toggleGeneral(false));
        }
    }
  }

  getVehicleLabel(vehicle: { vin?: string, licensePlate: string }): string {
    const parts = [];
    if (vehicle.vin) parts.push(vehicle.vin);
    if (vehicle.licensePlate) parts.push(vehicle.licensePlate);
    return parts.join(' - ');
  }

  toggleGeneral(status: boolean){
    if (status === true) {
      Object.keys(this.vehicleDetailsForm.controls).forEach(field => {
        const control = this.vehicleDetailsForm.get(field);
        if(!['creationDate', 'length'].includes(field)){
          control.enable();
        }
      });
    } else if(status === false) {
      this.vehicleDetailsForm.disable();
    }
    this.editable = status;
  }

  backAction() {
    this.localStorageService.setUseLocalData(this.filtersKey, true);
    this.router.navigate(["/veicoli"]);
  }

  async deleteVehicle() {
    const formData = this.vehicleDetailsForm.getRawValue();
    const args = {
      title: "Elimina veicolo",
      message: "Confermi l'eliminazione del veicolo " + (formData.vin ?? "") + (formData.licensePlate ?? "") + "?",
      action: async () => {
        await this.service.deleteVehicle(this.vehicleId).toPromise();
        this.triggerSnackbar('Veicolo eliminato');
        this.localStorageService.setUseLocalData(this.filtersKey, true);
        this.router.navigate(["/veicoli"]);
      }
    };
    this.subscriptions.push(
      this.dialogService
        .openConfirmationDialog(args, 'confirmation-dialog')
        .subscribe()
    );
  }

  async removeArrive() {
    const formData = this.vehicleDetailsForm.getRawValue();
    const args = {
      title: "Annulla ingresso",
      message: "Confermi l'annullamento dell'ingresso del veicolo " + (formData.vin ?? "") + (formData.licensePlate ?? "") + "? ATTENZIONE: la data e il mezzo di ingresso verrà cancellato in modo permanente.",
      action: async () => {
        await this.service.removeArrive(this.vehicleId).toPromise();
        this.triggerSnackbar('Ingresso annullato');
        this.localStorageService.setUseLocalData(this.filtersKey, true);
        this.router.navigate(["/veicoli"]);
      }
    };
    this.subscriptions.push(
      this.dialogService
        .openConfirmationDialog(args, 'confirmation-dialog')
        .subscribe()
    );
  }

  async removeLocations() {
    const formData = this.vehicleDetailsForm.getRawValue();
    const args = {
      title: "Rimuovi dal piazzale",
      message: "Confermi la rimozione dal piazzale del veicolo " + (formData.vin ?? "") + (formData.licensePlate ?? "") + "? ATTENZIONE: l'attuale posizione e l'intero storico delle posizioni verrà cancellato in modo permanente.",
      action: async () => {
        await this.service.removeLocations(this.vehicleId).toPromise();
        this.triggerSnackbar('Veicolo rimosso dal piazzale');
        this.localStorageService.setUseLocalData(this.filtersKey, true);
        this.router.navigate(["/veicoli"]);
      }
    };
    this.subscriptions.push(
      this.dialogService
        .openConfirmationDialog(args, 'confirmation-dialog')
        .subscribe()
    );
  }

  triggerSnackbar(message: string) {
    this.snackBar.open(message, '', {
      panelClass: 'nes-snackbar',
      duration: 4000,
      horizontalPosition: 'right'
    });
  }

  async saveVehicle(): Promise<boolean> {
    this.findAtLeastOneField(false);
    const formValid = this.vehicleDetailsForm.get('vin').valid && this.vehicleDetailsForm.get('licensePlate').valid;
    const hasAutocompletedValues = !!this.brandValue && !!this.modelValue && !!this.customerValue;
    if(formValid && !!this.isAtLeastOneIdentifyingFieldPresent && !!hasAutocompletedValues) {
      try {
        this.loading = true;
        const formData = this.vehicleDetailsForm.getRawValue();
        const vehicleObject: IPartialVehicle = {
          id: null,
          vin: formData.vin.length > 0 ? formData.vin : null,
          licensePlate: formData.licensePlate.length > 0 ? formData.licensePlate : null,
          date: formData.creationDate,
          brand: this.brandValue,
          model: this.modelValue,
          destinationCode: formData.destinationCode,
          customer: this.customerValue
        }
        if(!!this.detailMode && !!this.vehicleId) {
          vehicleObject.id = this.vehicleId;
          const success: boolean = await this.service.editVehicle(vehicleObject).toPromise();
          if(!!success){
            await this.getVehicleData();
            this.triggerSnackbar('Veicolo modificato');
          }
        } else {
          const newVehicle: IVehicleDTO = await this.service.addVehicle(vehicleObject).toPromise();
          this.triggerSnackbar('Veicolo salvato');
          if(!this.externalControls){
            this.router.navigate(['/veicoli/dettaglio/', newVehicle.id.toString()]);
          }else{
            this.onSaveResult.emit(newVehicle);
          }
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    } else {
      Object.keys(this.vehicleDetailsForm.controls).forEach(field => {
        const control = this.vehicleDetailsForm.get(field);
        control.markAsTouched({ onlySelf: false });
        control.markAsDirty();
      });
    }
    this.findAtLeastOneField(false);
    return false;
  }

  generateDdtLabelresponse(ddt: { id: string, number: number, date: Date }): string {
     if (!ddt || !ddt.number || !ddt.date) return '';
     const parts = [];
     parts.push(moment(ddt.date).format('MM'));
     parts.push(ddt.number);
     return parts.join(' / ');
  }

  checkForVin(): void{
    this.findAtLeastOneField();
    this.subscriptions.push(

      of(null).subscribe(result=>{
        if(result !== null && !!result.id){
          const dialog = this.dialog.open(VehicleVinTakenDialogComponent, {
            data: {
              vehicleId: result.id,
              vin: this.vehicleDetailsForm.get('vin').value
            },
            panelClass: 'vehicle-vin-taken-dialog',
            autoFocus: false
          });
        }
      })
    );
  }

  findAtLeastOneField(touchedFilter: boolean = true): void {
    const formData = this.vehicleDetailsForm.getRawValue();
    if(!touchedFilter || ( this.vehicleDetailsForm.get('vin').touched || this.vehicleDetailsForm.get('licensePlate').touched )) {
      this.isAtLeastOneIdentifyingFieldPresent = !!formData.vin || !!formData.licensePlate;
    }
  }

  get isCustomerFieldRequired(): boolean{
    let customerField = this.vehicleDetailsForm.controls['customer'];

    return customerField.touched && customerField.hasError('required');
  }

  get isCustomerFieldValid(): boolean{
    let customerField = this.vehicleDetailsForm.controls['customer'];
    return !customerField.touched || !!this.customerValue;
  }

  get isModelFieldRequired(): boolean{
    let modelField = this.vehicleDetailsForm.controls['model'];

    return modelField.touched && modelField.hasError('required');
  }

  get isModelFieldValid(): boolean{
    let modelField = this.vehicleDetailsForm.controls['model'];
    return !modelField.touched || !!this.modelValue;
  }

  get isBrandFieldRequired(): boolean{
    let brandField = this.vehicleDetailsForm.controls['brand'];
    return brandField.touched && brandField.hasError('required');
  }

  get isBrandFieldValid(): boolean{
    let brandField = this.vehicleDetailsForm.controls['brand'];
    return !brandField.touched || !!this.brandValue;
  }

  public isArrived(): boolean {
    const location = this.vehicleDetailsForm.get('position').value;
    const arrivedAt = this.vehicleDetailsForm.get('arrivedAt').value;
    const leftAt = this.vehicleDetailsForm.get('leftAt').value;
    return !location && !!arrivedAt && !leftAt;
  }

  public hasLocation(): boolean {
    const location = this.vehicleDetailsForm.get('position').value;
    return !!location;
  }



  private initCustomerControl(): void {
    this.vehicleDetailsForm.controls['customer'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        this.customerValue = this.filterAgainstResults(this.customerOptions, res, this.customerValue);
        this.updateCustomerAutocompleteResults(res);
      });

  }

  private initBrandControl(): void {
    this.vehicleDetailsForm.controls['brand'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
          this.brandValue = this.filterAgainstResults(this.brandOptions, res, this.brandValue);
          this.updateBrandAutocompleteResults(res);
        }
      );
  }

  private initModelControl(disabled = false): void {
    this.vehicleDetailsForm.setControl('model', this.fb.control({value: "", disabled}, [Validators.required]));
    this.modelValue = '';
    this.vehicleDetailsForm.controls['model'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        this.modelValue = this.filterAgainstResults(this.modelOptions, res, this.modelValue);
        this.updateModelAutocompleteResults(res);
      });
  }

  private filterAgainstResults(options: any, term: string, value: string): string {
    let result = null;
    if (!options) return value;
    options.forEach(
      (option) => {
        if (option.name === term) result = value;
      })
    return result;
  }

  public handleBrandAutocomplete(event): void {
    const foundValue = this.brandOptions.find((value) => value.name === event.option.value);
    if (foundValue) {
      this.initModelControl();
      this.brandValue = foundValue.id;
    }
  }

  public handleModelAutocomplete(event): void {
    const foundValue = this.modelOptions.find((value) => value.name === event.option.value);
    if (foundValue) this.modelValue = foundValue.id;
  }

  public handleCustomerAutocomplete(event): void {
    const foundValue = this.customerOptions.find((value) => value.name === event.option.value);
    this.vehicleDetailsForm.controls['customer'].setValue(event.option.value);
    if (foundValue) this.customerValue = foundValue.id;
  }

  public updateBrandAutocompleteResults(term: string): void {
    if (!term) {
      this.brandValue = null;
      return;
    }
    this.service.searchBrand(term).subscribe((options) => this.brandOptions = options)
  }

  public updateModelAutocompleteResults(term: string): void {
    if (!term) {
      this.modelValue = null;
      return;
    }
    this.service.searchBrandModel(this.brandValue, term).subscribe((options) => this.modelOptions = options)
  }

  public updateCustomerAutocompleteResults(term: string): void {
    if (!term) {
      this.customerValue = null;
      return;
    }
    this.service.searchCustomer(term).subscribe((options) => this.customerOptions = options.map((option) => {
      return {
        id: option.id,
        name: option.name.split("\n")[0].trim(),
      } as ISearchResult;
    }));

  }

  private generatePositionString(vehicle: IVehicle): string {
    const result = [];
    if (vehicle.sector) result.push(vehicle.sector);
    if (vehicle.lane) result.push(vehicle.lane);
    return result.join(' - ');
  }

  public openPositionsHistoryDialog(): void {
    let args = {
      vehicleId: this.idString,
    }
    this.subscriptions.push(
      this.dialogService
        .openVehiclePositionsHistoryDialog(args, 'vehicle-positions-history-dialog')
        .subscribe()
    );
  }

  public setDestinationCodeEditable(): void {
    this.editingDestinationCode = true;
    this.vehicleDetailsForm.get('destinationCode').enable();
    this.backupDestinationCode = this.vehicleDetailsForm.get('destinationCode').value;
  }

  public cancelDestinationCodeEdit(): void {
    this.editingDestinationCode = false;
    this.vehicleDetailsForm.get('destinationCode').disable();
    this.vehicleDetailsForm.get('destinationCode').setValue(this.backupDestinationCode);
  }

  public saveDestinationCode(): void {
    const snack = (message) => this.snackBar.open(message, '', {
      panelClass: 'nes-snackbar',
      duration: 4000,
      horizontalPosition: 'right'
    });
    this.editingDestinationCode = false;
    this.vehicleDetailsForm.get('destinationCode').disable();
    this.service.editVehicleDestinationCode(this.idString, this.vehicleDetailsForm.get('destinationCode').value)
      .subscribe((res) => {
        snack('Codice destinazione modificato');
      },
      (err) => {
        this.vehicleDetailsForm.get('destinationCode').setValue(this.backupDestinationCode);
      });
  }

  protected readonly RolesGuardHelper = RolesGuardHelper;
}
