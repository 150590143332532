import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RegistryFilter, CompanyDTOResponse, IndividualDTOResponse, PublicAdministrationDTOResponse } from '../../interfaces/registry.type';
import { RegistryDatasource } from '../../datasources/registry.datasource';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegistryListGenericComponent } from '../registry-list-generic/registry-list-generic.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistryService } from '../../services/registry.service';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Utils } from '../../classes/utils';

@Component({
  selector: 'app-registry-subject-selection-dialog',
  templateUrl: './registry-subject-selection-dialog.component.html',
  styleUrls: ['./registry-subject-selection-dialog.component.scss']
})
export class RegistrySubjectSelectionDialogComponent implements OnInit {

  @ViewChild(MatPaginator, {static: false}) matPaginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) matSort: MatSort;

  // --


  public tableFilter: any;

  public registryFilter: RegistryFilter;
  public registryDatasource: RegistryDatasource;

  // --

  private registry: any = {};

  private text = '';
  private displayMode: boolean;

  private target: CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse;

  constructor(public dialogRef: MatDialogRef<RegistryListGenericComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private snackBar: MatSnackBar, private registryService: RegistryService) {
  }

  ngOnInit(): void {
    this.initPage();
  }

  ngAfterViewInit(): void {

    // reset the paginator after sorting
    this.matSort.sortChange.subscribe((e) => {
      this.matPaginator.pageIndex = 0;
      Object.assign(this.registryFilter, {sort: e.active, direction: e.direction, pageNumber: 1});
    });

    this.matPaginator.page.subscribe((e) => {
      Object.assign(this.registryFilter, {
        pageNumber: this.matPaginator.pageIndex + 1,
        pageSize: this.matPaginator.pageSize
      });
    });

    // on sort or paginate events, load a new page
    merge(this.matSort.sortChange, this.matPaginator.page)
      .pipe(tap(() => this.fetchRegistry()))
      .subscribe();

  }

  // --

  get service(): RegistryService {
    return this.registryService;
  }

  get title(): string {
    if (this.data && this.data.title) {
      return this.data.title;
    }
    return '';
  }

  get breadcrumbs(): string[] {
    if (this.data && this.data.breadcrumbs) {
      return this.data.breadcrumbs;
    }
    return ['Home', 'Anagrafica'];
  }

  // --

  get defaultEntityFilter(): any {
    return {
      company: false,
      individual: false,
      publicAdministration: false,
    };
  }

  get defaultFilter(): RegistryFilter {
    return {
      text: '',
      sort: 'date',
      direction: 'desc',
      pageNumber: 1,
      pageSize: 10
    };
  }

  get registryColumns(): string[] {
    return ['name', 'type', 'headquarter', 'indicator'];
  }


  get isPrivateCompany(): boolean {
    return Utils.identifyPrivateCompany(this.registry);
  }

  get isIndividual(): boolean {
    return Utils.identifyIndividual(this.registry);
  }

  get isPublicCompany(): boolean {
    return Utils.identifyPublicCompany(this.registry);
  }

  get hasTarget(): boolean {
    if (this.target) {
      return true;
    }

    return false;
  }

  // --

  public applyFilterCheckbox(): void {
    this.applyFilter(this.text);
  }

  public applyFilter(event: string): void {
    this.matPaginator.pageIndex = 0;

    this.text = event;
    Object.assign(this.registryFilter, {
      text: event,
      pageNumber: this.matPaginator.pageIndex + 1,
      pageSize: this.matPaginator.pageSize
    });
    this.fetchRegistry();
  }

  public selectRegistry($event: Event, target: CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse): void {
    $event.stopPropagation();
    this.target = target;
  }

  public exposeType(type: string): string {
    return Utils.decodeSubjectType(type);
  }

  public exposePlace(city, province): string {
    return Utils.decodeSubjectPlace(city, province);
  }

  public hasToHighlight(target: CompanyDTOResponse | IndividualDTOResponse | PublicAdministrationDTOResponse): boolean {
    if (this.target && this.target.id === target.id) {
      return true;
    }

    return false;
  }

  public activateCancel(): void {
    this.dialogRef.close();
  }

  public activateConfirm(): void {
    if (this.target) {
      this.dialogRef.close(this.target);
    }
  }

  // --

  private initPage(): void {
    this.displayMode = true;

    this.tableFilter = Object.assign({}, this.defaultEntityFilter);
    this.registryFilter = Object.assign({}, this.defaultFilter);

    this.registryDatasource = new RegistryDatasource(this.registryService);
    this.registryDatasource.fetchRegistry(this.registryFilter);

  }

  defaultCompanyEntry(arg0: {}, defaultCompanyEntry: any): any {
  }

  // --

  private fetchRegistry(): void {
    this.registryDatasource.fetchRegistry(Object.assign({}, this.registryFilter, this.tableFilter));
  }
}
