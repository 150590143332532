import { Component, OnInit, Input, Inject } from '@angular/core';
import { IVehicleDetailsService, VEHICLE_DETAILS_SERVICE } from '../../services/vehicle-details-service.service.interface';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Guid } from 'guid-typescript';
import {ISearchResult, IVehicleBlockage, IVehicleBlockageType} from '../../shared/interfaces/vehicles.type';
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {catchError, debounceTime, distinctUntilChanged} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {RolesGuardHelper} from "../../../../shared/utils/roles-guard-helper";

@Component({
  selector: 'vehicle-details-blockages',
  templateUrl: './vehicle-details-blockages.component.html',
  styleUrls: ['./vehicle-details-blockages.component.scss']
})
export class VehicleDetailsBlockagesComponent implements OnInit {

  @Input() vehicleId: Guid = null;
  vService: IVehicleDetailsService;
  vehicleBlockages: IVehicleBlockage[];
  vehicleBlockageTypes: IVehicleBlockageType[];
  typeValue: string = '';
  public customerOptions;
  public loading = false;
  blockageInsetForm: FormGroup = this.fb.group({
    type: [''],
  });


  constructor(
    @Inject(VEHICLE_DETAILS_SERVICE) vService: IVehicleDetailsService,
    private fb: FormBuilder,
    ){
      this.vService = vService;
    }

  ngOnInit(): void {
    if(this.vehicleId === null) return;
    this.fetchVehicleBlockages();
  }

  private fetchVehicleBlockages(): void{
    this.loading = true;
    this.vService.getVehicleBlockages(this.vehicleId).subscribe(
      response => {
      this.vehicleBlockages = response;
      },
      error => {console.log(error)},
      () => {
        this.loading = false;
    });
  }

  public updateTypeAutocompleteResults(term: string): void {
    if (!term) {
      this.typeValue = null;
      return;
    }
    // this.vService.searchCustomer(term).subscribe((options) => this.customerOptions = options.map((option) => {
    //   return {
    //     id: option.id,
    //     name: option.name.split("\n")[0].trim(),
    //   } as ISearchResult;
    // }));
  }

  private initTypeControl(): void {
    this.blockageInsetForm.controls['type'].valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(res => {
        this.typeValue= this.filterAgainstResults(this.customerOptions, res, this.typeValue);
        this.updateTypeAutocompleteResults(res);
      });

  }

  private filterAgainstResults(options: any, term: string, value: string): string {
    let result = null;
    if (!options) return value;
    options.forEach(
      (option) => {
        if (option.name === term) result = value;
      })
    return result;
  }

  public isRowInactive(row: IVehicleBlockage): boolean{
    return !!row.to ? (row.to.getTime() - Date.now() < 0) : false;
  }

  public async saveBlockage(){
    const reason = this.blockageInsetForm.controls['type'].value;
    if (!reason) return;
    this.loading = true;
    try {
      await this.vService.addVehicleBlockage(this.vehicleId.toString(), reason).toPromise();
    }
    catch (e) {
      this.loading = false;
    }
    finally {
      this.fetchVehicleBlockages();
    }
  }

  public async closeBlockage(blockageId: string){
    this.loading = true;
    try {
      await this.vService.closeVehicleBlockage(this.vehicleId.toString(), blockageId).toPromise();
    }
    catch (e) {
      this.loading = false;
    }
    finally {
      this.fetchVehicleBlockages();
    }
  }

  get vehicleBlockagesDetails(): string[]{
    return [
      'type',
      'startDate',
      'endDate'
    ];
  }

  handleTypeAutocomplete($event: MatAutocompleteSelectedEvent) {

  }

  readonly RolesGuardHelper = RolesGuardHelper;
}
