<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Statistiche']" [title]="'Statistiche'" [loading]="false" [withoutBorder]="true">
  <nes-portlet-title [title]="'Statistiche'">
  </nes-portlet-title>
  <p *ngIf="!statistics">
    Calcolo in corso...
  </p>
  <table *ngIf="statistics" class="statistics">
    <tr>
      <td colspan="2"></td>
      <td *ngFor="let contract of contracts">{{ contract }}</td>
    </tr>

    <tr>
      <td rowspan="6">Entrati</td>
      <td>oggi</td>
      <td *ngFor="let value of arrivedToday">{{ value }}</td>
    </tr>
    <tr>
      <td>ieri</td>
      <td *ngFor="let value of arrivedYesterday">{{ value }}</td>
    </tr>
    <tr>
      <td>questo mese</td>
      <td *ngFor="let value of arrivedThisMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>il mese scorso</td>
      <td *ngFor="let value of arrivedLastMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>quest'anno</td>
      <td *ngFor="let value of arrivedThisYear">{{ value }}</td>
    </tr>
    <tr>
      <td>l'anno scorso</td>
      <td *ngFor="let value of arrivedLastYear">{{ value }}</td>
    </tr>
    
    <tr>
      <td rowspan="6">Usciti</td>
      <td>oggi</td>
      <td *ngFor="let value of leftToday">{{ value }}</td>
    </tr>
    <tr>
      <td>ieri</td>
      <td *ngFor="let value of leftYesterday">{{ value }}</td>
    </tr>
    <tr>
      <td>questo mese</td>
      <td *ngFor="let value of leftThisMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>il mese scorso</td>
      <td *ngFor="let value of leftLastMonth">{{ value }}</td>
    </tr>
    <tr>
      <td>quest'anno</td>
      <td *ngFor="let value of leftThisYear">{{ value }}</td>
    </tr>
    <tr>
      <td>l'anno scorso</td>
      <td *ngFor="let value of leftLastYear">{{ value }}</td>
    </tr>

    <tr>
      <td rowspan="6">DDT</td>
      <td>oggi</td>
      <td [attr.colspan]="contractsCount">{{ statistics.documentToday }}</td>
    </tr>
    <tr>
      <td>ieri</td>
      <td [attr.colspan]="contractsCount">{{ statistics.documentYesterday }}</td>
    </tr>
    <tr>
      <td>questo mese</td>
      <td [attr.colspan]="contractsCount">{{ statistics.documentThisMonth }}</td>
    </tr>
    <tr>
      <td>il mese scorso</td>
      <td [attr.colspan]="contractsCount">{{ statistics.documentLastMonth }}</td>
    </tr>
    <tr>
      <td>quest'anno</td>
      <td [attr.colspan]="contractsCount">{{ statistics.documentThisYear }}</td>
    </tr>
    <tr>
      <td>l'anno scorso</td>
      <td [attr.colspan]="contractsCount">{{ statistics.documentLastYear }}</td>
    </tr>
  </table>
  <button type="button" mat-button [disableRipple]="true" (click)="requestStatistics()" class="form-submit-button" *ngIf="statistics">
    <span class="label">Aggiorna</span>
  </button>
</nes-portlet>
