import { Component, OnInit, Inject, Input, ViewChild, ElementRef, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { IDossiersService, DOSSIERS_SERVICE, IDossierType } from '../../services/dossiers-service.service.interface';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IServiceAreaPositionDetails } from 'src/app/modules/service-area-mapping/position';
import { IDossierMovementSourceAndDestinationControlsOrder, DossierMovementSourceAndDestinationControlsComponent } from '../../shared/components/dossier-movement-source-and-destination-controls/dossier-movement-source-and-destination-controls.component';
import { ADossier, ExgressDossier, ProcessingDossier, IngressDossier, IDossierVehicleTransportDetails, DossierVehicleTransportType } from '../../shared/interfaces/dossier.type';
import * as moment from 'moment';
import { Subscription } from 'rxjs';


interface IPossibleDossierDataFields {
  customer?: number;
  date?: Date;
  time?: string;
  completionDate?: string;
  occurrenceDate?: string;
  customerCompanyName?: string;
  discoveryDate?: string;
  expiryDate?: string;
  isTimeConfirmed?: boolean;
  notes?: string;
  vehicleTransport?: {
    readonly type: DossierVehicleTransportType,
    details: {
      shipName?: string;
      trainNumber?: string;
      licensePlates?: string[];
    }
  };
}

@Component({
  selector: 'nes-dossier-details-general',
  templateUrl: './dossier-details-general.component.html',
  styleUrls: ['./dossier-details-general.component.scss']
})


export class DossierDetailsGeneralComponent implements OnInit, OnDestroy {
  @ViewChild('movementPositionControls') controls: DossierMovementSourceAndDestinationControlsComponent;
  @Input() dossierType: string;
  @Input()  get data(): ADossier {
              return this._data
            };
            set data(value: ADossier){
              this._data = value;
              this.updateFormData();
            };

  @Input() get editable(): boolean{
              return this.areFieldsEditable;
           };
           set editable(value: boolean){
             if(this.formData !== undefined ) this.setFieldsEditability(value);
             this.areFieldsEditable = value;
           };

  get formColumns(): string[] {
    if( this.dossierType === 'ingress'){
      return ['customer', 'arrivalOrDepartureDate', 'arrivalHour', 'isDateConfirmed', 'completionDate', 'vehicleTransport'];
    }else if( this.dossierType === 'exgress'){
      return ['customer', 'arrivalOrDepartureDate', 'departureHour', 'isDateConfirmed', 'completionDate', 'vehicleTransport', 'notes'];
    }else if(this.dossierType === 'damage'){
      return ['occurrenceDate', 'discoveryDate'];
    }else if(this.dossierType === 'processing'){
      return ['customer', 'completionDate', 'expiryDate'];
    }else if(this.dossierType === 'moving'){
      return ['expiryDate'];
    }
    return [];
  }
  dossierTypes: IDossierType[];
  formData: FormGroup;
  _data: ADossier = null;
  areFieldsEditable: boolean = true;
  fieldsData: IPossibleDossierDataFields;
  get dossierTypeLabel(): string{
    if(!!this.dossierType && !!this.dossierTypes)
      return this.dossierTypes.find((type) => type.slug === this.dossierType).name;

    return ''
  }

  get isCustomerFieldRequired(): boolean{

    let customerField = this.formData.controls['customer'];

    return customerField.touched && customerField.hasError('required');
  }

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(DOSSIERS_SERVICE) private dService: IDossiersService)
    { }


  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dService.getDossierTypes().subscribe(dossierTypes => this.dossierTypes = dossierTypes)
    );
    this.updateFormData();
  }

  private updateFormData(): void{
    if(!!this.data) this.generateFieldSet(this._data);
    this.generateForm();
    if(!!this.data) this.setFieldsEditability(this.areFieldsEditable ? this.areFieldsEditable : false);
  }

  private generateForm(): void{
    let columns = this.formColumns;
    this.formData = this.fb.group({});

    let emptyLocation = {
      area: null,
      sector: null,
      row: null
    }
    // customer field
    if(!!columns.includes('customer') && !this.formData.get('customer')){
      this.formData.addControl('customer', new FormControl(!!this.fieldsData && !!this.fieldsData.customerCompanyName ? this.fieldsData.customerCompanyName : null , Validators.required));
    }

    // notes field
    if(!!columns.includes('notes') && !this.formData.get('notes')){
      this.formData.addControl('notes', new FormControl({
        value: !!this.fieldsData && !!this.fieldsData.notes ? this.fieldsData.notes : null,
        disabled: !this.editable
      }));
    }

    // vinNumbers field
    if(!!columns.includes('vinNumbers')){
      this.formData.addControl('vinNumbers', new FormControl('', Validators.required));
    }

    // completionDate field
    if(!!columns.includes('completionDate')){
      this.formData.addControl('completionDate', new FormControl(!!this.fieldsData && !!this.fieldsData.completionDate ? this.fieldsData.completionDate : null));
    }

    // date field
    if(!!columns.includes('arrivalOrDepartureDate')){
      this.formData.addControl('arrivalOrDepartureDate',
      new FormControl(
        !!this.fieldsData && !!this.fieldsData.date ? this.fieldsData.date : new Date(new Date().setHours(0,0,0,0)),
        Validators.required
      )
    );
    }

    if(!!columns.includes('occurrenceDate')){
      this.formData.addControl('occurrenceDate',
        new FormControl(
          !!this.fieldsData && !!this.fieldsData.occurrenceDate ? this.fieldsData.occurrenceDate : null
        )
      );
    }

    if(!!columns.includes('discoveryDate')){
      this.formData.addControl('discoveryDate',
        new FormControl(
          !!this.fieldsData && !!this.fieldsData.discoveryDate ? this.fieldsData.discoveryDate : null
        )
      );
    }

    if(!!columns.includes('expiryDate')){
      this.formData.addControl('expiryDate',
        new FormControl(
          !!this.fieldsData && !!this.fieldsData.expiryDate ? this.fieldsData.expiryDate : null, Validators.required
        ),
      )
    }

    //hour field
    if(!!columns.includes('arrivalHour')){
      this.formData.addControl('arrivalHour', new FormControl(
        !!this.fieldsData && !!this.fieldsData.time ? this.fieldsData.time : '00:00',
        Validators.required));
    }

    //hour field
    if(!!columns.includes('departureHour')){
      this.formData.addControl('departureHour', new FormControl(
        !!this.fieldsData && !!this.fieldsData.time ? this.fieldsData.time : '00:00',
        Validators.required));
    }

    //isDateConfirmed field
    if(!!columns.includes('isDateConfirmed')){
      this.formData.addControl('isDateConfirmed',
      new FormControl(
        {
          value: !!this.fieldsData && !!this.fieldsData.isTimeConfirmed ? this.fieldsData.isTimeConfirmed : false,
          disabled: !this.editable
        }
      ));
    }

    //vehicleTransport field
    if(!!columns.includes('vehicleTransport')){
      let vehicleTransportFormGroup: FormGroup = new FormGroup({
        type: new FormControl(null, Validators.required),
        details: new FormGroup({
          carTransporterDetails: new FormGroup({
            fieldValue: new FormControl({value: null, disabled: true}),
            licensePlates: new FormControl({value: [], disabled: true}, [Validators.required]),
          }),
          shipName: new FormControl({value: null, disabled: true}, [Validators.required]),
          trainNumber: new FormControl({value: null, disabled: true}, [Validators.required])
        })
      })


      if(!!this.fieldsData && !!this.fieldsData.vehicleTransport){
        vehicleTransportFormGroup = new FormGroup({
          type: new FormControl(this.fieldsData.vehicleTransport.type, Validators.required),
          details: new FormGroup({
            carTransporterDetails: new FormGroup({
              fieldValue: new FormControl({value: null, disabled: this.fieldsData.vehicleTransport.type !== 0}),
              licensePlates: new FormControl({value: !!this.fieldsData.vehicleTransport.details.licensePlates ? this.fieldsData.vehicleTransport.details.licensePlates : [],
                                              disabled: false}, [Validators.required]),
            }),
            shipName: new FormControl({value: !!this.fieldsData.vehicleTransport.details.shipName ? this.fieldsData.vehicleTransport.details.shipName : null,
                                       disabled: this.fieldsData.vehicleTransport.type !== 1}, [Validators.required]),
            trainNumber: new FormControl({value: !!this.fieldsData.vehicleTransport.details.trainNumber ? this.fieldsData.vehicleTransport.details.trainNumber : null,
                                          disabled: this.fieldsData.vehicleTransport.type !== 2}, [Validators.required])
          })

        })
      }

      this.formData.addControl('vehicleTransport', vehicleTransportFormGroup);
    }
  }

  private generateFieldSet(data): void{
    let tempValues = {
    }

    if(!!data){
      if(!!data.customer) tempValues['customer'] = data.customer;
      if(!!data.arrivalDateAndTime) {
        tempValues['date'] = new Date(moment(data.arrivalDateAndTime).format('YYYY-MM-DD'));
        tempValues['time'] = moment(data.arrivalDateAndTime).format('HH:mm');
      }
      if(!!data.departureDateAndTime) {
        tempValues['date'] = new Date(moment(data.departureDateAndTime).format('YYYY-MM-DD'));
        tempValues['time'] = moment(data.departureDateAndTime).format('HH:mm');
      }
      if(!!data.completionDate) {
        tempValues['completionDate'] = new Date(moment(data.completionDate).format('YYYY-MM-DD'));
      }
      if(!!data.expiryDate) {
        tempValues['expiryDate'] = new Date(moment(data.expiryDate).format('YYYY-MM-DD'));
      }
      if(!!data.occurrenceDate) {
        tempValues['occurrenceDate'] = new Date(moment(data.occurrenceDate).format('YYYY-MM-DD'));
      }
      if(!!data.discoveryDate) {
        tempValues['discoveryDate'] = new Date(moment(data.discoveryDate).format('YYYY-MM-DD'));
      }
      if(data.customerCompanyName) {
        tempValues['customerCompanyName'] = data.customerCompanyName;
      }
      if(data.isTimeConfirmed !== undefined) tempValues['isTimeConfirmed'] = data.isTimeConfirmed;
      if(!!data.vehicleTransport) tempValues['vehicleTransport'] = data.vehicleTransport;
    }
    this.fieldsData = tempValues;
  }

  private setFieldsEditability(value: boolean): void{
    if(!value){
      Object.keys(this.formData.controls).forEach(field => {
        const control = this.formData.get(field);
        if(!control.hasOwnProperty('controls')){
          control.disable({ onlySelf: true });
        }
      });
      if(!this.formData.get('vehicleTransport')){
        this.formData.disable();
      }
    }else{
      Object.keys(this.formData.controls).forEach(field => {
        const control = this.formData.get(field);
        if(!control.hasOwnProperty('controls')){
          control.enable({ onlySelf: true });
        }
      });
      if(!this.formData.get('vehicleTransport')){
        this.formData.enable();
      }
    }
  }

  public onSave(): FormGroup{
    return this.formData;
  }

  public isFieldVisible(field: string): boolean{
    return !!this.formData.get(field);
  }
}
