<button class="nes_close_btn" mat-icon-button (click)="onClose()"><mat-icon>clear</mat-icon></button>
<nes-dialog [breadcrumb]="breadcrumbs" [title]="title">


    <vehicle-details-general #vehicleCreationForm [formOnly]="true" [externalControls]="true" (onSaveResult)="handleSave($event);"></vehicle-details-general>
  
    <div class="nes-dialog-footer-content not-border" dialog-footer>
      <button [disableRipple]="true" class="form-undo-button" mat-button (click)="onSwitchToSelection()">ANNULLA</button>
      <button [disableRipple]="true" class="form-submit-button" mat-button (click)="onSave()">AGGIUNGI</button>
    </div>
  
</nes-dialog>