import { Component, OnInit, Inject } from '@angular/core';
import { IDossiersService, DOSSIERS_SERVICE, IDossierType } from '../../services/dossiers-service.service.interface';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dossier-type-selection-dialog',
  templateUrl: './dossier-type-selection-dialog.component.html',
  styleUrls: ['./dossier-type-selection-dialog.component.scss']
})
export class DossierTypeSelectionDialogComponent implements OnInit {

  dossierTypes: IDossierType[] = null;
  formData: FormGroup = this.fb.group({
    type: [null, [Validators.required]]
  });

  constructor(
    public dialogRef: MatDialogRef<DossierTypeSelectionDialogComponent>,
    private fb: FormBuilder,
    @Inject(DOSSIERS_SERVICE) private dService: IDossiersService
    ){}

  ngOnInit(): void {
    this.dService.getDossierTypes().subscribe(dossierTypes => this.dossierTypes = dossierTypes);
  }


  public confirmSelection() {
    if (!!this.formData.valid) {
      this.close(this.formData.get('type').value);
    } else {
      this.formData.get('type').markAsTouched({ onlySelf: true });
    }
  }

  public cancel() {
    this.dialogRef.close(null);
  }

  public close(type: object): void{
    this.dialogRef.close({ type });
  }
}
