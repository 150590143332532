<nes-dialog [breadcrumb]="breadcrumbs" [title]="title">

    <nes-portlet-filter-bar (search)="applyFilter($event)">
    </nes-portlet-filter-bar>

    <nes-portlet-table [dataSource]="customers">
      <mat-table [dataSource]="customers" >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="col-60">Commitente</mat-header-cell>
          <mat-cell *matCellDef="let row" class="col-60">{{ row.value }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="customerColumns"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: customerColumns;" (click)="selectCustomer($event, row)" [ngClass]="{'highlight': hasToHighlight(row)}"></mat-row>
      </mat-table>

    </nes-portlet-table>

    <div class="nes-dialog-footer-content not-border" dialog-footer>
      <button [disableRipple]="true" class="form-undo-button" mat-button (click)="activateCancel()">Annulla</button>
      <button [disableRipple]="true" class="form-submit-button" mat-button *ngIf="hasTarget" (click)="activateConfirm()">Seleziona</button>
    </div>

</nes-dialog>
