<mat-form-field
  *ngIf="!disableInput"
  [class.hasError]="!!requiredError"
>
    <mat-label *ngIf="!!label">{{label}}</mat-label>
    <span matSuffix *ngIf="_value.lane !== null && !!enableOccupation">
      veicoli {{laneOccupation !== null ? laneOccupation : "_"}} / {{laneLength !== null ? laneLength : "_"}}
    </span>
    <input
    matInput
    [placeholder]="!!placeholder ? placeholder : 'Seleziona posizione'"
    [value]="inputLabel"
    readonly
    (click)="openDialog()">
</mat-form-field>
<div *ngIf="disableInput" (click)="openDialog()">
  <ng-content></ng-content>
</div>
