import { IServiceAreaPositionDetails } from 'src/app/modules/service-area-mapping/position';
import { Guid } from 'guid-typescript';
import { SubjectListDTOResponse } from 'src/app/modules/registry/common/interfaces/registry.type';
import { Paginator } from 'src/app/common/interfaces/paginator.type';
import * as moment from 'moment';

export interface IDossierVehicleTransportDetails {
  readonly type: DossierVehicleTransportType;
  details: ITransportTypeDetails;
}

export abstract class ADossierVehicleTransportDetail<TDetailType> implements IDossierVehicleTransportDetails {
  abstract get type(): DossierVehicleTransportType;
  details: TDetailType;
  protected constructor(details: TDetailType){
    this.details = details;
  }
}

export class ShipTransportDetails extends ADossierVehicleTransportDetail<IDossierShipDetails>
{
  get type(): DossierVehicleTransportType { return DossierVehicleTransportType.Ship; }

  constructor(details: IDossierShipDetails){
    super(details);
  }
}

export class TrainTransportDetails extends ADossierVehicleTransportDetail<IDossierTrainDetails>
{
  get type(): DossierVehicleTransportType { return DossierVehicleTransportType.Train; }

  constructor(details: IDossierTrainDetails){
    super(details);
  }
}

export class CarTransporterTransportDetails extends ADossierVehicleTransportDetail<IDossierCarTransporterDetails>
{
  get type(): DossierVehicleTransportType { return DossierVehicleTransportType.CarTransporter; }

  constructor(details: IDossierCarTransporterDetails){
    super(details);
  }
}

export enum DossierVehicleTransportType{
  CarTransporter = 0,
  Ship = 1,
  Train = 2
}


export interface ITransportTypeDetails {}

export interface IDossierShipDetails extends ITransportTypeDetails {
  shipName: string;
}

export interface IDossierTrainDetails extends ITransportTypeDetails {
  trainNumber: string;
}

export interface IDossierCarTransporterDetails extends ITransportTypeDetails {
  licensePlates: string[];
}

export abstract class ADossier {
  id: string;
  completionDate: string;
  code: string;

  setCompletionDate(value: Date) {
    this.completionDate = moment(new Date(value)).toISOString(true);
  }

  get type(): string { return this._type; };

  is(type: string): boolean {
    return this._type === type;
  }

  protected constructor(private _type: string, id: string = '', code: string = '') {
    this.id = id;
    this.code = code;
  }
}

export abstract class ADossierWithCustomer extends ADossier {
  customer: number;
  customerCompanyName: string = null;

  protected constructor(type: string, id: string = '', code: string) {
    super(type, id, code);
  }
}

export class IngressDossier extends ADossierWithCustomer {
  customer: number;
  arrivalDateAndTime: string;
  isTimeConfirmed: boolean;
  vehicleTransport: IDossierVehicleTransportDetails;
  destination: IServiceAreaPositionDetails;

  constructor(id: string, code: string, customer: number, arrivalDateAndTime:string , isTimeConfirmed: boolean, vehicleTransport: IDossierVehicleTransportDetails, customerCompanyName: string = null){
    super("ingress", id, code);
    this.customer = customer;
    this.customerCompanyName = customerCompanyName;
    this.arrivalDateAndTime = arrivalDateAndTime;
    this.isTimeConfirmed = isTimeConfirmed;
    this.vehicleTransport = vehicleTransport;
  }
}

export class ExgressDossier extends ADossierWithCustomer {
  customer: number;
  departureDateAndTime: string;
  isTimeConfirmed: boolean;
  vehicleTransport: IDossierVehicleTransportDetails;
  notes: string = '';
  constructor(id: string, code: string, customer: number, departureDateAndTime:string , isTimeConfirmed: boolean, vehicleTransport: IDossierVehicleTransportDetails, notes: string = null, customerCompanyName: string = null){
    super('exgress', id, code);
    this.customer = customer;
    this.customerCompanyName = customerCompanyName;
    this.departureDateAndTime = departureDateAndTime;
    this.isTimeConfirmed = isTimeConfirmed;
    this.vehicleTransport = vehicleTransport;
    if (notes) this.notes = notes;
  }
}

export class ProcessingDossier extends ADossierWithCustomer {
  customer: number;
  expiryDate: string;
  activities: Guid[];
  constructor(id: string, code: string, customer: number, expiryDate:string, activities: Guid[], customerCompanyName: string = null) {
    super('processing', id, code);
    this.customer = customer;
    this.customerCompanyName = customerCompanyName;
    this.expiryDate = expiryDate ? moment(new Date(expiryDate)).toISOString(true) : null;
    this.activities = activities;
  }
}

// export class MovingDossier extends ADossier {
//     destination: IServiceAreaPositionDetails;
//     expiryDate: string;
//     constructor(destination: IServiceAreaPositionDetails, expiryDate:string ){
//         super('moving');
//         this.expiryDate = expiryDate;
//         this.destination = destination;
//     }
// }
//
// export class DamageDossier extends ADossier {
//     occurrenceDate: string;
//     discoveryDate: string;
//     constructor(occurrenceDate:string , discoveryDate:string ){
//         super('damage');
//         this.occurrenceDate = occurrenceDate;
//         this.discoveryDate = discoveryDate;
//     }
// }

export interface IDossierVehicleRel{
  vehicle: Guid;
  dossier: string;
}

export interface IDossierBillingSetting{
  id: Guid;
  refId: string | Guid;
  service: string;
  customer: number;
}

export interface IDossierDTO {
  id: Guid;
  code: string;
  type: string;
  customer: string;
  vehicles: number;
}

export interface IDossierFilters {
  searchTerm: string;
  page: number;
}

export class DossierFilteredResults{
  rows: IDossierDTO[];
  totalRows: number;

  constructor(rows: IDossierDTO[], totalRows: number)
  {
    this.rows = rows;
    this.totalRows = totalRows;
  }
}

export interface IDossiersListingDTOResponse {
  pageNumber: number,
  pageSize: number,
  pageCount: number,
  totalRowCount: number,
  rows: IDossiersListingItemDto[]
}

export interface IDossiersListingItemDto {
  id: string;
  completedAt: string;
  createdAt: string;
  customerCompanyName: string;
  customerId: number;
  dossierCode: string;
  dossierType: string;
  vehicles: { id: string, address: string, vin: string, licensePlate: string }[];
}

export interface PagedDossierListDTOResponse extends Paginator {
  rows: SubjectListDTOResponse[];
}

export interface IDossierDTOResponse {
  meanOfTransport: {
    code: string,
    id: string
  },
  arrivalTime: string | null,
  arrivalTimeConfirmed: true,
  id: string,
  dossierCode: string | null,
  customerId: string,
  vehicles: {
    id: string,
    licensePlate: string | null,
    vin: string | null
  }[],
  completedAt: string | null,
  createdAt: string
}

export interface IDossiersPaginatedList<DetailType> {
  rows: DetailType[];
  totalRows: number
}

export interface IDossierActivity {
  id: Guid,
  name: string
}

export interface IDossierActivityDTO {
  id: string,
  name: string,
  systemReserved: boolean
}
