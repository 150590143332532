<nes-dialog [breadcrumb]="breadcrumbs" [title]="title">
  
    <nes-portlet-filter-bar (search)="applyFilter($event)">
      <div class="nes-portlet-filter-bar-col">
        <mat-checkbox (change)="applyFilterCheckbox()" [(ngModel)]="tableFilter.company" [disableRipple]="true">Denominazione</mat-checkbox>
        <mat-checkbox (change)="applyFilterCheckbox()" [(ngModel)]="tableFilter.individual" [disableRipple]="true">Nome e Cognome</mat-checkbox>
        <mat-checkbox (change)="applyFilterCheckbox()" [(ngModel)]="tableFilter.publicAdministration" [disableRipple]="true">P.A.</mat-checkbox>
      </div>
    </nes-portlet-filter-bar>
  
    <nes-portlet-table [dataSource]="registryDatasource">
      <mat-table [dataSource]="registryDatasource" matSort matSortDisableClear="true" matSortActive="name" matSortDirection="desc">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef class="col-60">Nome e Cognome / Ragione sociale</mat-header-cell>
          <mat-cell *matCellDef="let row" class="col-60">{{ row.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef class="col-60">Tipo</mat-header-cell>
          <mat-cell *matCellDef="let row" class="col-60">{{ exposeType(row.type) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="headquarter">
          <mat-header-cell *matHeaderCellDef>Indirizzo sede legale</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ exposePlace(row.city, row.region) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="indicator">
          <mat-header-cell *matHeaderCellDef class="col-10"></mat-header-cell>
          <mat-cell *matCellDef="let row" class="col-10 icon-eye">
            <mat-icon [svgIcon]="'mda-eye'"></mat-icon>
          </mat-cell>
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="registryColumns"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: registryColumns;" (click)="selectRegistry($event, row)" [ngClass]="{'highlight': hasToHighlight(row)}"></mat-row>
      </mat-table>
      <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [length]="registryDatasource.pagination.rowCount" [pageSize]="registryDatasource.pagination.pageSize" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  
    </nes-portlet-table>
  
    <div class="nes-dialog-footer-content not-border" dialog-footer>
      <button [disableRipple]="true" class="form-undo-button" mat-button (click)="activateCancel()">ANNULLA</button>
      <button [disableRipple]="true" class="form-submit-button" mat-button *ngIf="hasTarget" (click)="activateConfirm()">AGGIUNGI</button>
    </div>
  
</nes-dialog>