import { Component, OnInit, OnDestroy, Inject} from '@angular/core';


import { Subscription } from 'rxjs';
import { DOSSIERS_SERVICE, IDossiersService, IDossierType } from '../services/dossiers-service.service.interface';
import { IDossierDTO, IDossierFilters } from '../shared/interfaces/dossier.type';
import { DossierDialogsService } from '../dossier-dialogs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dossier-list',
  templateUrl: './dossier-list.component.html',
  styleUrls: ['./dossier-list.component.scss']
})
export class DossierListComponent implements OnInit, OnDestroy{

  dossierList: IDossierDTO[] = [];
  dossierTypes: IDossierType[] = [];
  dossierFilters: IDossierFilters = {
    searchTerm: '',
    page: 0
  }
  resultsCount: number = 0;

  private subscriptions: Subscription[] = [];

  // --

  get dossierDetails() {
    return ['code', 'type', 'customer', 'vehicles', 'indicator'];
  }

  // --

  constructor(
    @Inject(DOSSIERS_SERVICE) private dService: IDossiersService,
    private router: Router,
    private ddService: DossierDialogsService
  ) {
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit(): void {
    this.fetchDossierList();
    this.fetchDossierTypes();
  }

  // --

  openCreationDialog(): void {
    this.ddService.openDossierTypeSelectionDialog()
  }

  public getDossierType(type: string): string{
    return this.dossierTypes.find(dossierType => dossierType.key === type).name;
  }

  private fetchDossierList(): void {
    this.subscriptions.push(
      this.dService.getDossiers(this.dossierFilters)
      .subscribe(dossiers => {
        if(!dossiers) return;
        this.resultsCount = dossiers.totalRows;
        this.dossierList = dossiers.rows;
      })
    );
  }

  private fetchDossierTypes(): void {
    this.subscriptions.push(
      this.dService.getDossierTypes()
      .subscribe(types => {
        if(!types) return;

        this.dossierTypes = types;
      })
    );
  }

  public selectDossier(code: string): void {
    this.router.navigate(["/pratiche", "dettaglio"], { queryParams: { id: code } })
  }

  public applyFilters(e: string): void {
    if (this.dossierFilters.searchTerm === e) return;
    this.dossierFilters.page = 0;
    this.dossierFilters.searchTerm = e;
    this.fetchDossierList();
  }

  public updatePagination(e): void {

    this.dossierFilters.page = e.pageIndex;
    this.fetchDossierList();
  }
}
