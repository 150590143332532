import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {IWorkOrdersService, WORK_ORDERS_SERVICE} from "../../shared/interfaces/work-orders.interface";
import {IWorkOrdersListFilters} from "../../shared/interfaces/work-orders-list-filters";
import {Subscription} from "rxjs";
import {PageEvent} from "@angular/material/paginator";
import {
  IServiceAreaMappingService,
  SERVICE_AREA_MAPPING_SERVICE
} from "../../../service-area-mapping/shared/services/service-area-mapping.service.interface";
import {IMapSection} from "../../../service-area-mapping/shared/interfaces/map.type";
import {ISectorGroup, IStructuredSector} from "../../../service-area-mapping/types/service-area-mapping";
import {WorkOrder} from "../../shared/classes/work-order";
import {RolesGuardHelper} from "../../../../shared/utils/roles-guard-helper";

@Component({
  selector: 'app-work-orders-list',
  templateUrl: './work-orders-list.component.html',
  styleUrls: ['./work-orders-list.component.scss']
})
export class WorkOrdersListComponent implements OnInit, OnDestroy {

  public loading: boolean = false;
  public workOrders: any[] = [];
  public sectors: IStructuredSector[] = [];
  public workOrdersCount: number = 0;
  public firstLoad: boolean = true;
  private subscriptions: Subscription[] = [];
  public workOrderDetails: string[] = ['status', 'label', 'vehicles', 'destination'];
  public workOrdersFilter: IWorkOrdersListFilters = {
    pageNumber: 0,
    pageSize: 10,
  };

  constructor(
    @Inject(WORK_ORDERS_SERVICE) private workOrdersService: IWorkOrdersService,
    @Inject(SERVICE_AREA_MAPPING_SERVICE) private serviceAreaMappingService: IServiceAreaMappingService
  ) { }

  // -- Lifecycle Hooks

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.fetchSectors();
    this.loadWorkOrders();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // -- Public Methods

  public loadWorkOrders(): void {
    this.loading = true;
    this.subscriptions.push(
      this.workOrdersService.getWorkOrders(this.workOrdersFilter).subscribe((workOrders) => {
        this.workOrders = workOrders.rows.map((row) => {
          return {
            id: row.id,
            date: row.date,
            label: row.label,
            sector: this.getSectorTitle(row.sector),
            lane: row.lane,
            vehicles: row.vehicles,
            ticked: row.ticked,
          }
        });
        this.workOrdersCount = workOrders.totalRows;
        // this.workOrdersCount = workOrders.rows.length;
        this.loading = false;
        this.firstLoad = false;
      })
    );
  }

  public getWorkOrderDestination(workOrder: WorkOrder): string {
    const destinationParts = [];
    if (workOrder.sector) destinationParts.push(workOrder.sector);
    if (workOrder.lane) destinationParts.push(workOrder.lane);
    return destinationParts.join(' -  ');
  }

  private getSectorTitle(id: string): string {
    const sector = this.sectors.find((sector) => sector.id === id);
    if (!sector) console.log(this.sectors);
    return sector ? sector.label : '';
  }

  private async fetchSectors(): Promise<void> {
    this.sectors = await this.serviceAreaMappingService.getSectorsWithDetail().toPromise();
    Promise.resolve();
  }

  updatePagination($event: PageEvent) {
    this.workOrdersFilter.pageNumber = $event.pageIndex;
    this.workOrdersFilter.pageSize = $event.pageSize;

    this.loadWorkOrders();
  }

  applyFilters($event: string) {}

  readonly RolesGuardHelper = RolesGuardHelper;
}
