<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Ordini di uscita']" [title]="'Lista ordini di uscita'" [loading]="firstLoad" class="work-orders-list">
  <nes-portlet-filter-bar (search)="applyFilters($event)" class="hide-search">
    <div class="nes-portlet-filter-bar-col">
      <button [disableRipple]="true" mat-button color="primary" class="flex_center" [routerLink]="['/ordini-di-uscita/nuovo/']" *ngIf="RolesGuardHelper.canWrite()">Aggiungi ordine di uscita</button>
    </div>
  </nes-portlet-filter-bar>
  <nes-portlet-table [dataSource]="workOrders">
    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="workOrders">

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>
              <mat-icon *ngIf="row.ticked">done</mat-icon>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Data creazione</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.date | date: 'dd/MM/y' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="destination">
          <mat-header-cell *matHeaderCellDef>Destinazione</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ getWorkOrderDestination(row) }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicles">
          <mat-header-cell *matHeaderCellDef>Veicoli</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.vehicles.length}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="workOrderDetails"></mat-header-row>
        <mat-row class="_selectable" [routerLink]="['/ordini-di-uscita/' + row.id]" *matRowDef="let row; columns: workOrderDetails;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator [showFirstLastButtons]="true" [hidePageSize]="true" [pageIndex]="workOrdersFilter.pageNumber" (page)="updatePagination($event)" [length]="workOrdersCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
  </nes-portlet-table>
</nes-portlet>
