import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MapViewComponent} from "./map-view/map-view.component";

const routes: Routes = [
    {
      path: '',
      component: MapViewComponent ,
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ServiceAreaMappingRoutingModule {
}
