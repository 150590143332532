<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Veicoli', 'Import']" [title]="'Import da file'" [loading]="false"
  [withoutBorder]="true">
  <nes-portlet-title [title]="'Seleziona file da caricare'">
  </nes-portlet-title>
  <form class="nes-portlet-form-wr" [formGroup]="importForm">
    <div class="nes-portlet-form-col _two">
      <div class="_input_group _added_border">
        <label>{{ fileName ? fileName : "File"}}</label>
        <button type="button" mat-button [disableRipple]="true" (click)="triggerFileInput()" class="form-action-button">
          <span class="label">Seleziona File</span>
        </button>
        <input type="file" #fileInput (change)="onFileSelected($event)">
      </div>
      <div class="_input_group">
        <mat-form-field class="full-width">
          <mat-label>Contratto</mat-label>
          <input matInput placeholder="" [formControl]="contractFormControl" [matAutocomplete]="autocompleteContract"
            (blur)="contractFormControl.updateValueAndValidity()">
        </mat-form-field>
        <mat-autocomplete #autocompleteContract="matAutocomplete" (optionSelected)="handleContractAutocomplete($event)">
          <mat-option *ngFor="let option of contractOptions" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
    <div class="nes-portlet-form-col _two">
      <div class="_input_group">
        <div class="field-container">
          <mat-form-field class="datepicker" *ngIf="!disableRestrictedInputs">
            <mat-label>Data Arrivo</mat-label>
            <input matInput [matDatepicker]="arrivalOrDepartureDate" formControlName="arrivalDate"
              (click)="arrivalOrDepartureDate.open()" readonly>
            <mat-datepicker-toggle [disableRipple]="true" [for]="arrivalOrDepartureDate">
              <nes-icon matDatepickerToggleIcon [svgIcon]="'blue_calendar'"></nes-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #arrivalOrDepartureDate disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="fill-available has-left-toggle" *ngIf="toggledDateColumnInput">
            <mat-label>Indice colonna data arrivo</mat-label>
            <input matInput type="number" [min]="1" formControlName="arrivalDateColumn"
              (blur)="importForm.controls['arrivalDateColumn'].updateValueAndValidity()">
          </mat-form-field>
          <br>
          <mat-error *ngIf="isArrivalDateColumnFieldRequired && toggledDateColumnInput" class="standalone">Campo
            <b>obbligatorio</b></mat-error>
          <mat-error
            *ngIf="!isArrivalDateColumnFieldRequired && !isArrivalDateColumnFieldValid && toggledDateColumnInput"
            class="standalone"><b>Valore</b> non valido</mat-error>
        </div>
      </div>
      <div class="_input_group">
        <mat-form-field *ngIf="!disableRestrictedInputs">
          <mat-label>Mezzo di entrata</mat-label>
          <mat-select formControlName="arrivedBy">
            <mat-option value="Truck">Bisarca</mat-option>
            <mat-option value="Ship">Nave</mat-option>
            <mat-option value="Rail">Treno</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="nes-portlet-form-submit outside">
    <button type="button" mat-button [disableRipple]="true" (click)="onUpload()" class="form-submit-button"
      [disabled]="!file || loading || (!disableRestrictedInputs && !importForm.get('arrivedBy').valid)">
      <span class="label">Carica <mat-spinner *ngIf="loading" diameter="18" color="accent"></mat-spinner></span>
    </button>
  </div>
</nes-portlet>
<nes-portlet [breadcrumb]="[null]" [title]="'Esito importazione'" [loading]="false" [withoutBorder]="false"
  *ngIf="!!importResult">
  <mat-list>
    <mat-list-item class="result-list-item state_success" *ngIf="!!importResult.succeeded">
      <mat-icon class="heading-icon" matListItemIcon>check_circle</mat-icon>
      <div matListItemTitle>{{importResult.succeeded}} {{importResult.succeeded < importResult.total ? ' su ' +
          importResult.total : '' }} veicoli importati.</div>
    </mat-list-item>
    <mat-list-item class="result-list-item state_warning" *ngIf="!!importResult.alreadyExists">
      <mat-icon class="heading-icon" matListItemIcon>error</mat-icon>
      <div matListItemTitle>{{importResult.alreadyExists}} {{importResult.alreadyExists === 1 ? 'veicolo' : 'veicoli'}}
        già presenti nell'archivio.</div>
    </mat-list-item>
    <mat-list-item class="result-list-item state_error" *ngIf="!!importResult.failed">
      <mat-icon class="heading-icon" matListItemIcon>dangerous</mat-icon>
      <div matListItemTitle>{{importResult.failed}} {{importResult.failed === 1 ? 'veicolo' : 'veicoli'}} non sono stati
        importati per errori d'inserimento.</div>
    </mat-list-item>
  </mat-list>
</nes-portlet>
<nes-portlet [breadcrumb]="[null]" [title]="'Dettaglio importazione'" [loading]="false" [withoutBorder]="false"
  *ngIf="!!importResponse">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let c of importResponse; let i = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon
            [className]="'heading-icon ' + (c.completedItems < c.values.length ? c.completedItems === 0 ? 'state_error' : 'state_warning' : 'state_success')">
            {{c.completedItems < c.values.length ? c.completedItems===0 ? 'dangerous' : 'error' : 'check_circle' }}
              </mat-icon>
              <span *ngIf="!disableRestrictedInputs">Vagone&nbsp; </span> {{c.key}}
        </mat-panel-title>
        <mat-panel-description>
          {{c.completedItems}}/{{c.values.length}} telai importati
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-list role="list">
        <mat-list-item role="listitem" *ngFor="let v of c.values">
          <mat-icon matListItemIcon
            [className]="'heading-icon ' + (v.status !== 'CREATED' ? v.status === 'ERROR' ? 'state_error' : 'state_warning' : 'state_success')">
            {{v.status !== 'CREATED' ? v.status === 'ERROR' ? 'dangerous' : 'error' : 'check_circle'}}
          </mat-icon>
          {{v.vin}}
          <span class="spacer" *ngIf="v.status !== 'CREATED'">
            -
          </span>
          <span [className]="'reason ' + (v.status !== 'ERROR' ? 'state_warning' : 'state_error')"
            *ngIf="v.status !== 'CREATED'">
            {{mapStatus(v.status)}}
          </span>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</nes-portlet>