<nes-header></nes-header>
<nes-portlet [breadcrumb]="['Home', 'Veicoli', 'Blocchi']" [title]="title" [loading]="loading" [withoutBorder]="true" [class]="'vehicle-blockages-manager'">
  <nes-portlet-title [title]="'Dettagli operazione'">
  </nes-portlet-title>
  <div class="nes-portlet-form-wr">
    <div class="nes-portlet-form-col _two">
      <div class="_input_group">
        <mat-label>Operazione</mat-label>
        <mat-button-toggle-group [formControl]="operationType" >
          <mat-button-toggle value="block">Blocca</mat-button-toggle>
          <mat-button-toggle value="unblock">Sblocca</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="nes-portlet-form-col _two">
      <div class="_input_group _auto-flex">
        <mat-form-field>
          <input matInput placeholder="Codice blocco" [formControl]="blockageCode" class="full-width">
        </mat-form-field>
      </div>
    </div>
  </div>

  <nes-portlet-title [title]="vehicleSectionTitle">
  </nes-portlet-title>
  <nes-portlet-table [dataSource]="vehicles" [class]="{'_empty': vehicleListLength === 0}">
    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="vehicles">
        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Telaio / targa</mat-header-cell>
          <mat-cell *matCellDef="let row">
              <span *ngIf="row.type === 'input'" class="vehicle_search_list">
                <mat-form-field class="fill-available">
                  <mat-label>Vin</mat-label>
                  <input matInput
                         [formControl]="vehicleSearch"
                         (paste)="handlePaste($event)"
                         [matAutocomplete]="autocompleteSearch"
                         (blur)="vehicleSearch.updateValueAndValidity()"
                  >
                  <mat-spinner matSuffix [strokeWidth]="2" [diameter]="20" *ngIf="loadingSearch"></mat-spinner>
                </mat-form-field>
                <br>
                <mat-autocomplete #autocompleteSearch="matAutocomplete"
                                  (optionSelected)="handleSearchAutocomplete($event)">
                  <mat-option *ngFor="let option of vehicleSearchResults" [value]="option.vin">
                    {{ retrieveVehicleLabel(option) }}
                  </mat-option>
                </mat-autocomplete>
              </span>
            <span *ngIf="row.type !== 'input'">
                {{ retrieveVehicleLabel(row.vehicle) }}
              </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef>Marca / modello</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ retrieveVehicleDetails(row.vehicle) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="" *ngIf="row.type !== 'input'">
              <mat-icon *ngIf="row.vehicle.ticked">done</mat-icon>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef>Posizione</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ retrieveVehiclePosition(row.vehicle) }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="color">
          <mat-header-cell *matHeaderCellDef>Colore</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.type !== 'input'">
              {{ row.vehicle.color }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="destination_address">
          <mat-header-cell *matHeaderCellDef>Indirizzo destinazione</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span *ngIf="row.type !== 'input'">
              {{ row.vehicle.destinationAddress }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button aria-label="Cancella" *ngIf="row.type !== 'input'"
                    (click)="removeVehicle(row.vehicle)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="vehicleDetails"></mat-header-row>
        <mat-row *matRowDef="let row; columns: vehicleDetails;" [class]="{'_input': row.type === 'input'}"></mat-row>
      </mat-table>
    </div>
  </nes-portlet-table>
  <nes-portlet-footer>
    <button type="button" mat-button [disableRipple]="true" (click)="submit()" class="form-submit-button" [disabled]="vehicleListLength === 0 || !blockageCode.valid">
      {{title}}
    </button>
  </nes-portlet-footer>
</nes-portlet>
