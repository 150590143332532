<button class="nes_close_btn" mat-icon-button (click)="close()"><mat-icon>clear</mat-icon></button>
<nes-dialog [title]="'Danni veicolo'" [withoutBorder]="false">
  <nes-portlet-table [dataSource]="damages">
    <div class="table-container">
      <div class="table-loading-shade" *ngIf="loading">
        <mat-spinner [diameter]="26" *ngIf="loading"></mat-spinner>
      </div>
      <mat-table [dataSource]="damages">
        <ng-container matColumnDef="positionCode">
          <mat-header-cell *matHeaderCellDef>Codice posizione</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.positionCode }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="damageCode">
          <mat-header-cell *matHeaderCellDef>Codice danno</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.damageCode }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="severityCode">
          <mat-header-cell *matHeaderCellDef>Codice gravità</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.severityCode }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="note">
          <mat-header-cell *matHeaderCellDef>Note</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row.note }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row class="_selectable" *matRowDef="let row; columns: tableColumns;"></mat-row>
      </mat-table>
    </div>
  </nes-portlet-table>
  <div class="form-footer">
    <button [disableRipple]="true" class="flex_center" color="accent" mat-button (click)="print()">Stampa</button>
    <button [disableRipple]="true" mat-button (click)="close()">Chiudi</button>
  </div>
</nes-dialog>

<!--
<p>
  Vehicle Loss and Damage Report<br>
  Meldung über Transportschäden /- verluste<br>
  Informe de Danos y Faltas
</p>
<p>
  AIAG Coding
</p>
<p>
  Vehicle´s Destination Country/Bestimmungsland/Pais de Destino del Vehiculo
</p>
<table class="damages-print">
  <tr>
    <td>
      <img class="damages-print-image" src="/assets/images/damages-print-image.png">
    </td>
    <td>
      <img class="damages-print-table" src="/assets/images/damages-print-table.png">
    </td>
  </tr>
</table>
<table class="damages-print">
  <tr>
    <td>Model</td>
    <td>{{ vehicle.model }}</td>
  </tr>
  <tr>
    <td>Serial No.</td>
    <td>{{ vehicle.vin }}{{ vehicle.licensePlate }}</td>
  </tr>
</table>
<table class="damages-print">
  <tr>
    <td>Location of Inspection<br>Ort der Prüfung<br>Lugar de Inspeccion</td>
    <td colspan="4">Venezia</td>
  </tr>
  <tr>
    <td></td>
    <td>Serial No.</td>
    <td>Position code</td>
    <td>Damage code</td>
    <td colspan="4">Severity code</td>
  </tr>
</table>
<table class="damages-print">
  <tr>
    <td rowspan="2">Mode of Arrival<br>Beförderungsmittel<br>Medio de Transporte</td>
    <td colspan="3">Venezia</td>
  </tr>
  <tr>
    <td><img class="arrived-by" src="/assets/images/damages-print-truck.png"></td>
    <td><img class="arrived-by" src="/assets/images/damages-print-rail.png"></td>
    <td><img class="arrived-by" src="/assets/images/damages-print-ship.png"></td>
  </tr>
</table>
-->
