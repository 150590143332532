import {InjectionToken} from "@angular/core";
import {IUserData} from "../interfaces/user-data.interface";
import {ILoginRequest} from "../interfaces/request/login-request.interface";
import {IResetPasswordRequest} from "../interfaces/request/reset-password-request.interface";
import {IChangePasswordRequest} from "../interfaces/request/change-password-request.interface";
import {IAutoLoginRequest} from "../interfaces/request/auto-login-request.interface";

export const AUTHENTICATION_SERVICE: InjectionToken<IAuthenticationService> = new InjectionToken<IAuthenticationService>("Authentication Service Implementation");

export interface IAuthenticationService {
  getUserData(): IUserData;
  getToken(): string;
  saveToken(token: any): void;
  saveUserData(userData: IUserData): void;
  removeToken(): void;
  removeUserData(): void;
  isAuthenticated(): boolean;
  login(request: ILoginRequest): Promise<any>;
  logout(): Promise<any>;
  resetPassword(request: IResetPasswordRequest): Promise<any>;
  changePassword(request: IChangePasswordRequest): Promise<any>;
  autoLogin(request: IAutoLoginRequest): Promise<any>;
}
